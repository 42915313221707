import { react, useState } from 'react';
import { userRegistrationAction } from '../../action/Admin.action';
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, Form, Button } from 'react-bootstrap';

const UserRegistration = ({data, morn}) => {
    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true)
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let resp = await userRegistrationAction(formData);
            if (resp.code === 200) {
                toast.success(resp.msg);
                data(true);
                morn();

            } else {
                toast.error(resp.msg);
            }

        }
    }

    const handleChange = (name, event) => {
        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });
    }

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                        <Form.Group className="mb-2 mt-2" controlId="fullName">
                            <Form.Label>fullName<span className='star'>*</span></Form.Label>
                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name" onChange={e => handleChange('fullName', e)} value={formData.fullName ? formData.fullName : ""} minLength={2} maxLength={32} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid fullName.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="email">
                        <Form.Label>email<span className="star" >*</span></Form.Label>
                            <Form.Control
                                type="email"
                                autoComplete='false'
                                className="frmcnt"
                                pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                                placeholder="Email"
                                value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="phone">
                        <Form.Label>phone<span className='star'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="frmcnt"
                                placeholder="phone"
                                pattern='^[6-9][0-9]{9}$'
                                value={formData.phone ? formData.phone : ""} onChange={e => handleChange('phone', e)} minLength={10} maxLength={10} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid phone Number.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Password<span className="star" >*</span></Form.Label>
                            <Form.Control type='password' value={formData.password ? formData.password : ''} onChange={(e) => { handleChange('password', e) }} placeholder='password@****' required />
                        </Form.Group>
                        <Row className="mt-4 g-2">
                            <Col lg={12} className='text-end'>
                                <Button type="submit" className="vndrBtn" onSubmit={() => { handleSubmit() }}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default UserRegistration;