import react, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button, Card, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faShareNodes } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/home';
import { getCrewListAction, deleteAction } from "../../action/Admin.action";
import Pagination from '../common/Pagination';
import AddCrewMemeber from './AddCrewMember';
import { ShareButton } from '../../CrewThreeCrud/ShareCrewOptions';
// import { generateExcel } from '../../common/function';
import { generatePDF } from '../common/function';

const CrewList = () => {
    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [formData, setFormData] = useState({ page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" });
    const [startNum, setStartNum] = useState(0);
    const [editData, setEditData] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    let projectId = localStorage.getItem('projectId');

    // const generateExcel = () => {
    //     const workbook = new ExcelJS.Workbook();
    //     const worksheet = workbook.addWorksheet(`CrewList`);

    //     const headers = Object.keys(sheet[0]);

    //     const firstRowStyle = {
    //         alignment: { horizontal: 'center' },
    //         fill: {
    //             type: 'pattern',
    //             pattern: 'solid',
    //             fgColor: { argb: '20B2AA' },
    //         },
    //         font: {
    //             size: 11,
    //         },
    //     };

    //     headers.forEach((header, index) => {
    //         const cell = worksheet.getCell(1, index + 1);
    //         cell.value = header;
    //         cell.style = firstRowStyle;
    //     });

    //     for (let i = 0; i < sheet.length; i++) {
    //         const dataRow = sheet[i];
    //         headers.forEach((header, index) => {
    //             worksheet.getCell(i + 2, index + 1).value = dataRow[header];
    //         });
    //     }

    //     headers.forEach((header, index) => {
    //         const column = worksheet.getColumn(index + 1);
    //         let maxLength = header.length;
    //         sheet.forEach((dataRow) => {
    //             const cellValue = dataRow[header];
    //             if (cellValue) {
    //                 maxLength = Math.max(maxLength, String(cellValue).length);
    //             }
    //         });
    //         column.width = Math.max(maxLength + 2, header.length + 2);
    //     });

    //     const excelBlob = workbook.xlsx.writeBuffer().then((buffer) => {
    //         return new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     });

    //     const timestamp = new Date().toISOString();
    //     const excelName = `CrewList${timestamp}.xlsx`;

    //     return excelBlob.then((blob) => {
    //         saveAs(blob, excelName);
    //     });
    // };

    const decideType = (val) => {

    }

    const getList = async (action = '') => {
        setEditData({});
        let fData = { ...formData };
        if (action === 'clear') {

            fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC",projectId:projectId };
            setFormData(fData);
        }

        setStartNum(fData.page * fData.limit);

        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getCrewListAction(fData);
        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));

        if (resp.code === 200) {
            let newArry = resp.data;
            let a = (resp.data).map(item => {
                const { _id, name, designation, designationtName, department, departmentName, mobile, email } = item;
                return { _id, name, designation, designationtName, department, departmentName, mobile, email }
            });
            setDataList([...a]);
            setFormData({ ...formData, ...{ totalItems: resp.count } });
        }
    }

    const deleteHandele = async (Vid) => {
        let rsp = await deleteAction({ type: "crew", _id: Vid });
        if (rsp.code == 200) {
            getList();
        }
    }

    const formHandler = (e, field) => {

        let data = { ...formData };
        if (e.target) {
            data[field] = e.target.value;
        } else {
            data[field] = e;
        }
        setFormData(data);
    }

    useEffect(() => {
        getList();
    }, [formData.page])

    return (
        <>
            <div className='pt-4'>
                <Card className='crewCard'>
                    <Row className='mt-3 pt-2'>
                        {/* <Col lg={12}><AddCrewMemeber getList={e => getList()} editData={editData} /></Col> */}
                        <Col lg={6} className='col-12'><h6 className='crdHd1 mx-3'>CREW LIST</h6></Col>
                        <Col lg={6} className='col-12'>
                            <Form noValidate validated={validated} className='p-2 pt-0 pb-0'>
                                <Row className=''>
                                    <Col lg={6} className='lgsix p-1 mb-3'>
                                        <input type="text" placeholder='Search Name' className='BrdRm' onChange={e => formHandler(e, 'name')}></input><i onClick={e => getList(e)} className="fa fa-search"></i>
                                    </Col>
                                    <Col lg={3}>
                                        <Button className='vndrBtn' onClick={() => { setShow1(true) }}>+Add</Button>
                                    </Col>
                                    <Col lg={1} className='filePdf mt-2'>
                                        <FontAwesomeIcon className='hand' onClick={() => { generatePDF(dataList, 'CrewList') }} icon={faFilePdf} size="xl" title="Download" />
                                    </Col>
                                    <Col lg={2} className='filePdf mt-2 text-start' title="Share">
                                        <FontAwesomeIcon className='hand' onClick={() => { setShow(true) }} icon={faShareNodes} size="xl" />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <div><hr className='mrZero' /></div>
                    </Row>
                    <Row>
                        <Col md={12} className='table-container col-md-12'>
                            <table className="tbl table">
                                <thead className="headingtbl tbl2">
                                    <tr>
                                        <th className='tblHead text-center' scope="col">S.No.</th>
                                        <th className='tblHead' scope="col"> Name</th>
                                        <th className='tblHead' scope="col">Department</th>
                                        <th className='tblHead' scope="col">Designation</th>
                                        <th className='tblHead' scope="col">Mobile</th>
                                        <th className='tblHead' scope="col">Email</th>
                                        <th scope="col" colSpan="2" className='text-center tablData'>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="listing lis">
                                    {
                                        dataList && dataList.length > 0 && dataList.map((val, ind) => {
                                            return <tr key={ind}>
                                                <td style={{textAlign:"center"}} className='tblPr'>{startNum + ind + 1}</td>
                                                <td className='tblPr'>{val.name}</td>
                                                <td className='tblP'>{val && val.department != '000000000000000000000001' ? val.departmentName : "Actor/Actress"}</td>
                                                <td className='tblP'>{val && val.department != '000000000000000000000001' ? val.designationtName : 'Actor/Actress'}</td>
                                                {/* <td >{masters && masters.department && masters.department.length > 0 && masters.department.map((item, index) => {
                                                                if (val.department == item._id) {
                                                                    return (item.name)
                                                                }
                                                            })}</td>
                                                <td >{masters && masters.designation && masters.designation.length > 0 && masters.designation.map((item, index) => {
                                                                if (val.designation == item._id) {
                                                                    return (item.name)
                                                                }
                                                            })}</td> */}
                                                <td className='tblP'>{val.mobile}</td>
                                                <td className='tblP'>{val.email}</td>
                                                <td className='text-end'>
                                                    <i onClick={(e) => {setEditData(val);handleShow1()}} title="Edit" className="fa fa-pencil hand"></i>
                                                </td>
                                                <td><i onClick={() => { deleteHandele(val._id); }} title="Delete" className="icofont icofont-bin hand"></i></td>
                                            </tr>
                                        })
                                    }
                                    {dataList && dataList.length == 0 ? 'Data not avaialble.' : ''}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row className='justify-content-end mt-3 mb-3'>
                        <Col lg={3}>
                        <Pagination itemsPerPage={formData.limit} totalItems={formData.totalItems ? formData.totalItems : 0} setPage={e => setFormData({ ...formData, ...e })} />
                        </Col>
                    </Row>
                </Card>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header className="mdlHdr" closeButton>
                        <p>Share Via</p>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className='text-center'>
                            <Col >
                                <ShareButton data={dataList} url="https://web.whatsapp.com/send?text=" src="whatsapp" />
                            </Col>
                            <Col>
                                <ShareButton data={dataList} url="https://mail.google.com/send?text=" src="email" />
                            </Col>
                            <Col>
                                <ShareButton data={dataList} url="https://www.instagram.com" src="insta" />
                            </Col>
                            <Col>
                                <ShareButton data={dataList} url="https://twitter.com" src="twitter" />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header className='pbZero mb-2' closeButton >
                        <Modal.Title className='crdHd1'>Add New Crem Member</Modal.Title>
                    </Modal.Header>
                    <hr className='mrZero' />
                <AddCrewMemeber getList={e => getList()} editData={editData} />
                </Modal>
            </div>
        </>
    )
}

export default CrewList;