import { react, useState, useEffect } from "react";
import { Col, Form, Row, Button, Card } from "react-bootstrap";
import "../../index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { addProject } from "../../action/Admin.action.js";
import { setAlert, setProjectDetails } from "../../slices/home";
const AddProject = () => {
  const [file, setFile] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [isDisabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const masters = useSelector((state) => state.home.masters);

  const { state } = useLocation();
  console.log("state", state);
  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      //New
      let msg = formData._id
        ? "You have successfully updated the project."
        : "You have successfully created the project.";

      let postData = new FormData();
      for (let key in formData) {
        postData.append(key, formData[key]);
      }
      let resp = await addProject(postData);

      setDisabled(false);
      if (resp.code === 200) {
        dispatch(
          setAlert({ open: true, severity: "success", msg: msg, type: "" })
        );

        setTimeout(() => {
          if (formData._id) {
            navigate({
              pathname: `${process.env.PUBLIC_URL}/projectListing`,
              state: "",
            });
          } else {
            navigate(`${process.env.PUBLIC_URL}/projectDetails`);
          }
        }, 2000);
        localStorage.setItem("projectName", resp.data.name);
        localStorage.setItem("projectId", resp.data._id);
        localStorage.setItem("project", JSON.stringify(resp.data));
        dispatch(setProjectDetails(resp.data));
        // navigate(`${process.env.PUBLIC_URL}/projectDetails`);
      } else {
        dispatch(
          setAlert({ open: true, severity: "danger", msg: resp.msg, type: "" })
        );
      }
      return false;
    }
  };

  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    let formErrorsData = formErrors;

    let formDataData = formData;
    let file = event.target.files[0];
    setFile(URL.createObjectURL(event.target.files[0]));
    if (!file && file === undefined) {
      return false;
    }
    var fileName = file && file.name ? file.name : "";
    let extensions = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    if (file.size > 20971520) {
      formErrorsData[elename] = "File size not greater then 20MB.";
    } else if (
      extensions == "jpg" ||
      extensions == "png" ||
      extensions == "jpeg"
    ) {
      // formErrorsData[elename] = "";
      // formErrorsData["preview"] = "";
      formDataData["preview"] = URL.createObjectURL(event.target.files[0]);
      formDataData["fileType"] = extensions;
      formDataData[elename] = event.target.files[0];
      setFormData({ ...formData, ...formDataData });
    } else {
      formErrorsData[elename] = "File extensions doesn't match.";
    }
    setFormErrors({ ...formErrors, ...formErrorsData });
  };

  const handleChange = (post) => {
    let name = post.name;
    let event = post.event;
    let from = { ...formData };

    from[name] = event.target.value;

    setFormData({ ...formData, ...from });
  };

  const cancelBtn = () => {
    navigate({
      pathname: `${process.env.PUBLIC_URL}/projectListing`,
      state: "",
    });
  };

  useEffect(() => {
    // if (
    //   localStorage.getItem("projectName") &&
    //   localStorage.getItem("projectName") != ""
    // ) {
    //   // let project = getProject();
    //   // if(project.artiest.length === 0 || project.calendar.length === 0 || project.location.length === 0 || project.schedule.length === 0 || project.team.length === 0){
    //   //    setMasterModal(true);
    //   // }else if(project.file == ''){
    //   //    //window.location.href = "/importScript";
    //   // }else{
    //   //    window.location.href = "/project-details";
    //   // }
    // }

    //For Edit

    if (state && state._id) {
      let data = state;
      let editData = {
        _id: data._id,
        name: data.name,
        languageId: data.languageId,
        projectTypeId: data.projectTypeId,
        productionHouse: data.productionHouse,
        producerName: data.producerName,
        directorName: data.directorName,
        dop: data.dop,
        synopsis: data.synopsis,
        genreId: data.genreId,
        image: data.image,
      };
      setFormData(editData);
    }
  }, []);

  return (
    <>
      <div className="pt-4">
        <Row>
          <Col>
            <Card>
              <Card.Body className="crdBdy mx-3">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-1 mt-3 pt-2">
                    <Col xs="12" md="12">
                      <h6 className="crdHd1">
                        {formData._id ? "Update Project" : "Add New Project"}
                      </h6>

                      {/* <h6 className="crdHd1">Add Project</h6> */}
                      <hr />
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Label>
                        Project Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                        minLength={2}
                        maxLength={32}
                        className="filedbg brdr "
                        value={formData.name ? formData.name : ""}
                        onChange={(e) =>
                          handleChange({ name: "name", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Name.
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="12" md="4">
                      <Form.Label>
                        Language<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="filedbg brdr selectFnt"
                        value={formData.languageId ? formData.languageId : ""}
                        onChange={(e) =>
                          handleChange({ name: "languageId", event: e })
                        }
                        required
                      >
                        <option value="">Select Language Type</option>
                        {masters &&
                          masters.language &&
                          masters.language.length > 0 &&
                          masters.language.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid language.
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="12" md="4">
                      <Form.Label>
                        Project Type<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="filedbg brdr selectFnt"
                        value={
                          formData.projectTypeId ? formData.projectTypeId : ""
                        }
                        onChange={(e) =>
                          handleChange({ name: "projectTypeId", event: e })
                        }
                        required
                      >
                        <option value="">Select Project Type</option>
                        {masters &&
                          masters.projectType &&
                          masters.projectType.length > 0 &&
                          masters.projectType.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Provide a valid project type.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-1 mt-3 mt-4">
                    <Col xs="12" md="4">
                      <Form.Label>
                        Production House<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                        minLength={2}
                        maxLength={32}
                        className="filedbg brdr "
                        value={
                          formData.productionHouse
                            ? formData.productionHouse
                            : ""
                        }
                        onChange={(e) =>
                          handleChange({ name: "productionHouse", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Provide a valid Production House.
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="12" md="4">
                      <Form.Label>
                        Producer Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                        minLength={2}
                        maxLength={32}
                        className="filedbg brdr "
                        value={
                          formData.producerName ? formData.producerName : ""
                        }
                        onChange={(e) =>
                          handleChange({ name: "producerName", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Provide a valid Producer Name.
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="12" md="4">
                      <Form.Label>
                        Director Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                        minLength={2}
                        maxLength={32}
                        className="filedbg brdr "
                        value={
                          formData.directorName ? formData.directorName : ""
                        }
                        onChange={(e) =>
                          handleChange({ name: "directorName", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Provide a valid Director Name.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mb-1 mt-3 mt-4">
                    <Col xs="12" md="4">
                      <Form.Label>
                        Director of Photography<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                        minLength={2}
                        maxLength={32}
                        className="filedbg brdr "
                        value={formData.dop ? formData.dop : ""}
                        onChange={(e) =>
                          handleChange({ name: "dop", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid dop
                      </Form.Control.Feedback>
                    </Col>

                    <Col xs="12" md="8">
                      <Form.Label>Synopsis</Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                        minLength={2}
                        maxLength={250}
                        as="textarea"
                        className="filedbg brdr "
                        value={formData.synopsis ? formData.synopsis : ""}
                        onChange={(e) =>
                          handleChange({ name: "synopsis", event: e })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid synopsis.
                      </Form.Control.Feedback>
                    </Col>
                    <Col xs="12" md="4">
                      <Form.Label>
                        Genre<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        className="filedbg brdr selectFnt"
                        value={formData.genreId ? formData.genreId : ""}
                        onChange={(e) =>
                          handleChange({ name: "genreId", event: e })
                        }
                        required
                      >
                        <option value="">Select Genre Type</option>
                        {masters &&
                          masters.genre &&
                          masters.genre.length > 0 &&
                          masters.genre.map((item, index) => {
                            return (
                              <option value={item._id} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Genre.
                      </Form.Control.Feedback>
                    </Col>
                    <Col lg={8}>
                      <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                          className="fileFnt"
                          type="file"
                          placeholder=" "
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={(e) => {
                            fileChangedHandler(e, "image");
                          }}
                        />
                      </Form.Group>
                      <Form.Control.Feedback type="invalid">
                        It only accept png, jpg, jpeg files.
                      </Form.Control.Feedback>
                    </Col>
                    <Col lg={4} className="imgcss">
                      <img src={file} />
                    </Col>
                  </Row>
                  <Row className="justify-content-center"></Row>
                  <Row>
                    <Col className="text-center">
                      <Button
                        className="btnClr"
                        type="submit"
                        style={{ padding: "0.375rem 3.75rem" }}
                        disabled={isDisabled}
                      >
                        Save
                      </Button>
                    </Col>
                    <Col>
                      {" "}
                      <Button className="vndrBtn" onClick={cancelBtn} size="lg">
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AddProject;
