import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import React, { useState } from "react";
import { addSchedule } from "../../action/Admin.action";
import { setAlert, setRefresh } from '../../slices/home.js';
import { ToastContainer, toast } from "react-toastify";

const ScheduleFrm = (props) => {

    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(false);
    const [formErrors, setFormErrors] = useState({});


    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        var projectId = localStorage.getItem("projectId");


        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();

            var pair = { projectId: projectId }
            var obj = { ...formData, ...pair };
            let resp = await addSchedule(obj);

            if (resp.code === 200) {
                dispatch(setRefresh(true));
                setFormData({});
                toast.success("SuccessFully Added Schedule !");
            } else {
                toast.error(resp.msg);
                // dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }

            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });

    }

    //   useEffect(() => {
    //     getList();
    //   }, []);



    return (

        <>
            <Form className="p-5 mt-2 pt-1 pb-2" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>

                <Form.Group className="mb-3" controlId="name">
                    <Form.Control
                        type="text"
                        className="frmcnt"
                        placeholder="Schedule Name"
                        autoFocus
                        value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="startDate">
                    <Form.Control type="date" className="frmcnt" placeholder="Enter start date" value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid start date.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="endDate">
                    <Form.Control type="date" className="frmcnt" placeholder="Enter end date" value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="assistantDirector1">
                    <Form.Control
                        type="text"
                        className="frmcnt"
                        placeholder="Assistant Director 1"
                        value={formData.assistantDirector1 ? formData.assistantDirector1 : ""} onChange={e => handleChange('assistantDirector1', e)} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid Assistant Director 1.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="assistantDirector2">
                    <Form.Control
                        type="text"
                        className="frmcnt"
                        placeholder="Assistant Director 2"
                        value={formData.assistantDirector2 ? formData.assistantDirector2 : ""} onChange={e => handleChange('assistantDirector2', e)} required />
                    <Form.Control.Feedback type="invalid">
                        Please provide a valid name.
                    </Form.Control.Feedback>
                </Form.Group>

                <Row className="mt-4 mb-4 g-2 text-end">
                    <Col lg={12}>
                        <Button type="submit" className="vndrBtn">
                           Save
                        </Button>
                    </Col>
                    {/* <Col lg={6} className="text-end">
                                <Button className="vndrBtn" to="#">
                                    Cancel
                                </Button></Col> */}
                </Row>
            </Form>
        </>
    );
}

export default ScheduleFrm;