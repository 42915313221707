// CityPincodeFetcher.js
import React, { useState } from 'react';
import axios from 'axios';

const YourComponent = () => {
    const [city, setCity] = useState('');
    const [pincode, setPincode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const fetchPincode = async () => {
        setLoading(true);
        setError('');
        setPincode('');

        try {
            // Replace with a service that provides pincode based on city name
            // Here we mock the behavior by calling an example API that gets pin codes
            const response = await axios.get(`https://api.postalpincode.in/postoffice/${city}`);

            // Check if data exists
            if (response.data[0].Status === "Success") {
                const pincodes = response.data[0].PostOffice.map(po => po.Pincode);
                setPincode(pincodes.join(', ')); // Join multiple pincodes if available
            } else {
                setError('Pincode not found for the specified city');
            }
        } catch (err) {
            setError('An error occurred while fetching the pincode');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (city) {
            fetchPincode();
        }
    };

    return (
        <div>
            <h2>Get Pincode by City</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="city">City:</label>
                    <input
                        type="text"
                        id="city"
                        value={city}
                        onChange={handleCityChange}
                        required
                    />
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? 'Fetching...' : 'Get Pincode'}
                </button>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {pincode && <p>Pincode: {pincode}</p>}
        </div>
    );
};

export default YourComponent;
