import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import { getCrewListAction, deleteAction } from "../../action/Admin.action";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/home';
import { Link } from "react-router-dom";
import Pagination from '../common/Pagination';
import AddDirMembers from './DirMemberList'

const AddDirMember = (props) => {

    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [formData, setFormData] = useState({ page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" });
    const [startNum, setStartNum] = useState(0);
    const [editData, setEditData] = useState({});
    const masters = useSelector((state) => state.home.masters);
    const [show, setShow] = useState(false);
    const [st, setSt] = useState(false)
    let startNum1 = 0;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let projectId = localStorage.getItem('projectId');


    const getList = async (action = '') => {
        setEditData({});
        handleClose();
        let fData = { ...formData };
        if (action === 'clear') {
            fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC", projectId:projectId };
            setFormData(fData);
        }

        setStartNum(fData.page * fData.limit);

        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getCrewListAction(fData);
        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
        if (resp.code === 200) {
            setDataList(resp.data);
            setFormData({ ...formData, ...{ totalItems: resp.count } });
        }
    }

    const formHandler = (e, field) => {
        let data = { ...formData };
        if (e.target) {
            data[field] = e.target.value;
        } else {
            data[field] = e;
        }
        setFormData(data);
    }

    const deleteHandele = async (Vid) => {
        let rsp = await deleteAction({ type: "crew", _id: Vid });
        if (rsp.code == 200) {
            getList();
        }
    }

    useEffect(() => {
        getList();
    }, [formData.page])

    useEffect(()=>{
        getList();
    },[])

console.log(formData);

    return (
        <main id="main" className="main">
            <div className='pt-4'>
            <Card>
                <Card.Body className="px-1 crdBdy">
                    <Row className=''>
                        <Col lg={12} md={12}>

                            <Row className="mx-1 mt-3 pt-2">
                                <Col md={7} className='mt-2 px-0'><h6 className='crdHd1'>Direction Department </h6></Col>
                                <Col md={3} className='lgsix p-1 mb-3'>
                                    <input type="text" placeholder='Search Member Name' className='BrdRm' onChange={e => formHandler(e, 'name')} onClick={e => getList()}></input><i onClick={e => getList()} className="fa fa-search"></i>
                                </Col>
                                <Col md={2} className="text-end" onClick={() => { setFormData({}) }}>
                                    <Button type="button" className="bnt1 btn vndrBtn" onClick={() => { handleShow(); setSt(true) }}>Add New</Button>
                                </Col>
                                <hr />
                            </Row>
                            <Row className="px-0">
                                <Col md={12} className='table-container'>
                                    <table className="tbl1 table">
                                        <thead className="headingtbl tbl2">
                                            <tr>
                                                <th className="tblHead" scope="col">S.No.</th>
                                                <th className="tblHead" scope="col"> Name</th>
                                                <th className="tblHead" scope="col">Designation</th>
                                                <th className="tblHead" scope="col">Mobile</th>
                                                <th className="tblHead" scope="col">Email</th>
                                                {/* <th scope="col">Responsibility</th> */}
                                                <th scope="col" colSpan={2} className='text-center tblHead'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="listing lis">
                                            {

                                                dataList && dataList.length > 0 && dataList.map((val, ind) => {
                                                    if (val.department == "656b8e49f477e2b35e434afc") {
                                                        startNum1 = startNum1 + 1
                                                        return <tr key={ind}>
                                                            <td className='tblPr'>{startNum1}</td>
                                                            <td className='truncate-text tblPr' title={val.name}>{val.name}</td>
                                                            <td className='truncate-text tblP' >{masters && masters.designation && masters.designation.length > 0 && masters.designation.map((item, index) => {
                                                                if (val.designation == item._id) {
                                                                    return (item.name)
                                                                }
                                                            })}</td>
                                                            <td className='tblP'>{val.mobile}</td>
                                                            <td className='truncate-text tblP' title={val.email}>{val.email}</td>
                                                            {/* <td>{val.responsibility}</td> */}
                                                            {/*className='d-flex' */}
                                                            <td className='text-end'>
                                                                <i onClick={e => { setEditData(val); handleShow(); setSt(false) }} title="Edit" className="fa fa-pencil hand"></i>
                                                            </td>
                                                            <td><i onClick={() => { deleteHandele(val._id); }} title="Delete" className="icofont icofont-bin hand"></i></td>
                                                        </tr>
                                                    }
                                                })
                                            }
                                            {dataList && dataList.length == 0 ? 'Data not avaialble.' : ''}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-end pageRow">
                        <Col lg={3} className="pageCol">
                            <Pagination itemsPerPage={formData.limit} totalItems={formData.totalItems ? formData.totalItems : 0} setPage={e => setFormData({ ...formData, ...e })} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                    <Modal.Title className='crdHd1'>Add Direction Team</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    {/* <h6 className="mb-3">Add Direction Team</h6> */}
                    <AddDirMembers getList={e => getList()} editData={editData} refresh={st} />
                </Modal.Body>
            </Modal>
        </main>
    );
}

export default memo(AddDirMember);
