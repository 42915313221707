import React, { Fragment, useState, useEffect, useContext } from "react";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, H6, P } from "../AbstractElements";
import { BackgroundColor, EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from "../Constant";

import { useNavigate, Link } from "react-router-dom";
import man from "../assets/images/dashboard/profile.png";
// import logo from "../assets/images/logo.png";
import logo from '../assets/images/OldProImg/fc-logo.png'

import CustomizerContext from "../_helper/Customizer";
import OtherWay from "./OtherWay";
import { ToastContainer, toast } from "react-toastify";
import { adminLoginAction } from "../action/Admin.action";

const Signin = ({ selected }) => {
  // const [email, setEmail] = useState("test@gmail.com");
  // const [password, setPassword] = useState("test123");
  const [togglePassword, setTogglePassword] = useState(false);
  const [formData, setFormData] = useState({})
  const history = useNavigate();
  const navigate = useNavigate();

  const { layoutURL } = useContext(CustomizerContext);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  useEffect(() => {
    // localStorage.setItem("profileURL", man);
    // localStorage.setItem("Name", "Emay Walter");
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setValue(man);
    setName("Emay Walter");

    let resp = await adminLoginAction(formData);
    console.log(resp)

    if (resp.code === 200) {
      localStorage.setItem('login', JSON.stringify(resp.data));
      localStorage.setItem('loginData', JSON.stringify(true));
      localStorage.setItem('userType', resp.data.userType);

      localStorage.setItem('authorization', resp.data.token);
      toast.success(resp.msg);

      if (resp.data.userType == 1) {
        localStorage.setItem('loginType', 'admin');
        window.location.href = `${process.env.PUBLIC_URL}/admin/dashboard`;
      } else {
        localStorage.setItem('loginType', 'company');
        localStorage.setItem('companyId', resp.data.companyId);
        history(`${process.env.PUBLIC_URL}/dashboard/default`);
        // window.location.href = `${process.env.PUBLIC_URL}/projects`;
      }

    } else {
      var data = "";
      if (resp.msg) {
        data = resp.msg;
      }
      toast.error(resp.msg);
    }
  };

  const handleChange = (name, event) => {

    let from = { ...formData };

    from[name] = event.target.value;

    setFormData({ ...formData, ...from });

  }

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">

                  <Row className="mb-3"><Col className="logoImgLogin text-center"><img className="logoImgLogin1" src={logo} /></Col></Row>

                  <h6 className="form-heading">{selected === "simpleLogin" ? "" : "Sign In"}</h6>

                  <FormGroup className="mb-3" controlId="formBasicEmail">
                    <Label className="col-form-label" style={{ fontSize: "10px" }}>{EmailAddress}</Label>
                    <Input className="form-control signUpE" type="email" onChange={(e) => handleChange('email', e)} value={formData.email ? formData.email : ""} required />
                  </FormGroup>

                  <FormGroup className="position-relative">
                    <Label className="col-form-label" style={{ fontSize: "10px" }}>{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control signUpE" type={togglePassword ? "text" : "password"} onChange={(e) => handleChange('password', e)} value={formData.password ? formData.password : ''} />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>

                  <div className="position-relative form-group mb-0">
                    <div className="checkbox">
                      <Input id="checkbox1" type="checkbox" />
                      <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label>
                      <Link
                        className="link hand"
                        to={`${process.env.PUBLIC_URL}/forgot`}
                      >
                        {ForgotPassword}
                      </Link>
                      <Btn attrBtn={{ BackgroundColor: "#AA1163", className: "d-block w-100 mt-2 font12", onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
                    </div>
                  </div>

                  <OtherWay />
                </Form>

              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;
