import React, { useState, memo, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Table, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import vendorIcn from '../../assets/images/OldProImg/profileIcn.jpeg';
import { setAlert } from "../../slices/home";
import { addEditCrewAction } from "../../action/Admin.action";
import AlertBox from "../component/AlertBox";
import { imgPath } from "../common/function";
import { VendorType, VendorRole } from "../common/constant";
import line1 from '../../assets/images/OldProImg/line1.png';
import { ToastContainer, toast } from "react-toastify";



const ArtistDetails = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState("");
    const [ldata, setLdata] = useState(location.state)
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let obj1 = { _id: ldata._id }
            let newData = ({ ...formData, ...obj1 });

            let postData = new FormData();
            for (let key in newData) {
                postData.append(key, formData[key]);
            }


            setValidated(false)
            let resp = await addEditCrewAction(postData);
            setDisabled(false);
            if (resp.code === 200) {
                setFormData(resp.data)
                setLdata(resp.data)
                toast.success("Updated Successfully");
                // dispatch(setAlert({ open: true, severity: "success", msg: , type: '' }));
                handleClose();
            } else {
                let data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                toast.error(data);
                // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });

    }

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {
            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";

        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    useEffect(() => {
        setFormData({ name: ldata.name, number: ldata.number, mobile: ldata.mobile, email: ldata.email, characterName: ldata.characterName, image: ldata.image, _id: ldata._id });
    }, [])

    console.log(ldata);
    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy">
                        <Row className="mb-3 mt-3 pt-2">
                            <Col className="col-12 col-lg-9 col-sm-5 col-md-7"><h6 className="crdHd1">Artist Information</h6></Col>

                            {/* <Col className="col-8 col-sm-8 text-end col-lg-2 col-md-10"><Button className="bntCr myCr smsz" onClick={() => { navigate('/scene-option') }} >Back To Scene</Button></Col> */}

                            <Col className="text-end col-12 col-lg-3 col-md-5 col-sm-4 col">
                                <Button className="vndrBtn me-1" onClick={() => { navigate(`${process.env.PUBLIC_URL}/scene-option`) }} >Back </Button>
                                <Button className="vndrBtn" onClick={() => handleShow()}>Edit Info</Button></Col>
                        </Row>
                        <hr />
                        <Row className="justify-content-center">


                            <Col lg={8}>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="mt-0 pt-0">
                                        <Card.Text className="mt-3">
                                            <Table >
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="tblPr"> Name</td>
                                                        <td className="tblP">{ldata.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"> Number</td>
                                                        <td className="tblP">{ldata.number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Mobile</td>
                                                        <td className="tblP">{ldata.mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">E-mail Address</td>
                                                        <td className="tblP">  {ldata.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Character Name</td>
                                                        <td className="tblP">  {ldata.characterName}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                        <Card.Text className="mt-3">Profile Image</Card.Text>
                                        <img className="artistImg" src={ldata.image == "" || ldata.image == "undefined" ? vendorIcn : imgPath(ldata.image)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Modal show={show} onHide={handleClose} animation={false}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Header className="brdrR pb-0 mdlHdr" closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        <Row>
                                            <Col lg={12}><Modal.Title className="size1 m-1 mt-0 mb-0 crdHd1">Artist Details</Modal.Title></Col>
                                        </Row>
                                    </Modal.Title>
                                </Modal.Header>
                                <Row>
                                    <Col>
                                        <img src={line1} alt="" className="img-fluid12" />
                                    </Col>
                                </Row>
                                <Modal.Body className="pt-0">

                                    <Form className="mx-3" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                        <div>
                                            <Row>

                                                <Col lg={4}>
                                                    <Form.Group className="mb-2 txt" controlId="name">
                                                        <Form.Label>Artist Name<span className="star"> *</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Name"
                                                            autoFocus
                                                            pattern="^[A-Za-z]+(?: [A-Za-z]+)*$"
                                                            minLength="2"
                                                            maxLength="32"
                                                            className="brdr frmcnt"
                                                            value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4}>

                                                    <Form.Group className="mb-2 txt" controlId="Number">
                                                        <Form.Label>Number<span className="star"> *</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Number"
                                                            minLength={1}
                                                            maxLength={10}
                                                            className="brdr frmcnt"
                                                            pattern='^(0*[1-9][0-9]{0,9})$'
                                                            value={formData.number ? formData.number : ""} onChange={e => handleChange('number', e)} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid number.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4}>

                                                    <Form.Group className="mb-2 txt" controlId="email">
                                                        <Form.Label>E-mail<span className="star"> *</span></Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Email"
                                                            className="brdr frmcnt"
                                                            pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                                                            value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid email.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4}>

                                                    <Form.Group className="mb-2 txt" controlId="mobile">
                                                        <Form.Label>Mobile Number<span className="star"> *</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Mobile"
                                                            className="brdr frmcnt"
                                                            value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange('mobile', e)} minLength={10} maxLength={10} pattern='^[6-9][0-9]{9}$' required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid mobile.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4}>

                                                    <Form.Group className="mb-2 txt" controlId="characterName">
                                                        <Form.Label>Character Name<span className="star"> *</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            pattern="^[A-Za-z]+(?: [A-Za-z]+)*$"
                                                            minLength={2}
                                                            maxLength={120}
                                                            className="brdr frmcnt"
                                                            placeholder="character Name"
                                                            value={formData.characterName ? formData.characterName : ""} onChange={e => handleChange('characterName', e)} required />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a valid character Name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4}>

                                                    <Form.Group className="txt" controlId="image">
                                                        <Form.Label>Photo</Form.Label>
                                                        <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                                    </Form.Group>
                                                </Col>

                                                <Col lg={4} className="imgcss">
                                                    <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col className='col-6 col-lg-8 col-sm-6 '>
                                                    <Button type='submit' className="vndrBtn" onClick={e => { handleClose() }}>
                                                        Cancel
                                                    </Button>
                                                </Col>
                                                <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                                    <Button type='submit' className="btn vndrBtn">
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default memo(ArtistDetails);