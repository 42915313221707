import React, { useState, useEffect, Fragment } from 'react';
import { getImportScriptList } from '../../action/Admin.action';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/home';
import { bucketUrl } from '../common/constant';


const PdfPreviewDB = ({project}) => {

    var fileData2 = localStorage.getItem('fileDataPre');

    const dispatch = useDispatch();
    const [list, setList] = useState({});
    const [file, setFile] = useState();


    const getList = async () => {

        // const PreviewFile = JSON.parse(fileData2);

        var projectId = JSON.parse(localStorage.getItem("projectId"));
        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getImportScriptList({ projectId: projectId });
        const DB_ary = resp.data;
        function getFileNameByProjectId(projectId, DB_ary) {
            for (let i = 0; i < DB_ary.length; i++) {
                if (DB_ary[i].projectId === projectId) {
                    return DB_ary[i].file;
                }
            }
            return null;
        }
        var fileName = getFileNameByProjectId(projectId, DB_ary);
        const a = (JSON.stringify(fileName));
        const b = (a.replace(/"/g, ''));

        setFile(b);

        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
        if (resp.code === 200) {
            setList(resp.data);
        }

    }

    useEffect(() => {
       // getList();
    }, []);

    return (
        <iframe src={bucketUrl + project.file} width="100%" height="100%"/>
    );
}

export default PdfPreviewDB;
