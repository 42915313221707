import React, { memo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import mobil from "../../../assets/images/frontPageImg/backImage.jpeg";
import captionImage from "../../../assets/images/frontPageImg/captionImage.png";
import { useLocation } from 'react-router-dom';


function HomeBackImgCarousel() {

    const location = useLocation();
    const getClassName = (path) => location.pathname === path ? 'custom-link active' : 'custom-link';

    return (
        <div
            className="background-image-container"
            style={{ backgroundImage: `url(${mobil})` }}
        >
            <Container>
                <Row className="align-items-center carousel-caption">
                    <Col xs={12} md={5} className="text-center text-md-start">
                        <img src={captionImage} alt="Caption Image" className="img-fluid" />
                    </Col>
                    <Col xs={12} md={7} className="text-center text-md-start">
                        <h3 className="caption-title">Search, Track & Connect</h3>
                        <p className="caption-subtitle">With industries best Crew, Vendors & Talent.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default memo(HomeBackImgCarousel);
