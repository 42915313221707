import React, { useState, memo, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Table, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import pv1 from '../../assets/images/OldProImg/PictureVehicle/car1.png'
import { setAlert } from "../../slices/home";
import { addEditVendorPictureAction, getVendorDataAction } from "../../action/Admin.action";
import AlertBox from "../component/AlertBox";
import { listSceneScheduleAction } from "../../action/Admin.action";
import { imgPath } from "../common/function";
import { getCrewListAction } from "../../action/Admin.action";
import Select from 'react-select';
import line1 from '../../assets/images/OldProImg/line1.png';
import { ToastContainer, toast } from "react-toastify";



const PictureVehicleDetails = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [sceneList, setSceneList] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [file, setFile] = useState("");
  const [artistList, setArtistList] = useState([]);
  const [vendorList1, setVendorList1] = useState([]);
  const [locationList, setLocationList] = useState({});
  const [newart, setNewart] = useState([]);
  const [ldata, setLdata] = useState(location.state)
  const navigate = useNavigate();


  const getLocationList = async () => {
    const resp = await getLocationList();
    if (resp.code === 200) {
      setLocationList(resp.data);
    }
    let resp1 = await getVendorDataAction();
    if (resp1.code === 200) {
      setVendorList1(resp1.data)
    }
  }
  const getList1 = async () => {
    let resp = await getCrewListAction();
    // if (resp.code === 200) {
    //   if (resp.data.artiest && resp.data.artiest.length) {
    //     let artiests = resp.data.artiest;
    //     let artiestsList = [];
    //     for (let i in artiests) {
    //       let row = artiests[i];
    //       artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
    //     }
    //     setArtistList(artiestsList);
    //   }
    // }

    if (resp.code === 200) {
      let artiestsList = [];
      {
        resp && (resp.data.length > 0 && (resp.data).map((v, i) => {
          if (v.department == "000000000000000000000001") {
            artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
          }
        }))
      }
      setArtistList(artiestsList);
    }

    let resp1 = await getVendorDataAction();
    if (resp1.code === 200) {
      setVendorList1(resp1.data)
    }

    const resp2 = await listSceneScheduleAction();
    if (resp2.code === 200) {
      setSceneList(resp2.data);
    }
  }

  const getList2 = async () => {
    let newArray = artistList.filter(item => ldata.vehicleForWhom.includes(item.value));
    setNewart(newArray)
  }


  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);
      let postData = new FormData();
      for (let key in formData) {
        if (key == 'vehicleForWhom') {

        } else {
          postData.append(key, formData[key]);
        }
      }

      let artistNoList = [];
      for (let j in formData.vehicleForWhom) {

        let a = formData.vehicleForWhom[j];
        artistNoList.push(a.value);
      }
      postData.append('vehicleForWhom', JSON.stringify(artistNoList));
      let resp = await addEditVendorPictureAction(postData);
      setDisabled(false);

      if (resp.code === 200) {
        // dispatch(setAlert({ open: true, severity: "success", msg: "successfully Updated", type: '' }));
        toast.success("successfully Updated");
        handleClose();
        setLdata(resp.data)
      } else {
        var data = "";
        if (resp.msg) {
          data = resp.msg;
        }
        setDisabled(false);
        // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
        toast.error(data);

      }
      return false;
    }
  };

  const handleChange = (name, event, extra = "") => {

    let from = { ...formData };
    if (extra === 'multi') {
      from[name] = event;
    } else if (name === 'vehicleForWhom') {
      let arr = [];

      if (event.target.checked) {
        arr.push(event.target.value);
      } else {
        const index = arr.indexOf(event.target.value);
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }
      from[name] = arr;
    } else {
      from[name] = event.target.value;
    }
    setFormData({ ...formData, ...from });
  };



  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    let formErrorsData = formErrors;

    let formDataData = formData;
    let file = event.target.files[0];
    setFile(URL.createObjectURL(event.target.files[0]));
    if (!file && file === undefined) {

      return false;
    }
    var fileName = (file && file.name ? file.name : '');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

    if (file.size > 20971520) {
      formErrorsData[elename] = "File size not greater then 20MB.";
    } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

      formErrorsData[elename] = "";
      formErrorsData["preview"] = "";
      formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
      formDataData['fileType'] = extensions;
      formDataData[elename] = event.target.files[0];
      setFormData({ ...formData, ...formDataData });
    } else {
      formErrorsData[elename] = "File extensions doesn't match.";

    }
    setFormErrors({ ...formErrors, ...formErrorsData });
  }

  useEffect(() => {

    setFormData({ vehicleName: ldata.vehicleName, typeOfVehicle: ldata.typeOfVehicle, sceneNo: ldata.sceneNo, vendorId: ldata.vendorId, location: ldata.location, vehicleForWhom: newart, price: ldata.price, image: ldata.image, status: ldata.status, _id: ldata._id });
  }, [newart])

  useEffect(() => {
    getList1();
    getList2();

    setFormData({ vehicleName: ldata.vehicleName, typeOfVehicle: ldata.typeOfVehicle, sceneNo: ldata.sceneNo, vendorId: ldata.vendorId, location: ldata.location, vehicleForWhom: newart, price: ldata.price, image: ldata.image, status: ldata.status, _id: ldata._id });
  }, [])

  return (
    <>
      <div className="pt-4">
        <Card>
          <Card.Body className="crdBdy">
            <Row className="pt-4">
              <Col lg={5} className="col-2 col-lg-5 col-sm-5 col-md-7"><h6 className="crdHd1">Picture Vehicle </h6></Col>
              {/* <Col lg={2} className="col-5 col-md-10 text-end col-lg-2"><Button className="bntCr myCr btnClre" onClick={() => { navigate('/picture-vehicle') }} >Back</Button></Col> */}

              <Col lg={7} className="text-end col-10 col-lg-7 col-md-5 col-sm-4 col">
                <Button className="bntCr myCr me-1 btnClre" onClick={() => { navigate(`${process.env.PUBLIC_URL}/picture-vehicle`) }} >Back</Button>
                <Button className="bntCr myCr btnClre" onClick={() => { handleShow(); getList2(); }}>Edit Details</Button></Col>
            </Row>
            <hr />
            <Row>
              <Col lg={8}>
                <Row>
                  {/* <Col lg={12}>
                <h5 className="smsz">Picture Vehicle Information</h5>
              </Col> */}
                  <Col lg={12}>
                    <Row><Col lg={4}><AlertBox /></Col></Row>
                    <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                      <Card.Body className="crdBdy">
                        <Card.Text className="mt-3">
                          <Table>
                            <thead>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="tblPr"><b>Vehicle Name</b></td>
                                <td className="tblP">{ldata.vehicleName}</td>
                              </tr>
                              <tr>
                                <td className="tblPr"><b>Location</b></td>
                                <td className="tblP">{ldata.location}</td>
                              </tr>
                              <tr>
                                <td className="tblPr"><b>Vendor Name</b></td>
                                <td className="tblP">{vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                  if (ldata.vendorId == val._id) {
                                    return <option value={(val._id)} key={ind} className="optionSz" >{val.name}</option>
                                  }
                                })}</td>
                              </tr>
                              <tr>
                                <td className="tblPr"><b>Price</b></td>
                                <td className="tblP">{ldata.price}</td>
                              </tr>
                              <tr>
                                <td className="tblPr"><b>Scene No.</b></td>
                                <td className="tblP">{
                                  sceneList && sceneList.length > 0 && sceneList.map((val, ind) => {
                                    return (
                                      val && (val.sceneData).length) > 0 && (val.sceneData).map((itm, indx) => {
                                        if (ldata.sceneNo == itm._id) {
                                          return <option key={indx} value={itm._id} className="optionSz">{itm.scene}</option>
                                        }
                                      })
                                  })
                                }</td>
                              </tr>
                              <tr>
                                <td className="tblPr"><b>Vehicle for whom</b></td>
                                <td className="tblP">{artistList && artistList.length > 0 &&
                                  artistList.map((item, index) => {
                                    return (
                                      ldata && ldata.vehicleForWhom.length > 0 && ldata.vehicleForWhom.map((v1, i1) => {

                                        if (item.value == v1) {
                                          let nm = [item.label, " ,"]
                                          return (nm)
                                        }
                                      })
                                    )
                                  })}</td>
                              </tr>
                              <tr>
                                <td className="tblPr"><b>Status</b></td>
                                <td className="tblP">{ldata && ldata.status ? "Active" : "Inactive"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col>
                    <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                      <Card.Body className="crdBdy">
                        <Card.Text className="mt-3">
                          <Col lg={12}>
                            <h6 className="smsz">Picture Vehicle Image</h6>
                          </Col>
                          {/* <img className="vdImg" src={ldata.image ? ldata.image ? imgPath(ldata.image) : "" : file} /> */}

                          <img className="vdImg" src={ldata && ldata.image == "" ? pv1 : imgPath(ldata.image)} />

                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Modal size="lg" show={show} onHide={handleClose} animation={false} >
                <Modal.Header className="mdlHdr size1 brdrR pt-2 pb-1" closeButton>
                  <Row>
                    <Col lg={12}><h6 className="crdHd1" >Picture Vehicle</h6></Col>
                  </Row>

                </Modal.Header>

                {/* <Row>
                    <Col lg={11}><Modal.Title className="size1 m-2 mt-3 mb-0">Picture Vehicle</Modal.Title></Col>
                    <Col lg={1}>
                        <span className='hand cancel' onClick={() => { handleClose() }}>x</span>
                    </Col>
                </Row> */}


                <Row className="pt-0">
                  <Col>
                    <img src={line1} alt="" className="img-fluid12" />
                  </Col>
                </Row>
                <Modal.Body className="pt-1">
                  <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                    <Row className="mb-1 mx-2">
                      <Col lg={4}>
                        <Form.Group className="mb-1" controlId="vehicleName">
                          <Form.Label>Vehicle Name<span className='star'>*</span></Form.Label>
                          <Form.Control className="brdr frmcnt" autoFocus minLength={2} maxLength={32} type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of location" value={formData.vehicleName ? formData.vehicleName : ""} onChange={e => handleChange('vehicleName', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Vehicle Name.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="mb-1" controlId="typeOfVehicle">
                          <Form.Label>Type of Vehicle<span className='star'>*</span></Form.Label>
                          <Form.Control className="brdr frmcnt" type="text" value={formData.typeOfVehicle ? formData.typeOfVehicle : ""} onChange={e => handleChange('typeOfVehicle', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Type of vehicle.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        <Form.Group className="mb-1" controlId="sceneNo">
                          <Form.Label>Vehicle for scene no.<span className='star'>*</span></Form.Label>
                          <Form.Select className="brdr frmcnt" type="text" value={formData.sceneNo ? formData.sceneNo : ""} onChange={e => handleChange('sceneNo', e)} required >
                            <option value="">Select Option</option>
                            {
                              sceneList && sceneList.length > 0 && sceneList.map((val, ind) => {
                                return (
                                  val && (val.sceneData).length) > 0 && (val.sceneData).map((itm, indx) => {
                                    return <option key={indx} value={itm._id}>{itm.scene}</option>
                                  })
                              })
                            }
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Type of vehicle.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                    </Row>
                    <Row className="mx-2">
                      <Col lg={4} className="txt">
                        <Form.Group controlId="vehicleForWhom" className="txt">
                          <Form.Label >For Whom<span className='star'>*</span></Form.Label>
                          <Select className="brdr1 mselect frmcnt" aria-label="Default select example"
                            value={formData.vehicleForWhom ? formData.vehicleForWhom : []}
                            placeholder="Select"
                            isMulti
                            required
                            options={artistList}
                            classNamePrefix="select"
                            onChange={e => handleChange('vehicleForWhom', e, 'multi')}
                            isValid={formData.vehicleForWhom && formData.vehicleForWhom.length > 0} >
                            {
                              artistList && artistList.length > 0 && artistList.map((val, ind) => {
                                return <option value={(val.value)} key={ind}  >{val.label}</option>
                              })
                            }
                          </Select>
                          {validated === true && ((!formData.artiestId) || formData.artiestId && formData.artiestId.length === 0) &&
                            <p className="err">This field is required</p>}
                        </Form.Group>
                      </Col>

                      <Col lg={4} className="txt">
                        <Form.Group className="mb-1" controlId="location">
                          <Form.Label>Location<span className='star'>*</span></Form.Label>
                          <Form.Control className="brdr frmcnt" minLength={1} maxLength={32} type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of location" value={formData.location ? formData.location : ""} onChange={e => handleChange('location', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Location.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={4} className="txt">
                        <Form.Group className="mb-1" controlId="vendorId">
                          <Row><Col><Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                          </Col></Row>
                          <Form.Select className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                            <option value="">Select Vendor</option>
                            {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                              return <option value={(val._id)} key={ind}  >{val.name}</option>
                            })}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Vendor.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col lg={4}>
                        <Form.Group className="mb-1 mt-2" controlId="price">
                          <Form.Label>Price<span className='star'>*</span></Form.Label>
                          <Form.Control className="brdr frmcnt" minLength={1} maxLength={15} pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' type="text" placeholder="Name of location" value={formData.price ? formData.price : ""} onChange={e => handleChange('price', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Price.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>


                      <Col lg={4}>
                        <Form.Group className="mb-1 mt-2" controlId="status">
                          <Form.Label>Status<span className='star'>*</span></Form.Label>
                          <Form.Select className="brdr frmcnt" aria-label="Default select example"
                            placeholder="status" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Status.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mx-2">
                      <Col lg={4}>

                        <Form.Group className="mb-1" controlId="image">
                          <Form.Label>Image</Form.Label>
                          <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                        </Form.Group>
                      </Col>
                      <Col lg={4} className='imgcss mt-2'>
                        <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />
                        <img src={file} />
                      </Col>
                    </Row>
                    <Row className="g-2 mt-5 mx-2">
                      <Col className='col-6 col-lg-8 col-sm-6 '>
                        <Button variant="secondary" className="btnk cldrBtn" onClick={() => { handleClose() }}>
                          Cancel
                        </Button>
                      </Col>
                      <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                        <Button variant="secondary" type='submit' className="btnk cldrBtn"
                          disabled={isDisabled}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Modal.Body>
              </Modal>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default memo(PictureVehicleDetails);