import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, memo } from 'react';
import { addEditCrewAction, getCrewListAction } from "../../action/Admin.action";
import { setAlert, setMasterData } from '../../slices/home.js';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { GetAllCountries } from '../common/function'
import { ToastContainer, toast } from "react-toastify";


const AddCrewMemeber = (props) => {

    const dispatch = useDispatch();
    const masters = useSelector((state) => state.home.masters);

    const [formData, setFormData] = useState({});
    const [listData, setListData] = useState({});
    const [block, setBlock] = useState(1);
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const navigate = useNavigate();

    const getList = async (action = '') => {
        const resp = await getCrewListAction();
        setListData(resp.data)
    }

    const handleSubmit = async (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let msg = "You have successfully created member.";

            let resp = await addEditCrewAction(formData);
            setDisabled(false);

            if (resp.code === 200) {
                toast.success(msg);
                if (props.getList()) {

                }
                setFormData({});
                setValidated(false)
            } else {
                setDisabled(false);
                toast.error(resp.msg);
            }

            return false;
        }
    };

    const handleChange = (post) => {

        if (post.name == 'name') {
            const foundObjects = listData && listData.length > 0 && listData.map((v, i) => {
                if (v.name == post.event.target.value) {
                    return (
                        setValidated(true)
                    )
                }
            })
        }

        let name = post.name;
        let event = post.event;
        let from = { ...formData };

        from[name] = event.target.value;
        setFormData({ ...formData, ...from });
    }

    useEffect(() => {
        getList();
        if (props.editData && props.editData._id) {
            let edit = props.editData;
            setFormData({ _id: edit._id, name: edit.name, department: edit.department, designation: edit.designation, mobile: edit.mobile, email: edit.email });
        }
    }, [props.editData])

    return (
        <>
            <div>
                <div className="col-12 w-6" style={{ margin: "auto" }}>
                        {/* <Col className="col-1 mt-1">
                           
                        </Col> */}
                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                <Row className='mt-4 pt-1 px-2 justify-content-center'>
                                    <Col lg={11} className="col-11 px-2">
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Control
                                                type="text"
                                                placeholder="Name"
                                                pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                                                autoFocus
                                                className="frmcntadd1 "
                                                value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} minLength={2} maxLength={32} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={11} className="col-11 px-2">
                                        <Form.Group className="mb-3" controlId="department">

                                            <Form.Select className=" frmcntadd1" value={formData.department ? formData.department : ""} onChange={e => handleChange({ name: 'department', event: e })} >
                                                <option value="">Select Department</option>
                                                {masters && masters.department && masters.department.length > 0 && masters.department.map((item, index) => {
                                                    return <option value={item._id} key={index}>{item.name}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid department.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={11} className="col-11 px-2">
                                        <Form.Group className="mb-3" controlId="designation">

                                            <Form.Select className=" frmcntadd1" value={formData.designation ? formData.designation : ""} onChange={e => handleChange({ name: 'designation', event: e })} >
                                                <option value="">Select Designation</option>
                                                {masters && masters.designation && masters.designation.length > 0 && masters.designation.map((item, index) => {
                                                    if (formData.department == item.departmentId) {
                                                        return <option value={item._id} key={index}>{item.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Designation.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col lg={1} className="col-2 px-2">
                                        <Form.Group className="mb-3 " controlId="country">
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange({ name: 'mobileCountry', event: e })} required>
                                                <GetAllCountries />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> */}
                                    <Col lg={11} className="col-11 px-2">
                                        <Form.Group className="mb-3" controlId="mobile">
                                            <Form.Control
                                                type="text"
                                                pattern='^[6-9][0-9]{9}$'
                                                placeholder="Mobile No."
                                                className='frmcntadd1'
                                                value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange({ name: 'mobile', event: e })} minLength="10" maxLength="10" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid mobile.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={11} className="col-11 px-2">
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Control
                                                type="eamil"
                                                pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                                                placeholder='Email'
                                                className='frmcntadd1'
                                                value={formData.email ? formData.email : ""}
                                                onChange={e => handleChange({ name: 'email', event: e })} required />
                                            <Form.Control.Feedback type="invalid">Valid Email Is Required</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={11} className="col-11 text-end mb-3">
                                        <Button type='submit' className='btn btn-dark crewAddBtn'>Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                </div>
            </div>
        </>
    );
}

export default AddCrewMemeber;