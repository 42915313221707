import react from 'react';
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts';

const ProgressBar = (props)=>{
  const RadialSize = 110;

    return(
        <RadialBarChart
width={RadialSize/props.width}
height={RadialSize /props.height}
cx={RadialSize / 2}
cy={RadialSize / 2}
innerRadius={34}
outerRadius={28}
barSize={15}
data={[props.proData]}
startAngle={100}
endAngle={-270}
>
<PolarAngleAxis
  type="number"
  domain={[0, 100]}
  angleAxisId={0}
  tick={false}
/>
<RadialBar
  background
  clockWise
  dataKey="value"
  cornerRadius={RadialSize / 2}
  fill="#98d7c2"
/>
<text
  x={RadialSize / 2}
  y={RadialSize / 2}
  textAnchor="middle"
  dominantBaseline="middle"
  className="progress-label-round"
>
  {props.proData.value + props.per}
</text>
</RadialBarChart>

    )
}

export default ProgressBar;

