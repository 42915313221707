import React, { useState, useEffect } from "react";
import Header from "../../../FrontPage/Componets/Header/HeaderloginLite";
import background from '../../../assets/images/frontPageImg/freepik--background-simple--inject-126.png';
import Characters from '../../../assets/images/frontPageImg/freepik--Characters--inject-126.png';
import Plants from '../../../assets/images/frontPageImg/freepik--Plants--inject-126.png';
import Desk from '../../../assets/images/frontPageImg/freepik--Desk--inject-126.png';
import { selectUserTypeRegisterAction, getCategoryVendorDataAction } from "../../../action/Admin.action";
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../slices/home';
import { Container, Col, Row, Form, Button, Modal } from "react-bootstrap";
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../../../admin/common/function";
import CheckmarkIcon from "../../../assets/images/frontPageImg/CheckmarkIcon.png"
import arrowIcon from "../../../assets/images/frontPageImg/arrowIcon.png";
import axios from 'axios';

const UserRegister = (state) => {

    const location = useLocation();
    const { userType } = location.state || {};
    const [step, setStep] = useState(1);
    const [type, setType] = useState(userType || null);
    const [dataList, setDataList] = useState([]);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({ businessCountry: 'IN', });
    const [subCategories, setSubCategories] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [pincode, setPincode] = useState('');
    const getDataList = async () => {
        let resp;
        switch (userType) {
            case 3:
                resp = await getCategoryVendorDataAction({ type: 'vendor' });
                break;
            case 4:
                resp = await getCategoryVendorDataAction({ type: 'crew' });
                break;
            case 5:
                resp = await getCategoryVendorDataAction({ type: 'other' });
                break;
            default:
                return;
        }
        if (resp && resp.code === 200) {
            setDataList(resp.data);
        }
    };


    const validateStep = () => {
        const phoneNumberPattern = /^[0-9]{10}$/;
        switch (step) {
            case 1:
                return (
                    formData.businessContactName &&
                    phoneNumberPattern.test(formData.businessContactNumber) &&
                    (type !== 3 || type !== 4 || formData.categoryId) &&
                    (type !== 5 || formData.workType) &&
                    formData.email &&
                    (type !== 4 || formData.age) &&
                    (type !== 4 || formData.gender) &&
                    formData.businessCountry &&
                    formData.businessState &&
                    formData.businessCity &&
                    formData.businessPostalCode &&
                    (type !== 3 || formData.companyName)
                );
            case 2:
                return (

                    formData.businessAddress
                );
            default:
                return false;
        }
    };

    const handleNextStep = (event) => {
        if (validateStep()) {
            if (step === 1) {
                setStep(2);
            }
        } else {
            setValidated(true);
        }
    };

    const handlePreviousStep = () => {
        if (step === 2) {
            setStep(1);
        }
    };



    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false || !validateStep()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let formDataData = { ...formData, userType };
            let postData = new FormData();

            // Append all form data fields to the FormData object
            // for (let key in formDataData) {
            //     let item = formDataData[key];
            //     postData.append(key, item);
            // }
            for (let key in formDataData) {
                if (formDataData.hasOwnProperty(key)) {

                    if (key === 'productImg' || key === 'crewResume' || key === 'portfolio') {
                        if (formDataData[key]) {
                            postData.append(key, formDataData[key]);
                        }
                    } else {
                        postData.append(key, formDataData[key]);
                    }
                }
            }
            let msg = "You have successfully registered.";
            let resp = await selectUserTypeRegisterAction(postData);

            if (resp.code === 200) {
                localStorage.setItem('status', JSON.stringify(resp.data.status));
                dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                localStorage.setItem("login", JSON.stringify(resp.data));
                localStorage.setItem("userType", resp.data.userType);

                setShowSuccessModal(true);
            } else {
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }
        }
    };

    const fetchPincode = async (city) => {
        setLoading(true);
        setError('');
        setPincode('');

        try {
            const response = await axios.get(`https://api.postalpincode.in/postoffice/${city}`);
            if (response.data[0].Status === "Success") {
                const pincodes = response.data[0].PostOffice.map(po => po.Pincode);
                setPincode(pincodes.join(', '));
            } else {
                setError('Pincode not found for the specified city');
            }
        } catch (err) {
            setError('An error occurred while fetching the pincode');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (name, event) => {
        const value = name === 'productImg' || name === 'crewResume' || name === 'portfolio'
            ? event.target.files[0]
            : event.target.value;

        let updatedFormData = { ...formData, [name]: value };

        if (name === 'categoryId') {
            const selectedCategory = dataList.find(item => item._id === value);
            setSubCategories(selectedCategory ? selectedCategory.subCategory : []);
            updatedFormData.subCategoryId = '';
        }

        if (name === 'businessCity') {
            setFormData({ ...formData, [name]: value });
            fetchPincode(value);
        } else {
            setFormData(updatedFormData);
        }
    };


    useEffect(() => {
        getDataList();
    }, [state]);

    useEffect(() => {
        if (userType) {
            setType(userType);
        }
    }, [userType]);
    const handleContinue = () => {
        setShowSuccessModal(false);
        navigate(`${process.env.PUBLIC_URL}/user/dashboard`);
    };
    return (
        <>
            <Container fluid={true} className="p-0">
                {/* <Header /> */}
                <button
                    className="back-button"
                    onClick={() => navigate(-1)}
                    style={{
                        background: 'none',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '30px',
                        marginLeft: '30px'
                    }}
                >
                    <img src={arrowIcon} alt="Back" style={{ width: '15px', height: '15px', }} />
                    <span className="text-white" style={{ marginLeft: '10px' }}>Back</span>
                </button>
                <Container className="pt-1">
                    <Row className="justify-content-center" style={{ marginBottom: '20px' }}>
                        <Col md={5} className="position-relative" style={{ marginTop: '50px' }}>

                            <div className="image-container">
                                <img
                                    src={background}
                                    alt="Background"
                                    className="img-fluid"
                                    style={{
                                        borderRadius: "10px",
                                        maxHeight: "315px",
                                        width: "435px",
                                        objectFit: "cover",
                                        position: "absolute",
                                        zIndex: 1,
                                    }}
                                />
                                <img
                                    src={Plants}
                                    alt="Plants"
                                    className="img-fluid"
                                    style={{
                                        borderRadius: "10px",
                                        maxHeight: "188px",
                                        width: "138px",
                                        objectFit: "cover",
                                        position: "relative",
                                        top: "173px",
                                        left: "0px",
                                        zIndex: 2,
                                    }}
                                />
                                <img
                                    src={Desk}
                                    alt="Desk"
                                    className="img-fluid"
                                    style={{
                                        borderRadius: "10px",
                                        maxHeight: "111px",
                                        width: "235px",
                                        objectFit: "cover",
                                        position: "relative",
                                        top: "200px",
                                        left: "60px",
                                        zIndex: 2,
                                    }}
                                />
                                <img
                                    src={Characters}
                                    alt="Characters"
                                    className="img-fluid"
                                    style={{
                                        borderRadius: "10px",
                                        maxHeight: "343px",
                                        width: "282px",
                                        objectFit: "cover",
                                        position: "absolute",
                                        top: "37px",
                                        left: "80px",
                                        zIndex: 3,
                                    }}
                                />
                            </div>
                        </Col>
                        <Col md={7}>

                            <div
                                className="form-container"
                                style={{
                                    background: "#1D1F33",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    position: "relative",
                                    zIndex: 4,
                                }}
                            >
                                <h3
                                    className="text-white"
                                    style={{
                                        borderBottom: "1px solid #313A47",
                                        paddingBottom: "20px",
                                    }}
                                >
                                    Profile
                                </h3>

                                <Form className="pt-3 userForm" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                    {step === 1 ? (
                                        <>

                                            <Row>
                                                {type === 3 && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Company Name<span className="star">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="companyName"
                                                                    value={formData.companyName || ""}
                                                                    onChange={e => handleChange('companyName', e)}
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Company Name.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">Contact Name<span className="star">*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="businessContactName"
                                                                value={formData.businessContactName || ""}
                                                                onChange={e => handleChange('businessContactName', e)}
                                                                required
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Contact Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                {type === 5 && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Email ID<span className="star">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="email"
                                                                    value={formData.email || ""}
                                                                    onChange={e => handleChange('email', e)}
                                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Email ID.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>

                                            <Row>
                                                {(type === 3 || type === 4) && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Category<span className="star">*</span></Form.Label>
                                                                <Form.Select
                                                                    name="categoryId"
                                                                    value={formData.categoryId || ''}
                                                                    onChange={(e) => handleChange('categoryId', e)}
                                                                    required
                                                                >
                                                                    <option value="">Select Category</option>
                                                                    {dataList.map((item, index) => (
                                                                        <option value={item._id} key={index}>{item.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Category.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                                {(type === 3 || type === 4) && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Email ID<span className="star">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    name="email"
                                                                    value={formData.email || ""}
                                                                    onChange={e => handleChange('email', e)}
                                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Email ID.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                            <Row>
                                                {(type === 3 || type === 4) && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Sub Category</Form.Label>
                                                                <Form.Select
                                                                    name="subCategoryId"
                                                                    value={formData.subCategoryId || ''}
                                                                    onChange={(e) => handleChange('subCategoryId', e)}
                                                                >
                                                                    <option value="">Select Subcategory</option>
                                                                    {subCategories.map((sub, index) => (
                                                                        <option value={sub._id} key={index}>{sub.name}</option>
                                                                    ))}
                                                                </Form.Select>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Sub Category.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">Contact No<span className="star">*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="businessContactNumber"
                                                                value={formData.businessContactNumber || ""}
                                                                onChange={e => handleChange('businessContactNumber', e)}
                                                                required
                                                                pattern="\d*" minLength="10" maxLength="10"
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Contact No.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                {type === 5 && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Work Type<span className="star">*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="workType"
                                                                    value={formData.workType || ""}
                                                                    onChange={e => handleChange('workType', e)}
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Work Type
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>
                                            {type === 4 && (
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="mb-2">
                                                            <Form.Label className="text-white mb-2">Gender<span className="star"> *</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.gender ? formData.gender : ""} onChange={e => handleChange('gender', e)} required>
                                                                <option value="">Select Gender</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                                <option value="other">Other</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Gender.
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="mb-2">
                                                            <Form.Label className="text-white mb-2">Age<span className="star"> *</span></Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="age"
                                                                value={formData.age || ""}
                                                                onChange={e => handleChange('age', e)}
                                                                required
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Gender.
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">Country<span className="star">*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" className="filedbg" value={formData.businessCountry ? formData.businessCountry : ""} onChange={e => handleChange('businessCountry', e)} required>
                                                                <GetAllCountries />
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Country.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">State<span className="star">*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" className="filedbg" value={formData.businessState ? formData.businessState : ""} onChange={e => handleChange('businessState', e)} required>
                                                                <GetStatesOfCountry countryCode={formData.businessCountry ? formData.businessCountry : ""} />
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid State.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">City<span className="star">*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" className="filedbg" value={formData.businessCity ? formData.businessCity : ""} onChange={e => handleChange('businessCity', e)} required>
                                                                <GetCitiesOfState countryCode={formData.businessCountry ? formData.businessCountry : ""} stateCode={formData.businessState ? formData.businessState : ""} />
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid City.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">Pin Code<span className="star">*</span></Form.Label>
                                                            <Form.Select
                                                                aria-label="Select Pin Code"
                                                                className="filedbg"
                                                                value={formData.businessPostalCode || ""}
                                                                onChange={e => handleChange('businessPostalCode', e)}
                                                                required
                                                            >
                                                                <option value="">Select Pin Code</option>
                                                                {pincode && pincode.split(', ').map((pin, index) => (
                                                                    <option key={index} value={pin}>
                                                                        {pin}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Pin Code.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md="12">
                                                    <button
                                                        className="mt-3 btn text-white"
                                                        onClick={handleNextStep}
                                                        style={{
                                                            backgroundColor: "#AA1163",
                                                            fontSize: "1rem",
                                                        }}
                                                    >
                                                        Next
                                                    </button>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <>

                                            <Row>
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">Address<span className="star">*</span></Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                name="businessAddress"
                                                                rows="2"
                                                                value={formData.businessAddress || ""}
                                                                onChange={e => handleChange('businessAddress', e)}
                                                                required
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Address.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="mb-2">
                                                        <Form.Group>
                                                            <Form.Label className="text-white mb-2">Description</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                name="description"
                                                                rows="2"
                                                                value={formData.description || ""}
                                                                onChange={e => handleChange('description', e)}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Description.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {(type === 3 || type === 4) && (
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">GST Number</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="businessGstNumber"
                                                                    value={formData.businessGstNumber || ""}
                                                                    onChange={e => handleChange('businessGstNumber', e)}
                                                                    pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" minLength={1} maxLength={15}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid GST Number.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Pan No.</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="businessPan"
                                                                    value={formData.businessPan || ""}
                                                                    onChange={e => handleChange('businessPan', e)}
                                                                    pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                                                    minLength={10}
                                                                    maxLength={10}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Pan No.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                            {type === 4 && (
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Website</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="website"
                                                                    value={formData.website || ""}
                                                                    onChange={e => handleChange('website', e)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Website.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Imdb Link</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="imDbLink"
                                                                    value={formData.imDbLink || ""}
                                                                    onChange={e => handleChange('imDbLink', e)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Imdb Link.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                            {type === 5 && (
                                                <Row>
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Website</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="website"
                                                                    value={formData.website || ""}
                                                                    onChange={e => handleChange('website', e)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Website.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row>
                                                {type === 3 && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Add Product Image</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="productImg"
                                                                    onChange={e => handleChange('productImg', e)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                                {type === 4 && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Resume</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="crewResume"
                                                                    onChange={e => handleChange('crewResume', e)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                                {(type === 3 || type === 4) && (
                                                    <Col md="6">
                                                        <div className="mb-2">
                                                            <Form.Group>
                                                                <Form.Label className="text-white mb-2">Add Portfolio</Form.Label>
                                                                <Form.Control
                                                                    type="file"
                                                                    name="portfolio"
                                                                    onChange={e => handleChange('portfolio', e)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Row>

                                            <div>
                                                <button
                                                    className="mt-3 btn text-white"
                                                    disabled={isDisabled}
                                                    style={{
                                                        backgroundColor: "#AA1163",
                                                        fontSize: "1rem",
                                                    }}
                                                >
                                                    Register
                                                </button>
                                                {' '}
                                                <button
                                                    className="mt-3 btn text-white"
                                                    onClick={handlePreviousStep}
                                                    style={{
                                                        fontSize: "1rem",
                                                        marginLeft: '10px',
                                                        backgroundColor: '#455A64'
                                                    }}
                                                >
                                                    Back
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </Form>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Modal
                show={showSuccessModal}
                onHide={() => { }}
                centered
                backdrop="static"
            >
                <Modal.Body className="text-center liteModal-content">

                    <div className="my-4">

                        <img
                            src={CheckmarkIcon}
                            width={50}
                            height={50}
                            alt="Success Checkmark"
                        />
                    </div>
                    <h4 style={{ color: 'grey' }}>Registration Successful!</h4>
                    <p>You have successfully registered!</p>

                    {/* <Modal.Footer className="liteModal-content"> */}
                    <Button variant="success" onClick={handleContinue}>
                        Continue
                    </Button>
                </Modal.Body>
                {/* </Modal.Footer> */}
            </Modal>
        </>
    );
};



export default UserRegister;
