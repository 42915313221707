import React, { useState, useEffect, Fragment } from 'react';
import { Card, Col, Container, form, Row, Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert, setOneLinerAdd, setProjectDetails } from "../../slices/home";
import { getCrewListAction, getArtiestList, getLocationList, addOneLinerScript, projectDetailAction } from "../../action/Admin.action";
import { options, IntExt, ScriptDays } from '../common/constant';
import PdfPreviewDB from './GetImportScriptFile';
import SelectShedule from './scheduleSelect';
import SelectOptions from "../component/SelectOptions";
import Select from 'react-select';
import Sidebar from "../component/Sidebar";
import ArtistBtn from '../component/AddArtist';
import LocationBtn from '../component/AddLocationCom';
import { ToastContainer, toast } from "react-toastify";


const OneLinerScript = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const projectDetails = useSelector((state) => state.home.projectDetails);

  //const [projectDetails, setProjectDetails] = useState({});
  const [formData, setFormData] = useState({});
  const [artistList, setArtistList] = useState([]);
  const [artistList1, setArtistList1] = useState([]);
  const [locationList, setLocationList] = useState({});
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [refreshSchedule, setRefreshSchedule] = useState(false);
  const [hasUploadedFile, setHasUploadedFile] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const formattedDate = startDate.toLocaleDateString('en-GB');

  const getList = async () => {

    let resp = await projectDetailAction();
    if (resp.code === 200) {

      if (resp.data.file && resp.data.file !== '') {
      } else {
        setHasUploadedFile(true);
        navigate(`${process.env.PUBLIC_URL}/importScript`);
        if (hasUploadedFile) {
          toast.error("First Upload Atleast One File");
        }
      }

      dispatch(setProjectDetails(resp.data));

      if (resp.data.artiest && resp.data.artiest.length) {

        let artiests = resp.data.artiest;
        let artiestsList = [];
        for (let i in artiests) {
          let row = artiests[i];
          artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
        }
        setArtistList(artiestsList);
      }
    }

    let resp1 = await getCrewListAction();
    if (resp1.code === 200) {
      let artiestsList1 = [];
      {
        resp1 && (resp1.data).length > 0 && (resp1.data).map((v, i) => {
          if (v.department == "000000000000000000000001") {
            console.log(v)

            artiestsList1.push({ label: v.name + " (" + v.number + ")", value: v._id });

          }
        })
      }
      setArtistList1(artiestsList1);
      // if (resp.data.artiest && resp.data.artiest.length) {

      //   let artiests = resp.data.artiest;
      //   let artiestsList = [];
      //   for (let i in artiests) {
      //     let row = artiests[i];
      //     artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
      //   }
      //   // setArtistList(artiestsList);
      // }
    }
  }

  const getList2 = async () => {
    let projectId = localStorage.getItem('projectId');
    var resp = await getArtiestList({ projectId: projectId });

    if (resp.code === 200) {
      const Llist = (resp.data);
      const filteredArray = Llist.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      const data11 = []
      for (const i in filteredArray) {
        if (JSON.stringify(filteredArray[i].projectId) === localStorage.getItem('projectId')) {
          data11.push(filteredArray[i])
        }
      }
      setArtistList(data11);
    }

    var resp = await getLocationList({ projectId: projectId });

    if (resp.code === 200) {
      const Llist = (resp.data);
      const filteredArray = Llist.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      const data11 = []
      for (const i in filteredArray) {
        if (JSON.stringify(filteredArray[i].projectId) === localStorage.getItem('projectId')) {
          data11.push(filteredArray[i])
        }
      }
      setLocationList(data11);

    }

  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);
      let msg = "You have successfully script added";
      dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));

      let formDataData = { ...formData };

      let artistNoList = [];
      for (let j in formData.artistNo) {

        let a = formData.artistNo[j];
        artistNoList.push(a.value);
      }

      formDataData.artistNo = artistNoList;

      let resp = await addOneLinerScript(formDataData);
      setDisabled(false);
      if (resp.code === 200) {
        dispatch(setOneLinerAdd(true));
        toast.success(msg);
        dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
        setValidated(false);
        setFormData({})
        //window.location.href = "/addOneLinerScript";
      } else {
        setDisabled(false);
        dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
      }
      return false;
    }
  };

  const handleChange = (name, event, extra = "") => {

    let from = { ...formData };

    if (extra === 'multi') {

      from[name] = event;

    } else if (name === 'ScheduleIds') {

      let arr = from[name] ? from[name] : [];

      if (event.target.checked) {
        arr.push(event.target.value);
      } else {
        const index = arr.indexOf(event.target.value);
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }

      from[name] = arr;

    } else {
      from[name] = event.target.value;
    }

    setFormData({ ...formData, ...from });

  };

  useEffect(() => {

    getList();

  }, []);

  return (
    <>
      <main id="main" className="main pt-4">
        <Card>
          <Card.Body className='crdBdy'>
          <Row className="mt-3 pt-2">
          <Col lg={12} className='mt-2'>
          <h6 className="crdHd1 mb-3">One Liner Script</h6>
                      <hr className="mrZero" />
          </Col>
          </Row>
              <Row className="ml-0 mr-0 pt-2 mt-3">
                <Col md="12">
                  <Row className="upperFrm justify-content-center one1">
                    <Form className="row g-2 justify-content-center mt-0" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                      
                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Sheet<span className='star'> *</span></Form.Label>
                          <Form.Control type="text"
                            className="frmcnt"
                            autoFocus
                            pattern="^(?!0+(?:,0+)*$)\d+(\.\d+)?(?:,\d+(\.\d+)?)*$"
                            placeholder='e.g.-1,15'
                            value={formData.sheet ? formData.sheet : ""} onChange={e => handleChange('sheet', e)} minLength={1} maxLength={10} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Sheet.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Scene(s)<span className='star'> *</span></Form.Label>
                          <Form.Control type="text"
                            className="frmcnt"
                            value={formData.scene ? formData.scene : ""} onChange={e => handleChange('scene', e)} pattern="^(?!0+(?:,0+)*$)\d+(\.\d+)?(?:,\d+(\.\d+)?)*$"
                            placeholder='e.g.-5' minLength={1} maxLength={32} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Scene's.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group controlId="intExt">
                          <Form.Label>Int /Ext<span className='star'> *</span></Form.Label>
                          <Form.Select aria-label="Default select example" className="frmCtrl" onChange={e => handleChange('intExt', e)} value={formData.intExt ? formData.intExt : ""} required>
                            <option value="">Select Options</option>
                            <SelectOptions obj={IntExt} />
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Int/Ext.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group controlId="dayNight">
                          <Form.Label>Day /Night<span className='star'> *</span></Form.Label>
                          <Form.Select aria-label="Default select example" className="frmCtrl" onChange={e => handleChange('dayNight', e)} value={formData.dayNight ? formData.dayNight : ""} required>
                            <option value="">Select Options</option>
                            <option value="Day">Day</option>
                            <option value="Night">Night</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Day/Night.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Set<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" className="frmcnt" minLength={1} maxLength={32} pattern="^(?!0+$)[0-9]+(?:,[0-9]+)*$" placeholder='e.g.-1,15'
                            value={formData.set ? formData.set : ""} onChange={e => handleChange('set', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Set.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          {/* 
                    pattern='^(0*[1-9]+(?: [0-9]+){0,9})$'

                    pattern = "^[0-9]+(?:,[1-9]+)*$"

                    pattern = "^" */}


                          <Form.Label>Script Pages<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" className="frmcnt" minLength={1} maxLength={32} pattern="^(?!0+$)[0-9]+(?:,[0-9]+)*$" placeholder='e.g.-1,15'
                            value={formData.scriptPage ? formData.scriptPage : ""} onChange={e => handleChange('scriptPage', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Script Page.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Script Day<span className='star'> *</span></Form.Label>
                          <Form.Select aria-label="Default select example" className="frmCtrl" onChange={e => handleChange('scriptDay', e)} value={formData.scriptDay ? formData.scriptDay : ""} required>
                            <option value="">Select Options</option>
                            <SelectOptions obj={ScriptDays} />
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Script Day.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Unit<span className='star'> *</span></Form.Label>
                          <Form.Select aria-label="Default select example" className="frmCtrl" onChange={e => handleChange('unit', e)} value={formData.unit ? formData.unit : ""} required>
                            <option value="">Select Options</option>
                            {Array.from({ length: 250 }).map((_, index) => (
                              <option key={index} value={index + 1}>{index + 1}</option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Unit.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Sequence<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" className="frmcnt" pattern="^(?!0+(?:,0+)*$)\d+(\.\d+)?(?:,\d+(\.\d+)?)*$" placeholder='e.g.-1,15'
                            value={formData.sequence ? formData.sequence : ""} onChange={e => handleChange('sequence', e)} minLength={1} maxLength={12} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Sequence.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Synopsis<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" className="frmcnt" minLength={1} maxLength={150} pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" placeholder='enter synopsis...'
                            value={formData.synopsis ? formData.synopsis : ""} onChange={e => handleChange('synopsis', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Synopsis.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Year<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" className="frmcnt" minLength={4} maxLength={4} pattern="^(17\d{2}|18\d{2}|19\d{2}|20[0-2]\d|2033)$" placeholder='e.g.-2020'
                            min={1700} max={2029} value={formData.year ? formData.year : ""} onChange={e => handleChange('year', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Select Year Between 1700 to 2029.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={3}>
                        <Form.Group>
                          <Form.Label>Artist No<span className='star'> *</span></Form.Label>
                          <><br /></>
                          {
                            artistList1.length === 0 ?
                              <ArtistBtn data={getList} className="vndrBtn" />
                              : <Select
                                value={formData.artistNo ? formData.artistNo : []}
                                placeholder="Select"
                                isMulti
                                options={artistList1}
                                className="basic-multi-select mselect frmCtrl"
                                classNamePrefix="select"
                                onChange={e => handleChange('artistNo', e, 'multi')}
                                required
                              />
                          }
                          {validated === true && ((!formData.artistNo) || formData.artistNo && formData.artistNo.length === 0) &&
                            <p className="err">This field is required</p>}
                          {/* <Form.Control.Feedback type="invalid">
                        Please select options.
                      </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>

                      <Col md={3}>
                        <Form.Group>
                          <Form.Label>Location<span className='star'> *</span></Form.Label>
                          <><br /></>
                          {
                            projectDetails.location && projectDetails.location.length === 0 ?
                              <>
                                <LocationBtn data={getList} className="vndrBtn" />
                              </>
                              :
                              <select className="form-select filedbg frmCtrl" aria-label="Default select example" onChange={e => handleChange('projectLocationId', e)} value={formData.projectLocationId ? formData.projectLocationId : ""} required>
                                <option value="">Select Location</option>
                                {projectDetails.location && projectDetails.location.length > 0 && projectDetails.location.map((item, index) => {
                                  return <option value={item._id} key={index}>{item.name}</option>
                                })}
                              </select>
                          }
                          {validated === true && ((!formData.projectLocationId) || formData.projectLocationId && formData.projectLocationId.length === 0) &&
                            <p className="err">This field is required</p>}
                          {/* <Form.Control.Feedback type="invalid">
                        Please provide a valid Location.
                      </Form.Control.Feedback> */}
                        </Form.Group>
                      </Col>

                      <Col md={2}>
                        <Form.Group>
                          <Form.Label>Est. Time<span className='star'> *</span></Form.Label>
                          <Form.Control type="time"
                            value={formData.estTime ? formData.estTime : ""} className="frmcnt" onChange={e => handleChange('estTime', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid Est. Time.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col md={4}>
                        <Form.Group>
                          <Form.Label>Comments<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" className="frmcnt" maxLength={150} minLength={1} pattern="^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" placeholder='enter comments....'
                            value={formData.comments ? formData.comments : ""} onChange={e => handleChange('comments', e)} required />
                          <Form.Control.Feedback type="invalid">
                            Please provide Comments.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Row className="align-item-left">
                        <Col lg={2} className="mt-3 text-center g-2">
                          <Button type="submit" className="lgbt1 vndrBtn" disabled={isDisabled}>
                            Submit
                          </Button>
                        </Col>

                        <Col lg={1} className="mt-3 text-center">
                          <Button type="Button" className="border border-dark lgbt1 vndrBtn" onClick={e => { setFormData({}); setValidated(false) }} >
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Row>

                  <Row className="mt-4 gx-4">
                    <Col md={5} className="bgcl23 pl-0 pr-0">
                      <PdfPreviewDB project={projectDetails} />
                    </Col>
                    <Col md={7}>
                      <SelectShedule />
                    </Col>
                  </Row>
                </Col>
              </Row>
          </Card.Body>
        </Card>
      </main>
    </>
  );
}

export default OneLinerScript;