import React, { useState, memo, useEffect } from "react";
import { Card, Col, Container, Form, Row, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import Scene1 from '../../assets/images/OldProImg/Scenes/Scene1.png';
import city1 from '../../assets/images/OldProImg/city1.png';
import travel2 from '../../assets/images/OldProImg/travel2.png';
import travel3 from '../../assets/images/OldProImg/travel3.png';
import travel4 from '../../assets/images/OldProImg/travel4.png';
// import progress2 from '../../assets/img/vendor/p2.png';
// import progress3 from '../../assets/img/vendor/p3.png';
// import pv1 from '../../assets/img/PictureVehicle/car1.png'
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { setAlert } from "../../slices/home";
import AlertBox from "../component/AlertBox";
import { listSceneScheduleAction } from "../../action/Admin.action";
// import { Link } from "react-router-dom";
import { Link, useNavigate } from 'react-router-dom';
import { imgPath } from '../common/function'


const SceneOption = () => {
    const [modalShow, setModalShow] = useState(false);
    const [Step, setStep] = useState(1);

    const [modalShow1, setModalShow1] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [sceneList, setSceneList] = useState([]);
    const masters = useSelector((state) => state.home.masters);
    const navigate = useNavigate();

    const getList = async () => {
        const resp = await listSceneScheduleAction();
        if (resp.code === 200) {
            setSceneList(resp.data);
        }
    }
    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            // let resp = await listSceneScheduleAction();
            // console.log(resp);
            let resp;
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });

    }
    useEffect(() => {
        getList();
    }, [])

    return (
        <>

            <div className="pt-4">
               <Card>
                <Card.Body className="crdBdy">
                <Row className="mt-3 pt-2">
                {/* <i className="fa fa-arrow-left hand" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}></i> */}
                    <Col lg={10} className="col-6 col-sm-6 col-lg-3 pt-2"><h6 className="crdHd1">City To City Travel</h6></Col>
                    {/* <Col lg={2} className="col-6 col-sm-6 text-end px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}>Back</Button></Col> */}
                    <Col lg={2} className="col-6 col-sm-6 text-start px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={handleShow}>+ Add</Button></Col>
                </Row>
                <hr />

                <Row>
                    <Col lg={4} className=" mt-3" >
                        <Card style={{ borderRadius: '20px' }} >
                            <Container>
                                <Card.Title className="pt-2 mb-0" ></Card.Title>
                                <h6  >Main Actor</h6>
                                <Card.Body className="m-0 p-0">
                                    <Card.Text class="d-inline-block text-truncate  mb-0" style={{ maxWidth: '90%' }}>
                                    </Card.Text>
                                </Card.Body>
                                <Row>
                                    <Col className="col-3 text-end  col-lg-3">
                                        <h6 className="smsz1">05:45</h6>
                                        <h6 className="smsz1">DEL</h6>
                                    </Col>
                                    <Col className="col-6 col-lg-6">
                                        <img src={city1} alt="" className="img-fluid" width="100%"/>
                                    </Col>
                                    <Col className="col-3 col-lg-3">
                                        <h5 className="smsz1">07:50</h5>
                                        <h6  className="smsz1">CCU</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className=" text-Start">
                                        <p className="cityPara">indira Gandhi International Airport </p>
                                    </Col>
                                    <Col className=" text-end ">
                                        <p className="cityPara">Subhash Chandra Bose International Airport</p>
                                    </Col>
                                </Row>
                            </Container>
                        </Card>
                    </Col>
                </Row>
                </Card.Body>
               </Card>

               

            </div>


            {/* <Modal show={show} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <Modal.Title>Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                            <Row>
                                <Col lg={4} className="txt">
                                    <Form.Group className="mb-3" controlId="name">
                                        <Row><Col><Form.Label>Vehicle Name</Form.Label>
                                        </Col></Row>
                                        <Form.Control className="brdr" type="text" onChange={e => handleChange('name', e)} maxLength={45} value={formData.name ? formData.name : ""} required />
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group className="mb-3" controlId="vendorType">
                                        <Form.Label>Type of vehicle</Form.Label>
                                        <Form.Select className="brdr" aria-label="Default select example"
                                            onChange={e => handleChange('vendorType', e)} value={formData.vendorType ? formData.vendorType : ""} required>
                                            <option value=""></option>
                                            <option value="vendor1">vendor1</option>
                                            <option value="vendor2">vendor2</option>
                                            <option value="vendore3">vendor3</option>
                                            <option value="vendor4">vendor4</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group controlId="registeredNumber">
                                        <Row><Col><Form.Label>Vehicle for scene no.</Form.Label>
                                        </Col></Row>
                                        <Form.Control className="brdr" type="text" onChange={e => handleChange('registeredNumber', e)} value={formData.registeredNumber ? formData.registeredNumber : ""} maxLength={10} minLength={10} pattern="[0-9]*" />
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group className="mb-3" controlId="name">
                                        <Row><Col><Form.Label>Vendore Name</Form.Label>
                                        </Col></Row>
                                        <Form.Control className="brdr" type="text" onChange={e => handleChange('name', e)} maxLength={45} value={formData.name ? formData.name : ""} required />
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group className="mb-3" controlId="vendorRole">
                                        <Form.Label>Location</Form.Label>
                                        <Form.Select className="brdr" aria-label="Default select example"
                                            onChange={e => handleChange('vendorRole', e)} value={formData.vendorRole ? formData.vendorRole : ""} required>
                                            <option value=""></option>
                                            <option value="vendorRole1">vendor Role1</option>
                                            <option value="vendorRole2">vendor Role2</option>
                                            <option value="vendorRolee3">vendor Role3</option>
                                            <option value="vendorRole4">vendor Role4</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group className="mb-3" controlId="vendorRole">
                                        <Form.Label>Vehicle for whom</Form.Label>
                                        <Form.Select className="brdr" aria-label="Default select example"
                                            onChange={e => handleChange('vendorRole', e)} value={formData.vendorRole ? formData.vendorRole : ""} required>
                                            <option value=""></option>
                                            <option value="vendorRole1">vendor Role1</option>
                                            <option value="vendorRole2">vendor Role2</option>
                                            <option value="vendorRolee3">vendor Role3</option>
                                            <option value="vendorRole4">vendor Role4</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group controlId="registeredNumber">
                                        <Row><Col><Form.Label>Price</Form.Label>
                                        </Col></Row>
                                        <Form.Control className="brdr" type="text" onChange={e => handleChange('registeredNumber', e)} value={formData.registeredNumber ? formData.registeredNumber : ""} maxLength={10} minLength={10} pattern="[0-9]*" />
                                    </Form.Group>
                                </Col>

                                <Col lg={4} className="txt">
                                    <Form.Group className="mb-3" controlId="vendorRole">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select className="brdr" aria-label="Default select example"
                                            onChange={e => handleChange('vendorRole', e)} value={formData.vendorRole ? formData.vendorRole : ""} required>
                                            <option value=""></option>
                                            <option value="vendorRole1">vendor Role1</option>
                                            <option value="vendorRole2">vendor Role2</option>
                                            <option value="vendorRolee3">vendor Role3</option>
                                            <option value="vendorRole4">vendor Role4</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col className="text-end">
                                    <Button variant="secondary" className="btnk" onClick={e => setStep(Step + 1)}>
                                        Next
                                    </Button>
                                </Col>
                            </Row>
                </Modal.Body>
            </Modal> */}
        </>
    );
}

export default memo(SceneOption);