import React, { useState, useEffect, Fragment, memo } from 'react';
import { Row, Col, Table } from "react-bootstrap";
import { projectListing } from "../../action/Admin.action";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert, setOneLinerAdd } from '../../slices/home';
import { getOneLinerScriptList, getCrewListAction } from '../../action/Admin.action';
import Form from 'react-bootstrap/Form';

const SheduleOneLinerScriptListing = (props) => {

    const dispatch = useDispatch();
    const [list, setList] = useState({});
    const [formData, setFormData] = useState({});
    const [crewList, setCrewList] = useState({});

    const oneLinerAdd = useSelector((state) => state.home.oneLinerAdd);

    const getList = async () => {

        dispatch(setOneLinerAdd(false));

        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getOneLinerScriptList();
        console.log(resp);
        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
        
        if (resp.code === 200) {
            setList(resp.data);
        }

        const resp1 = await getCrewListAction();
        if(resp1.code===200){
            setCrewList(resp1.data)
        }

    }

    useEffect(() => {
        getList();
    }, []);

    useEffect(() => {
        if(oneLinerAdd === true){
            getList();
        }        
    }, [oneLinerAdd]);

    const handleChange = (name, event) => {
        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });
    };

    const ArtistList = ({data})=>{
        
        const [artists, setArtists] = useState([]);

        useEffect(()=>{

            let l = [];
            for(let i in data){
                l.push(data[i].number);
            }

            setArtists(l);

        }, [])

        return artists.join(', ');

    }    

    return (
        <div className="textBox bgc26 mt-3">
            <Row>
                <Col md={12} className="px-5"><h6 >One Liner Script</h6></Col>
                {/* <Col md={7} className="px-5">
                  <div className="input-group rounded">
                    <input type="search" className="form-control rounded" placeholder="Search scene/page no/ Keyword" aria-label="Search" aria-describedby="search-addon" />
                    <span className="input-group-text border-0" id="search-addon">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </Col> */}
                <Col lg={12} className="mt-3">
                    <Table striped bordered hover className="scenes-table">
                        <thead className="headingtbl">
                            <tr>
                                <th className="tableHead" scope="col">Scene No</th>
                                <th className="tableHead" scope="col">Title</th>
                                <th className="tableHead" scope="col">Ext/Int</th>
                                <th className="tableHead" scope="col">D/N</th>
                                <th className="tableHead" scope="col">Year</th>
                                <th className="tableHead" scope="col">Page</th>
                                <th className="tableHead" scope="col">Artist</th>.
                            </tr>
                        </thead>
                        <tbody className="listing">
                            {
                                list && list.length > 0 && list.map((val, ind) => {
                                    
                                    return <Fragment key={ind} >

                                        <tr>
                                            <td>
                                                <label>
                                                    <input type="checkbox" onChange={(event)=>props.setSceneIds(event)}  value={val._id} /> {val.scene}
                                                </label>
                                            </td>

                                            <td className='truncate-text' title={val.synopsis}>{val.synopsis}</td>
                                            <td>{val.intExt}</td>
                                            <td>{val.dayNight}</td>
                                            <td>{val.year}</td>
                                            <td>{val.scriptPage}</td>
                                            <td><ArtistList data={val.artistData} /></td>
                                        </tr>
                                    </Fragment>
                                })
                            }
                            {list == 0 ? 'Data not avaialble.' : ''}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
}

export default memo(SheduleOneLinerScriptListing);
