import { Col, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { addSchedule } from "../../action/Admin.action";
import { setAlert } from '../../slices/home.js';
import { Link } from "react-router-dom";
import ScheduleFrm from "./addScheduleForm.js";
import {getScheduleListAction} from '../../action/Admin.action.js'


const ScheduleBtn = (props) => {

    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [show1, setShow1] = useState(false);


    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    // const handleSubmit = async (event) => {
    //     const form = event.currentTarget;

    //     var projectId = localStorage.getItem("projectId");


    //     if (form.checkValidity() === false) {

    //         event.preventDefault();
    //         event.stopPropagation();
    //         setValidated(true);

    //     } else {
    //         event.preventDefault();
    //         event.stopPropagation();

    //         var pair = { projectId: projectId }
    //         var obj = { ...formData, ...pair };
    //         let resp = await addSchedule(obj);

    //         if (resp.code === 200) {
    //             dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
    //             props.data();
    //             setFormData({});
    //             handleShow1();
    //         } else {
    //             dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
    //         }

    //         return false;
    //     }
    // };

    // const handleChange = (name, event) => {

    //     let from = { ...formData };
    //     from[name] = event.target.value;
    //     setFormData({ ...formData, ...from });

    // }

//   useEffect(() => {
//     getList();
//   }, []);

// const getList = async () => {
//     const resp = await getScheduleListAction();

//     if (resp.code === 200) {
//         // props.getList();
//     }
//   }

//   useEffect(()=>{
//     getList();
//   },[])

    return (

        <>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header className="mdlHdr" closeButton onClick={()=>setValidated(false)}>
                    <Modal.Title>
                        <h6 className="crdHd1">Add Schedule</h6>
                    </Modal.Title>
                </Modal.Header>
                <hr className="mrZero"/>
                <Modal.Body>
                    <ScheduleFrm />
                </Modal.Body>
            </Modal>
            <Button type="Button" className="font12 border border-dark" variant="light" onClick={e=>{handleShow1();}}>
                + Schedule
            </Button>
        </>
    );
}

export default ScheduleBtn;