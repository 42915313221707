import React, { useState, memo, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Table, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import location1 from '../../assets/images/OldProImg/location1.png';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import Modal from 'react-bootstrap/Modal';
import { setAlert } from "../../slices/home";
import { addEditStayMangemnetAction } from "../../action/Admin.action";
import { getCrewListAction } from "../../action/Admin.action";
import { imgPath } from "../common/function";
import Select from 'react-select';
import moment from 'moment';
import line1 from '../../assets/images/OldProImg/line1.png'

const StayDetails = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [artistList, setArtistList] = useState([]);
    const [newart, setNewart] = useState([]);
    const [ldata, setLdata] = useState(location.state)
    const navigate = useNavigate();

    // const getList1 = async () => {
    //     let resp = await projectDetailAction();
    //     if (resp.code === 200) {
    //         if (resp.data.artiest && resp.data.artiest.length) {
    //             let artiests = resp.data.artiest;
    //             let artiestsList = [];
    //             for (let i in artiests) {
    //                 let row = artiests[i];
    //                 artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
    //             }
    //             setArtistList(artiestsList);
    //         }
    //     }

    // }
    const getList1 = async () => {
        let resp = await getCrewListAction();
        if (resp.code === 200) {
            let artiestsList = [];
            {resp && (resp.data.length>0 && (resp.data).map((v,i)=>{
                if(v.department=="000000000000000000000001"){
                    artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
                }
            }))}
            setArtistList(artiestsList);
        }

    }

    const getList2 = async () => {
        let newArray = artistList.filter(item => ldata.artiestId.includes(item.value));
        setNewart(newArray)
    }


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let postData = new FormData();
            for (let key in formData) {
                if (key == 'artiestId') {

                } else {
                    postData.append(key, formData[key]);
                }
            }

            let artistNoList = [];
            for (let j in formData.artiestId) {

                let a = formData.artiestId[j];
                artistNoList.push(a.value);
            }
            postData.append('artiestId', JSON.stringify(artistNoList));

            let resp = await addEditStayMangemnetAction(postData);
            setDisabled(false);

            setValidated(true);
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: "Updated Successfully", type: '' }));
                handleClose();
                setLdata(resp.data)
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            setDisabled(false);
            return false;
        }
    };

    const handleChange = (name, event, extra = "") => {
        if (name == "checkIn" || name == "checkOut") {
            const selectedStartDate = event.target.value;
            setStartDate(selectedStartDate);

            const endDateInput = document.getElementById("checkOut");
            if (endDateInput) {
                endDateInput.min = selectedStartDate;
                if (selectedStartDate > endDate) {
                    setEndDate("");
                }
            }


            const selectedEndDate = event.target.value;
            setEndDate(selectedEndDate);
            const startDateInput = document.getElementById("checkIn");
            if (startDateInput) {
                startDateInput.max = selectedEndDate;
                if (startDate && selectedEndDate < startDate) {
                    setStartDate("");
                }
            }
        }

        let from = { ...formData };
        if (extra === 'multi') {
            from[name] = event;
        } else if (name === 'artiestId') {
            let arr = [];

            if (event.target.checked) {
                arr.push(event.target.value);
            } else {
                const index = arr.indexOf(event.target.value);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }
            from[name] = arr;
        } else {
            from[name] = event.target.value;
        }
        setFormData({ ...formData, ...from });


    };



    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {

            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";

        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    useEffect(() => {
        let startD = (moment(ldata.checkIn).format("yyyy-MM-DD"))
        let endD = (moment(ldata.checkOut).format("yyyy-MM-DD"))
        setFormData({ title: ldata.title, hotelName: ldata.hotelName, country: ldata.country, state: ldata.state, city: ldata.city, artiestId: newart, prices: ldata.prices, image: ldata.image, status: ldata.status, _id: ldata._id, desc: ldata.desc, checkIn: startD, checkOut: endD });
    }, [newart])

    useEffect(() => {
        getList1();
        getList2();
        let startD = (moment(ldata.checkIn).format("yyyy-MM-DD"))
        let endD = (moment(ldata.checkOut).format("yyyy-MM-DD"))
        setFormData({ title: ldata.title, hotelName: ldata.hotelName, country: ldata.country, state: ldata.state, city: ldata.city, artiestId: newart, prices: ldata.prices, image: ldata.image, status: ldata.status, _id: ldata._id, desc: ldata.desc, checkIn: startD, checkOut: endD });
    }, [])

    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy">
                    <Row className="mb-3 pt-4 mb-2">
                    <Col className="col-2 col-lg-8 col-sm-5 col-md-7"><h6 className="crdHd1">Stay Information</h6></Col>
                    {/* <Col className="col-7 col-md-9 text-end col-lg-2"><Button className="bnt2 bntCr myCr smsz btnClre" onClick={() => { navigate('/stay-option') }} >Back</Button></Col> */}
                    <Col className="text-end col-10 col-lg-4 col-md-5 col-sm-4 col">
                    <Button className="bnt2 bntCr myCr me-1 smsz btnClre" onClick={() => { navigate(`${process.env.PUBLIC_URL}/stay-option`) }} >Back</Button>
                    <Button className="smsz bntCr myCr btnClre" onClick={() => { handleShow(); getList2() }}>Edit Stay Info.</Button></Col>
                </Row>
                <hr />
                <Row>
                    <Col lg={8}>
                        <Row >
                            {/* <Col lg={12}>
                                <h5 className="smsz">Stay Information</h5>
                            </Col> */}
                            <Col lg={12}>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                        <Card.Text className="mt-3">
                                            <Table>
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="tblPr"><b>Stay Title</b></td>
                                                        <td className="tblP">{ldata.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>Hotel Name</b></td>
                                                        <td className="tblP">{ldata.hotelName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>Country</b></td>
                                                        <td className="tblP">{ldata.country}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>State</b></td>
                                                        <td className="tblP">{ldata.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>City</b></td>
                                                        <td className="tblP">{ldata.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>Place for whom</b></td>
                                                        <td className="tblP">{artistList && artistList.length > 0 &&
                                                            artistList.map((item, index) => {
                                                                return (
                                                                    ldata && ldata.artiestId.length && ldata.artiestId.map((v1, i1) => {

                                                                        if (item.value == v1) {
                                                                            let nm = [item.label, " ,"]
                                                                            return (nm)
                                                                        }
                                                                    })

                                                                )
                                                            })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>CheckIn Date</b></td>
                                                        <td className="tblP">{(moment(ldata.checkIn).format("DD-MM-yyyy"))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>Check Out Date</b></td>
                                                        <td className="tblP">{(moment(ldata.checkOut).format("DD-MM-yyyy"))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>Price</b></td>
                                                        <td className="tblP">{ldata.prices}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"><b>Status</b></td>
                                                        <td className="tblP">{ldata && ldata.status ? "Active" : "Inactive"}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={4} className="text-center">
                        <Row>
                            {/* <Col lg={12}>
                                <h5 className="smsz">Stay Image</h5>
                            </Col> */}

                            <Col>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                   
                                        <Card.Text className="mt-3">Stay Image</Card.Text>
                                        <img className="sdImg" src={ldata.image == "" ? location1 : imgPath(ldata.image)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Modal size="lg" show={show} onHide={handleClose} animation={false} >
                        <Modal.Header className="mdlHdr size1 brdrR pt-2 pb-1" closeButton>
                            <Row>
                                <Col lg={12}><h6 >Stay Management</h6></Col>
                            </Row>

                        </Modal.Header>
                        <Row>
                            <Col>
                                <img src={line1} alt="" className="img-fluid12" />
                            </Col>
                        </Row>
                        <Modal.Body className="pt-0">
                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="title">
                                            <Form.Label>Title<span className='star'>*</span></Form.Label>
                                            <Form.Control className="brdr frmcnt" type="text" placeholder="Title Name" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" onChange={e => handleChange('title', e)} value={formData.title ? formData.title : ""} minLength={1} maxLength={40} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a  valid Title.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="hotelName">
                                            <Row><Col><Form.Label>Hotel Name<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" placeholder="Name of Hotel" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" onChange={e => handleChange('hotelName', e)} value={formData.hotelName ? formData.hotelName : ""} minLength={1} maxLength={30} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a  valid Hotel Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="artiestId" className="txt">
                                            <Form.Label >For Whom<span className='star'>*</span></Form.Label>
                                            <Select className="brdr1 mselect frmcnt" aria-label="Default select example"
                                                value={formData.artiestId ? formData.artiestId : []}
                                                placeholder="Select"
                                                isMulti
                                                required
                                                options={artistList}
                                                // className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={e => handleChange('artiestId', e, 'multi')}
                                                isValid={formData.artiestId && formData.artiestId.length > 0}>
                                                {
                                                    artistList && artistList.length > 0 && artistList.map((val, ind) => {
                                                        return <option value={(val.value)} key={ind}  >{val.label}</option>
                                                    })
                                                }
                                            </Select>
                                            {validated === true && ((!formData.artiestId) || formData.artiestId && formData.artiestId.length === 0) &&
                                                <p className="err">This field is required</p>}
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="country">
                                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                                                <GetAllCountries />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a  Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="state">
                                            <Form.Label>State<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                                                <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a  State.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="city">
                                            <Form.Label>City<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                                                <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a  City.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4}
                                        className="txt">
                                        <Form.Group className="mb-1" controlId="desc">
                                            <Form.Label>Location Description<span className='star'>*</span></Form.Label>
                                            <Form.Control as="textarea" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" onChange={e => handleChange('desc', e)} value={formData.desc ? formData.desc : ""} rows={5} minLength={2} maxLength={100} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a  valid Location Description.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={8}>
                                        <Row>
                                            <Col className="txt">
                                                <Form.Group className="mb-1" controlId="checkIn">
                                                    <Form.Label className="txt">Check In<span className='star'>*</span></Form.Label>
                                                    <Form.Control className="frmcnt" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.checkIn ? formData.checkIn : ""} onChange={e => handleChange('checkIn', e)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Start Date.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="txt">
                                                <Form.Group className="mb-2" controlId="checkOut">
                                                    <Form.Label className="txt">Check Out<span className='star'>*</span></Form.Label>
                                                    <Form.Control className="frmcnt" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.checkOut ? formData.checkOut : ""} onChange={e => handleChange('checkOut', e)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Start Date.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col className="txt">
                                                <Form.Group className="mb-2" controlId="prices">
                                                    <Row><Col><Form.Label>Price<span className='star'>*</span></Form.Label>
                                                    </Col></Row>
                                                    <Form.Control className="brdr frmcnt" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' type="text" placeholder="20000" onChange={e => handleChange('prices', e)} value={formData.prices ? formData.prices : ""} minLength={1} maxLength={10} required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a  valid Price.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="txt">
                                                <Form.Group controlId="status">
                                                    <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                    <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                        placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                        <option value="" >Select Status</option>
                                                        <option value="1" >Active</option>
                                                        <option value="0" >InActive</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a  valid Status.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="image">
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control className="brdr frmcnt" type="file" ize="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg"
                                                onChange={e => fileChangedHandler(e, 'image')}
                                            />
                                        </Form.Group>
                                        <Form.Control.Feedback type="invalid">
                                            It only accept png, jpg, jpeg files.
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col lg={4} className='imgcss'>
                                        <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />
                                    </Col>
                                </Row>
                                <Row className="mt-2 g-2 mx-2">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                        <Button className="btnk cldrBtn btnn" onClick={() => { handleClose() }}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                        <Button type='submit' className="btnk cldrBtn btnn"
                                            disabled={isDisabled}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default memo(StayDetails);