import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import { Provider } from 'react-redux';
import store from '../store';
import LocationDetails from '../admin/pages/LocationDetails'
import HomePage from "../FrontPage/Componets/Main/Main.js";

// setup fake backend

const Routers = () => {

  const [login, setLogin] = useState({});
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {

    console.log(localStorage.getItem("loginData"));

    if (localStorage.getItem("loginData") && localStorage.getItem("loginData") != '') {

      console.log(localStorage);

      // let loginData = JSON.parse(localStorage.getItem("loginData"));
      // if(loginData && loginData[0]){
      //   setLogin(loginData[0]);
      // }

    }
    let abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);



  return (
    <BrowserRouter basename={"/"}>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}/`} element={<HomePage />} />
            <Route path={"/"} element={<PrivateRoute />}>
              {login || authenticated ? (
                <>
                  <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />} />
                  <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default`} />} />
                </>
              ) : (
                ""
              )}
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}/admin/login`} element={<Signin />} />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </Provider>
    </BrowserRouter>
  );
};

export default Routers;
