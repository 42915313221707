import React, { useState, useEffect, Fragment, memo } from 'react';
import { Card, Col, Container, form, Row, Button, Form } from "react-bootstrap";
import { addSceneScheduleAction, getScheduleListAction } from "../../action/Admin.action";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert, setOneLinerAdd } from "../../slices/home";
import AlertBox from "../component/AlertBox";
import SheduleOneLinerScriptListing from "./SheduleOneLinerScriptListing";
import ScheduleBtn from '../component/AddScheduleCom';
import moment from 'moment';

const SelectShedule = () => {

  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.home.refresh);
  const [formData, setFormData] = useState({ scheduleId: "" });
  const [dateError, setDateError] = useState('');
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [scheduleList, setScheduleList] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  


  const getList = async () => {
    const resp = await getScheduleListAction();

    if (resp.code === 200) {
      setScheduleList(resp.data);
    }
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      let msg = "You have successfully scene schedule.";

      let resp = await addSceneScheduleAction(formData);
      setDisabled(false);
      if (resp.code === 200) {
        // getList();
        dispatch(setOneLinerAdd(true));
        dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
        setFormData({});
        setValidated(false)
      } else {

        dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));

      }
      return false;
    }
  };

  const handleChange = (name, event) => {


    let from = { ...formData };
    if (name === 'sceneIds') {
      let arr = from[name] ? from[name] : [];
      if (event.target.checked) {

        arr.push(event.target.value);
      } else {
        const index = arr.indexOf(event.target.value);
        if (index !== -1) {
          arr.splice(index, 1);
        }
      }

      from[name] = arr;

    } else {
      from[name] = event.target.value;
    }

    if (name === 'scheduleId') {
      let scId = event.target.value;
      var result = scheduleList.find(e => e._id == scId);
      var std1 = moment(result.startDate).format("yyyy-MM-DD");
      setStartDate(std1)
      var end1 = moment(result.endDate).format("yyyy-MM-DD");
      setEndDate(end1)
      var finalval = { scheduleId: scId, startDate:std1, endDate:end1 };
      
    }  


    const selectedEndDate = endDate === undefined ? event.target.value : endDate;
    setEndDate(selectedEndDate);

    const startDateInput = document.getElementById("startDate");
    setStartDate(startDateInput)
    if (startDateInput) {
      startDateInput.max = selectedEndDate;
      if (startDate && selectedEndDate <= startDate) {
        setStartDate(selectedEndDate);
      }
    }
    

    const selectedStartDate = startDate === undefined ? event.target.value : startDate;
    setStartDate(selectedStartDate);
    const endDateInput = document.getElementById("endDate");
    setEndDate(endDateInput)
    if (endDateInput) {
      endDateInput.min = selectedStartDate;
      if (selectedStartDate >= endDate) {
        setEndDate(selectedStartDate);
      }
    }
    setFormData({ ...formData, ...from, ...finalval }); 
  };

  useEffect(() => {
    getList();
  }, []);
  useEffect(() => {
    getList();
  }, [!refresh]);

  console.log(refresh)

  return (
    <Col md={12} className="pt-3 bgcl25">
      <SheduleOneLinerScriptListing setSceneIds={e => handleChange('sceneIds', e)} isDisabled={isDisabled} />
      <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>



        <Row className="mt-3 pt-1 pb-1 schedule-form ml-0 mr-0">
        <Col lg={12} className='text-black text-center sch'>
            Select Schedule
          </Col>
         
          <Col lg={4} className="px-2 FrWdth">
            <Form.Group >
              {
                scheduleList.length === 0 ? <div className='text-end'><ScheduleBtn /></div> :
                  <select className="form-select filedbg frmCtrl"  aria-label="Default select example" onChange={e => handleChange('scheduleId', e)} value={formData.scheduleId ? formData.scheduleId : ""} required>
                    <option value="" disabled>Schedule</option>
                    {scheduleList.length && scheduleList.map((item, index) => {
                      return <option value={item._id} key={index} >{item.name}</option>
                    })}
                  </select>
              }
            </Form.Group>
          </Col>

          <Col lg={3} className="px-1 FrWdth">
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Control className='setD' style={{height: "2rem", fontSize: "12px"}} type="date" placeholder="Start date" value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} min={formData.startDate}
                max={formData.endDate} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid start date.
              </Form.Control.Feedback>
            </Form.Group>

          </Col>

          <Col lg={3} className="px-1 FrWdth">
            <Form.Group className="mb-3 setD" controlId="endDate">
              <Form.Control className='setD' style={{height: "2rem", fontSize: "12px"}}  type="date" placeholder="End date" value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} min={formData.startDate}
                max={formData.endDate} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid end date.
              </Form.Control.Feedback>
            </Form.Group>

          </Col>
          
          <Col lg={2} className='FrWdth'>
            <Button type="submit" className="sceneBtnSv" variant="dark" disabled={isDisabled} >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}

export default memo(SelectShedule);