import { Card, Col, Container, form, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import React, { useState } from "react";
import { addEditCrewAction } from "../../action/Admin.action";
import { setAlert } from '../../slices/home.js';
import { Link } from "react-router-dom";
import { checkName, checkNumber, emailValidation, checkMobileNumber } from "../common/function";

const ArtistBtn = ({data}) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ department: "000000000000000000000001" });
  const [isDisabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [show1, setShow1] = useState(false);


  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    var projectId = localStorage.getItem("projectId");


    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      var pair = { projectId: projectId }
      var obj = { ...formData, ...pair };
      let resp = await addEditCrewAction(obj);

      if (resp.code === 200) {
        dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
        data();
        setFormData({ department: "000000000000000000000001" });
        setValidated(false);
      } else {
        setDisabled(false);
        dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
      }

      return false;
    }
  };

  const handleChange = (name, event) => {

    let from = { ...formData };
    from[name] = event.target.value;
    setFormData({ ...formData, ...from });

  }

  return (

    <>
      <Modal show={show1} >
        <Modal.Header className="mdlHdr">
          <h6 className="crdHd1">Add Artist</h6>
        </Modal.Header>
        <hr className="mrZero" />
        <Modal.Body>
          <Form className="p-5 pt-0 pb-0" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Control
                type="text"
                className="frmcnt"
                pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                placeholder="Name"
                autoFocus
                minLength="2"
                maxLength="32"
                value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="number">
              <Form.Control
                type="text"
                className="frmcnt"
                pattern='^(0*[1-9][0-9]{0,9})$'
                placeholder="Number"
                minLength="1"
                maxLength="10"
                value={formData.number ? formData.number : ""} onChange={e => handleChange('number', e)} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid number.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                className="frmcnt"
                pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                placeholder="Email"
                value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="mobile">
              <Form.Control
                type="text"
                className="frmcnt"
                placeholder="Mobile"
                pattern='^[6-9][0-9]{9}$'
                value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange('mobile', e)} minLength={10} maxLength={10} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid mobile.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="characterName">
              <Form.Control
                type="text"
                className="frmcnt"
                pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                minLength={1}
                maxLength={32}
                placeholder="character Name"
                value={formData.characterName ? formData.characterName : ""} onChange={e => handleChange('characterName', e)} required />
              <Form.Control.Feedback type="invalid">
                Please provide a valid character Name.
              </Form.Control.Feedback>
            </Form.Group>

            <Row className="mt-4 mb-4 g-2">
              <Col lg={6}>
                <Button type="submit" className="vndrBtn" onSubmit={() => { handleSubmit() }}>
                  Add
                </Button>
              </Col>
              <Col lg={6} className="text-end">
                <Button className="vndrBtn" onClick={() => { handleClose1(); setFormData({}); setValidated(false) }} >
                  cancel
                </Button></Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <Button type="Button" className="vndrBtn" onClick={handleShow1}>
        + Artist
      </Button>
    </>
  );
}

export default ArtistBtn;