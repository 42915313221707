import { react, useEffect, useState, memo } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SheetData } from '../common/constant';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { getVendorDataAction, getVehicleDataAction, getStayMangemnetDataAction, getArtiestList, listSceneScheduleAction } from '../../action/Admin.action';
import { generateExcel, generatePDF1, generateExcel1 } from '../common/function';
import { setAlert } from '../../slices/home';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";



const SheetGen = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [newDt, setNewDt] = useState();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dataNew, setDataNew] = useState({});
    const [dataNew1, setDataNew1] = useState({});
    const [dataNew2, setDataNew2] = useState({});
    const [dataNew3, setDataNew3] = useState({});
    const dispatch = useDispatch();


    const callList1 = async () => {
        const resp = await getVendorDataAction();
        if (resp.code == 200) {
            setDataNew(resp.data)
        }
        const resp1 = await getStayMangemnetDataAction();
        if (resp1.code == 200) {
            setDataNew1(resp1.data)
        }
        const resp2 = await getVehicleDataAction();
        if (resp2.code == 200) {
            setDataNew2(resp2.data)
        }
        const resp3 = await listSceneScheduleAction();
        if (resp3.code == 200) {
            setDataNew3(resp3.data)
        }
    }

    const handleClick = (ind, name) => {
        if (ind === 0) {
            navigate(`${process.env.PUBLIC_URL}/crewList`);
        } else {
            handleShow();
            setNewDt(name);
        }
    };

    const callList = (name) => {
        if (name === "Vendor") {
            if (Object.keys(dataNew).length === 0) {
                toast.error("Data Not Available");
            } else {
    const headers = [['S.no.', 'Name', 'Department','Designation','Mobile','Email']];

                let newData = dataNew.map((item, no) => ({
                    "S.No": no + 1,
                    Name: item.name,
                    Type: item.type,
                    Role: item.role,
                    "Mobile.No": item.number,
                    City: item.city
                }));
                // generateExcel(newData, name);
                generatePDF1(newData, name,headers);
                handleClose();
            }
        }
        else if (name === "DPR") {
            let dt = {}
            if (Object.keys(dt).length === 0) {
                toast.error("Data Not Available");

            } else {
                generateExcel(dt)
            }
        }
        else if (name === "Hotel Stay List") {
            if (Object.keys(dataNew1).length === 0) {
                toast.error("Data Not Available");
            } else {
                let newData1 = dataNew1.map((item, no) => ({
                    "S.No": no + 1,
                    Title: item.title,
                    "Hotel Name": item.hotelName,
                    "Check In": moment(item.checkIn).format('DD/MM/YYYY'),
                    "Check Out": moment(item.checkOut).format('DD/MM/YYYY')
                    ,
                    City: item.city
                }));
                generateExcel(newData1, name);
                handleClose();
            }
        }
        else if (name === "Transport") {
            let dt = {}
            if (Object.keys(dt).length === 0) {
                toast.error("Data Not Available");
            } else {
                generateExcel(dt);
                handleClose();

            }
        }
        else if (name === "Travel") {
            if (Object.keys(dataNew2).length === 0) {
                toast.error("Data Not Available");

            } else {
                let newData2 = dataNew2.map((item, no) => ({
                    "S.No": no + 1,
                    "Vehicle Name": item.name,
                    "Vehicle no.": item.vehicleNumber,
                    "Driver Name": item.driverName,
                    "Driver No.": item.driverNumber,
                    "Type Of Vehicle": item.typeOfVehicle,
                    "Insurance": item.insuranceStatus,
                    "Location": item.location,
                    "Price": item.price,
                }));
                generateExcel(newData2, name);
                handleClose();
            }
        }
        else if (name === "Costume Breakdown") {
            let dt = {}
            if (Object.keys(dt).length === 0) {
                toast.error("Data Not Available");
            } else {
                generateExcel(dt)
            }
        }
        else if (name === "Juniors Breakdown") {
            let dt = {}
            if (Object.keys(dt).length === 0) {
                toast.error("Data Not Available");
            } else {
                generateExcel(dt);
                handleClose();

            }
        }
        else if (name === "Call Sheet") {
            let dt = {}
            if (Object.keys(dt).length === 0) {
                toast.error("Data Not Available");
            } else {
                generateExcel(dt);
                handleClose();

            }
        }
        else if (name === "Art Breakdown") {
            let dt = {}
            if (Object.keys(dt).length === 0) {
                toast.error("Data Not Available");
            } else {
                generateExcel(dt);
                handleClose();

            }
        }
        else if (name === "Master Breakdown") {
            if (Object.keys(dataNew3).length === 0) {
                toast.error("Data Not Available");
            } else {
                let newData2 = dataNew3.map((item, no) => ({
                    "S.No": no + 1,
                    "SC#": item && (item.sceneData).map((v,n)=>{return(v.name)}),
                    "PAGES": item.vehicleNumber,
                    "INT/EXT.": item.driverName,
                    "DAY/NIGHT": item.driverNumber,
                    "LOCATION": item.typeOfVehicle,
                    "CAST ID": item.insuranceStatus,
                    "CAST WITH COSTUME CONT.": item.location,
                    "COSTUME DOUBLES": item.price,
                    "COSTUME NOTES": item.price,
                    "HAIR & MAKE UP NOTES": item.price,
                    "PROSTHETICS": item.price,
                    "KEY PROPS": item.price,
                    "PROPS": item.price,
                    "SET DRESSING": item.price,
                    "WEAPONS": item.price,
                    "FIRING WEAPONS": item.price,
                    "FEATURED EXTRAS": item.price,
                    "EXTRAS/STUNTMEN": item.price,
                    "REGULAR EXTRAS": item.price,
                    "TOTAL EXTRAS": item.price,
                    "SCENE TOTAL": item.price,
                    "DAY TOTAL": item.price,
                    "PICTURE VEHICLES": item.price,
                    "PRODUCTION": item.price,
                    "SPECIAL PROFESSIONALS": item.price,
                    "ACTION / SFX": item.price,
                    "ANIMALS": item.price,
                    "VFX": item.price,
                    "NOTES": item.price,
                }));
                generateExcel1(newData2, name);
                handleClose();
            }
        }
    }

    useEffect(() => {
        callList1();
    }, []);

    return (
        <>
            <div className='pt-3'>
                <Card className='mt-2'>
                    <Card.Body className='crdBdy10'>
                        <Row className='m-5'>
                            {
                                SheetData && SheetData.length > 0 && SheetData.map((val, ind) => {
                                    return (<>
                                        <Col className='mdfd-wdt' lg={2} md={4} sm={12}>
                                            <Card className='cstmCrd hand' onClick={() => handleClick(ind, (val.name))}>
                                                <Card.Body className="crdBdy10">
                                                    <Card.Text className='text-center imgWdt'>
                                                        <img src={val.img} />
                                                    </Card.Text>
                                                    <p className='cstmCrdTxt'>{val.name}</p>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Modal show={show} onHide={handleClose}>
                                            <Modal.Header closeButton>
                                                <h6>Download Sheet</h6>
                                            </Modal.Header>
                                            <Modal.Body className="crdBdy">
                                                <Row>
                                                    <Col className='col-10'>
                                                        <h6 className='colorTxt'>{newDt}</h6>
                                                    </Col>
                                                    <Col className='col-2'>
                                                        <span className='hand animationIcn' onClick={() => { callList(newDt); handleClose(); }}><FontAwesomeIcon icon={faFileArrowDown} title='Click For Download Pdf File' size='xl' /></span>
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                        </Modal>
                                    </>
                                    )
                                })
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default memo(SheetGen)