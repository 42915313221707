import React, { useState, memo, useEffect } from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import progress1 from '../../assets/images/OldProImg/progress1.png';
import progress2 from '../../assets/images/OldProImg/progress2.png';
// import progress3 from '../../assets/images/OldProImg/pb/vendorePb/three.png';
import vendorIcn from '../../assets/images/OldProImg/vendor/vendorIcn.png';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { setAlert } from "../../slices/home";
import AlertBox from "../component/AlertBox";
import { getVendorDataAction, addEditVendorAction, getCategoryVendorDataAction } from "../../action/Admin.action";
import { Link, useNavigate } from 'react-router-dom';
import { VendorType, VendorRole } from "../common/constant";
import { imgPath } from '../common/function';
import { ToastContainer, toast } from "react-toastify";
// toast.success(msg);
// toast.error(resp.msg);

const SceneOption = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [Step, setStep] = useState(1);
    const [category, setCategory] = useState({})
    const [modalShow1, setModalShow1] = useState(false);
    const [formData, setFormData] = useState({ page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" });
    const [startNum, setStartNum] = useState(0);
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const masters = useSelector((state) => state.home.masters);
    const navigate = useNavigate();
    const [emailErr, setEmailErr] = useState(false);
    const [mobileErr, setMobileErr] = useState(false);
    const [numErr, setNumErr] = useState(false);



    const getList = async (action = '') => {
        handleClose();
        let fData = { ...formData };
        if (action === 'clear') {
            fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
            setFormData(fData);
        }

        setStartNum(fData.page * fData.limit);

        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getVendorDataAction(fData);
        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
        if (resp.code === 200) {
            setVendorList(resp.data);
            setFormData({ ...formData, ...{ totalItems: resp.count } });
        }
    }
// console.log('vendorList',vendorList)

    const getCatList = async () => {
        const resp = await getCategoryVendorDataAction();
        if (resp.code === 200) {
            setCategory(resp.data);
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let postData = new FormData();
            for (let key in formData) {
                postData.append(key, formData[key]);
            }

            let stp = Step + 1;
            setStep(stp);
            let resp = (stp == 4) ? (await addEditVendorAction(postData)) : { code: 500 } && setValidated(false)
            // let resp = await addEditVendorAction(postData);
            setDisabled(false);
            if (resp && resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: "Vendor Added Successfully", type: '' }));
                setShow(false);

                getList();
                handleClose();
                setFormData({})
                setValidated(false);

            } else {
                var data = "";
                if (resp && resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
                // toast.error(data);
            }
            return false;
        }
    };

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {

            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";
        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    const handleChange = (name, event) => {
        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });
    }

    const formHandler = (e, field) => {

        let data = { ...formData };
        if (e.target) {
            data[field] = e.target.value;
        } else {
            data[field] = e;
        }
        setFormData(data);
    }

    useEffect(() => {
        getList();
        getCatList();
    }, [])

    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy mx-2">
                        <Row className="mt-3 pt-2">
                            <Col className="col-6 col-lg-5">
                                <h6 className="crdHd1 mt-1">Vendor</h6>
                                </Col>
                            <Col lg={3} className='lgsix p-1 mb-3'>
                                <input type="text" placeholder='Search Schedule Name' className='BrdRm hand' onChange={e => handleChange('name', e)} onClick={e => getList()}></input><i onClick={e => getList()} className="fa fa-search"></i>
                            </Col>
                            <Col className="col-6 text-center col-lg-2"><i onClick={() => setStep(1)}><Button className="vndrBtn" size="sm" onClick={() => { handleShow(); setValidated(false); setFormData({}) }}>+ Add</Button></i></Col>
                            <Col className="col-6 text-start col-lg-2"><i onClick={() => setStep(1)}><Button className="vndrBtn20" size="sm" onClick={() => { handleShow1(); setValidated(false); setFormData({}) }}>+ Add As Crew</Button></i></Col>

                        </Row>
                        <hr className="mrZero" />
                        <Row>
                            {
                                vendorList && vendorList.length > 0 && vendorList.map((item, index) => {

                                    return <Col lg={3} md={6} key={index} className="mt-4">
                                        <Card className="shaotp hand" style={{ borderRadius: '20px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/vendor-details`, { state: item }) }}>
                                            <div class="mx-auto mt-3 vndrImg">
                                                <img src={item.image ? imgPath(item.image) : vendorIcn} alt="" className="img-fluid" />
                                            </div>
                                            <Card.Body className="pb-0 crdBdy">
                                                <h6 className="text-center mt-3 truncate-text1"><b>{item.name}</b></h6>
                                                <p className="text-center mb-0 truncate-text1" style={{ fontSize: '12px' }}>{item.email}</p>
                                                <p className="text-center truncate-text1" style={{ fontSize: '12px' }}>{item.number}</p>
                                                <p className="text-center mb-0 truncate-text1" style={{ fontSize: '12px' }}>{item.type}</p>
                                                {/* <Card.Text class="d-inline-block text-truncate" style={{ maxWidth: '90%' }}>
                                            {item.synopsis}
                                        </Card.Text> */}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                })
                            }
                            {vendorList && vendorList.length == 0 && <p>----- No Data avaialble -----</p>}
                        </Row>
                    </Card.Body>
                </Card>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="size1 pb-0 mdlHdr" closeButton style={{ borderBottom: " 0 none" }}>
                    <h6 className="crdHd1 mx-3">Vendor</h6>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <AlertBox />
                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>


                        {Step == 1 &&
                            <>
                                <Row>
                                    <Col className='p-0 topPb'>
                                        <img src={progress1} alt="first" style={{ width: "100%" }} />
                                    </Col>
                                </Row>
                                <Row className="mx-2">

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="type">
                                            <Form.Label>Vendor Type<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                onChange={e => handleChange('type', e)} value={formData.type ? formData.type : ""} required>
                                                <option value="">Select Vendor</option>
                                                {VendorType && VendorType.length > 0 && VendorType.map((val, ind) => {
                                                    return (
                                                        <option value={val.value}>{val.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Vendor Type.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="name">
                                            <Row><Col><Form.Label>Name<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" minLength={2} onChange={e => handleChange('name', e)} maxLength={32} value={formData.name ? formData.name : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="registeredNumber">
                                            <Row><Col><Form.Label>Registered Number<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(0*[1-9][0-9]{0,9})$' onChange={e => handleChange('registeredNumber', e)} value={formData.registeredNumber ? formData.registeredNumber : ""} maxLength={10} minLength={1} required />
                                            {numErr && <p className="err1">This Registration No. Is Already Exist...!</p>}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Registered Number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="registeredSince">
                                            <Row><Col><Form.Label>Registered Since<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="number" onChange={e => handleChange('registeredSince', e)} value={formData.registeredSince ? formData.registeredSince : ""} pattern='[0-9]{4,}' minLength="4" maxLength="4" min={1500} max={2022} required />
                                            <Form.Control.Feedback type="invalid">
                                                Select Year Between 1500 to 2022.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="role">
                                            <Form.Label>Vendor Role<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                onChange={e => handleChange('role', e)} value={formData.role ? formData.role : ""} required>
                                                <option value="">Select Vendor Role</option>
                                                {VendorRole && VendorRole.length > 0 && VendorRole.map((val, ind) => {
                                                    if (val.subCatOf == formData.type) {
                                                        return <option value={val.value}>{val.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Vendor Role.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="email">
                                            <Row><Col><Form.Label>Email Id<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="email" pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$" onChange={e => handleChange('email', e)} value={formData.email ? formData.email : ""} required />
                                            {emailErr && <p className="err1">This Email Is Already Exist...!</p>}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="number">
                                            <Row><Col><Form.Label>Mobile Number<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^[6-9][0-9]{9}$' onChange={e => handleChange('number', e)} value={formData.number ? formData.number : ""} maxLength={10} minLength={10} required />
                                            {mobileErr && <p className="err1">This Mobile No. Is Already Exist...!</p>}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Mobile Number.
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="categoryId">
                                            <Form.Label>Category<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('categoryId', e)} value={formData.categoryId ? formData.categoryId : ""} required>
                                                <option value="">Select Category</option>
                                                {category && category.length > 0 && category.map((val, ind) => {
                                                    if (val.categoryId == "000000000000000000000000") {
                                                        return <option value={val._id} key={ind}>{val.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="subCategoryId">
                                            <Form.Label>Sub Category<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('subCategoryId', e)} value={formData.subCategoryId ? formData.subCategoryId : ""} required>
                                                <option value="">Select Sub Category</option>
                                                {category && category.length > 0 && category.map((val, ind) => {
                                                    if (val.categoryId===formData.categoryId) {
                                                        return <option value={val._id} key={ind}>{val.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Sub Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4}>

                                        <Form.Group className="txt" controlId="image">
                                            <Row><Col><Form.Label>Photo</Form.Label></Col></Row>
                                            <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Photo.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="imgcss">
                                        <img src={file} />
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3 mx-2">
                                    <Col lg={12} className="text-end">
                                        <Button className="vndrBtn" disabled={isDisabled} type="submit">
                                            Next
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {Step == 2 &&
                            <div>
                                <Row>
                                    <Col lg={12} className="p-0">
                                        <img src={progress2} style={{ width: "100%" }}></img>
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="country">
                                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                                                <GetAllCountries />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="state">
                                            <Form.Label>State<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                                                <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a state.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="city">
                                            <Form.Label>City<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                                                <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a City.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="address">
                                            <Row><Col><Form.Label>Address<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" minLength={2} maxLength={120} type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" onChange={e => handleChange('address', e)} value={formData.address ? formData.address : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="zipCode">
                                            <Row><Col><Form.Label>Postal Code<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('zipCode', e)} value={formData.zipCode ? formData.zipCode : ""} maxLength={6} minLength={6} pattern="^(?!0{6})0*[1-9]\d{0,5}$" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Postal Code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {/* <Row className="mx-2">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>   <Button variant="secondary" className="btnk mt-4 cldrBtn" onClick={e => setStep(Step - 1)}>
                                        Back
                                    </Button></Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>   <Button variant="secondary" className="btnk cldrBtn mt-4" type="submit">
                                        Next
                                    </Button></Col>
                                </Row> */} 
                                <Row className="mt-3 g-2 mx-2">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" onClick={e => setStep(Step - 1)}>
                                            Back
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" disabled={isDisabled}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>

                            </div>
                        }
                        {/* {Step == 3 &&
                            <div>
                                <Row>
                                    <Col lg={12} className="p-0">
                                        <img src={progress3} style={{ width: "100%" }}></img>
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="numberOfEmployee">
                                            <Row><Col><Form.Label>Number of Employee<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(0*[1-9][0-9]{0,9})$' minLength={1} maxLength={10} onChange={e => handleChange('numberOfEmployee', e)} value={formData.numberOfEmployee ? formData.numberOfEmployee : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Number of Employee.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    
                                </Row>
                                <Row className="mt-3 g-2 mx-2">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" onClick={e => setStep(Step - 1)}>
                                            Back
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" disabled={isDisabled}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        } */}
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Add as a crew */}
            <Modal show={show1} onHide={handleClose1} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="size1 pb-0 mdlHdr" closeButton style={{ borderBottom: " 0 none" }}>
                    <h6 className="crdHd1 mx-3">Vendor(crew)</h6>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <AlertBox />
                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>


                        {Step == 1 &&
                            <>
                                <Row>
                                    <Col className='p-0 topPb'>
                                        <img src={progress1} alt="first" style={{ width: "100%" }} />
                                    </Col>
                                </Row>
                                <Row className="mx-2">

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="type">
                                            <Form.Label>Vendor Type<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                onChange={e => handleChange('type', e)} value={formData.type ? formData.type : ""} required>
                                                <option value="">Select Vendor</option>
                                                {VendorType && VendorType.length > 0 && VendorType.map((val, ind) => {
                                                    return (
                                                        <option value={val.value}>{val.name}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Vendor Type.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="name">
                                            <Row><Col><Form.Label>Name<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" minLength={2} onChange={e => handleChange('name', e)} maxLength={32} value={formData.name ? formData.name : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="registeredNumber">
                                            <Row><Col><Form.Label>Registered Number<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(0*[1-9][0-9]{0,9})$' onChange={e => handleChange('registeredNumber', e)} value={formData.registeredNumber ? formData.registeredNumber : ""} maxLength={10} minLength={1} required />
                                            {numErr && <p className="err1">This Registration No. Is Already Exist...!</p>}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Registered Number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="registeredSince">
                                            <Row><Col><Form.Label>Registered Since<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="number" onChange={e => handleChange('registeredSince', e)} value={formData.registeredSince ? formData.registeredSince : ""} pattern='[0-9]{4,}' minLength="4" maxLength="4" min={1500} max={2022} required />
                                            <Form.Control.Feedback type="invalid">
                                                Select Year Between 1500 to 2022.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="role">
                                            <Form.Label>Vendor Role<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                onChange={e => handleChange('role', e)} value={formData.role ? formData.role : ""} required>
                                                <option value="">Select Vendor Role</option>
                                                {VendorRole && VendorRole.length > 0 && VendorRole.map((val, ind) => {
                                                    if (val.subCatOf == formData.type) {
                                                        return <option value={val.value}>{val.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Vendor Role.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="email">
                                            <Row><Col><Form.Label>Email Id<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="email" pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$" onChange={e => handleChange('email', e)} value={formData.email ? formData.email : ""} required />
                                            {emailErr && <p className="err1">This Email Is Already Exist...!</p>}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Email.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="number">
                                            <Row><Col><Form.Label>Mobile Number<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^[6-9][0-9]{9}$' onChange={e => handleChange('number', e)} value={formData.number ? formData.number : ""} maxLength={10} minLength={10} required />
                                            {mobileErr && <p className="err1">This Mobile No. Is Already Exist...!</p>}
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Mobile Number.
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    </Col>

                                    <Col lg={4}>

                                        <Form.Group className="txt" controlId="image">
                                            <Row><Col><Form.Label>Photo</Form.Label></Col></Row>
                                            <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Photo.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="imgcss">
                                        <img src={file} />
                                    </Col>
                                </Row>

                                <Row className="justify-content-end mt-3 mx-2">
                                    <Col lg={12} className="text-end">
                                        <Button className="vndrBtn" disabled={isDisabled} type="submit">
                                            Next
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {Step == 2 &&
                            <div>
                                <Row>
                                    <Col lg={12} className="p-0">
                                        <img src={progress2} style={{ width: "100%" }}></img>
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="country">
                                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                                                <GetAllCountries />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="state">
                                            <Form.Label>State<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                                                <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a state.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="city">
                                            <Form.Label>City<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                                                <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a City.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="address">
                                            <Row><Col><Form.Label>Address<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" minLength={2} maxLength={120} type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" onChange={e => handleChange('address', e)} value={formData.address ? formData.address : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="zipCode">
                                            <Row><Col><Form.Label>Postal Code<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('zipCode', e)} value={formData.zipCode ? formData.zipCode : ""} maxLength={6} minLength={6} pattern="^(?!0{6})0*[1-9]\d{0,5}$" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Postal Code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>   <Button variant="secondary" className="btnk mt-4 cldrBtn" onClick={e => setStep(Step - 1)}>
                                        Back
                                    </Button></Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>   <Button variant="secondary" className="btnk cldrBtn mt-4" type="submit">
                                        Next
                                    </Button></Col>
                                </Row>
                            </div>
                        }
                        {/* {Step == 3 &&
                            <div>
                                <Row>
                                    <Col lg={12} className="p-0">
                                        <img src={progress3} style={{ width: "100%" }}></img>
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="numberOfEmployee">
                                            <Row><Col><Form.Label>Number of Employee<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(0*[1-9][0-9]{0,9})$' minLength={1} maxLength={10} onChange={e => handleChange('numberOfEmployee', e)} value={formData.numberOfEmployee ? formData.numberOfEmployee : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Number of Employee.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="categoryId">
                                            <Form.Label>Category<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('categoryId', e)} value={formData.categoryId ? formData.categoryId : ""} required>
                                                <option value="">Select Category</option>
                                                {category && category.length > 0 && category.map((val, ind) => {
                                                    if (val.categoryId == "000000000000000000000000") {
                                                        return <option value={val._id} key={ind}>{val.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="subCategoryId">
                                            <Form.Label>Sub Category<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('subCategoryId', e)} value={formData.subCategoryId ? formData.subCategoryId : ""} required>
                                                <option value="">Select Sub Category</option>
                                                {category && category.length > 0 && category.map((val, ind) => {
                                                    if (val.categoryId != "000000000000000000000000") {
                                                        return <option value={val._id} key={ind}>{val.name}</option>
                                                    }
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Sub Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="mt-3 g-2 mx-2">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" onClick={e => setStep(Step - 1)}>
                                            Back
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" disabled={isDisabled}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        } */}
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default memo(SceneOption);