import React, { useState, memo, useEffect } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import Scene6 from '../../assets/images/OldProImg/Scenes/scene6.jpeg';
import moment from 'moment';
import { setAlert } from "../../slices/home";
import { listSceneScheduleAction } from "../../action/Admin.action";
import { imgPath } from '../common/function'
import { useLocation, Link, useNavigate } from 'react-router-dom';

import ScheduleMenu from "./ScheduleMenu";

const SceneOption = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [sceneList, setSceneList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;


    const getList = async () => {
        const resp = await listSceneScheduleAction();
        if (resp.code === 200) {
            setSceneList((resp.data).sort((a, b) => new Date(a.startDate) - new Date(b.startDate)));
        }
    }
    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let resp = await listSceneScheduleAction();
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });

    }
    useEffect(() => {
        getList();
        { state && setSceneList(state.sceneData) }
    }, [])

    // console.log(state);


    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className='crdBdy'>
                        {/* <Row className="mt-5 pt-5 pt-2 px-3">
                <i className="fa fa-arrow-left hand" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}></i>
                    <Col className="col-3 col-lg-3"><h5 className="pt-2 smsz">Schedule</h5></Col>
                    <Col className="col-7 text-end col-lg-7"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i> <Button className="btnClre btnClr smsz btn btn-primary" onClick={() => { navigate(`${process.env.PUBLIC_URL}/selectSchedule`) }} >Back </Button></Col>
                    <Col className="col-2"><Button className="btnClre btnClr smsz btn btn-primary" onClick={() => { navigate(`${process.env.PUBLIC_URL}/schedule-menu`) }} >+ Schedule</Button></Col>
                </Row> */}
                        <Row className="mt-3">
                        {/* <i className="fa fa-arrow-left hand" onClick={() => { navigate(`${process.env.PUBLIC_URL}/projectDetails`) }}></i> */}
                            <Col lg={12} className="text-end px-4">
                                <Button className="vndrBtn" onClick={() => { navigate(`${process.env.PUBLIC_URL}/projectDetails`) }}>
                                    Back
                                </Button>
                            </Col>
                            {/* {sceneList && sceneList.length > 0 && sceneList.map((val, ind) => {
                    return <>
                     <Col lg={4} md={6} key={ind} >
                            <Card className="hand optCrdshad hiquery" style={{ borderRadius: '20px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/scene-details`, { state: val }) }}>
                                <img src={val.image ? imgPath(val.image) : Scene6} alt="" className="img-fluid optionImg" />

                                <Card.Body>
                                    <Card.Title className="pb-0 snClrHd">Scene {val.scene}</Card.Title>
                                    <Card.Text class="d-inline-block mb-0 cardText" >
                                        {val.synopsis}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </>
                })} */}
                            {/* <ScheduleMenu /> */}
                        </Row>
                        <hr />

                        {
                            sceneList && sceneList.length > 0 && sceneList.map((val, ind) => {
                                let data1 = (val.sceneData);
                                let startD = (moment(val.startDate).format("DD-MM-yyyy"))
                                return <Row className="mt-3">
                                    <Col lg={12} className="">{startD}</Col>
                                    {
                                        data1 && data1.length > 0 && data1.map((item, index) => {
                                            return <Col lg={4} md={6} key={index} >
                                                <Card className="hand optCrdshad hiquery brdr" style={{ borderRadius: '20px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/scene-details`, { state: item }) }}>
                                                    <img src={item.image ? imgPath(item.image) : Scene6} alt="" className="img-fluid optionImg" />

                                                    <Card.Body className="scenCrdPd">
                                                        <h6 className="pb-0 snClrHd mt-2">Scene {item.scene}</h6>
                                                        <Card.Text class="d-inline-block mb-0 cardText" >
                                                            {item.synopsis}
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        })
                                    }
                                </Row>
                            })
                        }
                        
                        {sceneList && sceneList.length==0 && <>
                            <Link to={`${process.env.PUBLIC_URL}/addOneLinerScript`}><Button variant="outline-info" className="vndrBtn">+ Add Scene</Button></Link>
                        </>}
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default memo(SceneOption);