import React, { useState, useEffect, memo } from "react";
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  addEditDesignationAction,
  getDepartmentDataAction,
} from "../../action/Admin.action";
import { useDispatch } from "react-redux";
import { setAlert } from "../../slices/home";
import { PermissionList } from "../../admin/common/constant";

const AddEdit = (props) => {
  let navigate = useNavigate();
  let { state } = useLocation();

  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ permission: [] });
  const [dataList, setDataList] = useState();

  const getList = async () => {
    let resp = await getDepartmentDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      //setValidated(false);
      setDisabled(true);
      let msg = "";
      if (formData._id) {
        msg = "You have successfully role updated.";
      } else {
        msg = "You have successfully added new role.";
      }
      let resp = await addEditDesignationAction(formData);

      if (resp.code === 200) {
        dispatch(
          setAlert({ open: true, severity: "success", msg: msg, type: "" })
        );
        setTimeout(() => {
          navigate({
            pathname: `${process.env.PUBLIC_URL}/admin/designations`,
            state: "",
          });
        }, 2000);
      } else {
        setDisabled(false);
        dispatch(
          setAlert({ open: true, severity: "danger", msg: resp.msg, type: "" })
        );
      }
      return false;
    }
  };

  const handleChange = (post) => {
    let name = post.name;
    let event = post.event;
    let from = { ...formData };

    if (post.fieldType === "permission") {
      let index = from.permission.indexOf(post.value);
      if (index > -1) {
        from.permission.splice(index, 1);
      } else {
        from.permission.push(post.value);
      }
    } else {
      from[name] = event.target.value;
    }

    setFormData({ ...formData, ...from });
  };

  const cancelBtn = () => {
    navigate({
      pathname: `${process.env.PUBLIC_URL}/admin/designations`,
      state: "",
    });
  };

  useEffect(() => {
    getList();

    if (state && state._id) {
      let data = state;
      let editData = {
        _id: data._id,
        name: data.name,
        departmentId: data.departmentId,
        shortName: data.shortName,
        permission: data.permission ? data.permission : [],
        status: data.status,
      };
      setFormData(editData);
    }
  }, []);

  console.log(formData);

  return (
    <div className="mt-3">
      <Card className="mt-5">
        <Card.Body className="crdBdy">
          <div className="Role-page text-start companyhed mt-3">
            <Row className="justify-content-center">
              <Col md={9} lg={10}>
                <h6 className="crdHd1">
                  {formData._id ? "Update Designation" : "Add New Designation"}
                </h6>
              </Col>
              <Col md={3} lg={2} className="text-right">
                <Link
                  to={`${process.env.PUBLIC_URL}/admin/designations`}
                  className="text-white btn pull-right"
                >
                  Back
                </Link>
              </Col>
            </Row>
            <hr className="mrZero" />
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="mt-4">
                <Row>
                  <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>
                        Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-\ & \\&\]+)$"
                        maxLength={45}
                        minLength={2}
                        placeholder="Name"
                        className="filedbg cmnsz"
                        value={formData.name ? formData.name : ""}
                        onChange={(e) =>
                          handleChange({ name: "name", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>
                        Short Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-\ & \\&\]+)$"
                        maxLength={45}
                        minLength={2}
                        placeholder="Short Name"
                        className="filedbg cmnsz"
                        value={formData.shortName ? formData.shortName : ""}
                        onChange={(e) =>
                          handleChange({ name: "shortName", event: e })
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Short Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Group className="mb-3" controlId="department">
                        <Form.Label>Department Name</Form.Label>
                        <Form.Select
                          className="filedbg cmnsz"
                          value={
                            formData.departmentId ? formData.departmentId : ""
                          }
                          onChange={(e) =>
                            handleChange({ name: "departmentId", event: e })
                          }
                        >
                          <option value="">Select Department</option>
                          {dataList &&
                            dataList.length > 0 &&
                            dataList.map((val, ind) => {
                              return (
                                <option value={val._id} key={ind}>
                                  {val.name}
                                </option>
                              );
                            })}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid department.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Col>
                  {/* <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>Listed Status<span className="star"> *</span></Form.Label>
                      <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.status > -1 ? formData.status : ""} onChange={e => handleChange({ name: 'status', event: e })} required>
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Listed Status.
                      </Form.Control.Feedback>
                    </div>
                  </Col> */}
                </Row>
                <Row>
                  <h6 className="crdHd1">
                    Permission<span className="star"> *</span>
                  </h6>
                  {PermissionList.map((item, index) => {
                    return (
                      <Col xs="12" lg="12" key={index} className="mb-3">
                        <h6>{item.name}</h6>
                        <Row>
                          {item.action.map((perm, ind) => {
                            let checked = formData.permission.includes(
                              perm.type
                            )
                              ? true
                              : false;
                            return (
                              <Col key={ind} className="px-2">
                                {/* <Form.Control type="checkbox" value={formData.shortCode?formData.shortCode:""} onChange={e=>handleChange('shortCode', e)} title={perm.name}/> */}
                                <Form.Check
                                  type="checkbox"
                                  label={perm.name}
                                  onChange={(e) =>
                                    handleChange({
                                      name: "permision",
                                      event: e,
                                      value: perm.type,
                                      fieldType: "permission",
                                    })
                                  }
                                  checked={checked}
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
                <Button
                  type="submit"
                  className="vndrBtn"
                  size="lg"
                  disabled={isDisabled}
                >
                  Save
                </Button>{" "}
                <Button className="vndrBtn" onClick={cancelBtn} size="lg">
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default memo(AddEdit);
