import react, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
// import forgetImg from '../../assets/img/forget1.png'
import forgetImg from '../../assets/images/OldProImg/forget1.png';
import { forgotPasswordAction } from '../../action/Admin.action';
import ChangePassword from './changePassword';
import OtpInput from 'react-otp-input';
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";


const ForgetPassword = () => {
    const [formData, setFormData] = useState({});
    const [visibl, setVisibl] = useState(true);
    const [seconds, setSeconds] = useState(120);
    const navigate = useNavigate();

    const handelSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        let resp = await forgotPasswordAction(formData);
        // console.log(resp);
        if (resp.code === 200) {
            setVisibl(false);
            setSeconds(120);
            toast.success("OTP Send To Your Mail");
        }else{
            toast.error("This email address is not present !");  
        }
    }

    const handelChange = (name, event) => {
        let newForm = { ...formData };
        newForm[name] = event.target.value;
        setFormData({ ...formData, ...newForm });
    }

    // const goto = ()=>{
    //     navigate(`${process.env.PUBLIC_URL}/login`)
    // }

    useEffect(() => {
        let intervalId;

        if (seconds > 0) {
            intervalId = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        if (seconds === 0) {
            clearInterval(intervalId);
            // setVisibl(true)
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [seconds]);

    useEffect(() => { }, []);

    return (
        <>
            <Row className='m-2 p-3 justify-content-center'>
                <Card className='p-3 frgtCrd mt-5'>
                    <Card.Title>Forget Password</Card.Title>
                    <Card.Body>
                        {
                            visibl &&
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    <img src={forgetImg} style={{ width: "100%" }} />
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                    <Form onSubmit={(e) => handelSubmit(e)}>
                                        <Row>
                                            <Col lg={12} md={12} sm={12}>
                                                <Form.Group>
                                                    <Form.Label>Enter Your E-mail</Form.Label>
                                                    <Form.Control type='email' value={formData.email ? formData.email : ''} onChange={(e) => { handelChange('email', e) }} placeholder='i.e. abc@gmail.com' required />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} className='text-center'>
                                                <Button type="submit" className="btn btn-dark m-3 vndrBtn">Submit</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        }
                        {
                            !(visibl) && <ChangePassword email={formData.email} />
                        }
                        <Row className='text-end'>
                            <Col lg={12} md={12} sm={12}><p><Link className='hand' to={`${process.env.PUBLIC_URL}/login`} >GO TO LOGIN</Link></p></Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </>
    )
}

export default ForgetPassword;