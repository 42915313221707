import React, { Fragment, useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from 'react-big-calendar';
//import DemoLink from '../../DemoLink.component'
import events from './events';
import * as dates from './dates';
import { getLocationSceneDataAction, addEditEventCalenderAction, getEventCalenderDataAction } from "../../action/Admin.action";
import { ToastContainer, toast } from "react-toastify";

import "react-big-calendar/lib/css/react-big-calendar.css";

import { Modal, Button, Row, Col, Table, Form, Card } from 'react-bootstrap';


const mLocalizer = momentLocalizer(moment)

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })

/**
 * We are defaulting the localizer here because we are using this same
 * example on the main 'About' page in Storybook
 */
export default function Calendars({
  localizer = mLocalizer,
  showDemoLink = true,
  ...props
}) {

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [listData, setListData] = useState([]);
  const [listData1, setListData1] = useState([]);
  const [formData, setFormData] = useState({colorcode:"red"});
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  const [sceneDetails, setSceneDetails] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      let resp = await addEditEventCalenderAction(formData);
      if (resp.code === 200) {
        toast.success("saved Successfully !");
        // dispatch(setAlert({ open: true, severity: "success", msg: "saved Successfully !", type: '' }));
        setFormData({});
        handleClose1();
        getList();
      } else {
        var data = "";
        if (resp.msg) {
          data = resp.msg;
        }
        setDisabled(false);
        toast.error(data);
        // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
      }
      return false;
    }
  };

  const getList = async () => {
    let resp = await getLocationSceneDataAction();
    if (resp.code === 200) {
      const schList = resp.data;
      let newSchList = [];
      let x = 0;
      for (let i in schList) {
        let sch = schList[i];
        for (let j in sch.sceneData) {
          let scene = sch.sceneData[j];
          newSchList.push({
            id: x,
            title: scene.synopsis,
            start: new Date(moment(sch.startDate).format('YYYY, M, D')),
            end: new Date(moment(sch.startDate).format('YYYY, M, D')),
            scene: scene
          });
          x++;
        }
      }
      setListData(newSchList);
    }

    let resp1 = await getEventCalenderDataAction();
    if (resp1.code === 200) {
      let newSchList2 = [];
      {
        resp1 && resp1.count > 0 && (resp1.data).map((v, i) => {

          newSchList2.push({
            id: v._id,
            title: v.title,
            start: new Date(moment(v.startDate).format('YYYY, M, D')),
            end: new Date(moment(v.endDate).format('YYYY, M, D')),
            // scene:{sheet:"",scene:"",intExt:"",dayNight:"",set:"",scriptDay:"",unit:"",sequence:"",artistNumber:"",projectLocationName:"",estTime:"",comments:""}
            myEvent: "true",
            bgClr: v.colorcode,
          });
        })
      }
      setListData1(newSchList2);
    }
  }

  useEffect(() => {
    getList();
  }, []);


  //   const handleSelectEvent = useCallback(
  //   (event) => window.alert(event.title),
  //   []
  // )

  const handleSelectEvent = (event) => {
    //window.alert(event.title);
    setSceneDetails(event);
    handleShow();
  }

  const handleChange = (name, event) => {
    let from = { ...formData };
    from[name] = event.target.value;
    setFormData({ ...formData, ...from });
  }

  return (
    <main id="main" className="main">
      <div className='pt-4'>
        <Card>
          <Card.Body>
            <Row>
              <Col className='col-12 text-end pt-0 pb-2 mt-0'>
                <Button className="cldrBtn mb-2" onClick={handleShow1}>
                  Create New Event !
                </Button>
                <hr className='mrZero' />
                <Modal show={show1} onHide={handleClose1}>
                  <Modal.Header className='mdlHdr' closeButton style={{ paddingLeft: "25px" }}>
                    <Modal.Title><span style={{ fontSize: "16px" }}>Creating New Event</span></Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Card className="shadow p-3 pt-0" style={{ padding: "0px", margin: "0px" }}>
                      <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
                        <Form.Group controlId="title">
                          <Form.Label>Event Name<span className='star'> *</span></Form.Label>
                          <Form.Control type="text" onChange={e => handleChange('title', e)} value={formData.title ? formData.title : ''} required />
                          <Form.Control.Feedback type="invalid">Enter Valid Event Name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="startDate">
                          <Form.Label>Start Date<span className='star'> *</span></Form.Label>
                          <Form.Control type="date" onChange={e => handleChange('startDate', e)} value={formData.startDate ? formData.startDate : ''} required />
                          <Form.Control.Feedback type="invalid">Enter Valid Start Date.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="endDate">
                          <Form.Label>End Date<span className='star'> *</span></Form.Label>
                          <Form.Control type="date" onChange={e => handleChange('endDate', e)} value={formData.endDate ? formData.endDate : ''} required />
                          <Form.Control.Feedback type="invalid">Enter Valid End Date.</Form.Control.Feedback>
                        </Form.Group>
                        <Row className='mt-3'>
                          <Col lg={4} className="px-1"><input type="radio" name="color" onChange={e => handleChange('colorcode', e)} value="red" checked={formData.colorcode === 'red'} /><span style={{ color: "red" }} className="clrpicker">Red</span></Col>
                          <Col lg={4} className="px-1"><input type="radio" name="color" onChange={e => handleChange('colorcode', e)} value="green" checked={formData.colorcode === 'green'} /><span style={{ color: "green" }} className="clrpicker">Green</span></Col>
                          <Col lg={4} className="px-1"><input type="radio" name="color" onChange={e => handleChange('colorcode', e)} value="blue" checked={formData.colorcode === 'blue'} /><span style={{ color: "blue" }} className="clrpicker">Blue</span></Col>
                          <Col lg={4} className="px-1"><input type="radio" name="color" onChange={e => handleChange('colorcode', e)} value="yellow" checked={formData.colorcode === 'yellow'} /><span style={{ color: "yellow" }} className="clrpicker">Yellow</span></Col>
                          <Col lg={4} className="px-1"><input type="radio" name="color" onChange={e => handleChange('colorcode', e)} value="black" checked={formData.colorcode === 'black'} /><span style={{ color: "black" }} className="clrpicker">Black</span></Col>
                          <Col lg={4} className="px-1"><input type="radio" name="color" onChange={e => handleChange('colorcode', e)} value="grey" checked={formData.colorcode === 'grey'} /><span style={{ color: "grey" }} className="clrpicker">Grey</span></Col>
                        </Row>

                        {/* <label className='mt-2' for="colorPicker">Select a Color:</label>
              <input className='mt-2' value={formData.Colorcode ? formData.Colorcode : ''} onChange={e => handleChange('Colorcode', e)} type="color" id="colorPicker" name="colorPicker" />

              <p className='mt-2'><span className='p-1 border' style={{ borderRadius: "8px" }}>{formData.Colorcode ? formData.Colorcode : ''}</span></p> */}

                        <Row className='mt-3'>
                          <Col lg={7}><Button type="submit" className='mt-3 vndrBtn'>Create Event</Button></Col>
                          <Col lg={5} className="text-end"><Button className='mt-3 vndrBtn' onClick={() => setFormData({})}>Clear All</Button></Col>
                        </Row>
                      </Form>
                    </Card>
                  </Modal.Body>

                </Modal>
              </Col>
            </Row>

            <div style={{ height: "160vh" }} {...props}>
              {/* <Calendar
          //components={components}
          //defaultDate={defaultDate}
          events={[...listData, ...listData1]}
          localizer={localizer}
          //max={max}
          showMultiDayTimes
          step={60}
          //views={views}
          onSelectEvent={handleSelectEvent}
        /> */}
              <Calendar
                events={[...listData, ...listData1]}
                localizer={localizer}
                showMultiDayTimes
                step={60}
                onSelectEvent={handleSelectEvent}
                eventStyleGetter={(event, start, end, isSelected) => {
                  const defaultBackgroundColor = 'blue'; // Set your default background color here

                  // Check if the event has a specific colorcode property, otherwise use the default color
                  const backgroundColor = event.colorcode || defaultBackgroundColor;

                  const style = {
                    backgroundColor,
                  };

                  return { style };
                }}
              />
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header className='mdlHdr' closeButton>
                {/* <Modal.Title>One liner Script Or Event</Modal.Title> */}
                <h6>One liner Script Or Event</h6>
                { }
              </Modal.Header>
              {sceneDetails && sceneDetails.scene &&
                <Modal.Body className="scene-detail-calender">
                  <Table>
                    <tr>
                      <td colSpan="5" className="py-3">
                        Date:<br /><span style={{ color: "#9700DE" }}>{moment(sceneDetails.start).format("DD MMMM YYYY")}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="py-3">Sheet<br /><span className="text-black">{sceneDetails.scene.sheet}</span></td>
                      <td>Scene(s)<br /><span className="text-black">{sceneDetails.scene.scene}</span></td>
                      <td>Int/ Ext<br /><span className="text-black">{sceneDetails.scene.intExt}</span></td>
                      <td>Day/ Night<br /><span className="text-black">{sceneDetails.scene.dayNight}</span></td>
                      <td>Set<br /><span className="text-black">{sceneDetails.scene.set}</span></td>
                    </tr>
                    <tr>
                      <td className="py-3">Script Pages<br /><span className="text-black">{sceneDetails.scene.scriptPage}</span></td>
                      <td>Script Day(s)<br /><span className="text-black">{sceneDetails.scene.scriptDay}</span></td>
                      <td>Unit<br /><span className="text-black">{sceneDetails.scene.unit}</span></td>
                      <td colSpan="2">Sequence<br /><span className="text-black">{sceneDetails.scene.sequence}</span></td>
                    </tr>
                    <tr>
                      <td className="py-3" colSpan="3">Synopsis<br /><span className="text-black">{sceneDetails.scene.synopsis}</span></td>
                      <td>Artist No<br /><span className="text-black">{sceneDetails.scene.artistNumber}</span></td>
                      <td>Location<br /><span className="text-black">{sceneDetails.scene.projectLocationName}</span></td>
                    </tr>
                    <tr>
                      <td className="py-3">Est. Time<br /><span className="text-black">{sceneDetails.scene.estTime} hr</span></td>
                      <td colSpan="4">Comments<br /><span className="text-black">{sceneDetails.scene.comments}</span></td>
                    </tr>
                  </Table>
                </Modal.Body>
              }
              {sceneDetails && sceneDetails.myEvent &&
                <Modal.Body className="scene-detail-calender">
                  <Table>
                    <tr>
                      <td className="py-3">Event Name<br /><span className="text-black">{sceneDetails.title}</span></td>
                    </tr>
                    <tr>
                      <td className="py-3">Start Date<br /><span className="text-black">{moment(sceneDetails.start).format("DD MMMM YYYY")}</span></td>
                    </tr>
                    <tr>
                      <td className="py-3">End Date<br /><span className="text-black">{moment(sceneDetails.end).format("DD MMMM YYYY")}</span></td>
                    </tr>
                  </Table>
                </Modal.Body>
              }
            </Modal>
          </Card.Body>
        </Card>
      </div>
    </main>
  )
}
Calendars.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  showDemoLink: PropTypes.bool,
}