import React, { Fragment, } from "react";
import { Col, Container, Row } from "reactstrap";
import "../../../assets/style.css";

import Confirmedrafiki from '../../../assets/images/frontPageImg/Confirmed-rafiki.png';
import arrowIcon from "../../../assets/images/frontPageImg/arrowIcon.png";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";


const Verified = ({ selected }) => {
    const navigate = useNavigate();
    return (
        <Fragment>
            <Container fluid={true} className="p-0">
                <button
                    className="back-button"
                    onClick={() => navigate(-1)}
                    style={{
                        background: 'none',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '30px',
                        marginLeft: '30px'
                    }}
                >
                    <img src={arrowIcon} alt="Back" style={{ width: '15px', height: '15px', }} />
                    <span className="text-white" style={{ marginLeft: '10px' }}>Back</span>
                </button>
                <Container>
                    <Row>

                        <Col xs="12" className="text-center" style={{ height: "65vh" }}>
                            <img src={Confirmedrafiki} alt="" style={{ height: "72%", maxWidth: "100%", padding: 20 }} />
                            <h2 className="text-white" >Verified!</h2>
                            <p className="text-white pt-3" >You have successfully verified the account.</p>
                        </Col>
                    </Row>
                    <div className="text-center mt-2">
                        <Link to={`${process.env.PUBLIC_URL}/selectUserType`}>
                            <button type="button" style={{ backgroundColor: "#AA1163", width: 200 }} className="btn btn-square text-white">Continue</button></Link>
                    </div>
                </Container>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default Verified;
