import React, {memo, useEffect, useState} from 'react';

const SelectOptions = ({obj={}})=>{

    const [dataList, setDataList] = useState([]);

    useEffect(()=>{
        let list = [];
        for(let key in obj){
            list.push({label:obj[key], value:key});
        }
        setDataList(list);
    },[])

  return (<>
        {dataList.map((item, index)=>{
            return <option value={item.value} key={index}>{item.label}</option>
        })}
  </>);
}

export default memo(SelectOptions);
