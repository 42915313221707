import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import location1 from '../../assets/images/OldProImg/PictureVehicle/SM11.jpg';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { getVehicleDataAction, addEditVehicleAction, getScheduleListAction, getVendorDataAction, listSceneScheduleAction, getLocationList } from "../../action/Admin.action";
import { useState, memo, useEffect } from 'react';
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import line1 from '../../assets/images/OldProImg/line1.png'
import { imgPath } from '../common/function';
import { setAlert } from "../../slices/home";
import { projectDetailAction } from "../../action/Admin.action";
import Select from 'react-select';
import AlertBox from '../component/AlertBox'
import das2 from '../../assets/images/OldProImg/das2.png';
import Table from 'react-bootstrap/Table';
import { getTeamList, deleteAction, getCrewListAction } from "../../action/Admin.action";
import GuageChart from '../common/guageChart';

const DashBoard1 = () => {
    const [locationList, setLocationList] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formData, setFormData] = useState({});
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [category, setCategory] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [artistList, setArtistList] = useState([]);
    const [file, setFile] = useState("");
    const [Step, setStep] = useState(1);
    const [vehicleList, setVehicleList] = useState({});
    const [crewList, setCrewList] = useState({});
    const [vendoList, setVendorList] = useState({})
    const [ldata, setLdata] = useState(location.state);
    const [seeAll, setSeeAll] = useState(false);
    const [seeAll1, setSeeAll1] = useState(false);
    const [seeAll2, setSeeAll2] = useState(false);
    const [seeAll3, setSeeAll3] = useState(false);
    const [seeAll4, setSeeAll4] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [editData, setEditData] = useState({});
    const [startNum, setStartNum] = useState(0);
    const [getLst, setGetLst] = useState([]);
    const [getVnLst, setGetVnLst] = useState([]);
    const [sdlLst, setSdlLst] = useState();
    const [sceneData, setSceneData] = useState();





    const getList = async (action = '') => {
        const resp = await getTeamList();
        setGetLst(resp.data)
    }

    const getCrew = async (action = '') => {
        const resp = await getCrewListAction();
        if (resp.code === 200) {
            setCrewList(resp.data)
        }
    }

    const getLocationList = async () => {
        const resp = await getVendorDataAction();
        if (resp.code === 200) {
            setGetVnLst(resp.data)
        }

        const resp1 = await getScheduleListAction();
        if (resp1.code === 200) {
            setSdlLst(resp1.data)
        }

        const resp2 = await listSceneScheduleAction();
        if (resp2.code === 200) {
            setSceneData(resp2.data);
        }

        const resp3 = await getLocationList();
        if (resp3.code === 200) {
            setLocationList(resp3.data);
        }
    }
    let prNm = localStorage.getItem('projectName');

    useEffect(() => {
        getList();
        getCrew();
        getLocationList();
    }, [])

    return (
        <>
            <Row className="mt-3 pt-4">
                <Col className="col-5 col-sm-5 col-lg-2 col-md-2 pt-2">
                    <h4 className="pt-2 smsz"> {prNm}</h4>
                </Col>
            </Row>
            <Row className='mb-3'>
                {
                    sdlLst && sdlLst.length > 0 && sdlLst.map((v, i) => {
                        return <Col lg={4} md={6} className=" mt-1 " >
                            <Card className='m-2 me-2' style={{ borderRadius: '10px' }}  >
                                <Card.Title className=" mb-0" ></Card.Title>
                                <p className="cardtitle paratit  m-0 mx-3" style={{ fontSize: '18px' }}>{v.name}</p>
                                <GuageChart className='dsbrd2' />
                                <Row className='mt-3' >
                                    <Col lg={7} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>Total Task</Col>
                                    <Col lg={5} className='mt-1 mb-1   text-center paratit1' style={{ fontSize: '14px' }}>100</Col>
                                </Row>
                                <Row>
                                    <Col lg={7} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>Task Completed</Col>
                                    <Col lg={5} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>50</Col>
                                </Row>
                                <Row>
                                    <Col lg={7} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>In process</Col>
                                    <Col lg={5} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>20</Col>
                                </Row>
                                <Row>
                                    <Col lg={7} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>Reamaining</Col>
                                    <Col lg={5} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>30</Col>
                                </Row>
                                <Row>
                                    <Col lg={7} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>Estimated Time To Complete</Col>
                                    <Col lg={5} className='mt-1 mb-1 text-center paratit1' style={{ fontSize: '14px' }}>6 Month</Col>
                                </Row>
                                <Card.Body className="p-0 m-0">
                                    <Card.Text class="d-inline-block text-truncate  mb-0" style={{ maxWidth: '90%' }}>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    })
                }
            </Row>


            {/* <Table className="float-start brdR border mb-5">
                <thead>
                    <tr class="table-primary">

                        <th><span className='m-2'>Production Team</span></th>
                        <th></th>
                        <th></th>
                        <th>
                            <Row className='text-end'>
                                <Col className='col-lg-9'>
                                    {!seeAll && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll(true) }}>view all</p></>}
                                    {seeAll && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll(false) }}>see less</p></>}
                                </Col>
                            </Row>
                        </th>

                    </tr>

                    <tr class="thead-light">
                        <td><b>Name</b></td>
                        <td><b>Role</b></td>
                        <td><b>Email Id</b></td>
                        <td><b>Phone Number</b></td>
                    </tr>
                </thead>
                <tbody className="listing lis">
                    {
                        getLst && getLst.slice(0, 3).map((val, ind) => {
                            return <tr key={ind} class="thead-light">
                                <td>{val.name}</td>
                                <td>{val.designationName}</td>
                                <td>{val.email}</td>
                                <td>{val.mobile}</td>
                            </tr>
                        })
                    }
                    {getLst && getLst.length == 0 ? 'Data not avaialble.' : ''}
                </tbody>
                {
                    seeAll && <>

                        <tbody className="listing lis">
                            {
                                getLst && getLst.slice(3).map((val, ind) => {
                                    return <tr key={ind} class="thead-light">
                                        <td>{val.name}</td>
                                        <td>{val.designationName}</td>
                                        <td>{val.email}</td>
                                        <td>{val.mobile}</td>
                                    </tr>
                                })
                            }
                            {getLst && getLst.length == 0 ? 'Data not avaialble.' : ''}
                        </tbody>

                    </>
                }
            </Table> */}

            <Table className="float-start brdR border mb-5">
                <thead>
                    <tr class="table-primary">

                        <th>Location</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            <Row className='text-end'>
                                <Col className='col-lg-9'>
                                    {!seeAll1 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll1(true) }}>view all</p></>}
                                    {seeAll1 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll1(false) }}>see less</p></>}
                                </Col>
                            </Row>
                        </th>

                    </tr>

                    <tr class="thead-light">
                        <td><b>Name</b></td>
                        <td><b>Status</b></td>
                        <td><b>Tittle</b></td>
                        <td><b>Date</b></td>
                        <td><b>Status</b></td>
                    </tr>
                </thead>
                <tbody className="listing lis">
                    {
                        locationList && locationList.length > 0 && locationList.map((val, ind) => {
                            if (ind === 0) {
                                return <tr key={ind} class="thead-light">
                                    <td>{val.name}</td>
                                    <td>{val.designationName}</td>
                                    <td>{val.email}</td>
                                    <td>{val.email}</td>
                                    <td>{val.mobile}</td>
                                </tr>
                            }
                        })
                    }
                    {locationList && locationList.length == 0 ? 'Data not avaialble.' : ''}
                </tbody>
                {
                    seeAll1 && <>

                        <tbody className="listing lis">
                            {
                                locationList && locationList.length > 0 && locationList.map((val, ind) => {
                                    if (ind >= 1) {
                                        return <tr key={ind} class="thead-light">
                                            <td>{val.name}</td>
                                            <td>{val.designationName}</td>
                                            <td>{val.email}</td>
                                            <td>{val.email}</td>
                                            <td>{val.mobile}</td>
                                        </tr>
                                    }
                                })
                            }
                        </tbody>

                    </>
                }
            </Table>

            <Table className="float-start brdR border mb-5">
                <thead>
                    <tr class="table-primary">

                        <th>Scene</th>
                        <th></th>
                        <th></th>
                        <th>
                            <Row className='text-end'>
                                <Col className='col-lg-9'>
                                    {!seeAll2 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll2(true) }}>view all</p></>}
                                    {seeAll2 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll2(false) }}>see less</p></>}
                                </Col>
                            </Row>
                        </th>

                    </tr>

                    <tr class="thead-light">
                        <td><b>Name</b></td>
                        <td><b>Role</b></td>
                        <td><b>Email Id</b></td>
                        <td><b>Phone Number</b></td>
                    </tr>
                </thead>
                {/* getLst && getLst.length > 2 && getLst.map((val, ind) => { */}
                <tbody className="listing lis">
                    {
                        sceneData && sceneData.length > 0 && sceneData.map((val, ind) => {
                            if (ind === 0) {
                                return <tr key={ind} class="thead-light">
                                    <td>{val.name}</td>
                                    <td>{val.designationName}</td>
                                    <td>{val.email}</td>
                                    <td>{val.mobile}</td>
                                </tr>
                            }
                        })
                    }
                    {sceneData && sceneData.length == 0 ? 'Data not avaialble.' : ''}
                </tbody>
                {
                    seeAll2 && <>

                        <tbody className="listing lis">
                            {
                                sceneData && sceneData.length > 0 && sceneData.map((val, ind) => {
                                    if (ind >= 1) {
                                        return <tr key={ind} class="thead-light">
                                            <td>{val.name}</td>
                                            <td>{val.designationName}</td>
                                            <td>{val.email}</td>
                                            <td>{val.mobile}</td>
                                        </tr>
                                    }
                                })
                            }
                        </tbody>

                    </>
                }
            </Table>

            <Table className="float-start brdR border mb-5">
                <thead>
                    <tr class="table-primary">

                        <th>Vendor</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                            <Row className='text-end'>
                                <Col className='col-lg-9'>
                                    {!seeAll3 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll3(true) }}>view all</p></>}
                                    {seeAll3 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll3(false) }}>see less</p></>}
                                </Col>
                            </Row>
                        </th>
                        <th></th>
                    </tr>

                    <tr class="thead-light">
                        <td><b>Name</b></td>
                        <td><b>Tittle</b></td>
                        <td><b>Email Id</b></td>
                        <td><b>Address</b></td>
                        <td><b>Phone Number</b></td>
                        <td><b>Logo</b></td>
                    </tr>
                </thead>
                {/* getLst && getLst.length > 2 && getLst.map((val, ind) => { */}
                <tbody className="listing lis">
                    {
                        getVnLst && getVnLst.length > 0 && getVnLst.map((val, ind) => {
                            if (ind === 0) {
                                return <tr key={ind} class="thead-light">
                                    <td>{val.name}</td>
                                    <td>{val.role}</td>
                                    <td> {val.email}</td>
                                    <td> {val.address}</td>
                                    <td>{val.number}</td>
                                    <td>{<img className='emg' src={file == "" ? val.image ? imgPath(val.image) : "" : file} />}</td>
                                </tr>
                            }
                        })
                    }
                    {getVnLst && getVnLst.length == 0 ? 'Data not avaialble.' : ''}
                </tbody>
                {
                    seeAll3 && <>

                        <tbody className="listing lis">
                            {
                                getVnLst && getVnLst.length > 0 && getVnLst.map((val, ind) => {
                                    if (ind >= 1) {
                                        return <tr key={ind} class="thead-light">
                                            <td>{val.name}</td>
                                            <td>{val.role}</td>
                                            <td> {val.email}</td>
                                            <td> {val.address}</td>
                                            <td>{val.number}</td>
                                            <td>{val.mobile}</td>
                                        </tr>
                                    }
                                })
                            }
                        </tbody>

                    </>
                }
            </Table>

            <Table className="float-start brdR border mb-5">
                <thead>
                    <tr class="table-primary">

                        <th>Production Team</th>
                        <th></th>
                        <th></th>
                        <th>
                            <Row className='text-end'>
                                <Col className='col-lg-9'>
                                    {!seeAll4 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll4(true) }}>view all</p></>}
                                    {seeAll4 && <>
                                        <p className="hand m-2 mb-0" onClick={e => { setSeeAll4(false) }}>see less</p></>}
                                </Col>
                            </Row>
                        </th>

                    </tr>

                    <tr class="thead-light">
                        <td><b>Name</b></td>
                        <td><b>Role</b></td>
                        <td><b>Email Id</b></td>
                        <td><b>Phone Number</b></td>
                    </tr>
                </thead>
                <tbody className="listing lis">
                    {crewList && crewList.length > 0 && crewList.map((v, i) => {

                        if (v.department === '64b4542035ff4a84bdc63ecd' && i < 1) {
                            console.log(v)
                            return (<tr key={i} class="thead-light">
                                <td>{v.name}</td>
                                <td>{v && (v.roleDocs).length > 0 && (v.roleDocs).map((val, ind) => {
                                    console.log(val)
                                    return (val.name)
                                })}</td>
                                <td>{v.email}</td>
                                <td>{v.mobile}</td>
                            </tr>
                            )
                        }
                    })}
                </tbody>
                {
                    seeAll4 && <>

                        <tbody className="listing lis">
                            {
                                crewList && crewList.length > 1 && crewList.map((v, i) => {


                                    if (v.department === '64b4542035ff4a84bdc63ecd' && i >= 1) {
                                        console.log(v)
                                        return (<tr key={i} class="thead-light">
                                            <td>{v.name}</td>
                                            <td>{v && (v.roleDocs).length > 0 && (v.roleDocs).map((val, ind) => { return (val.name) })}</td>
                                            <td>{v.email}</td>
                                            <td>{v.mobile}</td>
                                        </tr>
                                        )
                                    }

                                })
                            }
                            {crewList && crewList.length == 0 ? 'Data not avaialble.' : ''}
                        </tbody>

                    </>
                }
            </Table>
        </>
    );
}
export default memo(DashBoard1);



