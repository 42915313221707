import React,{memo} from 'react'
import Modal from 'react-bootstrap/Modal';
import {Row,Col,Table} from 'react-bootstrap'

const DepartmentViewModal = ({formData}) => {
  return (
    <>
        <Modal.Header closeButton>
        {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <Row>
                <Col md={12} lg={12} >
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th colSpan="2" >
                                    <h5>Department Details:-</h5>
                                </th>
                            </tr>
                            <tr>
                                <th className="col-md-4">Name</th>
                                <td className="col-md-8">{formData.name}</td>
                            </tr>
                           
                            <tr>
                                <th className="col-md-4">Short Name</th>
                                <td className="col-md-8">{formData.shortName}</td>
                            </tr>
                            <tr>
                                <th className="col-md-4">Status</th>
                                <td className="col-md-8"></td>
                            </tr>
                          
                        </thead>
                    </Table>
                </Col>
            </Row>
        </Modal.Body>
        
    </>
  )
}

export default memo(DepartmentViewModal);