import { Card, Col, Form, Row, Button, Modal } from "react-bootstrap";
import LeadActress from '../../assets/images/OldProImg/profileIcn.jpeg';
import AlertBox from "../component/AlertBox";
import { useState, memo, useEffect } from 'react';
import { imgPath } from '../common/function';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAlert } from "../../slices/home";
import { sceneMaster } from "../common/constant";
// import moment from 'moment';
import pb5 from '../../assets/images/OldProImg/progess/pp5.png'
// import p1 from '../../assets/img/progess/pb1.png';
import p1 from '../../assets/images/OldProImg/progess/pp1.png';
import p2 from '../../assets/images/OldProImg/progess/pp2.png';
import p3 from '../../assets/images/OldProImg/progess/pp3.png';
import p4 from '../../assets/images/OldProImg/progess/pp4.png';
import { getVendorDataAction, editSceneAttributeAction, listSceneScheduleAction, getArtiestList, getCrewListAction } from "../../action/Admin.action";
import { Link } from "react-router-dom";



const ArtistOption = () => {
    // const [modalShow, setModalShow] = useState(false);
    // const [modalShow1, setModalShow1] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [artistList, setArtistList] = useState({});
    const [Step, setStep] = useState(1);
    const location = useLocation();
    let sceneData = location.state;
    const [ldata, setLdata] = useState(sceneData);
    const [vendorList1, setVendorList1] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const masters = useSelector((state) => state.home.masters);
    // const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    // const [dataList, setDataList] = useState([]);
    // const [mName, setMName] = useState("costume");


    const getList = async () => {
        let resp1 = await getVendorDataAction();
        if (resp1.code === 200) {
            setVendorList1(resp1.data)
        }
    }
    const getList12 = async () => {
        let resp1 = await getArtiestList();
        if (resp1.code === 200) {
            // setVendorList1(resp1.data)
        }
    }
    const getList1 = async () => {
        let resp1 = await getCrewListAction();
        if (resp1.code === 200) {
            {
                resp1 && resp1.data && (resp1.data).length > 0 && (resp1.data).map((v, i) => {
                    if (v.department == "000000000000000000000001") {
                        setArtistList(resp1.data)

                    }
                })
            }
        }
    }

    const recall = async () => {
        let resp2 = await listSceneScheduleAction();
        if (resp2.code == 200) {
            {
                resp2.data && resp2.data.length > 0 && resp2.data.map((itm, indx) => {
                    return (
                        itm.sceneData && itm.sceneData.length > 0 && itm.sceneData.map((v1, i1) => {
                            if ((v1._id) == ldata._id) {
                                return (setLdata(v1))
                            }
                        })
                    )
                })
            }
        }
    }

    const backBtn = async (event) => {
        let stp = Step - 1;
        setStep(stp)
        if (stp == 2 && ldata.makeup) {
            setFormData({ sceneId: ldata._id, type: "makeup", makeupArtist: ldata.makeup.makeupArtist, makeupType: ldata.makeup.makeupType, vendorId: ldata.makeup.vendorId, startDate: ldata.makeup.startDate, endDate: ldata.makeup.endDate, status: ldata.makeup.status, expense: ldata.makeup.expense })
        }
        else if (stp == 1 && ldata.costume) {
            setFormData({ sceneId: ldata._id, type: "costume", costumeName: ldata.costume.costumeName, designerName: ldata.costume.designerName, vendorId: ldata.costume.vendorId, startDate: ldata.costume.startDate, endDate: ldata.costume.endDate, status: ldata.costume.status, expense: ldata.costume.expense });
        }
        else if (stp == 3 && ldata.hairStyle) {
            setFormData({ sceneId: ldata._id, type: "hairStyle", hairStylistName: ldata.hairStyle.hairStylistName, hairStylistType: ldata.hairStyle.hairStylistType, vendorId: ldata.hairStyle.vendorId, startDate: ldata.hairStyle.startDate, endDate: ldata.hairStyle.endDate, status: ldata.hairStyle.status, expense: ldata.hairStyle.expense })
        }
        else if (stp == 4 && ldata.setDesign) {
            setFormData({ sceneId: ldata._id, type: "setDesign", setDesignerName: ldata.setDesign.setDesignerName, architectureStyle: ldata.setDesign.architectureStyle, vendorId: ldata.setDesign.vendorId, timePeriod: ldata.setDesign.timePeriod, startDate: ldata.setDesign.startDate, endDate: ldata.setDesign.endDate, status: ldata.setDesign.status, expense: ldata.setDesign.expense });
        }
        else if (stp == 5 && ldata.props) {
            setFormData({ sceneId: ldata._id, type: "props", propsType: ldata.props.propsType, propsDesc: ldata.props.propsDesc, timePeriod: ldata.props.timePeriod, vendorId: ldata.props.vendorId, startDate: ldata.props.startDate, endDate: ldata.props.endDate, status: ldata.props.status, expence: ldata.props.expence });

        }
        else {
            setFormData({ sceneId: ldata._id, type: sceneMaster[stp] })
        }
    }
    const awak = async () => {
        setStep(1);
        handleShow();
        setFormData({ sceneId: ldata._id, type: "costume", costumeName: ldata && ldata.costume && ldata.costume.costumeName, designerName: ldata && ldata.costume && ldata.costume.designerName, vendorId: ldata && ldata.costume && ldata.costume.vendorId, startDate: ldata && ldata.costume && ldata.costume.startDate, endDate: ldata && ldata.costume && ldata.costume.endDate, status: ldata && ldata.costume && ldata.costume.status, expense: ldata && ldata.costume && ldata.costume.expense });
    }
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let resp = await editSceneAttributeAction(formData);
            setDisabled(false);
            setValidated(false)
            if (resp.code === 200) {
                setLdata(resp.data)
                // let stp = (event.target.value) != "" ? ((event.target.value) - 1) : (Step + 1)
                let stp = Step + 1;
                let dec = (stp == 6) ? (handleClose() && setStep(1)) : setStep(stp);
                recall();
                if (stp == 2 && ldata.makeup) {
                    setFormData({ sceneId: ldata._id, type: "makeup", makeupArtist: ldata.makeup.makeupArtist, makeupType: ldata.makeup.makeupType, vendorId: ldata.makeup.vendorId, startDate: ldata.makeup.startDate, endDate: ldata.makeup.endDate, status: ldata.makeup.status, expense: ldata.makeup.expense })
                }
                else if (stp == 1 && ldata.costume) {
                    setFormData({ sceneId: ldata._id, type: "costume", costumeName: ldata.costume.costumeName, designerName: ldata.costume.designerName, vendorId: ldata.costume.vendorId, startDate: ldata.costume.startDate, endDate: ldata.costume.endDate, status: ldata.costume.status, expense: ldata.costume.expense });
                }
                else if (stp == 3 && ldata.hairStyle) {
                    setFormData({ sceneId: ldata._id, type: "hairStyle", hairStylistName: ldata.hairStyle.hairStylistName, hairStylistType: ldata.hairStyle.hairStylistType, vendorId: ldata.hairStyle.vendorId, startDate: ldata.hairStyle.startDate, endDate: ldata.hairStyle.endDate, status: ldata.hairStyle.status, expense: ldata.hairStyle.expense })
                }
                else if (stp == 4 && ldata.setDesign) {
                    setFormData({ sceneId: ldata._id, type: "setDesign", setDesignerName: ldata.setDesign.setDesignerName, architectureStyle: ldata.setDesign.architectureStyle, vendorId: ldata.setDesign.vendorId, timePeriod: ldata.setDesign.timePeriod, startDate: ldata.setDesign.startDate, endDate: ldata.setDesign.endDate, status: ldata.setDesign.status, expense: ldata.setDesign.expense });
                }
                else if (stp == 5 && ldata.props) {
                    setFormData({ sceneId: ldata._id, type: "props", propsType: ldata.props.propsType, propsDesc: ldata.props.propsDesc, timePeriod: ldata.props.timePeriod, vendorId: ldata.props.vendorId, startDate: ldata.props.startDate, endDate: ldata.props.endDate, status: ldata.props.status, expence: ldata.props.expence });

                }
                else {
                    setFormData({ sceneId: ldata._id, type: sceneMaster[stp] })
                }
                // getList1();
                let opn = (event.target.value) != '' ? false : true
                dispatch(setAlert({ open: opn, severity: "success", msg: "Successfully Submitted", type: '' }));
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            return false;
        }
    };


    const handleChange = (name, event) => {

        let from = { ...formData };

        from[name] = event.target.value;

        //start Date And Date Validation
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);

        const endDateInput = document.getElementById("endDate");
        if (endDateInput) {
            endDateInput.min = selectedStartDate;
            if (selectedStartDate > endDate) {
                setEndDate("");
            }
        }

        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
        const startDateInput = document.getElementById("startDate");
        if (startDateInput) {
            startDateInput.max = selectedEndDate;
            if (startDate && selectedEndDate < startDate) {
                setStartDate("");
            }
        }

        setFormData({ ...formData, ...from });

    }

    useEffect(() => {
        getList();
        getList1();
        getList12();
        recall();
        if (ldata && ldata.costume) {
            setFormData({ sceneId: ldata._id, type: "costume", costumeName: ldata.costume.costumeName, designerName: ldata.costume.designerName, vendorId: ldata.costume.vendorId, startDate: ldata.costume.startDate, endDate: ldata.costume.endDate, status: ldata.costume.status, expense: ldata.costume.expense });
        }
        else {
            if (ldata) {
                setFormData({ sceneId: ldata._id, type: "costume", });
            }
        }
    }, [])
    console.log(artistList);

    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy">
                        <Row className="mt-3 pt-2">
                            <Col lg={6} className="col-4 pt-2"><h6 className="crdHd1">Artists</h6></Col>
                            <Col lg={6} className="col-8 text-end"><i className="bi bi-search serBx m-2" />
                                <i className="bi bi-filter m-2"></i>
                                <Button className="bntCr myCr smsz mx-2" onClick={(e) => { navigate(`${process.env.PUBLIC_URL}/scene-option`) }}>Back</Button>
                                <Button className="bntCr myCr smsz" onClick={(e) => { recall(e); setValidated(false); setStep(1); awak(e); handleShow() }}>+Scene Master</Button></Col>
                        </Row>
                        <hr />

                        <Row className="mt-4">
                            {ldata && ldata.artistNo.length > 0 && ldata.artistNo.map((val, ind) => {
                                return (

                                    artistList && artistList.length > 0 && artistList.map((itm, indx) => {
                                        if (val == itm._id) {
                                            return (
                                                <Col lg={3} md={6}>
                                                    <Card className="hand" style={{ borderRadius: '20px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/artist-details`, { state: itm }) }}>
                                                        <img className="img21" src={itm.image && itm.image != "undefined" ? imgPath(itm.image) : LeadActress} />
                                                        <Card.Body className="m-0 p-0 text-center">
                                                            <h6 >{itm.name}</h6>
                                                            <p class="d-inline-block text-truncate" style={{ maxWidth: '90%' }}>{itm.locationDesc}</p>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    }))
                            })}
                        </Row>

                        <Row>
                            <Modal show={show}
                                onHide={handleClose}

                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Body>

                                    <Row><Col lg={3}><AlertBox /></Col></Row>
                                    {Step == 1 &&
                                        <div>
                                            <Row>
                                                <Col lg={4}>
                                                    <AlertBox />
                                                </Col>
                                            </Row>
                                            <Row><Col lg={11}><Modal.Title className="crdHd1 m-1 mt-0 mb-0">Costume</Modal.Title></Col>
                                                <Col lg={1}>
                                                    <span className='hand cancel closeBtn' onClick={() => { handleClose() }}>x</span>
                                                </Col>
                                                {/* <hr /> */}
                                            </Row>
                                            {/* <Row>
                                                <Col className="p-0 text-center mt-2">
                                                    <img src={p1} className="img-fluid" />
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col className="p-0 text-center mt-2">
                                                    <img src={p1} className="img-fluid" style={{ width: '100%', height: 'auto' }} />
                                                </Col>
                                            </Row>
                                            <Form className="mx-3" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                                <Row>
                                                    <Form.Group className="d-none" controlId="type">
                                                        <Form.Control className="brdr frmcnt" type="text"
                                                            maxLength={35} minLength={3} onChange={e => handleChange('type', e)} value={formData.type ? formData.type : "costume"} required />
                                                    </Form.Group>
                                                    <Col className="txt" lg={4}>
                                                        <Form.Group className="mb-2" controlId="costumeName">
                                                            <Form.Label>Costume Name<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" maxLength={32} minLength={2} autoFocus type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Costume" value={formData.costumeName ? formData.costumeName : ""} onChange={e => handleChange('costumeName', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Costume Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="designerName">
                                                            <Form.Label>Design Name<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$"
                                                                maxLength={32} minLength={2} placeholder="Name of Designer" onChange={e => handleChange('designerName', e)} value={formData.designerName ? formData.designerName : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Designer Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col className="txt" lg={4}>
                                                        <Form.Group className="mb-2" controlId="vendorId">
                                                            <Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                                                                <option value="">Select Vendor</option>
                                                                {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                                                    return <option value={(val._id)} key={ind}  >{val.name}</option>
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col className="txt" lg={4}>
                                                        <Form.Group className="mb-2" controlId="startDate">
                                                            <Form.Label>Start Date<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Start Date.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="txt" lg={4}>
                                                        <Form.Group className="mb-2" controlId="endDate">
                                                            <Form.Label>End Date<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="date"
                                                                min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid End Date.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="txt" lg={4}>
                                                        <Form.Group className="mb-2" controlId="status">
                                                            <Form.Label>Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="status" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                                <option value="">Select Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Select Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="txt" lg={4}>
                                                        <Form.Group className="mb-2" controlId="expense">
                                                            <Form.Label>Expense<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' minLength={1} maxLength={15} placeholder="100,000" value={formData.expense ? formData.expense : ""} onChange={e => handleChange('expense', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Amount.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                                        <Button variant="secondary" value="5" className="btnk vndrBtn" onClick={() => { handleClose(); getList(); }}>
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                                        <Button variant="secondary" type="submit" className="btnk vndrBtn" disabled={isDisabled}>
                                                            Next
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </div>
                                    }
                                    {Step == 2 &&
                                        <div>
                                            <Row>
                                                <Col>
                                                    <h6 className="m-3 mt-0 mb-0 crdHd1">Makeup</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-0 text-center mt-2">
                                                    <img src={p2} className="img-fluid" style={{ width: '100%', height: 'auto' }} />
                                                </Col>
                                            </Row>
                                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                                <Row>
                                                    <Form.Group className="d-none" controlId="type">
                                                        <Form.Control className="brdr" type="text" onChange={e => handleChange('type', e)} value={formData.type ? formData.type : "makeup"} required />
                                                    </Form.Group>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="makeupArtist">
                                                            <Form.Label>Makeup Artist<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" minLength={2} maxLength={32} placeholder="Name of Makeup Artist" onChange={e => handleChange('makeupArtist', e)} value={formData.makeupArtist ? formData.makeupArtist : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a Valid makeup Artist Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="makeupType">
                                                            <Form.Label>Makeup Type<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" minLength={2} maxLength={32} placeholder="Type of Makeup" onChange={e => handleChange('makeupType', e)} value={formData.makeupType ? formData.makeupType : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Makeup Type.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="vendorId">
                                                            <Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                                                            <Form.Control as='select' className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                                                                <option value="">Select Vendor</option>
                                                                {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                                                    return <option value={(val._id)} key={ind}  >{val.name}</option>
                                                                })}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Select Valid Vendor.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="startDate">
                                                            <Form.Label>Start Date<span className='star'>*</span></Form.Label>
                                                            <Form.Control type="date" className="brdr frmcnt" value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)}
                                                                min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Start Date.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="endDate">
                                                            <Form.Label className="txt">End Date<span className='star'>*</span></Form.Label>
                                                            <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid End Date.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="status">
                                                            <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                            <Form.Control as='select' className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required >

                                                                <option value="">Select Option</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Select Valid Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="expense">
                                                            <Form.Label>Expense<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' maxLength={15} minLength={1} placeholder="22200" onChange={e => handleChange('expense', e)} value={formData.expense ? formData.expense : ""} required />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                                        <Button variant="secondary" className="btnk vndrBtn" onClick={backBtn}>
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                                        <Button variant="secondary" type="submit" className="btnk vndrBtn" >
                                                            Next
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    }
                                    {Step == 3 &&
                                        <div>
                                            <Row>
                                                <Col>
                                                    <h6 className="m-3 mt-0 mb-0 crdHd1">Hairstyle</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-0 text-center mt-2">
                                                    <img src={p3} className="img-fluid" style={{ width: '100%', height: 'auto' }} />
                                                </Col>
                                            </Row>
                                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                                <Row>
                                                    <Form.Group className="d-none" controlId="type">
                                                    </Form.Group>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="hairStylistName">
                                                            <Form.Label>HairStylish<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" placeholder="Name of Hairstylish" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" minLength="2" maxLength="25" onChange={e => handleChange('hairStylistName', e)} value={formData.hairStylistName ? formData.hairStylistName : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Hair Style.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="hairStylistType">
                                                            <Form.Label>HairStyle Type<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" aria-label="Default select example"
                                                                type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" minLength="2" maxLength="25" onChange={e => handleChange('hairStylistType', e)} value={formData.hairStylistType ? formData.hairStylistType : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Hair Style Type.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="vendorId">
                                                            <Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                                                            <Form.Control as='select' className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                                                                <option value="">Select Vendor</option>
                                                                {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                                                    return <option value={(val._id)} key={ind}  >{val.name}</option>
                                                                })}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Row>

                                                        <Col lg={4} className="txt">
                                                            <Form.Group className="mb-2" controlId="startDate">
                                                                <Form.Label>Start Date<span className='star'>*</span></Form.Label>
                                                                <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Start Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="txt">
                                                            <Form.Group className="mb-2" controlId="endDate">
                                                                <Form.Label>End Date<span className='star'>*</span></Form.Label>
                                                                <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid End Date.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={4} className="txt">
                                                            <Form.Group controlId="status">
                                                                <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                                <Form.Control as='select' className="brdr frmcnt" aria-label="Default select example"
                                                                    placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                                    <option value="">Select Option</option>
                                                                    <option value="1">Active</option>
                                                                    <option value="0">Inactive</option>
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Status.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4} className="txt">
                                                            <Form.Group className="mb-2" controlId="expense">
                                                                <Form.Label>Expense<span className='star'>*</span></Form.Label>
                                                                <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' minLength="1" maxLength="15" placeholder="7000" onChange={e => handleChange('expense', e)} value={formData.expense ? formData.expense : ""} required />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid expense.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                                        <Button variant="secondary" value='2' className="btnk vndrBtn" onClick={backBtn}>
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                                        <Button variant="secondary" type="submit" className="btnk vndrBtn" >
                                                            Next
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    }
                                    {Step == 4 &&
                                        <div>
                                            <Row>
                                                <Col>
                                                    <h6 className="m-3 mt-0 mb-0 crdHd1">Set Design</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-0 text-center mt-2">
                                                    <img src={p4} className="img-fluid" style={{ width: '100%', height: 'auto' }} />
                                                </Col>
                                            </Row>
                                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                                <Row>
                                                    <Form.Group className="d-none" controlId="type">
                                                        <Form.Control className="brdr" type="text" onChange={e => handleChange('type', e)} value={formData.type ? formData.type : "setDesign"} required />
                                                    </Form.Group>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="setDesignerName">
                                                            <Form.Label>Set Designer<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" placeholder="Name of Designer" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" minLength="2" maxLength="32" onChange={e => handleChange('setDesignerName', e)} value={formData.setDesignerName ? formData.setDesignerName : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Set Designer.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="architectureStyle">
                                                            <Form.Label >Architecture Style<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" aria-label="Default select example"
                                                                placeholder="Style Name" minLength="2" maxLength="32" onChange={e => handleChange('architectureStyle', e)} value={formData.architectureStyle ? formData.architectureStyle : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="txt">
                                                        <Form.Group className="mb-2" controlId="timePeriod">
                                                            <Form.Label>Time Period<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="time" onChange={e => handleChange('timePeriod', e)} value={formData.timePeriod ? formData.timePeriod : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Time.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="vendorId">
                                                            <Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                                                                <option value="">Select Vendor</option>
                                                                {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                                                    return <option value={(val._id)} key={ind}  >{val.name}</option>
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="startDate">
                                                            <Form.Label>Start Date<span className='star'>*</span></Form.Label>
                                                            <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Start Date.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="endDate">
                                                            <Form.Label>End Date<span className='star'>*</span></Form.Label>
                                                            <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid End Date.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="status">
                                                            <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                                <option value="">Select Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="expense">
                                                            <Form.Label>Expense<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' minLength="1" maxLength="15" placeholder="456778" onChange={e => handleChange('expense', e)} value={formData.expense ? formData.expense : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Expense.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                                        <Button variant="secondary" onClick={backBtn} className="btnk vndrBtn">
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                                        <Button variant="secondary" type="submit" className="btnk vndrBtn" >
                                                            Next
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    }
                                    {Step == 5 &&
                                        <div>
                                            <Row>
                                                <Col>
                                                    <h6 className="m-3 mt-0 mb-0 crdHd1">Props</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="p-0 text-center mt-2">
                                                    <img src={pb5} className="img-fluid" style={{ width: '100%', height: 'auto' }} />
                                                </Col>
                                            </Row>
                                            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                                <Row >
                                                    <Col lg={8}>
                                                        <Row>
                                                            <Form.Group className="d-none" controlId="type">
                                                                <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('type', e)} value={formData.type ? formData.type : "props"} required />
                                                            </Form.Group>
                                                            <Col lg={6} className="txt">
                                                                <Form.Group className="mb-2" controlId="propsType">
                                                                    <Form.Label>Type<span className='star'>*</span></Form.Label>
                                                                    <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" minLength={2} maxLength={32} placeholder="Name of Props" onChange={e => handleChange('propsType', e)} value={formData.propsType ? formData.propsType : ""} required />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please provide a valid Props-Type.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6} className="txt">
                                                                <Form.Group className="mb-2" controlId="timePeriod">
                                                                    <Form.Label>Time Period<span className='star'>*</span></Form.Label>
                                                                    <Form.Control className="brdr frmcnt" type="time" onChange={e => handleChange('timePeriod', e)} value={formData.timePeriod ? formData.timePeriod : ""} required />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please provide a valid Time Period.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6} className="txt">
                                                                <Form.Group className="mb-2" controlId="startDate">
                                                                    <Form.Label>Start Date<span className='star'>*</span></Form.Label>
                                                                    <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please provide a valid Start Date.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6} className="txt">
                                                                <Form.Group className="mb-2" controlId="endDate">
                                                                    <Form.Label>End Date<span className='star'>*</span></Form.Label>
                                                                    <Form.Control type="date" className="brdr frmcnt" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please provide a valid End Date.
                                                                    </Form.Control.Feedback>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <Col className="txt">
                                                            <Form.Group className="mb-2" controlId="propsDesc">
                                                                <Form.Label>Props Description</Form.Label>
                                                                <Form.Control as="textarea" className="brdr frmcnt" minLength={2} maxLength={250} onChange={e => handleChange('propsDesc', e)} value={formData.propsDesc ? formData.propsDesc : ""} rows={5} />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a valid Description.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="vendorId">
                                                            <Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                                                                <option value="">Select Vendor</option>
                                                                {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                                                    return <option value={(val._id)} key={ind}  >{val.name}</option>
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-2" controlId="status">
                                                            <Form.Label>Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                                <option value="">Select Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Inactive</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="txt">
                                                        <Form.Group className="mb-2" controlId="expence">
                                                            <Form.Label>Expense<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' minLength={1} maxLength={15} onChange={e => handleChange('expence', e)} value={formData.expence ? formData.expence : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Expense.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className="mt-3">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                                        <Button variant="secondary" className="btnk vndrBtn " onClick={backBtn}>
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end' >
                                                        <Button variant="secondary" className="btnk vndrBtn" type='submit' disabled={isDisabled}>
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    }
                                </Modal.Body>
                            </Modal>
                        </Row>
                    </Card.Body>
                </Card>
            </div >
        </>
    );
}

export default memo(ArtistOption);