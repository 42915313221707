import img1 from '../../assets/images/OldProImg/sheetGen/CrewList.png';
import img2 from '../../assets/images/OldProImg/vendor.png';
import img3 from '../../assets/images/OldProImg/sheetGen/hotel.png';
import img4 from '../../assets/images/OldProImg/sheetGen/transport.png';
import img5 from '../../assets/images/OldProImg/sheetGen/travel.png';
import masterBd from '../../assets/images/OldProImg/sheetGen/masterBd.png';
import dpr from '../../assets/images/OldProImg/sheetGen/dpr.png';




export const Locationpermission = { 0: 'Yes', 1: 'No' };
export const Permission = { 0: 'No', 1: 'Yes' };
export const Status = { 1: 'Active', 0: 'InActive' };



export const NavTypes = [
    {
        name: "TEAM",
        id: 1
    },
    {
        name: "ARTIST",
        id: 2
    },
    {
        name: "SCHEDULE",
        id: 3
    },
    {
        name: "CALENDAR",
        id: 4
    },
    {
        name: "LOCATION",
        id: 5
    }
];

export const LocationType = [
    {
        name: "Industrial",
        id: 1
    },
    {
        name: "Nature",
        id: 2
    },
    {
        name: "Hilly",
        id: 3
    },
    {
        name: "Sea Side",
        id: 4
    },
    {
        name: "Fords",
        id: 5
    }
];

export const IntExt = { "Int": "Interior", "Ext": "Exterior" };
export const ScriptDays = { "Monday": "Monday", "Tuesday": "Tuesday", "Wednesday": "Wednesday", "Thusday": "Thusday", "Friday": "Friday", "Saturday": "Saturday", "Sunday": "Sunday" };

// export const IntExt = [
//     {
//         name:"Interior",
//         short:"Int",
//         id:1
//     },
//     {
//         name:"Exterior.",
//         short:"Ext",
//         id:2
//     }
// ];

export const PermissionList = [

    {
        "name": "Team Access",
        "type": "1",
        "action": [
            {
                "name": "View",
                "type": "TEAM_LIST"
            },
            {
                "name": "Add",
                "type": "TEAM_ADD"
            },
            {
                "name": "Edit",
                "type": "TEAM_EDIT"
            },
            {
                "name": "Delete",
                "type": "TEAM_Delete"
            }
        ]
    },
    {
        "name": "Company Access",
        "type": "2",
        "action": [
            {
                "name": "View",
                "type": "COMPANY_LIST"
            },
            {
                "name": "Add",
                "type": "COMPANY_ADD"
            },
            {
                "name": "Edit",
                "type": "COMPANYEDIT"
            },
            {
                "name": "Delete",
                "type": "COMPANY_Delete"
            }
        ]
    }
];


export const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 10]

export const oneLinerFields = {
    "sheet": "Sheet",
    "scene": "Scene No",
    "intExt": "Int /Ext",
    "dayNight": "Day /Night",
    "set": "Set",
    "scriptPage": "Script Pages",
    "scriptDay": "Script Day",
    "unit": "Unit",
    "sequence": "Sequence",
    "synopsis": "One Liner Heading",
    "year": "Year",
    "artistNumber": "Character IDs",
    "projectLocationId": "Location",
    "estTime": "Est. Time",
    "comments": "Comments",
};

export const getOneLinerFields = () => {
    let list = [];
    for (let key in oneLinerFields) {
        list.push({ label: oneLinerFields[key], value: key });
    }
    return list;
}

export const bucketUrl = "https://zena-media.s3.ap-south-1.amazonaws.com/productionDev/";

export const imgPath = (image) => {

    //return  process.env.REACT_APP_API_URL+'/'+image;
    //return  'http://3.109.97.64:3005/'+image;

    return bucketUrl + image;

}

export const xml = '<?xml version="1.0" encoding="UTF-8"?><Error><Code>AccessDenied</Code><Message>Access Denied</Message><RequestId>TNJY8THN1NB0H43V</RequestId><HostId>N8bekZFCQ0BhLcHHJBfkw5BAV0I2AjktT3ov3Ng11/vHGvHafjIN8/WFBCt1NzaPf/9EnD3L4Id76HZha0Qn6Q==</HostId></Error>';

export const VehicleType = [
    {
        name: "SUV",
        value: "SUV"
    },
    {
        name: "Hatchback",
        id: "Hatchback"
    },
    {
        name: "Minivan",
        id: "Minivan"
    },
    {
        name: "Crossover",
        id: "Crossover"
    },
    {
        name: "Coupe",
        id: "Coupe"
    },
    {
        name: "Convertible",
        id: "Convertible"
    }
];

export const VendorRole = [
    {
        name: "Camera equipment",
        value: "Camera equipment",
        subCatOf: "Equipment Rental Companies"
    },
    {
        name: "Lighting gear",
        value: "Lighting gear",
        subCatOf: "Equipment Rental Companies"
    },
    {
        name: "Sound equipment",
        value: "Sound equipment",
        subCatOf: "Equipment Rental Companies"
    },
    {
        name: "Grip equipment",
        value: "Grip equipment",
        subCatOf: "Equipment Rental Companies"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Equipment Rental Companies"
    },
    {
        name: "Sound stages",
        value: "Sound stages",
        subCatOf: "Production Studios"
    },
    {
        name: "Backlots",
        value: "Backlots",
        subCatOf: "Production Studios"
    },
    {
        name: "indoor and outdoor shooting",
        value: "indoor and outdoor shooting",
        subCatOf: "Production Studios"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Production Studios"
    },
    {
        name: "Props",
        value: "Props",
        subCatOf: "Props and Set Decoration Companies"
    },
    {
        name: "Set pieces",
        value: "Set pieces",
        subCatOf: "Props and Set Decoration Companies"
    },
    {
        name: "Decorations needed",
        value: "Decorations needed",
        subCatOf: "Props and Set Decoration Companies"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Props and Set Decoration Companies"
    },
    {
        name: "Clothing",
        value: "Clothing",
        subCatOf: "Costume Suppliers"
    },
    {
        name: "Accessories",
        value: "Accessories",
        subCatOf: "Costume Suppliers"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Costume Suppliers"
    },
    {
        name: "visual effects (VFX)",
        value: "visual effects (VFX)",
        subCatOf: "Special Effects Companies"
    },
    {
        name: "Practical effects",
        value: "Practical effects",
        subCatOf: "Special Effects Companies"
    },
    {
        name: "computer-generated imagery (CGI)",
        value: "computer-generated imagery (CGI)",
        subCatOf: "Special Effects Companies"
    },
    {
        name: "Physical effects",
        value: "Physical effects",
        subCatOf: "Special Effects Companies"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Special Effects Companies"
    },
    {
        name: "Meals and snacks",
        value: "Meals and snacks",
        subCatOf: "Catering Services"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Catering Services"
    },
    {
        name: "Logistics And Crew Shipment",
        value: "Logistics And Crew Shipment",
        subCatOf: "Transportation Services"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Transportation Services"
    },
    {
        name: "editing suites",
        value: "editing suites",
        subCatOf: "Post-Production Facilities"
    },
    {
        name: "color grading",
        value: "color grading",
        subCatOf: "Post-Production Facilities"
    },
    {
        name: "sound mixing",
        value: "sound mixing",
        subCatOf: "Post-Production Facilities"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Post-Production Facilities"
    },
    {
        name: "protect against accidents",
        value: "protect against accidents",
        subCatOf: "Insurance Companies"
    },
    {
        name: "unforeseen events",
        value: "unforeseen events",
        subCatOf: "Insurance Companies"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Insurance Companies"
    },
    {
        name: "obtaining necessary permits and permissions",
        value: "obtaining necessary permits and permissions",
        subCatOf: "Location Services"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Location Services"
    },
    {
        name: "finding actors and extras",
        value: "finding actors and extras",
        subCatOf: "Casting Agencies"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Casting Agencies"
    },
    {
        name: "protect equipment & Team",
        value: "protect equipment & Team",
        subCatOf: "Security Services"
    },
    {
        name: "Other",
        value: "Other",
        subCatOf: "Security Services"
    },
];

export const VendorType = [
    {
        name: "Equipment Rental Companies",
        value: "Equipment Rental Companies"
    },
    {
        name: "Production Studios",
        value: "Production Studios"
    },
    {
        name: "Props and Set Decoration Companies",
        value: "Props and Set Decoration Companies"
    },
    {
        name: "Costume Suppliers",
        value: "Costume Suppliers"
    },
    {
        name: "Special Effects Companies",
        value: "Special Effects Companies"
    },
    {
        name: "Catering Services",
        value: "Catering Services"
    },
    {
        name: "Transportation Services",
        value: "Transportation Services"
    },
    {
        name: "Post-Production Facilities",
        value: "Post-Production Facilities"
    },
    {
        name: "Insurance Companies",
        value: "Insurance Companies"
    },
    {
        name: "Location Services",
        value: "Location Services"
    },
    {
        name: "Casting Agencies",
        value: "Casting Agencies"
    },
    {
        name: "Security Services",
        value: "Security Services"
    }
];

export const MyTask = [
    {
        sNo: "01",
        task: "Arrange camera for tommorrow",
        msg: 5,
        status: 0
    },
    {
        sNo: "02",
        task: "Organize hair and makeup artist",
        msg: 0,
        status: 0
    },
    {
        sNo: "03",
        task: "Send updated script",
        msg: 1,
        status: 0
    },
    {
        sNo: "04",
        task: "Arrange camera for tommorrow",
        msg: 4,
        status: 1
    },
    {
        sNo: "05",
        task: "Organize hair and makeup artist",
        msg: 9,
        status: 1
    },
    {
        sNo: "06",
        task: "Send updated script",
        msg: 0,
        status: 0
    },
    {
        sNo: "07",
        task: "Send updated script",
        msg: 1,
        status: 0
    },
    {
        sNo: "08",
        task: "Arrange camera for tommorrow",
        msg: 4,
        status: 1
    },
    {
        sNo: "09",
        task: "Organize hair and makeup artist",
        msg: 9,
        status: 1
    },
    {
        sNo: "10",
        task: "Send updated script",
        msg: 0,
        status: 0
    },
    {
        sNo: "11",
        task: "Arrange camera for tommorrow",
        msg: 5,
        status: 1
    },
    {
        sNo: "12",
        task: "Organize hair and makeup artist",
        msg: 8,
        status: 1
    }
]

export const ToDoList = [
    {
        sNo: "01",
        task: "Organize hair, makeup artist",
        reportTo: "Ross G.",
        dueDate: "21 Sept, 2022",
        msg: 5,
        status: 0
    },
    {
        sNo: "02",
        task: "Organize props",
        reportTo: "Monica",
        dueDate: "21 Sept, 2022",
        msg: 0,
        status: 0
    },
    {
        sNo: "03",
        task: "Cast your talent",
        reportTo: "Ross G.",
        dueDate: "21 Sept, 2022",
        msg: 1,
        status: 0
    },
    {
        sNo: "04",
        task: "Permits for the location",
        reportTo: "Monica",
        dueDate: "22 Sept, 2022",
        msg: 4,
        status: 1
    },
    {
        sNo: "05",
        task: "Lock shooting script",
        reportTo: "Chandler",
        dueDate: "23 Sept, 2022",
        msg: 9,
        status: 1
    },
    {
        sNo: "06",
        task: "Organize props",
        reportTo: "Rachel Green",
        dueDate: "23 Sept, 2022",
        msg: 0,
        status: 1
    },
    {
        sNo: "07",
        task: "Cast your talent",
        reportTo: "Rachel Green",
        dueDate: "23 Sept, 2022",
        msg: 1,
        status: 1
    },
]

export const UpcommingEvents = [
    {
        sNo: "01",
        eventName: "Event",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    },
    {
        sNo: "02",
        eventName: "Organize props",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "04",
        day: "Sun"
    },
    {
        sNo: "03",
        eventName: "Cast your talent",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "10",
        day: "Sat"
    },
    {
        sNo: "04",
        eventName: "Permits for the location",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    },
    {
        sNo: "05",
        eventName: "Lock shooting script",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    }
]
export const CurrentEvent = [
    {
        sNo: "01",
        eventName: "Event",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    },
    {
        sNo: "02",
        eventName: "Organize props",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "04",
        day: "Sun"
    },
    {
        sNo: "03",
        eventName: "Cast your talent",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "10",
        day: "Sat"
    },
    {
        sNo: "04",
        eventName: "Permits for the location",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    },
    {
        sNo: "05",
        eventName: "Lock shooting script",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    }
]
export const PastEvent = [
    {
        sNo: "01",
        eventName: "Event",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    },
    {
        sNo: "02",
        eventName: "Organize props",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "04",
        day: "Sun"
    },
    {
        sNo: "03",
        eventName: "Cast your talent",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "10",
        day: "Sat"
    },
    {
        sNo: "04",
        eventName: "Permits for the location",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    },
    {
        sNo: "05",
        eventName: "Lock shooting script",
        from: "04:00 PM - 06:00 PM",
        location: "Indore",
        date: "02",
        day: "Fri"
    }
]

export const preProData = { name: 'PreProduction', value: 100 }
export const proData = { name: 'Production', value: 65 }
export const postproData = { name: 'Post Production', value: 30 }
export const ReamainingDays = { name: 'Reamaining Days', value: 140 }
export const teams = { name: 'Teams', value: 50 }

export const sceneMaster = { 1: "costume", 2: "makeup", 3: "hairStyle", 4: "setDesign", 5: "props" }

export const Data = [
    { label: 'Category A', value: 25, color: 'blue' },
    { label: 'Category B', value: 30, color: 'green' },
    { label: 'Category C', value: 15, color: 'orange' },
    { label: 'Category D', value: 30, color: 'red' },
];

export const ProjectData = [
    { _id: '001', name: 'Gadar-2', startDate: '12/01/2023', endDate: '15/01/2024', schedule: { 0: { name: 'schedule -1' }, 1: { name: 'schedule -2' }, 2: { name: 'schedule -3' } }, location: { 0: { name: 'location -1' }, 1: { name: 'location -2' }, 2: { name: 'location -3' } }, assignedTo: { 0: { name: 'a' }, 2: { name: 'c' } }, status: 'active' },
    { _id: '002', name: 'KGF-2', startDate: '23/01/2024', endDate: '15/01/2025', schedule: { 0: { name: 'schedule -1' }, 1: { name: 'schedule -2' }, 2: { name: 'schedule -3' } }, location: { 0: { name: 'location -1' }, 1: { name: 'location -2' }, 2: { name: 'location -3' } }, assignedTo: { 0: { name: 'a' }, 2: { name: 'c' } }, status: 'active' },
    { _id: '003', name: 'ADI-PURUSH', startDate: '11/01/2026', endDate: '15/01/2027', schedule: { 0: { name: 'schedule -1' }, 1: { name: 'schedule -2' }, 2: { name: 'schedule -3' } }, location: { 0: { name: 'location -1' }, 1: { name: 'location -2' }, 2: { name: 'location -3' } }, assignedTo: { 0: { name: 'a' }, 2: { name: 'c' } }, status: 'active' },
    { _id: '004', name: 'RRR', startDate: '05/01/2023', endDate: '15/01/2025', schedule: { 0: { name: 'schedule -1' }, 1: { name: 'schedule -2' }, 2: { name: 'schedule -3' } }, location: { 0: { name: 'location -1' }, 1: { name: 'location -2' }, 2: { name: 'location -3' } }, assignedTo: { 0: { name: 'a' }, 2: { name: 'c' } }, status: 'active' },
    { _id: '005', name: 'DREAM-GIRL', startDate: '02/01/2024', endDate: '15/01/2025', schedule: { 0: { name: 'schedule -1' }, 1: { name: 'schedule -2' }, 2: { name: 'schedule -3' } }, location: { 0: { name: 'location -1' }, 1: { name: 'location -2' }, 2: { name: 'location -3' } }, assignedTo: { 0: { name: 'a' }, 2: { name: 'c' } }, status: 'active' }
]

export const projectName = [
    { _id: '001' },
    { _id: '002' },
    { _id: '003' },
    { _id: '004' },
    { _id: '005' },
]
export const SheetData = [
    {no:1,name:"Crew List", img:img1},
    {no:2,name:"Vendor", img:img2},
    {no:3,name:"Hotel Stay List", img:img3},
    // {no:4,name:"Transport", img:img4},
    {no:5,name:"Travel", img:img5},
    {no:6,name:"Master Breakdown", img:masterBd},
    {no:7,name:"Costume Breakdown", img:dpr},
    {no:8,name:"Juniors Breakdown", img:masterBd},
    {no:9,name:"Call Sheet", img:dpr},
    {no:10,name:"DPR", img:masterBd},
    {no:11,name:"Art Breakdown", img:dpr},
]

// export const dataList = [
//     {_id:"001",name:"sanjeev",department:"node.js",designation:"junior developer",mobile:"9998887771",email:"sanjeev@gmail.com"},
//     {_id:"002",name:"rahul",department:"react.js",designation:"junior developer",mobile:"9998887772",email:"rahul@gmail.com"},
//     {_id:"003",name:"shubham",department:"data science",designation:"junior developer",mobile:"9998887773",email:"shubham@gmail.com"},
//     {_id:"004",name:"ankit",department:"IOS Developer",designation:"junior developer",mobile:"9998887774",email:"ankit@gmail.com"},
//     {_id:"005",name:"vishal",department:"data science",designation:"junior developer", mobile:"9998887775",email:"vishal@gmail.com"},
// ]
