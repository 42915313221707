import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import { Card } from "react-bootstrap";

const FilmFestivals = ({ selected }) => {
    const filmFestivals = [
        {
            sn: 1,
            name: "NFDC MUMBAI",
            contactDetails: "NFDC - FD Complex,24, Dr. Gopalrao Deshmukh Marg,Mumbai 400 026, Maharashtra.",
            email: "nfdc@nfdcindia.com",
            mobileNo: "Tel: +91 22 35248444 "
        },
        {
            sn: 2,
            name: "NFDC KOLKATA",
            contactDetails: "Film Centre, RIC Industrial Estate,Upen Banerjee Road, Parnasree,Kolkata 700 060, West Bengal.",
            email: "rama@nfdcindia.com",
            mobileNo: "Tel: +91 33240 15232|33 , Fax: +91 33240 15232"
        },
        {
            sn: 3,
            name: "NFDC DELHI",
            contactDetails: "4th Floor, Soochna Bhavan, Phase 1,C.G.O Complex, Lodhi Road,New Delhi 110 003.",
            email: "ajay@nfdcindia.com",
            mobileNo: "Tel: +91 11243 69462|63, Fax: +91 11243 68011"
        },
        {
            sn: 4,
            name: "NFDC CHENNAI",
            contactDetails: "1st Floor, Co-optex Warehouse Building,350 Pantheon Road, Egmore,Chennai 600 008",
            email: "rohini@nfdcindia.com | nidhi@nfdcindia.com",
            mobileNo: "Tel: +91 44281 92304|2410, Fax: +91 44281 92408"
        },
        {
            sn: 5,
            name: "NFDC Thiruvananthapuram",
            contactDetails: "Chitranjali Studio Complex,Thiruvallam,Thiruvananthapuram - 695027",
            email: "jain@nfdcindia.com",
            mobileNo: "Telfax:  +91 4712384033"
        },
        {
            sn: 6,
            name: "IFTDA",
            contactDetails: "G-8/9/10, Crescent Towers, Near Morya House, Off New Link Road, Andheri (W), Mumbai – 400 053 India.",
            email: "contact@directorsiftda.com | iftdaindia@gmail.com",
            mobileNo: "022 46088096 (Landline), +91 9892885346, +91 7021494476 "
        },
        {
            sn: 7,
            name: "WIFPA",
            contactDetails: "206/207, Richa Bldg., 2nd flr,Plot No B-29, Opposite City Mall,New Link Road, Andheri(west)Mumbai 400 053",
            email: "wifpa_2006@yahoo.co.in | www.wifpa.net",
            mobileNo: "022 26732960 "
        },

    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <HomeHeader />
            <section className="container" style={{ marginTop: "125px" }}>
                <Card>
                    <Card.Header>
                        <h2 className="text-center" >Film Fastivals</h2>
                    </Card.Header>
                    <table className="table table-striped mt-3">
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Name</th>
                                <th>Contact Details</th>
                                <th>Email</th>
                                <th>Mobile Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filmFestivals.map((association, index) => (
                                <tr key={index}>
                                    <td>{association.sn}</td>
                                    <td>{association.name}</td>
                                    <td>{association.contactDetails}</td>
                                    <td>{association.email}</td>
                                    <td>{association.mobileNo}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card>
            </section>
            <HomeFooter />
        </Fragment >
    );
};

export default FilmFestivals;
