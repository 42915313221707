import React, { Fragment, } from "react";
import { useLocation } from 'react-router-dom';
import { getMemberOnDepartment } from "../../../action/Admin.action";
import { Container, Card, Button, Row, Col, Form } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";
import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import HomeFooter from "../Footer/HomeFooter";

const Job = ({ selected }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const status = JSON.parse(localStorage.getItem("status"));

    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="job-section">
                <Container className="search-bar" >
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '20px' }}>
                        <Col md={8} >
                            <Form style={{ paddingTop: '25px' }}>
                                <Row className="g-2">

                                    <Col xs={12} md={4}>
                                        <Form.Group>

                                            <Form.Select
                                                name="subCate"

                                            >
                                                <option value="">Select</option>

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid .
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="d-flex search-baree" >
                                            <Form.Control

                                                type="text"
                                                name="name"
                                                placeholder="🔍 Search "
                                                className="custom-inputee custom-placeholder"

                                                autocomplete="off"
                                            />
                                            <Button className="search-button" type="submit">
                                                <img src={searchIcon} alt="Search" />
                                            </Button>
                                        </div>

                                        {/* {suggestions.length > 0 && ( */}
                                        {/* <ul className="suggestions-list"> */}
                                        {/* {suggestions.map((category) => ( */}
                                        {/* <li


                                                className="suggestion-item"
                                            >
                                                <span className="suggestion-icon">🔍</span>
                                                <div className="suggestion-details">
                                                    <span className="suggestion-name"></span>
                                                    <span className="suggestion-category">Category</span>
                                                </div>
                                            </li> */}
                                        {/* ))} */}
                                        {/* </ul> */}
                                        {/* )} */}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        {/* <Col md={4} className="text-end" style={{ paddingTop: '25px' }} >
                            <button className="ms-2 custom-button" style={{ width: '105px', padding: '5px', borderRadius: '8px' }} onClick={() => navigate(-1)}>Back</button>
                        </Col> */}
                    </Row>
                </Container>
                <Container>
                    <div className="card-grid mt-3">
                        {/* {categoryData.map((item, index) => ( */}
                        <Card className="card-custom hand" >

                            <div className="job-rating-card">
                                <div className="rating-badge">

                                </div>
                            </div>
                            <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                <div className="card-content flex-grow-1">
                                    <Card.Title></Card.Title>
                                    <Card.Subtitle className="mb-2 "></Card.Subtitle>
                                    <Card.Text><i class="fas fa-map-marker-alt"></i></Card.Text>
                                </div>
                                <div className="button-group mt-auto">
                                    <button className=" btn me-2" style={{ backgroundColor: '#AA1163', color: '#FFFFFF', fontSize: '12px' }}>Apply Now</button>
                                    {/* <Button variant="success">Call Now</Button> */}
                                </div>
                            </Card.Body>
                        </Card>
                        {/* ))} */}
                    </div>
                </Container>
            </section>
            <HomeFooter />
        </Fragment >
    );
};

export default Job;
