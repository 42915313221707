import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import React from 'react';
import whatsapp from '../assets/images/OldProImg/share/whatsapp1.png';
import email from '../assets/images/OldProImg/share/email.jpg';
import insta from '../assets/images/OldProImg/share/instagram-logo.jpg';
import twitter from '../assets/images/OldProImg/share/BirdIcon.png';

export const ShareButton = (props) => {
  const handleWhatsAppShare = (props, url, namess) => {
    console.log(props)
    let name = 'sheet';
    let dataList = props.data;

    // Extract only the desired fields from each object in the array
    const filteredData = dataList.map(({ name, departmentName, designationtName, email, mobile }) => ({
      Name: name,
      Department: departmentName || '',
      Designation: designationtName || '',
      Mobile: mobile,
      Email: email
    }));

    const pdf = new jsPDF();
    const headers = Object.keys(filteredData[0]);

    pdf.autoTable({
      head: [headers],
      body: filteredData.map(row => headers.map(header => row[header])),
      startY: 20,
      theme: 'striped',
    });

    const timestamp = new Date().toISOString();
    const pdfName = `${name}${timestamp}.pdf`;

    if (namess === "email") {
      const recipientEmail = filteredData.map((v) => v.Email);
      const subject = 'Updated Crew Member List';
      const body = 'Updated Crew Sheet';

      saveAndEmail(pdf, pdfName, recipientEmail, subject, body);

    } else if (namess === "insta" || namess === "twitter") {
      saveAndOpen(pdf, pdfName, props.url);

    } else {
      const msg = "Select File Manually---";
      const whatsappWebUrl = `${url}${msg}`;
      saveAndOpen(pdf, pdfName);
      window.open(whatsappWebUrl, "_blank");
    }
  };

  const saveAndEmail = (pdf, pdfName, recipientEmail, subject, body) => {
    const pdfBlob = pdf.output('blob');
    saveAs(pdfBlob, pdfName);
    window.location.href = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${pdfName}`;
  };

  const saveAndOpen = (pdf, pdfName, url) => {
    const pdfBlob = pdf.output('blob');
    saveAs(pdfBlob, pdfName);
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <img onClick={() => handleWhatsAppShare(props, props.url, props.src)} src={props.src === "whatsapp" ? whatsapp : (props.src === "email") ? email : (props.src === "insta") ? insta : (props.src === "twitter" ? twitter : '')} height="30px" width="30px" className='hand' />
  );
};
