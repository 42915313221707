import { createSlice } from "@reduxjs/toolkit";

const pd = localStorage.getItem("project")
  ? JSON.parse(localStorage.getItem("project"))
  : {};

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    homeData: [],
    successData: {},
    adminPathName: "",
    alert: {
      open: false,
      vertical: "top",
      horizontal: "center",
      severity: "success",
      msg: "Loading...",
      type: "",
    },
    loader: {
      open: false,
      vertical: "bottom",
      horizontal: "center",
      severity: "success",
      msg: "Loading...",
    },
    oneLinerAdd: false,
    projectDetails: pd,
    masters: {},
    refresh: false,
  },
  reducers: {
    setSuccessData: (state, action) => {
      state.successData = action.payload;
    },
    setadminPathName: (state, action) => {
      state.adminPathName = action.payload;
    },
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setProjectDetails: (state, action) => {
      state.projectDetails = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setOneLinerAdd: (state, action) => {
      state.oneLinerAdd = action.payload;
    },
    setMasterData: (state, action) => {
      state.masters = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = action.payload;
    },
  },
});

export const {
  setSuccessData,
  setadminPathName,
  setAlert,
  setLoader,
  setOneLinerAdd,
  setProjectDetails,
  setMasterData,
  setRefresh,
} = homeSlice.actions;

export default homeSlice.reducer;
