import ComingSimple from '../Components/Pages/ComingSoon/ComingSimple';
import CreatePwd from '../Components/Pages/Auth/CreatePwd';
import ForgetPwd from '../Components/Pages/Auth/ForgetPwd';
import LoginOne from '../Components/Pages/Auth/LoginOne';
import LoginSweetalert from '../Components/Pages/Auth/LoginSweetalert';
import LoginTooltip from '../Components/Pages/Auth/LoginTooltip';
import LoginTwo from '../Components/Pages/Auth/LoginTwo';
import LoginValidation from '../Components/Pages/Auth/LoginValidation';
import Maintenance from '../Components/Pages/Auth/Maintenance';
import RegisterBgImg from '../Components/Pages/Auth/RegisterBgImg';
import RegisterSimple from '../Components/Pages/Auth/RegisterSimple';
import RegisterVideo from '../Components/Pages/Auth/RegisterVideo';
import UnlockUser from '../Components/Pages/Auth/UnlockUser';
import ErrorPage1 from '../Components/Pages/ErrorPages/ErrorPage400';
import ErrorPage2 from '../Components/Pages/ErrorPages/ErrorPage401';
import ErrorPage3 from '../Components/Pages/ErrorPages/ErrorPage403';
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404';
import Logins from '../Auth/Signin';
import LoginForm from '../Components/Pages/Auth/LoginForm';
import ComingBgImg from '../Components/Pages/ComingSoon/ComingBgImg';
import ComingBgVideo from '../Components/Pages/ComingSoon/ComingBgVideo';
import Error500 from '../Components/Pages/ErrorPages/ErrorPage500';
import Error503 from '../Components/Pages/ErrorPages/ErrorPage503';
import Sample from '../Components/Pages/Sample';
import ForgetPassword from '../admin/pages/ForgetPassword';
import HomePage from "../FrontPage/Componets/Main/Main";
import CategoryProfileListing from "../FrontPage/Componets/Pages/CategoryProfileListing";
import CategoryProfileDetails from "../FrontPage/Componets/Pages/CategoryProfileDetails";
import CategoryList from "../FrontPage/Componets/Pages/CategoryList";
import TermsAndConditions from "../FrontPage/Componets/Pages/TermsAndConditions";
import PrivacyPolicy from "../FrontPage/Componets/Pages/PrivacyPolicy";
import DisclaimerOfWarranties from "../FrontPage/Componets/Pages/DisclaimerOfWarranties";
import FilmAssociations from "../FrontPage/Componets/Pages/FilmAssociations";
import FilmFestivals from "../FrontPage/Componets/Pages/FilmFestivals";
import Job from "../FrontPage/Componets/Pages/Job";

import Slider from '../FrontPage/Componets/Pages/Slider';
import LiteHome from '../FrontPage/Componets/Pages/IndexLite';
import OtpCheck from '../FrontPage/Componets/Pages/OtpCheck';
import Verified from '../FrontPage/Componets/Pages/Verified';
import YourComponent from '../FrontPage/Componets/Pages/YourComponent';




// import CategoryPage from '../FrontPage/Componets/Pages/CategoryPage';


import LoginWithOtp from '../FrontPage/Componets/Pages/LoginWithOtp';
export const authRoutes = [

  //New film crew lite

  { path: `${process.env.PUBLIC_URL}`, Component: <HomePage /> },
  { path: `${process.env.PUBLIC_URL}/`, Component: <HomePage /> },
  { path: `${process.env.PUBLIC_URL}/categoryProfileListing`, Component: <CategoryProfileListing /> },
  { path: `${process.env.PUBLIC_URL}/categoryProfileDetails`, Component: <CategoryProfileDetails /> },
  { path: `${process.env.PUBLIC_URL}/category-list`, Component: <CategoryList /> },
  { path: `${process.env.PUBLIC_URL}/termsAndConditions`, Component: <TermsAndConditions /> },
  { path: `${process.env.PUBLIC_URL}/film-associations`, Component: <FilmAssociations /> },
  { path: `${process.env.PUBLIC_URL}/film-festivals`, Component: <FilmFestivals /> },
  { path: `${process.env.PUBLIC_URL}/job`, Component: <Job /> },
  { path: `${process.env.PUBLIC_URL}/privacyPolicy`, Component: <PrivacyPolicy /> },
  { path: `${process.env.PUBLIC_URL}/disclaimerOfWarranties`, Component: <DisclaimerOfWarranties /> },
  { path: `${process.env.PUBLIC_URL}/YourComponent`, Component: <YourComponent /> },

  // { path: `${process.env.PUBLIC_URL}/slider`, Component: <Slider /> },
  // { path: `${process.env.PUBLIC_URL}/litehome`, Component: <LiteHome /> },
  // { path: `${process.env.PUBLIC_URL}/loginLite`, Component: <LoginLite /> },
  // { path: `${process.env.PUBLIC_URL}/loginLite`, Component: <LoginLite /> },
  { path: `${process.env.PUBLIC_URL}/otpCheck`, Component: <OtpCheck /> },

  //New
  { path: `${process.env.PUBLIC_URL}/loginWithOtp`, Component: <LoginWithOtp /> },

  { path: `${process.env.PUBLIC_URL}/admin/login`, Component: <Logins /> },
  { path: `${process.env.PUBLIC_URL}/forgot`, Component: <ForgetPassword /> },
  { path: `${process.env.PUBLIC_URL}/project`, Component: <Logins /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/login-simple`, Component: <LoginForm /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/login-img`, Component: <LoginOne /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/login-bg-img`, Component: <LoginTwo /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/login-validation`, Component: <LoginValidation /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/login-tooltip`, Component: <LoginTooltip /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/login-sweetalert`, Component: <LoginSweetalert /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/register-simple`, Component: <RegisterSimple /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/register-bg-img`, Component: <RegisterBgImg /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/register-video`, Component: <RegisterVideo /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/unlock-user`, Component: <UnlockUser /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/forget-pwd`, Component: <ForgetPwd /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/create-pwd`, Component: <CreatePwd /> },
  { path: `${process.env.PUBLIC_URL}/pages/authentication/maintenance`, Component: <Maintenance /> },

  //Coming-soon
  { path: `${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon`, Component: <ComingSimple /> },
  { path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-img`, Component: <ComingBgImg /> },
  { path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-video`, Component: <ComingBgVideo /> },

  //Error
  { path: `${process.env.PUBLIC_URL}/pages/errors/error400`, Component: <ErrorPage1 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error401`, Component: <ErrorPage2 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error403`, Component: <ErrorPage3 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error404`, Component: <ErrorPage4 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error500`, Component: <Error500 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error503`, Component: <Error503 /> },
];
