import React, { useState, memo, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Table, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import location1 from '../../assets/images/OldProImg/PictureVehicle/SM11.jpg';
import { setAlert } from "../../slices/home";
import { addEditVehicleAction, getCategoryVendorDataAction, getVehicleDataAction, getVendorDataAction, getCrewListAction } from "../../action/Admin.action";
import { imgPath } from "../common/function";
import line1 from '../../assets/images/OldProImg/line1.png';
import Select from 'react-select';


const LocalTravelDetails = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [category, setCategory] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState("");
    const [Step, setStep] = useState(1);
    const [vehicleList, setVehicleList] = useState({})
    const [vendoList, setVendorList] = useState({})
    const [ldata, setLdata] = useState(location.state)
    const navigate = useNavigate();
    const [artistList, setArtistList] = useState([]);
    const [vfwList, setVfwList] = useState([]);
    const [newart, setNewart] = useState([]);




    // const getList1 = async () => {
    //     let resp = await projectDetailAction();
    //     if (resp.code === 200) {
    //         if (resp.data.artiest && resp.data.artiest.length) {
    //             let artiests = resp.data.artiest;
    //             let artiestsList = [];
    //             for (let i in artiests) {
    //                 let row = artiests[i];
    //                 artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
    //             }
    //             setArtistList(artiestsList);
    //         }
    //     }
    // }
    const getList1 = async () => {
        let resp = await getCrewListAction();
        if (resp.code === 200) {
            let artiestsList = [];
            {resp && (resp.data.length>0 && (resp.data).map((v,i)=>{
                if(v.department=="000000000000000000000001"){
                    artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
                }
            }))}
            setArtistList(artiestsList);
        }

    }

    const getList2 = async () => {
        handleShow();
        setValidated(false);
        let newArray = artistList.filter(item => ldata.vehicleForWhom.includes(item.value));
        setNewart(newArray)
    }


    const getLocationList = async () => {
        const resp = await getVehicleDataAction();
        if (resp.code === 200) {
            setVehicleList(resp.data);
        }
        let newArray = artistList.filter(item => ldata.vehicleForWhom.includes(item.value));
        setVfwList(newArray)
    }

    const getVendorList = async () => {
        const resp = await getVendorDataAction();
        if (resp.code === 200) {
            setVendorList(resp.data);
        }
    }

    const getCatList = async () => {
        const resp = await getCategoryVendorDataAction();
        if (resp.code === 200) {
            setCategory(resp.data);
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let postData = new FormData();
            for (let key in formData) {
                if (key == 'vehicleForWhom') {

                } else {
                    postData.append(key, formData[key]);
                }
            }

            let artistNoList = [];
            for (let j in formData.vehicleForWhom) {

                let a = formData.vehicleForWhom[j];
                artistNoList.push(a.value);
            }
            postData.append('vehicleForWhom', JSON.stringify(artistNoList));

            let resp = await addEditVehicleAction(postData);
            setDisabled(false);

            setValidated(true);
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: "Updated Successfully", type: '' }));
                handleClose();
                setLdata(resp.data);
                let newArray = artistList.filter(item => resp.data.vehicleForWhom.includes(item.value));
                setVfwList(newArray)
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            setDisabled(false);
            return false;
        }
    };


    const handleChange = (name, event, extra = "") => {
        let from = { ...formData };
        if (extra === 'multi') {
            from[name] = event;
        } else if (name === 'vehicleForWhom') {
            let arr = [];

            if (event.target.checked) {
                arr.push(event.target.value);
            } else {
                const index = arr.indexOf(event.target.value);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }
            from[name] = arr;
        } else {
            from[name] = event.target.value;
        }
        setFormData({ ...formData, ...from });

    };


    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {
            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";

        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    useEffect(() => {
        getLocationList();
        setFormData({ name: ldata.name, vehicleNumber: ldata.vehicleNumber, driverName: ldata.driverName, driverNumber: ldata.driverNumber, typeOfVehicle: ldata.typeOfVehicle, vehicleForWhom: newart, insuranceStatus: ldata.insuranceStatus, vendorName: ldata.vendorName, vendorId: ldata.vendorId, location: ldata.location, price: ldata.price, status: ldata.status, image: ldata.image, _id: ldata._id });
    }, [newart])


    useEffect(() => {
        getLocationList();
        getCatList();
        getList1();
        getVendorList();
        setFormData({
            name: ldata.name, vehicleNumber: ldata.vehicleNumber, driverName: ldata.driverName, driverNumber: ldata.driverNumber, typeOfVehicle: ldata.typeOfVehicle, vehicleForWhom: newart, insuranceStatus: ldata.insuranceStatus, vendorName: ldata.vendorName, vendorId: ldata.vendorId, location: ldata.location, price: ldata.price, status: ldata.status, image: ldata.image, _id: ldata._id
        });
    }, [])


    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy">
                        <Row className="pt-4 mb-3 m-0 mb-1">
                            <Col className="col-2 col-lg-7 col-sm-5 col-md-7 px-0">
                                <h6 className="smsz">Vehicle Information</h6>
                            </Col>
                            {/* <Col className="text-end  col col-lg-2 col-md-2 col-sm-2">
                        <Button className="btnClr" variant="primary" onClick={() => { navigate('/local-travel') }}>
                            Back
                        </Button>
                    </Col> */}
                            <Col className="text-end col-10 col-lg-5 col-md-5 col-sm-4 col px-0" onClick={() => setStep(1)}>
                                <Button className="btnClr btnClre me-1" variant="primary" onClick={() => { navigate(`${process.env.PUBLIC_URL}/local-travel`) }}>
                                    Back
                                </Button>
                                <Button className="btnClr btnClre" variant="primary" onClick={() => { getList2(); }}>
                                    Edit Details
                                </Button>
                            </Col>
                        </Row>
                        <hr />
                        <Row >
                            {/* <Col lg={12} className="mb-1">
                        <h5>Vehicle Information</h5>
                    </Col> */}
                            <Col lg={8}>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                        <Card.Text className="mt-3">

                                            <Table >
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="tblPr">Vehicle Name</td>
                                                        <td className="tblP">{ldata.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Vehicle Number</td>
                                                        <td className="tblP">{ldata.vehicleNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Driver Name</td>
                                                        <td className="tblP">{ldata.driverName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Driver Number</td>
                                                        <td className="tblP"> {ldata.driverNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Type of Vehicle</td>
                                                        <td className="tblP">{ldata.typeOfVehicle}</td>
                                                    </tr>
                                                    {/* <tr>
                                                <td className="tblP">Vehicle for whom</td>
                                                <td className="tblP">{
                                                    vfwList && vfwList.length > 0 && vfwList.map((v, i) => {
                                                        let nm = [v.label, " ,"]
                                                        return (nm)
                                                    })
                                                }</td>
                                            </tr> */}
                                                    <tr>
                                                        <td className="tblPr">Insurance Status</td>
                                                        <td className="tblP">{ldata.insuranceStatus}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Vendor Name</td>
                                                        <td className="tblP">{ldata && ldata.status ? "Active" : "Inactive"}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Vendor Id</td>
                                                        <td className="tblP">{vendoList && vendoList.length > 0 &&
                                                            vendoList.map((item, index) => {
                                                                if (item._id == ldata.vendorId) {
                                                                    return item.name
                                                                }
                                                            })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Location</td>
                                                        <td className="tblP">{ldata.location}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr">Price</td>
                                                        <td className="tblP">{ldata.price}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tblPr"> Status</td>
                                                        <td className="tblP"> {ldata.status == '0' ? 'InActive' : 'Active'}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>


                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card className="crdShadZero hand" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                        <Card.Text className="mt-3">Vehicle Profile Image</Card.Text>
                                        <img className="ltdImg" src={ldata && ldata.image == "" ? location1 : imgPath(ldata.image)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row >
                            <Modal show={show} onHide={handleClose} animation={false}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                noValidate validated={validated} onSubmit={e => handleSubmit(e)}
                                centered>
                                    <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                                    <Modal.Title className='crdHd1'>Vehicle Details</Modal.Title>
                                </Modal.Header>
                                {/* <Row>
                                    <Col lg={11}><Modal.Title className="size1 m-2 mt-3 mb-0">Vehicle Details</Modal.Title></Col>
                                    <Col lg={1}>
                                        <span className='hand cancel' onClick={() => { handleClose() }}>x</span>
                                    </Col>
                                </Row> */}
                                <Modal.Body className="pt-1">
                                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                        {
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <img src={line1} alt="" className="img-fluid12" />
                                                    </Col>
                                                </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="name">
                                                            <Row><Col><Form.Label>Vehicle Name</Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of Vehicle" onChange={e => handleChange('name', e)} value={formData.name ? formData.name : ""} minLength={1} maxLength={25} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vehicle name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="vehicleNumber">
                                                            <Form.Label>Vehicle Number<span className='star'>*</span></Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Number of Vehicle" onChange={e => handleChange('vehicleNumber', e)} value={formData.vehicleNumber ? formData.vehicleNumber : ""} minLength={1} maxLength={12} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a Vehicle Number.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="driverName">
                                                            <Row><Col><Form.Label>Driver Name<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Driver" onChange={e => handleChange('driverName', e)} value={formData.driverName ? formData.driverName : ""} minLength={1} maxLength={32} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Driver Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="driverNumber">
                                                            <Row><Col><Form.Label>Driver Number<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" placeholder="Number of Driver" onChange={e => handleChange('driverNumber', e)} value={formData.driverNumber ? formData.driverNumber : ""}
                                                                minLength={10}
                                                                maxLength={12}
                                                                pattern='^[6-9][0-9]{9}$'
                                                                required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Driver Number.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="typeOfVehicle">
                                                            <Row><Col><Form.Label>Type of Vehicle<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Number of Driver" onChange={e => handleChange('typeOfVehicle', e)} value={formData.typeOfVehicle ? formData.typeOfVehicle : ""} maxLength={10} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Type Of vehicle.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="vehicleForWhom" className="txt">
                                                            <Form.Label >For Whom<span className='star'>*</span></Form.Label>
                                                            <Select className="brdr1 mselect frmcnt" aria-label="Default select example"
                                                                value={formData.vehicleForWhom ? formData.vehicleForWhom : []}
                                                                placeholder="Select"
                                                                isMulti
                                                                required
                                                                options={artistList}
                                                                // className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                isValid={formData.vehicleForWhom && formData.vehicleForWhom.length > 0}
                                                                onChange={e => handleChange('vehicleForWhom', e, 'multi')}>
                                                                {
                                                                    artistList && artistList.length > 0 && artistList.map((val, ind) => {
                                                                        return <option value={(val.value)} key={ind}  >{val.label}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            {validated === true && ((!formData.artiestId) || formData.artiestId && formData.artiestId.length === 0) &&
                                                                <p className="err">This field is required</p>}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="insuranceStatus">
                                                            <Form.Label >Insurance Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('insuranceStatus', e)} value={formData.insuranceStatus ? formData.insuranceStatus : ""} required>
                                                                <option value="" >Insurance Status</option>
                                                                <option value="Yes" >Yes</option>
                                                                <option value="No" >No</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Insurance Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="vendorName">
                                                            <Row><Col><Form.Label>Vendor Name<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Vendor" onChange={e => handleChange('vendorName', e)} value={formData.vendorName ? formData.vendorName : ""} minLength={1} maxLength={20} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="vendorId">
                                                            <Form.Label >Vendor Id<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required>
                                                                {vendoList && vendoList.length > 0 && vendoList.map((val, ind) => {
                                                                    return <option value={val._id} >{val.name}</option>
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Id.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="location">
                                                            <Row><Col><Form.Label>Location<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" placeholder="Name of Location" onChange={e => handleChange('location', e)} value={formData.location ? formData.location : ""} minLength={1} maxLength={20} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Location.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="price">
                                                            <Row><Col><Form.Label>Price<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' placeholder="" onChange={e => handleChange('price', e)} value={formData.price ? formData.price : ""} minLength={1} maxLength={10} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a Price.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="status">
                                                            <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                                <option value="" >Select Status</option>
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Inactive</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="image">
                                                            <Form.Label>Image</Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="file" ize="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                                        </Form.Group>
                                                        <Form.Control.Feedback type="invalid">
                                                            It only accept png, jpg, jpeg files.
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col lg={4} className='imgcss'>
                                                        <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />
                                                    </Col>
                                                </Row>
                                                <Row className=' mt-3 mx-2'>
                                                    <Col className='col-6 col-lg-8 col-sm-6'>
                                                        <Button className="btnk cldrBtn" onClick={() => { handleClose(); getList1(); }}>
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end' >
                                                        <Button type='submit' className="btnk cldrBtn"
                                                            disabled={isDisabled}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default memo(LocalTravelDetails);