import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, memo } from 'react';
import { addSchedule, getScheduleListAction } from "../../action/Admin.action";
import moment from 'moment';
import { setAlert } from '../../slices/home.js';
import { Link } from "react-router-dom";
import { checkName } from "../common/function.js";


const Schedule = (props) => {

    const dispatch = useDispatch();
    const masters = useSelector((state) => state.home.masters);

    const [formData, setFormData] = useState({});
    const [listData, setListData] = useState({});
    const [block, setBlock] = useState(1);
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const getList = async (action = '') => {
        const resp = await getScheduleListAction();
        setListData(resp.data)
    }

    const handleSubmit = async (event) => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let msg = "You have successfully created member.";

            dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
            let resp = await addSchedule(formData);
            setDisabled(false);

            if (resp.code === 200) {
                if (props.getList()) {

                }
                dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                setFormData({});
                setValidated(false)
            } else {
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }

            return false;
        }
    };

    const handleChange = (post) => {

        if (post.name == 'name') {
            const foundObjects = listData && listData.length > 0 && listData.map((v, i) => {
                if(v.name == post.event.target.value){
                    return (
                        setValidated(true)
                    )
                }
            })
        }
        
        let name = post.name;
        let event = post.event;
        let from = { ...formData };

        from[name] = event.target.value;
        //start Date And Date Validation
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);

        const endDateInput = document.getElementById("endDate");
        if (endDateInput) {
            endDateInput.min = selectedStartDate;
            if (selectedStartDate > endDate) {
                setEndDate("");
            }
        }


        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
        const startDateInput = document.getElementById("startDate");
        if (startDateInput) {
            startDateInput.max = selectedEndDate;
            if (startDate && selectedEndDate < startDate) {
                setStartDate("");
            }
        }

        setFormData({ ...formData, ...from });
    }

    useEffect(() => {
        getList();
        if (props.editData && props.editData._id) {
            let edit = props.editData;
            let startD = (moment(edit.startDate).format("yyyy-MM-DD"))
            let endD = (moment(edit.endDate).format("yyyy-MM-DD"))
            setFormData({ _id: edit._id, name: edit.name, startDate: startD, endDate: endD, assistantDirector1: edit.assistantDirector1, assistantDirector2: edit.assistantDirector2 });
        }
        if(props.refresh===true){
            setFormData({})
        }

    }, [props.editData])
    return (
        <>
            <div>
                <div className="col-12 w-6" style={{ margin: "auto" }}>
                    {/* <div className="modal-header teamBox">
                    </div> */}
                    {/* <h5 className="paraCenter mb-3 mt-3">{localStorage.getItem("projectName")}</h5> */}

                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Control
                                type="text"
                                placeholder="Schedule Name"
                                pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                                autoFocus
                                className="frmcntadd "
                                value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} minLength={2} maxLength={32} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="startDate">
                            <Form.Control type="date" placeholder="Start Date- "
                                min={new Date().toISOString().split('T')[0]}
                                max={(new Date().getFullYear() + 10) + "-12-31"}
                                className="frmcntadd "
                                value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange({ name: 'startDate', event: e })} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Start Date.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="endDate">
                            <Form.Control type="date" placeholder="End Date- "
                                min={new Date().toISOString().split('T')[0]}
                                max={(new Date().getFullYear() + 10) + "-12-31"}
                                className="frmcntadd "
                                value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange({ name: 'endDate', event: e })} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid End Date.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="assistantDirector1">
                            <Form.Control
                                type="text"
                                placeholder="Assistant Director 1"
                                minLength={2}
                                maxLength={32}
                                className="frmcntadd "
                                pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                                value={formData.assistantDirector1 ? formData.assistantDirector1 : ""} onChange={e => handleChange({ name: 'assistantDirector1', event: e })} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Assistant Director 1 Name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="assistantDirector2">
                            <Form.Control
                                type="text"
                                placeholder="Assistant Director 2"
                                minLength={2}
                                maxLength={32}
                                className="frmcntadd "
                                pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                                value={formData.assistantDirector2 ? formData.assistantDirector2 : ""} onChange={e => handleChange({ name: 'assistantDirector2', event: e })} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Assistant Director 2 Name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Row className="mt-4 mb-4 g-2">
                            <Col lg={6}>
                                <Button type="submit" style={{ padding: ".5rem 5rem .5rem 5rem" }} className="lgbt2 lgbtTm bnt1 btnn" variant="dark">
                                    Save
                                </Button>
                            </Col>
                            {/* <Col lg={6} className="text-end  mt-3">
                                <Link className="lgbt12 nextBtn rounded-1" to="#" onClick={e => updateTab()}>
                                    Next
                                </Link></Col> */}
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Schedule;