import React, { useState, useEffect, Fragment } from 'react';
import { Card, Col, Container, form, Row, Button, Form, Modal } from "react-bootstrap";

const Dashboard = () => {

    return (
        <main id="main" className="main">
            <section className="section">
            </section>
        </main>
    );
}

export default Dashboard; 