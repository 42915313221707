import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './UserRouters';
import AppLayout from '../Layout/UserLayout';

const UserLayoutRoutes = () => {

    return (
        <>
            <Routes>
                {routes.map(({ path, Component }, i) => (
                    <Fragment key={i}>
                        <Route element={<AppLayout />} key={i}>
                            <Route path={path} element={Component} />
                        </Route>
                    </Fragment>
                ))}
            </Routes>
        </>
    );
};

export default UserLayoutRoutes;