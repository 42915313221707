import React from 'react';
import { PieChart, Pie } from 'recharts';

const RADIAN = Math.PI / 180;
const data = [
  { name: 'Part 1', value: 10},
  { name: 'Part 2', value: 10},
  { name: 'Part 3', value: 10},
  { name: 'Part 4', value: 10},
  { name: 'Part 5', value: 10},
  { name: 'Part 6', value: 10},
  { name: 'Part 7', value: 10},
  { name: 'Part 8', value: 10},
  { name: 'Part 9', value: 10},
  { name: 'Part 10', value: 10},
];
const cx = 45;
const cy = 80;
const iR = 60;
const oR = 75;

const needle = (percentage, cx, cy, iR, oR) => {
  const ang = 180.0 * (1 - percentage / 100);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle key="circle" cx={x0} cy={y0} r={r} fill="#2fa1c6cc" stroke="none" />,
    <path
      key="path"
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="none"
      fill="#2fa1c6cc"
    />,
  ];
};

const HalfGaugeChart = (props) => {
  return (
    <PieChart width={100} height={100} className={props.className}>
      <Pie
        dataKey="value"
        startAngle={0}
        endAngle={180}
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={iR}
        outerRadius={oR}
        fill={data.color}
        stroke="none"
        className='colrChnge'
        viewBox='100 120 100 200'
      />
      {needle(75, cx, cy, iR-30, oR-30)}
    </PieChart>
  );
};

export default HalfGaugeChart;




// export default HalfGaugeChart
