import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import React, { useState } from "react";
import { addCalendar } from "../../action/Admin.action";
import { setAlert } from '../../slices/home.js';
import { Link } from "react-router-dom";
import { checkName} from "../common/function.js";


const Calendar = ({ setActiveTab }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [isDisabled, setDisabled] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateError, setDateError] = useState('');


    const handleSubmit = async(event) => {
        const form = event.currentTarget;
  
        if (form.checkValidity() === false) {
          
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            
        }else{
            event.preventDefault();
            event.stopPropagation();
            //setValidated(false);
            setDisabled(true);

            let msg = "You have successfully created Calendar.";
            
            dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' })); 
            setDisabled(false);
           let resp = await addCalendar(formData);

                if (resp.code === 200) {
                    dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
                    setFormData({})
                } else {
                    
                    dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
                }
            return false;
        }      
    };

    const handleSubmit22 = async (event) => {
        const form = event.currentTarget;
        var projectId = JSON.parse(localStorage.getItem("projectId"));

        if (new Date(endDate) < new Date(startDate)) {
            setDateError('End date must be after start date');
            return;
        }


        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            var pair = { projectId: projectId, startDate:startDate,endDate:endDate}
            var obj = { ...formData, ...pair };
            if ((formErrors.name == "") && (formErrors.executiveProducer == "") && (formErrors.lineProducer == "")){
                let resp = await addCalendar(obj);
                if (resp.code === 200) {
                    dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
                    setFormData({});
                    setStartDate({});
                    setEndDate({});
                } else {
    
                    setDisabled(false);
                    dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
    
                }
            }else{
                dispatch(setAlert({ open: true, severity: "success", msg: "fill all fields", type: '' }));
            }
            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });        

    }

    const updateTab = () => {

        setActiveTab(5);

    }
    function handleStartDateChange(event) {
        setStartDate(event.target.value);
        setDateError('');
    }

    function handleEndDateChange(event) {
        setEndDate(event.target.value);
        setDateError('');
    }    


    return (
        <>        
            <div>
                <div className="col-11 w-6" style={{ margin: "auto" }}>
                    <div className="modal-header teamBox">
                    </div>
                    <h5 className="paraCenter mb-3 mt-3">{localStorage.getItem("projectName")}</h5>

                    <Form noValidate validated={validated} onSubmit={e=>handleSubmit(e)}>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                autoFocus
                                value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid name.
                                </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="startDate">
                            <Form.Control type="date" placeholder="Enter start date" value={formData.startDate?formData.startDate:""} onChange={e => handleChange('startDate', e)} required/>
                            <Form.Control.Feedback type="invalid">
                                    Please provide a valid start date.
                                </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="endDate">
                            <Form.Control type="date" placeholder="Enter end date" value={formData.endDate?formData.endDate:""} onChange={e => handleChange('endDate', e)} required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid end date.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="executiveProducer">
                            <Form.Control
                                type="text"
                                placeholder="Executive Producer"
                                value={formData.executiveProducer ? formData.executiveProducer : ""} onChange={e => handleChange('executiveProducer', e)} required/>
                                 <Form.Control.Feedback type="invalid">
                                    Please provide a valid Executive Producer.
                                </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="lineProducer">
                            <Form.Control
                                type="text"
                                placeholder="Line Producer"
                                value={formData.lineProducer ? formData.lineProducer : ""} onChange={e => handleChange('lineProducer', e)} required/>
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Line Producer.
                                </Form.Control.Feedback>
                        </Form.Group>

                        <Row className="mt-4 mb-4 g-2">
                            <Col lg={6}>
                                <Button type="submit" style={{ padding: ".5rem 5rem .5rem 5rem" }} disabled={isDisabled} className="lgbt" variant="dark" >
                                    Add More
                                </Button>
                            </Col>
                            <Col lg={6} className="text-end  mt-3">
                            <Link  className="lgbt12 nextBtn rounded-1" to="#" onClick={e => updateTab()}>
                                    Next
                                </Link></Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Calendar;