import React, { useState, useEffect } from 'react';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { CHECKALL, DeliveryComplete, DeliveryProcessing, Notification, OrderComplete, TicketsGenerated } from '../../../Constant';
import {getCrewListAction} from '../../../action/Admin.action'
const Notificationbar = () => {
const [notificationDropDown, setNotificationDropDown] = useState(false);
const [list, setList] = useState({});
const [task, setTask] = useState();
const a = localStorage.getItem('login');
const b = ((JSON.parse(a)).email);
 const currentDate = new Date();


  const getList = async()=>{
    let fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
    let resp = await getCrewListAction(fData);
    if(resp.code===200){
      setList(resp.data);
    }
  }

  const decide = () => {
    list && list.length>0 && list.map((val,ind)=>{
      if(val.email===b){
        setTask(((val.roleDocs).length))  
      }
    })}

  useEffect(()=>{
    getList();
  },[])


      useEffect(() => {
        decide()
    }, [list])


  // useEffect(() => {
  //   const differenceInMillis = currentDate - createdAt;
  //   const seconds = Math.floor(differenceInMillis / 1000);
  //   const minutes = Math.floor(seconds / 60);
  //   const hours = Math.floor(minutes / 60);
  //   const days = Math.floor(hours / 24);

  //   setTimeDifference({
  //     days: days,
  //     hours: hours % 24,
  //     minutes: minutes % 60,
  //     seconds: seconds % 60,
  //   });
  // }, [createdAt, currentDate]);

  return (
    <li className='onhover-dropdown'>
      <div className='notification-box' onClick={() =>{ setNotificationDropDown(!notificationDropDown); getList()}}>
        <SvgIcon iconId='notification' />
        <span className='badge rounded-pill badge-secondary'>{task}</span>
      </div>
      <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}>
        <h6 className='f-18 mb-0 dropdown-title crdHd1'>{Notification}</h6>
        {list && list.length>0 && list.map((val,ind)=>{
          if(val.email===b){
            if((val.roleDocs).length>0){
              return <ul>
              {val && (val.roleDocs).length>0 && (val.roleDocs).map((v,i)=>{
                  return <li className='b-l-primary border-4 p-2'>your role is - {v.name}</li>
              })}
                </ul>
            }
          }
        })}
       
      </div>
    </li>
  );
};

export default Notificationbar;
