import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import location1 from '../../assets/images/OldProImg/PictureVehicle/SM11.jpg';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { getVehicleDataAction, addEditVehicleAction, getVendorDataAction, getCrewListAction } from "../../action/Admin.action";
import { useState, memo, useEffect } from 'react';
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import line1 from '../../assets/images/OldProImg/line1.png'
import { imgPath } from '../common/function';
import { setAlert } from "../../slices/home";
import Select from 'react-select';
// import AlertBox from '../component/AlertBox'
// import travel4 from '../../assets/images/OldProImg/travel4.png';

const LocalTravel = () => {
    const [locationList, setLocationList] = useState()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formData, setFormData] = useState({});
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    // const [category, setCategory] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [artistList, setArtistList] = useState([]);
    const [file, setFile] = useState("");
    // const [Step, setStep] = useState(1);
    // const [vehicleList, setVehicleList] = useState({})
    const [vendoList, setVendorList] = useState({})
    // const [ldata, setLdata] = useState(location.state)



    // const getList1 = async () => {
    //     let resp = await projectDetailAction();
    //     if (resp.code === 200) {
    //         if (resp.data.artiest && resp.data.artiest.length) {
    //             let artiests = resp.data.artiest;
    //             let artiestsList = [];
    //             for (let i in artiests) {
    //                 let row = artiests[i];
    //                 artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
    //             }
    //             setArtistList(artiestsList);
    //         }
    //     }

    // }
    const getList1 = async () => {
        let resp = await getCrewListAction();
        if (resp.code === 200) {
            let artiestsList = [];
            {resp && (resp.data.length>0 && (resp.data).map((v,i)=>{
                if(v.department=="000000000000000000000001"){
                    artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
                }
            }))}
            setArtistList(artiestsList);
        }
    }

    const getVendorList = async () => {
        const resp = await getVendorDataAction();
        if (resp.code === 200) {
            setVendorList(resp.data);
        }
    }

    const handleChange = (name, event, extra = "") => {
        let from = { ...formData };
        if (extra === 'multi') {
            from[name] = event;
        } else if (name === 'vehicleForWhom') {
            let arr = [];

            if (event.target.checked) {
                arr.push(event.target.value);
            } else {
                const index = arr.indexOf(event.target.value);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }
            from[name] = arr;
        } else {
            from[name] = event.target.value;
        }
        setFormData({ ...formData, ...from });

    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let postData = new FormData();
            for (let key in formData) {
                if (key == 'vehicleForWhom') {

                } else {
                    postData.append(key, formData[key]);
                }
            }
            let artistNoList = [];
            for (let j in formData.vehicleForWhom) {

                let a = formData.vehicleForWhom[j];
                artistNoList.push(a.value);
            }
            postData.append('vehicleForWhom', JSON.stringify(artistNoList));
            let resp = await addEditVehicleAction(postData);
            setDisabled(false);
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: "SuccessFully Added", type: '' }));
                handleClose();
                setFormData({});
                getList();
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }

            return false;
        }
    };

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {
            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";

        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    const getList = async () => {
        const resp = await getVehicleDataAction();
        if (resp.code === 200) {
            setLocationList(resp.data);
        }
    }
    useEffect(() => {
        getList();
        getList1();
        getVendorList();


    }, [])

    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className='crdBdy'>
                        <div>
                            <Row className="mt-3 pt-3">
                                {/* <i className="fa fa-arrow-left hand" onClick={() => { navigate(`${process.env.PUBLIC_URL}/travel-management`) }}></i> */}
                                <Col className="col-6 col-lg-10 col-sm-6 pt-2"><h6 className='crdHd1'>Local-Travel in City</h6></Col>
                                {/* <Col className="col-6 text-end col-lg-2 col-sm-6 smsz px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={() => { navigate(`${process.env.PUBLIC_URL}/travel-management`) }}>Back</Button></Col> */}
                                <Col className="col-6 text-start col-lg-2 col-sm-6 smsz px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={() => { handleShow(); setValidated(false) }}>+ Add</Button></Col>
                            </Row>
                            <hr />
                            {/* <Row className="mt-4">
                    {locationList && locationList.length > 0 && locationList.map((item, index) => {
                        return <Col md={3}>
                            <Card className="text-center p-0 hand" onClick={() => { navigate('/local-travel-details', { state: item }) }}>

                                <Card.Body className='p-0'>
                                    <Row><Col lg={3}><AlertBox /></Col></Row>
                                    <img src={item.image ? imgPath(item.image) : location1} alt="" className="img-fluid optionImg  img-fluid tmImg" />

                                    <Card.Title className="fw-bold pt-3 pb-0"  style={{ fontSize:'15px' }}>{item.name}</Card.Title>
                                    <p class="d-inline-block text-truncate" style={{ maxWidth: '90%' }}>{item.locationDesc}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    })}
                </Row> */}


                            <Row className="mt-4">
                                {locationList && locationList.length > 0 && locationList.map((item, index) => {
                                    return <Col lg={4} md={6}>
                                        <Card style={{ borderRadius: '20px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/local-travel-details`, { state: item }) }}
                                            className="brdr text-center pt-4 optCrdshad card hand">
                                            <p className="cardtitle m-0" style={{ fontSize: '15px' }}>{item.name}</p>
                                            <Card.Body className="p-0 m-0">
                                                <Card.Text class="d-inline-block text-truncate  mb-0" style={{ maxWidth: '90%' }}>
                                                </Card.Text>
                                            </Card.Body>
                                            <span className="cardListImg">
                                                <img src={item.image ? imgPath(item.image) : location1} alt="" className="img-fluid tmImg" />
                                            </span>
                                        </Card>
                                    </Col>
                                })}
                            </Row>
                        </div>
                        <div >
                            <Modal show={show} onHide={handleClose} animation={false}
                                size="lg"
                                noValidate validated={validated} onSubmit={e => handleSubmit(e)}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                                    <Modal.Title className='crdHd1'>Local Travel</Modal.Title>
                                </Modal.Header>

                                {/* <Row>
                                    <Col lg={11}><Modal.Title className="size1 m-2 mt-3 mb-0">Local Travel</Modal.Title></Col>
                                    <Col lg={1}>
                                        <span className='hand cancel btn-close' onClick={() => { handleClose() }}></span>
                                    </Col>
                                </Row> */}
                                <Modal.Body className='pt-1'>
                                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                        {
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <img src={line1} alt="" className="img-fluid12" />
                                                    </Col>
                                                </Row>

                                                <Row className='mx-2'>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="name">
                                                            <Form.Label>Vehicle Name<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of Vehicle" onChange={e => handleChange('name', e)} value={formData.name ? formData.name : ""} minLength={2} maxLength={32} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid  Vehicle Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="vehicleNumber">
                                                            <Form.Label>Vehicle Number<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Number of Vehicle" onChange={e => handleChange('vehicleNumber', e)} value={formData.vehicleNumber ? formData.vehicleNumber : ""} minLength={1} maxLength={12} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a Vehicle Number.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="driverName">
                                                            <Form.Label>Driver Name<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Driver" onChange={e => handleChange('driverName', e)} value={formData.driverName ? formData.driverName : ""} minLength={2} maxLength={20} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Driver Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='mx-2'>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="driverNumber">
                                                            <Form.Label>Driver Number<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^[6-9][0-9]{9}$' placeholder="Number of Driver" onChange={e => handleChange('driverNumber', e)} value={formData.driverNumber ? formData.driverNumber : ""} minLength={10} maxLength={10} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Driver Number.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="typeOfVehicle">
                                                            <Form.Label>Type Of vehicle<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text"
                                                                pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of Vendor" onChange={e => handleChange('typeOfVehicle', e)} value={formData.typeOfVehicle ? formData.typeOfVehicle : ""} minLength={2} maxLength={20} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Type Of vehicle.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="vehicleForWhom" className="txt">
                                                            <Form.Label >For Whom<span className='star'>*</span></Form.Label>
                                                            <Select className="brdr1 mselect frmcnt" aria-label="Default select example"
                                                                value={formData.vehicleForWhom ? formData.vehicleForWhom : []}
                                                                placeholder="Select"
                                                                isMulti
                                                                options={artistList}
                                                                classNamePrefix="select"
                                                                onChange={e => handleChange('vehicleForWhom', e, 'multi')}>
                                                                <option value="">Select Options</option>
                                                                {
                                                                    artistList && artistList.length > 0 && artistList.map((val, ind) => {
                                                                        return <option value={(val.value)} key={ind}  >{val.label}</option>
                                                                    })
                                                                }
                                                            </Select>
                                                            {validated === true && ((!formData.artiestId) || formData.artiestId && formData.artiestId.length === 0) &&
                                                                <p className="err">This field is required</p>}
                                                            <Form.Control.Feedback type="invalid">
                                                                Please Select a Valid Option.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='m-2'>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="insuranceStatus">
                                                            <Form.Label >Insurance Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('insuranceStatus', e)} value={formData.insuranceStatus ? formData.insuranceStatus : ""} required>
                                                                <option value="" >Insurance Status</option>
                                                                <option value="Yes" >Yes</option>
                                                                <option value="No" >No</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Insurance Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="vendorName">
                                                            <Form.Label>Vendor Name<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Vendor" onChange={e => handleChange('vendorName', e)} value={formData.vendorName ? formData.vendorName : ""} minLength={2} maxLength={20} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="vendorId">
                                                            <Form.Label >Vendor Id<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required>
                                                                <option value="" >Select Vendor</option>
                                                                {vendoList && vendoList.length > 0 && vendoList.map((val, ind) => {
                                                                    return <option value={val._id} >{val.name}</option>
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Id.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='mx-2'>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="location">
                                                            <Form.Label>Location<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="" onChange={e => handleChange('location', e)} value={formData.location ? formData.location : ""} minLength={2} maxLength={32} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Location.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="price">
                                                            <Form.Label>Price<span className='star'>*</span></Form.Label>

                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' placeholder="" onChange={e => handleChange('price', e)} value={formData.price ? formData.price : ""} minLength={1} maxLength={15} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a Price.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="status">
                                                            <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                                <option value="" >Select Status</option>
                                                                <option value="1" >Active</option>
                                                                <option value="0" >Inactive</option>
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Status.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className='mx-2'>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="image">
                                                            <Form.Label>Image</Form.Label>
                                                            <Form.Control className="brdr frmcnt" type="file" Size="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                                        </Form.Group>
                                                        <Form.Control.Feedback type="invalid">
                                                            It only accept png, jpg, jpeg files.
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                    <Col lg={4} className='imgcss'>
                                                        <img src={file} />
                                                    </Col>
                                                </Row>

                                                <Row className='g-2 mt-3 mx-2'>
                                                    <Col className='col-6 col-lg-8 col-sm-6' >
                                                        <Button variant="secondary" className="btnk cldrBtn" onClick={() => { handleClose(); setFormData({}) }}>
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end' >
                                                        <Button variant="secondary" type='submit' className="btnk cldrBtn"
                                                            disabled={isDisabled}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}
export default memo(LocalTravel);



