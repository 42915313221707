import { postRequest, getRequest } from "./helper"

export const adminLoginAction = (data) => {
    return postRequest('login', data).then(res => { return res.data });
}

export const addProject = (data) => {
    return postRequest('addProject', data).then(res => { return res.data });
}

export const projectListingAction = (data) => {
    return postRequest('getProjectList', data).then(res => { return res.data });
}

export const projectDetailAction = (data) => {
    return postRequest('getProjectDetail', data).then(res => { return res.data });
}

export const addTeam = (data) => {
    return postRequest('addTeam', data).then(res => { return res.data });
}

export const addArtiest = (data) => {
    return postRequest('addArtiest', data).then(res => { return res.data });
}

export const addSchedule = (data) => {
    return postRequest('addSchedule', data).then(res => { return res.data });
}

export const addCalendar = (data) => {
    return postRequest('addCalendar', data).then(res => { return res.data });
}

export const addLocation = (data) => {
    return postRequest('addLocation', data).then(res => { return res.data });
}

export const addOneLinerScript = (data) => {
    return postRequest('addOneLinerScript', data).then(res => { return res.data });
}

export const getOneLinerScriptList = (data) => {
    return postRequest('getOneLinerScriptList', data).then(res => { return res.data });
}

export const getArtiestList = (data) => {
    return postRequest('getArtiestList', data).then(res => { return res.data });
}

export const getLocationList = (data) => {
    return postRequest('getLocationList', data).then(res => { return res.data });
}

export const addScheduleGeneratorAction = (data) => {
    return postRequest('addScheduleGenerator', data).then(res => { return res.data });
}

export const getScheduleGenerator = (data) => {
    return postRequest('getScheduleGenerator', data).then(res => { return res.data });
}

export const importScriptAction = (data) => {
    return postRequest('importScript', data).then(res => { return res.data });
}

export const getImportScriptList = (data) => {
    return postRequest('getImportScriptList', data).then(res => { return res.data });
}

export const getTeamList = (data) => {
    return postRequest('getTeamList', data).then(res => { return res.data });
}

export const getScheduleListAction = (data) => {
    return postRequest('getScheduleList', data).then(res => { return res.data });
}

export const addSceneScheduleAction = (data) => {
    return postRequest('addSceneSchedule', data).then(res => { return res.data });
}

export const listSceneScheduleAction = (data) => {
    return postRequest('getSceneSchedule', data).then(res => { return res.data });
}

export const createCompanyAction = (data) => {
    return postRequest('createCompany', data).then(res => { return res.data });
}

export const companyListAction = (data) => {
    return postRequest('companyList', data).then(res => { return res.data });
}

export const editCompanyAction = (data) => {
    return postRequest('editCompany', data).then(res => { return res.data });
}

export const addEditDepartmentAction = (data) => {
    return postRequest('addEditDepartment', data).then(res => { return res.data });
}

export const getDepartmentDataAction = (data) => {
    return postRequest('getDepartmentData', data).then(res => { return res.data });
}
export const designationListAction = (data) => {
    return postRequest('getDesignationData', data).then(res => { return res.data });
}

export const addEditDesignationAction = (data) => {
    return postRequest('addEditDesignation', data).then(res => { return res.data });
}

export const _deleteAction = (data) => {
    return postRequest('_delete', data).then(res => { return res.data });
}


export const addEditLanguageAction = (data) => {
    return postRequest('addEditLanguage', data).then(res => { return res.data });
}

export const languageListAction = (data) => {
    return postRequest('getLanguageData', data).then(res => { return res.data });
}

export const addEditGenreAction = (data) => {
    return postRequest('addEditGenre', data).then(res => { return res.data });
}

export const genreListAction = (data) => {
    return postRequest('getGenreData', data).then(res => { return res.data });
}

export const addEditProjectTypeAction = (data) => {
    return postRequest('addEditProjectType', data).then(res => { return res.data });
}

export const projectTypeListAction = (data) => {
    return postRequest('getProjectTypeData', data).then(res => { return res.data });
}

export const getMasterDataAction = (data) => {
    return postRequest('getMasterData', data).then(res => { return res.data });
}

export const addEditLocationTypeAction = (data) => {
    return postRequest('addEditLocationType', data).then(res => { return res.data });
}

export const getLocationTypeDataAction = (data) => {
    return postRequest('getLocationTypeData', data).then(res => { return res.data });
}

export const addOneLinerFiledsAction = (data) => {
    return postRequest('addOneLinerFileds', data).then(res => { return res.data });
}

export const getLocationSceneDataAction = (data) => {
    return postRequest('getLocationSceneData', data).then(res => { return res.data });
}

export const editSceneAction = (data) => {
    return postRequest('editScene', data).then(res => { return res.data });
}

export const addEditCategoryAction = (data) => {
    return postRequest('addEditCategory', data).then(res => { return res.data });
}

export const getCategoryDataAction = (data) => {
    return postRequest('getCategoryData', data).then(res => { return res.data });
}

export const addEditCategoryVendorAction = (data) => {
    return postRequest('addEditCategoryVendor', data).then(res => { return res.data });
}

export const getCategoryVendorDataAction = (data) => {
    return postRequest('getCategoryVendorData', data).then(res => { return res.data });
}

export const getCategoryVendorAction = (data) => {
    return postRequest('getCategoryVendor', data).then(res => { return res.data });
}

export const addEditStayMangemnetAction = (data) => {
    return postRequest('addEditStayMangemnet', data).then(res => { return res.data });
}

export const getStayMangemnetDataAction = (data) => {
    return postRequest('getStayMangemnetData', data).then(res => { return res.data });
}

export const addEditVendorAction = (data) => {
    return postRequest('addEditVendor', data).then(res => { return res.data });
}

export const getVendorDataAction = (data) => {
    return postRequest('getVendorData', data).then(res => { return res.data });
}

export const getVehicleDataAction = (data) => {
    return postRequest('getVehicleData', data).then(res => { return res.data });
}

export const addEditVehicleAction = (data) => {
    return postRequest('addEditVehicle', data).then(res => { return res.data });
}
export const addEditVendorPictureAction = (data) => {
    return postRequest('addEditVendorPicture', data).then(res => { return res.data });
}

export const getVendorPictureDataAction = (data) => {
    return postRequest('getVendorPictureData', data).then(res => { return res.data });
}
export const editSceneAttributeAction = (data) => {
    return postRequest('editSceneAttribute', data).then(res => { return res.data });
}
export const deleteAction = (data) => {
    return postRequest('_delete', data).then(res => { return res.data });
}
export const forgotPasswordAction = (data) => {
    return postRequest('forgotPassword', data).then(res => { return res.data });
}
export const resetPasswordAction = (data) => {
    return postRequest('resetPassword', data).then(res => { return res.data });
}
export const changePasswordAction = (data) => {
    return postRequest('changePassword', data).then(res => { return res.data });
}
export const addEditCrewAction = (data) => {
    return postRequest('addEditCrew', data).then(res => { return res.data });
}
export const getCrewListAction = (data) => {
    return postRequest('getCrewList', data).then(res => { return res.data });
}
export const addEditEventCalenderAction = (data) => {
    return postRequest('addEditEventCalender', data).then(res => { return res.data });
}
export const getEventCalenderDataAction = (data) => {
    return postRequest('getEventCalenderData', data).then(res => { return res.data });
}
export const addEditRoleAction = (data) => {
    return postRequest('addEditRole', data).then(res => { return res.data });
}
export const getRoleDataAction = (data) => {
    return postRequest('getRoleData', data).then(res => { return res.data });
}
export const userRegistrationAction = (data) => {
    return postRequest('userRegistration', data).then(res => { return res.data });
}
export const otpVerificationAction = (data) => {
    return postRequest('otpVerification', data).then(res => { return res.data });
}
export const getVendorProductCategory = (data) => {
    return postRequest('getVendorProductCategory', data).then(res => { return res.data });
}
export const addEditVendorProductCategory = (data) => {
    return postRequest('addEditVendorProductCategory', data).then(res => { return res.data });
}
// Action for change status
export const changeStatusAction = (data) => {
    return postRequest('changeStatus', data).then(res => { return res.data })
}


// new fc lite api  

export const getMemberOnDepartment = (data) => {
    return postRequest('getMemberOnDepartment', data).then(res => { return res.data });
}

export const getMemberDetails = (data) => {
    return postRequest('getMemberDetails', data).then(res => { return res.data });
}

export const logInWithEmailOrMobileAction = (data) => {
    return postRequest('logInWithEmailOrMobile', data).then(res => { return res.data });
}

export const emailOrPhoneOtpVerifyAction = (data) => {
    return postRequest('emailOrPhoneOtpVerify', data).then(res => { return res.data });
}

// export const loginWithOtpAction = (data) => {
//     return postRequest('loginWithOtp', data).then(res => { return res.data });
// }

// export const logInWithEmailOtpAction = (data) => {
//     return postRequest('logInWithEmailOtp', data).then(res => { return res.data });
// }

// export const mblOtpCheckAction = (data) => {
//     return postRequest('mblOtpCheck', data).then(res => { return res.data });
// }

// export const emailOtpVerifyAction = (data) => {
//     return postRequest('emailOtpVerify', data).then(res => { return res.data });
// }

export const selectUserTypeRegisterAction = (data) => {
    return postRequest('selectUserTypeRegister', data).then(res => { return res.data });
}