import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { getLocationList, deleteAction } from "../../action/Admin.action";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/home';
import moment from 'moment';
import { Link } from "react-router-dom";
import Location from '../component/AddLocation';
import Pagination from '../common/Pagination';

const LocationMenu = (props) => {

    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [formData, setFormData] = useState({ page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" });
    const [startNum, setStartNum] = useState(0);
    const [editData, setEditData] = useState({});

    const getList = async (action = '') => {
        setEditData({});
        let fData = { ...formData };
        if (action === 'clear') {

            fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
            setFormData(fData);
        }

        setStartNum(fData.page * fData.limit);

        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getLocationList(fData);
        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
        if (resp.code === 200) {
            setDataList(resp.data);
            setFormData({ ...formData, ...{ totalItems: resp.count } });
        }
    }


    const formHandler = (e, field) => {

        let data = { ...formData };
        if (e.target) {
            data[field] = e.target.value;
        } else {
            data[field] = e;
        }
        setFormData(data);
    }

    const deleteHandele = async(Vid)=>{
        let rsp = await deleteAction({type:"projectLocation",_id:Vid});
    if(rsp.code==200){
        getList();
    }
    }

    useEffect(() => {
        getList();
    }, [formData.page])

    return (
        <main id="main" className="main">
            <Row className="mx-2">
                <Col lg={9} md={12} className='table-container'>
                    <Card className='resp2 mt-4'>
                        <Card.Body>
                            <Row className="mt-4">
                                <Col md={6} className='mt-2'><h6>LOCATION</h6></Col>
                                <Col md={6} className='lgsix p-1 mb-3'>
                                    <input type="text" placeholder='Search Location Name' className='BrdRm' onChange={e => formHandler(e, 'name')}></input><i className="bi bi-search serBx" onClick={e => getList()}></i>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} >
                                    <table className="tbl2 table table-light table-striped table-container"  style={{"fontSize":"13px"}}>
                                        <thead className="headingtbl tbl2">
                                            <tr style={{ "backgroundColor": "darkgray" }}>
                                                <th scope="col">S.No.</th>
                                                <th scope="col"> Name</th>
                                                <th scope="col">Location type</th>
                                                <th scope="col">Short List For</th>
                                                <th scope="col">Contact Person</th>
                                                <th scope="col">Contact Number</th>
                                                <th scope="col">Permission</th>
                                                <th scope="col" colSpan={2} className='text-center'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="listing lis">
                                            {
                                                dataList && dataList.length > 0 && dataList.map((val, ind) => {
                                                    let startD = (moment(val.startDate).format("yyyy-MM-DD"))
                                                    let endD = (moment(val.endDate).format("yyyy-MM-DD"))
                                                    return <tr key={ind}>
                                                        <td>{startNum + ind + 1}</td>
                                                        <td className='truncate-text'>{val.name}</td>
                                                        <td className='truncate-text'>{val.locationName}</td>
                                                        <td>{val.shortListFor}</td>
                                                        <td>{val.contactPerson}</td>
                                                        <td>{val.contactNumber}</td>
                                                        <td>{[val.permission]}</td>
                                                        <td className=''>  
                                                            <Button className="icnBtn btn-dark" onClick={e => setEditData(val)} ><i className="bi bi-pencil"></i></Button>
                                                        </td>
                                                        <td><Button onClick={()=>{deleteHandele(val._id)}} className="icnBtn btn-dark" ><i className="bi bi-trash"></i></Button></td>
                                                    </tr>
                                                })
                                            }
                                            {dataList && dataList.length == 0 ? 'Data not avaialble.' : ''}
                                        </tbody>
                                    </table>
                                    <Pagination itemsPerPage={formData.limit} totalItems={formData.totalItems ? formData.totalItems : 0} setPage={e => setFormData({ ...formData, ...e })} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={6} className="mt-4">
                    <h5 className="mb-3 smsz">Add Location</h5>
                    <Location getList={e => getList()} editData={editData} />
                </Col>
            </Row>
        </main>
    );
}

export default memo(LocationMenu);
