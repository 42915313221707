import { react, useState } from 'react';
import { otpVerificationAction } from '../../action/Admin.action';
import { ToastContainer, toast } from "react-toastify";
import { Row, Col, Form, Button } from 'react-bootstrap';

const OtpVerification = ({data1}) => {
    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true)
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let resp = await otpVerificationAction(formData);
            if (resp.code === 200) {
                toast.success("Verification Completed !");
                data1(false)

            } else {
                toast.error(resp.msg);
            }

        }
    }

    const handleChange = (name, event) => {
        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });
    }

    return (
        <>
            <Row>
                <Col lg={12}>
                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                        
                        <Form.Group className="mb-2" controlId="email">
                        <Form.Label>email<span className="star" >*</span></Form.Label>
                            <Form.Control
                                type="email"
                                autoComplete='false'
                                className="frmcnt"
                                pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                                placeholder="Email"
                                value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="otp">
                        <Form.Label>otp<span className='star'>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                className="frmcnt"
                                placeholder="otp"
                                value={formData.otp ? formData.otp : ""} onChange={e => handleChange('otp', e)} minLength={6} maxLength={6} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid otp.
                            </Form.Control.Feedback>
                        </Form.Group>

                        
                        <Row className="mt-4 g-2">
                            <Col lg={12} className='text-end'>
                                <Button type="submit" className="vndrBtn" onSubmit={() => { handleSubmit() }}>
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default OtpVerification;