import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import location1 from '../../assets/images/OldProImg/location1.png';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { getStayMangemnetDataAction } from "../../action/Admin.action";
import { useState, memo, useEffect } from 'react';
import { Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
// import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { setAlert } from '../../slices/home.js';
import Form from 'react-bootstrap/Form';
import line1 from '../../assets/images/OldProImg/line1.png'
import { imgPath } from '../common/function';
import { addEditStayMangemnetAction } from "../../action/Admin.action";
import { getCrewListAction } from "../../action/Admin.action";
import Select from 'react-select'
import { isValid } from 'date-fns';
import { ToastContainer, toast } from "react-toastify";


const StayOption = () => {
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [locationList, setLocationList] = useState();
    const [artistList, setArtistList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formData, setFormData] = useState({});
    const handleShow = () => setShow(true);
    const navigate = useNavigate();
    const [file, setFile] = useState("");
    const [ldata, setLdata] = useState();
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");


    const getList1 = async () => {
        let resp = await getCrewListAction();
        if (resp.code === 200) {
            let artiestsList = [];
            {
                resp && (resp.data.length > 0 && (resp.data).map((v, i) => {
                    if (v.department == "000000000000000000000001") {
                        artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
                    }
                }))
            }
            setArtistList(artiestsList);
            // if (resp.data.artiest && resp.data.artiest.length) {
            //     let artiests = resp.data.artiest;
            //     let artiestsList = [];
            //     for (let i in artiests) {
            //         let row = artiests[i];
            //         artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
            //     }
            //     setArtistList(artiestsList);
            // }
        }

    }


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let postData = new FormData();
            for (let key in formData) {
                if (key == 'artiestId') {

                } else {
                    postData.append(key, formData[key]);
                }
            }

            let artistNoList = [];
            for (let j in formData.artiestId) {

                let a = formData.artiestId[j];
                artistNoList.push(a.value);
            }
            postData.append('artiestId', JSON.stringify(artistNoList));

            let resp = await addEditStayMangemnetAction(postData);
            setDisabled(false);
            if (resp.code === 200) {
                getList();
                handleClose();
                setFormData({});
                setValidated(false);
                toast.success("Saved !");

            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
                toast.error(data);
            }
            return false;
        }
    };



    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;
        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {
            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {
            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";
        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }



    const handleChange = (name, event, extra = "") => {

        if (name == "checkIn" || name == "checkOut") {
            const selectedStartDate = event.target.value;
            setStartDate(selectedStartDate);

            const endDateInput = document.getElementById("checkOut");
            if (endDateInput) {
                endDateInput.min = selectedStartDate;
                if (selectedStartDate > endDate) {
                    setEndDate("");
                }
            }
            // setValidated(true);

            const selectedEndDate = event.target.value;
            setEndDate(selectedEndDate);
            const startDateInput = document.getElementById("checkIn");
            if (startDateInput) {
                startDateInput.max = selectedEndDate;
                if (startDate && selectedEndDate < startDate) {
                    setStartDate("");
                }
            }
        }

        let from = { ...formData };
        if (extra === 'multi') {
            from[name] = event;
        } else if (name === 'artiestId') {
            let arr = [];

            if (event.target.checked) {
                arr.push(event.target.value);
            } else {
                const index = arr.indexOf(event.target.value);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }
            from[name] = arr;
        } else {

            from[name] = event.target.value;
        }
        setFormData({ ...formData, ...from });

    };




    const getList = async () => {
        const resp = await getStayMangemnetDataAction();
        if (resp.code === 200) {
            setLocationList(resp.data);

            setLdata(resp.data);
        }
    }
    useEffect(() => {
        getList();
        getList1();
    }, [])


    return (
        <>
            <div className='pt-4'>
                <Card>
                    <Card.Body className='crdBdy'>
                        <Row className="mt-3 pt-3">
                            {/* <i className="fa fa-arrow-left hand" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}></i> */}
                            <Col className="col-6 col-lg-10 pt-2"><h6 className='crdHd1'>Stay Management</h6></Col>
                            {/* <Col className="col-2 text-end col-lg-2 px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}>Back</Button></Col> */}
                            <Col className="col-2 text-start col-lg-2 px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={() => { handleShow(); setValidated(false) }}>+ Add</Button></Col>
                        </Row>
                        <hr />
                        <Row className='mt-2'>
                            {locationList && locationList.length > 0 && locationList.map((item, index) => {
                                return <Col lg={4} md={6} className='mt-3'>
                                    <Card style={{ borderRadius: "30px" }} className="text-center hand brdr" onClick={() => { navigate(`${process.env.PUBLIC_URL}/stay-details`, { state: item }) }}>
                                        <img src={item.image ? imgPath(item.image) : location1} alt="" className="img-fluid optionImg" />
                                        <Card.Body className="crdBdy1">
                                            <Card.Title className="fw-bold  dd fw-bold mb-1 pb-1">{item.title}</Card.Title>
                                            <p class="d-inline-block cardText colr mb-0" >{item.desc}</p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            })}
                        </Row>
                    </Card.Body>
                </Card>
            </div>

            <Modal show={show} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                    <Modal.Title className='crdHd1'>Stay Management</Modal.Title>
                </Modal.Header>
                {/* <hr /> */}
                {/* <Row>
                    <Col lg={11}><h6 className="m-2 mt-3 mb-0">Stay Management</h6></Col>
                    <Col lg={1}>
                        <span className='hand cancel btn-close' onClick={() => { handleClose() }}></span>
                    </Col>
                </Row> */}
                <Modal.Body className='pt-0'>
                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                        {
                            <div>
                                <Row>
                                    <Col>
                                        <img src={line1} alt="" className="img-fluid12" />
                                    </Col>
                                </Row>

                                <Row className='mx-2'>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="title">
                                            <Form.Label>Title<span className='star'>*</span></Form.Label>

                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Title Name" onChange={e => handleChange('title', e)} value={formData.title ? formData.title : ""} minLength={2} maxLength={32} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Title.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="hotelName">
                                            <Form.Label>Hotel Name<span className='star'>*</span></Form.Label>

                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of Hotel" onChange={e => handleChange('hotelName', e)} value={formData.hotelName ? formData.hotelName : ""} minLength={2} maxLength={25} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Hotel Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="artiestId" className="txt">
                                            <Form.Label >For Whom<span className='star'>*</span></Form.Label>
                                            <Select className="brdr1 mselect frmcnt" aria-label="Default select example"
                                                value={formData.artiestId ? formData.artiestId : []}
                                                placeholder="Select"
                                                isMulti
                                                required
                                                options={artistList}
                                                // className="basic-multi-select"
                                                classNamePrefix="select"
                                                isValid={formData.artiestId && formData.artiestId.length > 0}
                                                onChange={e => handleChange('artiestId', e, 'multi')}>
                                                <option value="">Select Option</option>
                                                {
                                                    artistList && artistList.length > 0 && artistList.map((val, ind) => {
                                                        return <option value={(val.value)} key={ind}  >{val.label}</option>
                                                    })
                                                }
                                            </Select>
                                            {validated === true && ((!formData.artiestId) || formData.artiestId && formData.artiestId.length === 0) &&
                                                <p className="err">This field is required</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mx-2'>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="country">
                                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                                                <GetAllCountries />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="state">
                                            <Form.Label>State<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                                                <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a State.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="city">
                                            <Form.Label>City<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                                                <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a City.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className='mx-2'>
                                    <Col lg={4}
                                        className="txt">
                                        <Form.Group className="mb-1" controlId="desc">
                                            <Form.Label>Location Description<span className='star'>*</span></Form.Label>
                                            <Form.Control as="textarea" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" onChange={e => handleChange('desc', e)} value={formData.desc ? formData.desc : ""} rows={5} minLength={2} maxLength={250} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Valid Location Description.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={8}>
                                        <Row>
                                            <Col className="txt">
                                                <Form.Group className="mb-1" controlId="checkIn">
                                                    <Form.Label className="txt">Check In<span className='star'>*</span></Form.Label>
                                                    <Form.Control className='frmcnt' type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.checkIn ? formData.checkIn : ""} onChange={e => handleChange('checkIn', e)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Check In.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="txt">
                                                <Form.Group className="mb-1" controlId="checkOut">
                                                    <Form.Label className="txt">Check Out<span className='star'>*</span></Form.Label>
                                                    <Form.Control className='frmcnt' type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.checkOut ? formData.checkOut : ""} onChange={e => handleChange('checkOut', e)} required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Check Out.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="txt">
                                                <Form.Group className="mb-1" controlId="prices">
                                                    <Row><Col><Form.Label>Price<span className='star'>*</span></Form.Label>
                                                    </Col></Row>
                                                    <Form.Control className="brdr frmcnt" type="text" pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' laceholder="20000" onChange={e => handleChange('prices', e)} value={formData.prices ? formData.prices : ""} minLength={1} maxLength={15} required />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a Valid Price.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col className="txt">
                                                <Form.Group controlId="status">
                                                    <Form.Label >Status<span className='star'>*</span></Form.Label>
                                                    <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                        placeholder="Select" onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                                        <option value="" >Select Status</option>
                                                        <option value="1" >Active</option>
                                                        <option value="0" >InActive</option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a Valid Selection.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                    </Col>
                                </Row>

                                <Row className='mx-2'>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="mb-1" controlId="image">
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control className="brdr frmcnt" type="file" ize="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg"
                                                onChange={e => fileChangedHandler(e, 'image')}
                                            />
                                        </Form.Group>
                                        <Form.Control.Feedback type="invalid">
                                            It only accept png, jpg, jpeg files.
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col lg={4} className='imgcss'>
                                        {/* <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} /> */}
                                        <img src={file} />
                                    </Col>

                                </Row>

                                <Row className='g-2 mx-2 mt-2'>
                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                        <Button variant="secondary" className="btnk cldrBtn" onClick={() => { setFormData({}); handleClose() }}>
                                            Cancel
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                        <Button variant="secondary" type='submit' className="btnk cldrBtn"
                                            disabled={isDisabled}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Form>
                </Modal.Body >
            </Modal >

        </>
    );
}

export default memo(StayOption);



