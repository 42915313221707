import React, { useState, useEffect, useRef } from "react";
import { selectUserTypeRegisterAction, getCategoryVendorDataAction } from "../../../action/Admin.action";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../slices/home';
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState, imgPath } from "../../../admin/common/function";
import CheckmarkIcon from "../../../assets/images/frontPageImg/CheckmarkIcon.png"
import { FaCamera } from 'react-icons/fa';
import axios from "axios";
const ProfileEdit = ({ onClose }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [successMessage, setSuccessMessage] = useState("");


    const loginData = JSON.parse(localStorage.getItem("login")) || {};
    const userType = loginData.userType || null;
    const fileInputRef = useRef(null);
    const [pincodeOptions, setPincodeOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [formData, setFormData] = useState({
        companyName: loginData.companyName || "",
        businessContactName: loginData.businessContactName || "",
        categoryId: loginData.categoryId || "",
        email: loginData.email || "",
        subCategoryId: loginData.subCategoryId || "",
        businessContactNumber: loginData.businessContactNumber || "",
        age: loginData.age || "",
        gender: loginData.gender || "",
        businessCountry: loginData.businessCountry || "",
        businessState: loginData.businessState || "",
        businessCity: loginData.businessCity || "",
        businessPostalCode: loginData.businessPostalCode || "",
        businessAddress: loginData.businessAddress || "",
        description: loginData.description || "",
        businessGstNumber: loginData.businessGstNumber || "",
        businessPan: loginData.businessPan || "",
        website: loginData.website || "",
        imDbLink: loginData.imDbLink || "",
        productImg: loginData.productImg || "",
        crewResume: loginData.crewResume || "",
        portfolio: loginData.portfolio || "",
        userProfile: loginData.userProfile || "",
        workType: loginData.workType || "",
    });
    const [image, setImage] = useState(
        "https://i.imgur.com/bDLhJiP.jpg"
    );
    const [step, setStep] = useState(1);
    const [dataList, setDataList] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);


    const fetchPincode = async (city) => {
        setLoading(true);
        setError('');
        setPincodeOptions([]);

        try {
            const response = await axios.get(`https://api.postalpincode.in/postoffice/${city}`);
            if (response.data[0].Status === "Success") {
                const pincodes = response.data[0].PostOffice.map(po => po.Pincode);
                setPincodeOptions(pincodes);
                setFormData(prev => ({
                    ...prev,
                    businessPostalCode: pincodes.includes(prev.businessPostalCode) ? prev.businessPostalCode : ''
                }));
            } else {
                setError('Pincode not found for the specified city');
                setFormData(prev => ({ ...prev, businessPostalCode: '' }));
            }
        } catch (err) {
            setError('An error occurred while fetching the pincode');
        } finally {
            setLoading(false);
        }
    };


    const getDataList = async () => {
        let resp;
        switch (userType) {
            case 3:
                resp = await getCategoryVendorDataAction({ type: 'vendor' });
                break;
            case 4:
                resp = await getCategoryVendorDataAction({ type: 'crew' });
                break;
            case 5:
                resp = await getCategoryVendorDataAction({ type: 'other' });
                break;
            default:
                return;
        }
        if (resp && resp.code === 200) {
            setDataList(resp.data);
        }
    };


    useEffect(() => {
        getDataList();

    }, [userType]);


    useEffect(() => {
        if (formData.categoryId && dataList.length > 0) {
            const selectedCategory = dataList.find(item => item._id === formData.categoryId);

            if (selectedCategory) {
                setSubCategories(selectedCategory.subCategory || []);
                const isSubCategoryValid = selectedCategory.subCategory.some(sub => sub._id === formData.subCategoryId);
                if (!isSubCategoryValid) {
                    setFormData(prev => ({ ...prev, subCategoryId: "" }));
                }
            } else {
                setSubCategories([]);
                setFormData(prev => ({ ...prev, subCategoryId: "" }));
            }
        }
    }, [dataList, formData.categoryId]);
    useEffect(() => {
        if (loginData?.userProfile) {
            setImage(imgPath(loginData.userProfile));
        } else {
            setImage("https://i.imgur.com/bDLhJiP.jpg");
        }
    }, [loginData?.userProfile]);

    const validateStep = () => {
        const phoneNumberPattern = /^[0-9]{10}$/;
        switch (step) {
            case 1:
                return (
                    formData.businessContactName &&
                    phoneNumberPattern.test(formData.businessContactNumber) &&
                    (userType !== 3 || userType !== 4 || formData.categoryId) &&
                    (userType !== 5 || formData.workType) &&
                    formData.email &&
                    (userType !== 4 || formData.age) &&
                    (userType !== 4 || formData.gender) &&
                    formData.subCategoryId &&
                    formData.businessCountry &&
                    formData.businessState &&
                    formData.businessCity &&
                    formData.businessPostalCode &&
                    (userType !== 3 || formData.companyName)
                );
            case 2:
                return (
                    formData.businessAddress
                );
            default:
                return false;
        }
    };

    const handleNextStep = (event) => {
        event.preventDefault();
        if (validateStep()) {
            if (step === 1) {
                setStep(2);
            }
        } else {
            setValidated(true);
        }
    };


    const handlePreviousStep = (event) => {
        event.preventDefault();
        if (step === 2) {
            setStep(1);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false || !validateStep()) {
            event.stopPropagation();
            setValidated(true);
        } else {
            setValidated(false);
            setDisabled(true);
            let formDataData = { ...formData, userType };
            let postData = new FormData();


            for (let key in formDataData) {
                let item = formDataData[key];

                if (item instanceof File) {
                    postData.append(key, item);
                } else {
                    postData.append(key, item);
                }
            }
            let msg = "You have successfully updated your profile.";
            let resp = await selectUserTypeRegisterAction(postData);

            if (resp.code === 200) {
                localStorage.setItem('status', JSON.stringify(resp.data.status));
                localStorage.setItem("login", JSON.stringify(resp.data));
                localStorage.setItem("userType", resp.data.userType);
                setSuccessMessage(msg);
                dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                setTimeout(() => {
                    onClose();
                }, 3000);

            } else {
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }
        }
    };

    const handleChange = (name, event) => {
        const value = event.target.value;
        setFormData(prev => ({ ...prev, [name]: value }));

        if (name === 'categoryId') {
            const selectedCategory = dataList.find(item => item._id === value);
            setSubCategories(selectedCategory ? selectedCategory.subCategory : []);
            setFormData(prev => ({ ...prev, subCategoryId: "" }));
        }

        if (name === 'businessCity') {
            fetchPincode(value);
        }
    };


    const handleContinue = () => {
        setShowSuccessModal(false);
        navigate(`${process.env.PUBLIC_URL}/user/dashboard`);
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);

            setFormData(prev => ({ ...prev, userProfile: file }));
        }
    };
    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleRemoveImage = () => {
        setImage("https://i.imgur.com/bDLhJiP.jpg");
        setFormData(prev => ({ ...prev, userProfile: null }));
    };
    useEffect(() => {
        if (formData.businessCity) {
            fetchPincode(formData.businessCity);
        }
    }, [formData.businessCity]);
    return (
        <>
            <Form className="userForm" noValidate validated={validated} onSubmit={handleSubmit}>
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {step === 1 ? (
                    <>

                        <div className="row d-flex justify-content-end">
                            <div className="col-md-7">
                                <div className="pb-4">
                                    <div className="image-upload-container text-center">
                                        <img
                                            src={image}
                                            width="100"
                                            className="rounded-circle"
                                            alt="Profile"
                                            style={{ cursor: 'pointer' }}
                                            onClick={triggerFileInput}
                                        />
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={handleImageUpload}
                                            ref={fileInputRef}
                                        />

                                        <div className="camera-icon-overlay" onClick={triggerFileInput}>
                                            <FaCamera size={20} color="white" />
                                        </div>

                                    </div>
                                    {formData.userProfile && formData.userProfile !== "" && (
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            className="mt-2"
                                            onClick={handleRemoveImage}
                                        >
                                            Remove
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Row>
                            {userType === 3 && (
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">Company Name<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="companyName"
                                                value={formData.companyName}
                                                onChange={(e) => handleChange('companyName', e)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Company Name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )}
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">Contact Name<span className="star">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="businessContactName"
                                            value={formData.businessContactName}
                                            onChange={(e) => handleChange('businessContactName', e)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Contact Name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                            {userType === 5 && (
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">Email ID<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={(e) => handleChange('email', e)}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                className="custom-input"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Email ID.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )}
                        </Row>

                        <Row>
                            {(userType === 3 || userType === 4) && (
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group controlId="categoryId">
                                            <Form.Label className="text-white mb-2">Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="categoryId"
                                                value={formData.categoryId}
                                                onChange={(e) => handleChange('categoryId', e)}
                                            >
                                                <option value="">Select Category</option>
                                                {dataList.map((category) => (
                                                    <option key={category._id} value={category._id}>
                                                        {category.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )}
                            {(userType === 3 || userType === 4) && (
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">Email ID<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={(e) => handleChange('email', e)}
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Email ID.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )}
                        </Row>

                        <Row>
                            {(userType === 3 || userType === 4) && (
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group controlId="subCategoryId">
                                            <Form.Label className=" text-white mb-2">Sub Category</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="subCategoryId"
                                                value={formData.subCategoryId}
                                                onChange={(e) => handleChange('subCategoryId', e)}
                                                disabled={subCategories.length === 0}
                                            >
                                                <option value="">Select Sub Category</option>
                                                {subCategories.map((subCategory) => (
                                                    <option key={subCategory._id} value={subCategory._id}>
                                                        {subCategory.name}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )}
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">Contact No<span className="star">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="businessContactNumber"
                                            value={formData.businessContactNumber}
                                            onChange={(e) => handleChange('businessContactNumber', e)}
                                            required
                                            pattern="\d{10}"
                                            minLength="10"
                                            maxLength="10"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Contact No.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                            {userType === 5 && (
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">Work Type<span className="star">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="workType"
                                                value={formData.workType || ""}
                                                onChange={e => handleChange('workType', e)}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Work Type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        {userType === 4 && (
                            <Row>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <Form.Label className="text-white mb-2">Gender<span className="star"> *</span></Form.Label>
                                        <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.gender ? formData.gender : ""} onChange={e => handleChange('gender', e)} required>
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Gender.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-2">
                                        <Form.Label className="text-white mb-2">Age<span className="star"> *</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="age"
                                            value={formData.age || ""}
                                            onChange={e => handleChange('age', e)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Gender.
                                        </Form.Control.Feedback>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">Country<span className="star">*</span></Form.Label>
                                        <Form.Select
                                            name="businessCountry"
                                            value={formData.businessCountry}
                                            onChange={(e) => handleChange('businessCountry', e)}
                                            required
                                        >
                                            <GetAllCountries />
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Country.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">State<span className="star">*</span></Form.Label>
                                        <Form.Select
                                            name="businessState"
                                            value={formData.businessState}
                                            onChange={(e) => handleChange('businessState', e)}
                                            required
                                        >
                                            <GetStatesOfCountry countryCode={formData.businessCountry} />
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid State.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">City<span className="star">*</span></Form.Label>
                                        <Form.Select
                                            name="businessCity"
                                            value={formData.businessCity}
                                            onChange={(e) => handleChange('businessCity', e)}
                                            required
                                        >
                                            <GetCitiesOfState
                                                countryCode={formData.businessCountry}
                                                stateCode={formData.businessState}
                                            />
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid City.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">
                                            Pin Code<span className="star">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            name="businessPostalCode"
                                            value={formData.businessPostalCode || ''}
                                            onChange={(e) => handleChange('businessPostalCode', e)}
                                            required
                                        >

                                            <option value="" disabled>Select Pin Code</option>
                                            {pincodeOptions.map((pin, index) => (
                                                <option key={index} value={pin}>
                                                    {pin}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        {error && <div className="text-danger">{error}</div>}
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button
                                    className="mt-3"
                                    onClick={handleNextStep}
                                    disabled={isDisabled}
                                    style={{
                                        backgroundColor: "#AA1163",
                                        fontSize: "1rem",
                                    }}
                                >
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <>
                        {/* Step 2 Form Fields */}
                        <Row>
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">Address<span className="star">*</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="businessAddress"
                                            rows="2"
                                            value={formData.businessAddress}
                                            onChange={(e) => handleChange('businessAddress', e)}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Address.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-2">
                                    <Form.Group>
                                        <Form.Label className="text-white mb-2">Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="description"
                                            rows="2"
                                            value={formData.description}
                                            onChange={(e) => handleChange('description', e)}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Description.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </Col>
                        </Row>
                        {(userType === 3 || userType === 4) && (
                            <Row>
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">GST Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="businessGstNumber"
                                                value={formData.businessGstNumber}
                                                onChange={(e) => handleChange('businessGstNumber', e)}
                                                pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                                minLength={15}
                                                maxLength={15}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid GST Number.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">Pan No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="businessPan"
                                                value={formData.businessPan}
                                                onChange={(e) => handleChange('businessPan', e)}
                                                pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                                minLength={10}
                                                maxLength={10}

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Pan No.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {userType === 4 && (
                            <Row>
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">Website</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="website"
                                                value={formData.website}
                                                onChange={(e) => handleChange('website', e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Website.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mb-2">
                                        <Form.Group>
                                            <Form.Label className="text-white mb-2">IMDb Link</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="imDbLink"
                                                value={formData.imDbLink}
                                                onChange={(e) => handleChange('imDbLink', e)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid IMDb Link.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <div>
                            <Button
                                className="mt-3"
                                type="submit"
                                disabled={isDisabled}
                                style={{
                                    backgroundColor: "#AA1163",
                                    fontSize: "1rem",
                                }}
                            >
                                Profile Update
                            </Button>
                            {' '}
                            <Button
                                className="mt-3"
                                onClick={handlePreviousStep}
                                style={{
                                    fontSize: "1rem",
                                    marginLeft: '10px',
                                    backgroundColor: '#455A64'
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    </>
                )}
            </Form>

            {/* Success Modal */}
            <Modal
                show={showSuccessModal}
                onHide={() => { }}
                centered
                backdrop="static" // Prevent closing the modal by clicking outside
            >
                <Modal.Body className="text-center">
                    {/* Blue checkmark icon */}
                    <div className="my-4">
                        <img
                            src={CheckmarkIcon}
                            width={50}
                            height={50}
                            alt="Success Checkmark"
                        />
                    </div>
                    <h4>Registration Successful!</h4>
                    <p>You have successfully registered!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleContinue}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};






export default ProfileEdit;
