import React, { Fragment, useEffect, useState } from "react";
import { getCategoryVendorDataAction } from "../../../action/Admin.action";
import { setAlert } from '../../../slices/home';
import { Container, Row, Col, Card, Button, Form, } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import { imgPath, objToArray, GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../../../admin/common/function"
import HomeFooter from "../Footer/HomeFooter";
import HomeBackImgCarousel from "../Header/HomeBackImgCarousel"
const Main = ({ selected }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [formData, setFormData] = useState({
        name: '', businessCountry: 'IN',
        businessState: '',
        businessCity: ''
    });
    const [suggestions, setSuggestions] = useState([]);

    const fetchData = async () => {
        setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' });

        const resp = await getCategoryVendorDataAction();

        setAlert({ open: false });

        if (resp && resp.code === 200) {
            setDataList(resp.data);
        } else {
            console.error("Error fetching data:", resp);
        }
    };

    const fetchSuggestions = async (name) => {
        if (name.length >= 1) {
            const resp = await getCategoryVendorDataAction({ name });
            if (resp && resp.code === 200) {
                setSuggestions(resp.data);
            } else {
                console.error("Error fetching suggestions:", resp);
            }
        } else {
            setSuggestions([]);
        }
    };

    const handleInputChange = async (e) => {
        const name = e.target.value;
        setFormData({ ...formData, name });

        await fetchSuggestions(name);
    };

    const handleSuggestionClick = (category) => {
        setFormData({ ...formData, name: category.name });
        setSuggestions([]);
        fetchData();
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        if (formData.name) {
            await fetchData();

            if (dataList.length > 0) {



                const selectedCategory = dataList.find(item => item.name.toLowerCase() === formData.name.toLowerCase());

                if (selectedCategory) {
                    const selectedId = selectedCategory._id;
                    handleCardClick(selectedCategory);
                } else {
                    console.error("No matching category found");
                }
            } else {
                console.error("No categories found");
            }
        }
    };
    const handleChange = (name, event) => {
        const value = event.target.value;
        let updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);
    };

    const handleCardClick = (category) => {
        const { businessCountry, businessState, businessCity } = formData;

        navigate(`${process.env.PUBLIC_URL}/categoryProfileListing`, {
            state: {
                id: category,
                businessCountry,
                businessState,
                businessCity
            }
        });
    };

    useEffect(() => {
        setFormData({
            ...formData,
            businessState: '',
            businessCity: ''
        });
    }, [formData.businessCountry]);
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Fragment>
            <HomeHeader />
            <HomeBackImgCarousel />
            <section className="search-bar-section">
                <Container className="search-bar mt-2">
                    <Form onSubmit={handleSearch}>
                        <Row className="g-2">
                            <Col xs={12} md={2}>
                                <Form.Group>
                                    <Form.Select aria-label="Default select example" className="filedbg" value={formData.businessCountry ? formData.businessCountry : ""} onChange={e => handleChange('businessCountry', e)} >
                                        <GetAllCountries />
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Country.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group>
                                    <Form.Select aria-label="Default select example" className="filedbg" value={formData.businessState ? formData.businessState : ""} onChange={e => handleChange('businessState', e)} >
                                        <GetStatesOfCountry countryCode={formData.businessCountry ? formData.businessCountry : ""} />
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid State.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={2}>
                                <Form.Group>
                                    <Form.Select aria-label="Default select example" className="filedbg" value={formData.businessCity ? formData.businessCity : ""} onChange={e => handleChange('businessCity', e)} >
                                        <GetCitiesOfState countryCode={formData.businessCountry ? formData.businessCountry : ""} stateCode={formData.businessState ? formData.businessState : ""} />
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid City.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={4}>
                                <div className="d-flex search-baree">
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder="🔍 Search for Name"
                                        className="custom-inputee custom-placeholder"
                                        onChange={handleInputChange}
                                        value={formData.name}
                                        autocomplete="off"
                                    />
                                    <button className="search-button" type="submit" disabled={!formData.name}>
                                        <img src={searchIcon} alt="Search" />
                                    </button>
                                </div>
                                {suggestions.length > 0 && (
                                    <ul className="suggestions-list">
                                        {suggestions.map((category) => (
                                            <li
                                                key={category._id}
                                                onClick={() => handleSuggestionClick(category)}
                                                className="suggestion-item"
                                            >
                                                <span className="suggestion-icon">🔍</span>
                                                <div className="suggestion-details">
                                                    <span className="suggestion-name">{category.name}</span>
                                                    <span className="suggestion-category">Category</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </Col>

                        </Row>
                    </Form>
                </Container>
            </section>
            <section id="category" className="category-section">
                <Container>
                    <Row className="mb-4">
                        <Col md={6}>
                            <div className="placeholder-card" style={{ backgroundColor: '#26392a' }}></div>
                        </Col>
                        <Col md={2}>
                            <div className="placeholder-card" style={{ backgroundColor: '#4b2d20' }}></div>
                        </Col>
                        <Col md={2}>
                            <div className="placeholder-card" style={{ backgroundColor: '#40153d' }}></div>
                        </Col>
                        <Col md={2}>
                            <div className="placeholder-card " style={{ backgroundColor: '#431a1f' }}></div>
                        </Col>
                    </Row>

                    <Row>
                        {dataList && objToArray(dataList).map((item, index) => (
                            <Col xs={6} sm={4} md={3} lg={2} className="text-center mb-4" key={index}>
                                <div className="category-card hand" onClick={() => handleCardClick(item)}>
                                    <div className="icon-container">
                                        <Card.Img variant="top" src={imgPath(item.categoryImg)} alt={item.name} />
                                    </div>
                                    <Card.Body className="card-body-responsive">
                                        <Card.Text >{item.name}</Card.Text>
                                    </Card.Body>
                                </div>
                            </Col>
                        ))}
                    </Row>

                </Container>
            </section>
            <HomeFooter />
        </Fragment >
    );
};

export default Main;
