import { useState, memo, useEffect, Fragment } from 'react';
import moment from 'moment';
import { Col, Row, Button, Alert, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocationList, listSceneScheduleAction, getVendorDataAction } from "../../action/Admin.action";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { addLocation, getCategoryDataAction, getLocationSceneDataAction, getScheduleListAction, projectListingAction } from "../../action/Admin.action";
import { setAlert } from "../../slices/home";
import place from '../../assets/images/OldProImg/dashbord/place.png';
import filter from '../../assets/images/OldProImg/dashbord/filter.png';
import people from '../../assets/images/OldProImg/dashbord/people.png';
import { MyTask, ToDoList, UpcommingEvents, CurrentEvent, PastEvent } from '../common/constant';
import actv from '../../assets/images/OldProImg/dashbord/statusA.png';
import psv from '../../assets/images/OldProImg/dashbord/statusP.png';
import sms from '../../assets/images/OldProImg/dashbord/sms.png';
import assign1 from '../../assets/images/OldProImg/dashbord/assign2.png';
import assign2 from '../../assets/images/OldProImg/dashbord/assign1.png';
import evline from '../../assets/images/OldProImg/dashbord/evline.png'
import { PieChart, Pie, Legend, Cell, Label, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { preProData, proData, postproData, ReamainingDays, teams, ProjectData, projectName } from '../common/constant';
import ProgressBar from '../common/progressBar';
import CustomizedAreaChart from './reChart';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


const DashBoard = () => {

  const [Step, setStep] = useState(1);
  const location = useLocation();
  let locationData = location.state;
  const width1 = { width: "90%" };
  const width3 = { width: "100%" };
  const width2 = { width: "90%" };
  const width4 = { width: "10%" };
  const [ldata, setLdata] = useState(locationData);
  const [seeAll, setSeeAll] = useState(false);
  const [seeAll1, setSeeAll1] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({});
  const [sceneList, setSceneList] = useState({});
  const [scheduleList, setScheduleList] = useState({});
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const masters = useSelector((state) => state.home.masters);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [locationList, setLocationList] = useState();
  const [sceneData, setSceneData] = useState();
  const [vendorData, setVendorData] = useState();
  const [projectLst, setProjectLst] = useState();
  const [projct, setProjct] = useState([]);
  const [rmDays, setRmDays] = useState({ name: 'Reamaining Days', value: 140 });
  const [key, setKey] = useState('Current Events');
  // const ReamainingDays = { name: 'Reamaining Days', value: 140 };


  const [file, setFile] = useState("");
  const data = [
    { name: "Completed", value: 45, fill: "rgba(116, 190, 165, 0.9)" },
    { name: "In Progress", value: 20, fill: "rgba(152, 215, 194, 1)" },
    { name: "Pending", value: 35, fill: "rgba(152, 215, 194, 0.6)" }
  ];
  const data1 = [
    { name: 'Apr', x: 16, y: 40 },
    { name: 'May', x: 35, y: 20 },
    { name: 'June', x: 16, y: 60 },
    { name: 'July', x: 42, y: 42 },
    { name: 'Aug', x: 30, y: 59 },
    { name: 'Sept', x: 90, y: 61 },
    { name: 'Oct', x: 62, y: 85 },
    { name: 'Nov', x: 70, y: 60 },
    { name: 'Dec', x: 40, y: 63 },
  ];
  const totalValue = data.reduce((total, entry) => total + entry.value, 0);
  const CustomLegend = ({ payload }) => {
    return (
      <Row className='custom-legend d-flex p-0'>
        {payload.map((entry, index) => (
          <Col key={`legend-${index}`} style={{ color: entry.color }} className='listyle d-flex p-0'>
            <span className='legend-color' style={{ backgroundColor: entry.color, height: "15px", width: "15px", borderRadius: "5px" }}></span>
            <span className='donutCount'>{entry.payload.value}</span>
            <span className='donutName'>{entry.payload.name}</span>
          </Col>
        ))}
      </Row>
    );
  };

  const ReamainingDys = () => {
    let lastEndDate = '';
    sceneList && sceneList.length > 0 && sceneList.map((v, i) => {
      if (v.endDate > lastEndDate) {
        lastEndDate = v.endDate;
      }
      const presentDate = new Date();
      const endDate = new Date(lastEndDate);
      const timeDifference = endDate.getTime() - presentDate.getTime();
      const totalDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
      setRmDays({ name: 'Reamaining Days', value: totalDays })
    })
  }

  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      let postData = new FormData();
      for (let key in formData) {
        postData.append(key, formData[key]);
      }
      let a = (Step + 1)
      setStep(a);
      setValidated(false);
      setDisabled(false)
      if (a == 4) {
        let resp = await addLocation(postData);
        setDisabled(false);
        let msg = "Location Updated Successfully";

        if (resp.code === 200) {
          setLdata(resp.data)
          getDataList();
          dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
          let b = (a == 4) ? handleClose() : ''
        } else {
          var data = "";
          if (resp.msg) {
            data = resp.msg;
          }
          setDisabled(false);
          dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
        }
      }
      return false;
    }
  };
  const handleChange = (name, event) => {
    let from = { ...formData };
    from[name] = event.target.value;
    //start Date And Date Validation
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);
    const endDateInput = document.getElementById("endDate");
    if (endDateInput) {
      endDateInput.min = selectedStartDate;
      if (selectedStartDate > endDate) {
        setEndDate("");
      }
    }

    const selectedEndDate = event.target.value;
    setEndDate(selectedEndDate);
    const startDateInput = document.getElementById("startDate");
    if (startDateInput) {
      startDateInput.max = selectedEndDate;
      if (startDate && selectedEndDate < startDate) {
        setStartDate("");
      }
    }

    setFormData({ ...formData, ...from });

  }

  const getDataList = async () => {
    const resp = await getCategoryDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
    const resp1 = await projectListingAction();
    if (resp.code === 200) {
      setProjectLst(resp1.data);
    }
  }
  const getSceneList = async () => {
    const resp = await listSceneScheduleAction();
    if (resp.code === 200) {
      setSceneList(resp.data.sort((a, b) => new Date(a.startDate) - new Date(b.startDate)));
    }

    const Sresp = await getScheduleListAction();
    if (Sresp.code === 200) {
      setScheduleList(Sresp.data);
    }
  }
  const getList = async () => {
    const resp = await getLocationList();
    if (resp.code === 200) {
      setLocationList(resp);
    }
    const resp1 = await listSceneScheduleAction();
    if (resp.code === 200) {
      setSceneData(resp1)
    }
    const resp2 = await getVendorDataAction();
    if (resp.code === 200) {
      setVendorData(resp2)
    }
  }


  const RadialSize = 110;

  useEffect(() => {
    getList();
    getDataList();
    getSceneList();
    ReamainingDys();
  }, []);

  useEffect(() => {
  }, [projct,]);

  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    let formErrorsData = formErrors;

    let formDataData = formData;
    let file = event.target.files[0];
    setFile(URL.createObjectURL(event.target.files[0]));
    if (!file && file === undefined) {
      return false;
    }
    var fileName = (file && file.name ? file.name : '');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

    if (file.size > 20971520) {
      formErrorsData[elename] = "File size not greater then 20MB.";
    } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

      formErrorsData[elename] = "";
      formErrorsData["preview"] = "";
      formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
      formDataData['fileType'] = extensions;
      formDataData[elename] = event.target.files[0];
      setFormData({ ...formData, ...formDataData });
    } else {
      formErrorsData[elename] = "File extensions doesn't match.";

    }
    setFormErrors({ ...formErrors, ...formErrorsData });
  }

  useEffect(() => {
    // let startDate = (moment(ldata.startDate).format("yyyy-MM-DD"))
    // let endDate = (moment(ldata.endDate).format("yyyy-MM-DD"))
    // setFormData({ name: ldata.name, country: ldata.country, state: ldata.state, city: ldata.city, locationName: ldata.locationName, locationTypeId: ldata.locationTypeId, genreId: ldata.genreId, paymnetStatus: ldata.paymnetStatus, dayWise: ldata.dayWise, categoryId: ldata.categoryId, subCategoryId: ldata.subCategoryId, locationDesc: ldata.locationDesc, pricing: ldata.pricing, hourly: ldata.hourly, permissionRequired: ldata.permission, permissionFrom: ldata.permissionFrom, startDate: startDate, endDate: endDate, expense: ldata.expense, image: ldata.image, intExt: ldata.intExt, dayNight: ldata.dayNight, _id: ldata._id, longitute: ldata.longitute, latitude: ldata.latitude });
  }, [])

  console.log(projct)


  return (
    <div className='pt-4'>
      <Card>
        <Card.Body className='crdBdy'>
          <Row className="mt-3 pt-2">
            <Col lg={8}>
              <Form className="m-0" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                <Row>
                  <Col lg={2} md={4} className="txt col-6">
                    <Form.Group className="mb-3" controlId="endDate">
                      <Form.Control className="dshForm frmcnt frm1" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} placeholder='End Date' onChange={e => handleChange('endDate', e)} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid End Date.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} className="txt col-6">
                    <Form.Group className="mb-3 " controlId="startDate">
                      <Form.Control className="dshForm frmcnt frm1" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} placeholder='Start Date' onChange={e => handleChange('startDate', e)} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Start Date.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} className="txt col-6">
                    <Form.Group className="mb-3" controlId="hotelName">
                      <Form.Select className="brdr dshForm frmcnt" aria-label="Default select example"
                        placeholder="Hotel Name" onChange={e => handleChange('hotelName', e)} value={formData.hotelName ? formData.hotelName : ""} required>
                        <option value="">Hotel Name</option>
                        <option value="Sherreton">Sherreton</option>
                        <option value="Taj">Taj</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a Valid Permission From.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} className="txt col-6">
                    <Form.Group className="mb-3" controlId="state">
                      <Form.Select className="brdr selectcountry dshForm frmcnt" aria-label="Default select example"
                        placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                        <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} />
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select State.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={4} className="txt col-6">
                    <Form.Group controlId="city">
                      <Form.Select className="brdr selectcountry dshForm mb-3 frmcnt" aria-label="Default select example"
                        placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                        <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select City.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={2} className=''>
                    <Button type='submit' className="bnt bnt-yellow mybtn21">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>

          <Row className='mt-2'>
            <Col lg={8}>
              <Row className='me-3 mt-3'>
                <Col lg={3} md={4} sm={6}>
                  <Card className='dsbCard'>
                    <Card.Body className='proDtlBdy'>
                      <ProgressBar width="1.0702" height="1.1133" proData={preProData} per='%' />
                      <Card.Text style={{ fontSize: '10px', margin: '0px 0px 0px 15px' }}>
                        Pre Production
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Card className='dsbCard'>
                    <Card.Body className='proDtlBdy'>
                      <ProgressBar width="1.1702" height="1.2333" proData={proData} per='%' />
                      <Card.Text style={{ fontSize: '10px', margin: '5px 0px 0px 25px' }}>
                        Production
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Card className='dsbCard'>
                    <Card.Body className='proDtlBdy'>
                      <ProgressBar width="1.1702" height="1.2333" proData={postproData} per='%' />
                      <Card.Text style={{ fontSize: '10px', margin: '5px 0px 0px 15px' }}>
                        Post Production
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Card className='dsbCard'>
                    <Card.Body className='proDtlBdy'>
                      <ProgressBar width="1.1702" height="1.2333" proData={rmDays} per='' />

                      <Card.Text style={{ fontSize: '10px' }}>
                        Reamaining Days
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <Card className='dsbCard'>
                    <Card.Body className='proDtlBdy'>
                      <ProgressBar width="1.1702" height="1.2333" proData={teams} per='' />
                      <Card.Text style={{ fontSize: '10px', margin: '5px 0px 0px 35px' }}>
                        Teams
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className='wdth'>
                    <Card className='dsbCard'>
                      <Card.Body className='proDtlBdy'>
                        <div className='d-flex mt-4'><Card.Img className='dsbIcn' variant="top" src={place} /> <h6 className='mt-1'>{locationList && locationList.count}</h6></div>
                        <Card.Text style={{ fontSize: '12px', margin: '0px 0px 5px 25px' }}>
                          Location
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className='wdth'>
                    <Card className='dsbCard'>
                      <Card.Body className='proDtlBdy'>
                        <div className='d-flex mt-4'>
                          <Card.Img className='dsbIcn' variant="top" src={filter} />
                          <h6 className='mt-1'>{sceneData && sceneData.count}</h6></div>
                        <Card.Text style={{ fontSize: '12px', margin: '0px 0px 5px 30px' }}>
                          Scene
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className='wdth'>
                    <Card className='dsbCard'>
                      <Card.Body className='proDtlBdy'>
                        <div className='d-flex mt-4'>
                          <Card.Img className='dsbIcn' variant="top" src={people} />
                          <h6 className='mt-1'>{vendorData && vendorData.count}</h6></div>
                        <Card.Text style={{ fontSize: '12px', margin: '0px 0px 5px 25px' }}>
                          Vendors
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6}>
                  <div className='wdth'>
                    <Card className='dsbCard'>
                      <Card.Body className='proDtlBdy'>
                        <div className='d-flex mt-4'>
                          <Card.Img className='dsbIcn' style={{ marginLeft: "15px" }} variant="top" src={people} />
                          <h6 className='mt-1'>250 Cr</h6></div>
                        <Card.Text style={{ fontSize: '12px', margin: '0px 0px 5px 25px' }}>
                          Budget
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}><h6>On Goinig Tasks</h6></Col>
                <Col lg={6}>
                  <Card className='dsbCard'>
                    <Card.Body className="crdBdy">
                      <Card.Text style={{ fontSize: '15px', margin: '10px 0px 0px 10px', color: 'rgba(94, 94, 94, 1)' }}>
                        Location selection
                      </Card.Text>
                      <p style={{ color: 'rgba(153, 159, 174, 1)', fontSize: '12px', margin: '5px 0px 0px 10px' }}>Progress</p>
                      <div className='outer'><div className='inner' style={width1}></div><div className='text-end fntSz'>{width1.width}</div></div>
                      <Card.Img className='temp-Img' variant="top" src={assign1} />

                    </Card.Body>
                  </Card>

                  <Card className='dsbCard'>
                    <Card.Body className="crdBdy">
                      <Card.Text style={{ fontSize: '15px', margin: '10px 0px 0px 10px', color: 'rgba(94, 94, 94, 1)' }}>
                        Vendors
                      </Card.Text>
                      <p style={{ color: 'rgba(153, 159, 174, 1)', fontSize: '12px', margin: '5px 0px 0px 10px' }}>Progress</p>
                      <div className='outer'><div className='inner' style={width2}></div><div className='text-end fntSz'>{width2.width}</div></div>
                      <Card.Img className='temp-Img' variant="top" src={assign1} />

                    </Card.Body>
                  </Card>

                </Col>
                <Col lg={6}>
                  <Card className='dsbCard'>
                    <Card.Body className="crdBdy">
                      <Card.Text style={{ fontSize: '15px', margin: '10px 0px 0px 10px', color: 'rgba(94, 94, 94, 1)' }}>
                        Cast selection
                      </Card.Text>
                      <p style={{ color: 'rgba(153, 159, 174, 1)', fontSize: '12px', margin: '5px 0px 0px 10px' }}>Progress</p>
                      <div className='outer'><div className='inner' style={width3}></div><div className='text-end fntSz'>{width3.width}</div></div>
                      <Card.Img className='temp-Img' variant="top" src={assign1} />

                    </Card.Body>
                  </Card>
                  <Card className='dsbCard'>
                    <Card.Body className="crdBdy">
                      <Card.Text style={{ fontSize: '15px', margin: '10px 0px 0px 10px', color: 'rgba(94, 94, 94, 1)' }}>
                        Scene
                      </Card.Text>
                      <p style={{ color: 'rgba(153, 159, 174, 1)', fontSize: '12px', margin: '5px 0px 0px 10px' }}>Progress</p>
                      <div className='outer'><div className='inner' style={width4}></div><div className='text-end fntSz'>{width4.width}</div></div>
                      <Card.Img className='temp-Img' variant="top" src={assign1} />

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Card className='main1'>
                    <Card.Body className='crdBdy'>
                      <Row className='mt-3'>
                        <Col lg={8}><p className='twCrdHd'>Upcomming Meetings</p></Col>
                        <Col lg={4}><p className='twCrd hand'>See all</p></Col>
                      </Row>
                      <div className='mainScroll'>
                        <div className='subScroll'>
                          <Card className='subCrd subCrd1 me-3'>
                            <Card.Body className='subCrdBdy'>
                              <Row>
                                <Col lg={9} className='pe-0'><p className='parasubC'>Meeting regarding the script</p></Col>
                                <Col lg={3}><p className='paraCrdTm'>12:00 PM</p></Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <p className='paraCrdmain'>Lorem ipsum dolor sit amet, consectetur adipist. Bibendu ornare nulla sit.</p>
                                </Col>
                                <Col lg={4}><Button className='subCrdBtn'>Set Reminder</Button></Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          <Card className='subCrd subCrd1 me-3'>
                            <Card.Body className='subCrdBdy'>
                              <Row>
                                <Col lg={9} className='pe-0'><p className='parasubC'>Meeting regarding the script</p></Col>
                                <Col lg={3}><p className='paraCrdTm'>12:00 PM</p></Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <p className='paraCrdmain'>Lorem ipsum dolor sit amet, consectetur adipist. Bibendu ornare nulla sit.</p>
                                </Col>
                                <Col lg={4}><Button className='subCrdBtn'>Set Reminder</Button></Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          <Card className='subCrd subCrd1 me-3'>
                            <Card.Body className='subCrdBdy'>
                              <Row>
                                <Col lg={9} className='pe-0'><p className='parasubC'>Meeting regarding the script</p></Col>
                                <Col lg={3}><p className='paraCrdTm'>12:00 PM</p></Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <p className='paraCrdmain'>Lorem ipsum dolor sit amet, consectetur adipist. Bibendu ornare nulla sit.</p>
                                </Col>
                                <Col lg={4}><Button className='subCrdBtn'>Set Reminder</Button></Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          <Card className='subCrd subCrd1 me-3'>
                            <Card.Body className='subCrdBdy'>
                              <Row>
                                <Col lg={9} className='pe-0'><p className='parasubC'>Meeting regarding the script</p></Col>
                                <Col lg={3}><p className='paraCrdTm'>12:00 PM</p></Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <p className='paraCrdmain'>Lorem ipsum dolor sit amet, consectetur adipist. Bibendu ornare nulla sit.</p>
                                </Col>
                                <Col lg={4}><Button className='subCrdBtn'>Set Reminder</Button></Col>
                              </Row>
                            </Card.Body>
                          </Card>

                          <Card className='subCrd subCrd1 me-3'>
                            <Card.Body className='subCrdBdy'>
                              <Row>
                                <Col lg={9} className='pe-0'><p className='parasubC'>Meeting regarding the script</p></Col>
                                <Col lg={3}><p className='paraCrdTm'>12:00 PM</p></Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <p className='paraCrdmain'>Lorem ipsum dolor sit amet, consectetur adipist. Bibendu ornare nulla sit.</p>
                                </Col>
                                <Col lg={4}><Button className='subCrdBtn'>Set Reminder</Button></Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>

                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className='main1'>
                    <Card.Body className='dsbTbl'>
                      <Row className='mt-3'>
                        <Col lg={8}><p className='twCrdHd'>My Task</p></Col>
                        <Col lg={4}><p className='twCrd hand'>See all</p></Col>
                      </Row>
                      <Row className='mainScroll'>

                        <Table className='subScroll'>

                          <tbody>
                            {MyTask && MyTask.length > 0 && MyTask.map((val, itm) => {
                              return <Fragment key={itm} >
                                <tr className='dsbrdTbl'>
                                  <td style={{ fontSize: "12px" }}>{val.sNo}</td>
                                  <td style={{ fontSize: "12px" }}>{val.task}</td>
                                  <td style={{ fontSize: "12px" }}>{(val.msg > 0) ? <span className='d-flex'><img className='me-1' src={sms} />{val.msg}</span> : ''}</td>
                                  <td style={{ fontSize: "12px" }}>Details</td>
                                  <td style={{ fontSize: "12px" }} className='hand'>{val.status === 1 ? <><img style={{ width: "20px" }} src={actv} /></> : <><img style={{ width: "20px" }} src={psv} /></>}</td>
                                </tr>
                              </Fragment>
                            })}
                          </tbody>
                        </Table>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className='main12 table-container col-md-12'>
                    <div className='todoTbl'>To do list</div>
                    <Table className='subScroll'>

                      <thead>
                        <th className='todohd'></th>
                        <th></th>
                        <th className='todohd'>Task</th>
                        <th></th>
                        <th></th>
                        <th className='todohd'>Report to</th>
                        <th className='todohd'>Due date</th>
                        <th className='todohd'>Assigned to</th>
                      </thead>

                      <tbody>
                        {ToDoList && ToDoList.length > 0 && ToDoList.map((val, itm) => {
                          return <Fragment key={itm} >

                            <tr className='dsbrdTbl'>
                              <td style={{ fontSize: "12px" }}>{val.sNo}</td>
                              <td style={{ fontSize: "12px" }} className='hand'>{val.status === 1 ? <><img style={{ width: "20px" }} src={actv} /></> : <><img style={{ width: "20px" }} src={psv} /></>}</td>
                              <td style={{ fontSize: "12px" }}>{val.task}</td>
                              <td style={{ fontSize: "12px" }}>{(val.msg > 0) ? <span className='d-flex'><img className='me-1' src={sms} />{val.msg}</span> : ''}</td>
                              <td style={{ fontSize: "12px" }}>Details</td>
                              <td style={{ fontSize: "12px" }}>{val.reportTo}</td>
                              <td style={{ fontSize: "12px" }}>{val.dueDate}</td>
                              <td style={{ fontSize: "12px" }}><img src={assign1} style={{ width: "80px" }} /></td>
                            </tr>
                          </Fragment>
                        })}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <Row className='text-end mt-2'>
                <Col className='col-lg-4 col-2 col-md-2 text-start'>
                  <h6>Alerts</h6>
                </Col>
                <Col className='col-lg-8'>
                  {!seeAll && <>
                    <p className="hand" onClick={e => { setSeeAll(true) }}>See All</p></>}
                  {seeAll && <>
                    <p className="hand" onClick={e => { setSeeAll(false) }}>See less</p></>}
                </Col>
              </Row>
              <Card className='notCard mb-4'>
                <Card.Body className="crdBdy">
                  <Row>
                    <Col className='col-lg-8 col-md-8 col-9'>
                      <Card.Text className='notiTxt'>
                        Camera not arranged for the upcoming shoot
                      </Card.Text>
                    </Col>
                    <Col className='col-lg-4 col-md-4 col-3'>
                      <Card.Text className='notiTxt2 text-end'>
                        1 day left
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {seeAll && <>
                <Card className='notCard mb-4'>
                  <Card.Body className="crdBdy">
                    <Row>
                      <Col className='col-lg-8 col-md-8 col-9'>
                        <Card.Text className='notiTxt'>
                          Camera not arranged for the upcoming shoot
                        </Card.Text>
                      </Col>
                      <Col className='col-lg-4 col-md-4 col-3'>
                        <Card.Text className='notiTxt2 text-end'>
                          1 day left
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className='notCard mb-4'>
                  <Card.Body className="crdBdy">
                    <Row>
                      <Col className='col-lg-8 col-md-8 col-9'>
                        <Card.Text className='notiTxt'>
                          Camera not arranged for the upcoming shoot
                        </Card.Text>
                      </Col>
                      <Col className='col-lg-4 col-md-4 col-3'>
                        <Card.Text className='notiTxt2 text-end'>
                          1 day left
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className='notCardBtm mb-4'>
                  <Card.Body className="crdBdy">
                    <Row>
                      <Col className='col-lg-8 col-md-8 col-9'>
                        <Card.Text className='notiTxt'>
                          Camera not arranged for the upcoming shoot
                        </Card.Text>
                      </Col>
                      <Col className='col-lg-4 col-md-4 col-3'>
                        <Card.Text className='notiTxt2 text-end'>
                          1 day left
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card className='notCardBtm mb-4'>
                  <Card.Body className="crdBdy">
                    <Row>
                      <Col className='col-lg-8 col-md-8 col-9'>
                        <Card.Text className='notiTxt'>
                          Camera not arranged for the upcoming shoot
                        </Card.Text>
                      </Col>
                      <Col className='col-lg-4 col-md-4 col-3'>
                        <Card.Text className='notiTxt2 text-end'>
                          1 day left
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>}

              <Row className='text-end mt-2'>
                <Col className='col-lg-4 col-2 col-md-2'>
                  <h6>Schedule</h6>
                </Col>
                <Col className='col-lg-8 ' >
                  {!seeAll1 && <>
                    <p className="hand" onClick={e => { setSeeAll1(true) }}>See All</p></>}
                  {seeAll1 && <>
                    <p className="hand" onClick={e => { setSeeAll1(false) }}>See less</p></>}
                </Col>
              </Row>
              {sceneList && sceneList.length > 0 && sceneList.slice(0, 1).map((val, ind) => {
                return val.sceneData && val.sceneData.length > 0 && ((val.sceneData).slice(0, 1)).map((v, i) => {
                  return (
                    <Card key={i} className='scdlCrd mb-4 scdl'>
                      <Card.Body className="crdBdy">
                        <Row>
                          <Col className='scdlsubCrd scdl1  col-lg-1 col-1 col-md-1'></Col>
                          <Col className=' mt-1 col-lg-11 col-md-11 col-11'>
                            <Row>
                              <Col className='scDt mt-1 col-lg-12 col-7 col-md-7'>{moment(val.startDate).format("DD-MM-yyyy")}  {moment((v.estTime), 'HH:mm').format('hh:mm A')}</Col>
                              <Col className='scTxt mt-1 col-lg-12 col-12 col-md-12 cardText'>{v.synopsis}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  )
                })
              })}

              {seeAll1 && <>
                {sceneList && sceneList.length > 0 && (sceneList.slice(1, (sceneList.length))).map((val, ind) => {
                  return val.sceneData && val.sceneData.length > 0 && ((val.sceneData).slice(0, (sceneList.length))).map((v, i) => {
                    return (
                      <Card key={i} className='scdlCrd mb-4 scdl'>
                        <Card.Body className="crdBdy">
                          <Row>
                            <Col className='scdlsubCrd scdl1  col-lg-1 col-1 col-md-1'></Col>
                            <Col className=' mt-1 col-lg-11 col-md-11 col-11'>
                              <Row>
                                <Col className='scDt mt-1 col-lg-12 col-7 col-md-7'>{moment(val.startDate).format("DD-MM-yyyy")}  {moment((v.estTime), 'HH:mm').format('hh:mm A')}</Col>
                                <Col className='scTxt mt-1 col-lg-12 col-12 col-md-12 cardText'>{v.synopsis}</Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    )
                  })
                })}

              </>}
              <Card className='allEvCrd mb-4 table-container col-md-12'>


                <Tabs
                  id="controlled-tab-example uniId"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  defaultActiveKey="Current Events"
                  className="mb-3 uniEvnt"
                  style={{ fontSize: "10px" }}
                >
                  <Tab eventKey="Current Events" title="Current Events" style={{}}>
                    <Card.Body className='evTpScroll evMainBdy crdBdy'>
                      {UpcommingEvents && UpcommingEvents.length > 0 && UpcommingEvents.map((val, evnt) => {
                        return (
                          <Row className='evBtmScroll'>
                            <Col lg={1} className='p-0 mt-3'>
                              <p className='dayDate mb-0'>{val.date}</p>
                              <p className='daydate1'>{val.day}</p>
                            </Col>
                            <Col lg={1} className='p-0 m-auto'>
                              <img className='evline' src={evline} />
                            </Col>
                            <Col lg={10} className='p-0'>
                              <Card className='evcrd'>
                                <Card.Body style={{ padding: "0px" }}>
                                  <Row>
                                    <Col lg={4}><img className='evImg' src={assign2} /></Col>
                                    <Col lg={8}>
                                      <p className='evsubCrd1 truncate-text'>{val.eventName}</p>
                                      <p className='evsubCrd'>{val.from}</p>
                                      <p className='evsubCrd'>{val.location}</p>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        )
                      })}
                    </Card.Body>
                  </Tab>
                  <Tab eventKey="Upcoming Events" title="Upcoming Events">
                    No Upcomming Events Right Now
                  </Tab>
                  <Tab eventKey="Past Events" title="Past Events">
                    Now Past Events Right Now
                  </Tab>
                </Tabs>



                {/* <Row className='allEvnt evNm11 evNm1'>
              <Col className='col-lg-4 col-3 hand evNm evNm1 p-0'>Current Events</Col>
              <Col className='col-lg-4 col-3 hand evNm evNm1 p-0'>Upcoming Events</Col>
              <Col className='col-lg-4 col-3 hand evNm p-0'>Past Events</Col>
            </Row> */}

              </Card>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg={5}>
              <Card className=''>
                <Card.Body>
                  {/* onMouseEnter={this.onPieEnter} */}
                  <Card.Text><p className='mt-2 taskOvr'>Tasks Overview</p></Card.Text>
                  <PieChart width={330} height={295} className='rechgartpieChrt' >
                    <Legend
                      content={<CustomLegend />}
                      height={36}
                      iconType="square"
                      layout="horizontal"
                      verticalAlign="bottom"
                      iconSize={10}
                      padding={5}
                      className='rechartLegent'
                    />
                    <Pie
                      data={data}
                      cx={160}
                      cy={120}
                      innerRadius={60}
                      outerRadius={110}
                      fill="#8884d8"
                      paddingAngle={0}
                      dataKey="value"
                      className='rechartPie'
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.fill} />
                      ))}
                      <Label
                        value={`${totalValue} Tasks`}
                        position="center"
                        fill="#000"
                        fontSize={16}
                      />
                    </Pie>
                  </PieChart>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={7}>
              <Card className=''>
                <Card.Body style={{ padding: "23px 5px 46px 5px" }}>
                  <Form>
                    <Row className='mt-3'>
                      <Col lg={3} style={{ color: "rgba(94, 94, 94, 1)", fontSize: "12px", paddingTop: "6px" }}>Project Tracking</Col>
                      <Col lg={3}>
                        <Form.Group>
                          <Form.Control className='areaFrm' type='date' />
                        </Form.Group>
                      </Col>
                      <Col lg={3}>
                        <Form.Group>
                          <Form.Select className='areaFrm' onChange={(e) => setProjct(JSON.parse(e.target.value))}>
                            <option disabled>Project Name</option>
                            {projectLst && projectLst.length > 0 && projectLst.map((val, itm) => {

                              {
                                sceneData && sceneData.length > 0 && sceneData.map((v, i) => {
                                })
                              }
                              return <option value={JSON.stringify(val)} className='todohd'>{val.name}</option>

                            })}
                          </Form.Select>
                        </Form.Group></Col>
                      <Col lg={3}><Button className='delayBtn'>Delayed</Button></Col>
                    </Row>
                  </Form>
                  <Row className='mt-3 pe-5'>
                    <CustomizedAreaChart />
                  </Row>

                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <Card className='main12 table-container col-md-12'>
                <div className='todoTbl'>Reports</div>
                <Table className='subScroll me-3'>

                  <thead>
                    <th className='todohd'>
                      <select className='proLst' onChange={(e) => setProjct(JSON.parse(e.target.value))}>
                        <option disabled>Project Name</option>
                        {projectLst && projectLst.length > 0 && projectLst.map((val, itm) => {

                          {
                            sceneData && sceneData.length > 0 && sceneData.map((v, i) => {
                            })
                          }
                          return <option value={JSON.stringify(val)} className='todohd'>{val.name}</option>

                        })}
                      </select>
                    </th>
                    <th className='todohd'>Task</th>
                    <th className='todohd'>Start Date</th>
                    <th className='todohd'>End Date</th>
                    <th className='todohd'>Schedule</th>
                    <th className='todohd'>Location</th>
                    <th className='todohd'>Assigned to</th>
                    <th className="todohd">Status</th>
                  </thead>

                  <tbody>
                    <tr className='dsbrdTbl'>
                      <td style={{ fontSize: "14px" }}>{projct && projct.name}</td>
                      <td style={{ fontSize: "14px" }} className='hand'>Task</td>
                      <td style={{ fontSize: "14px" }}>Start Date</td>
                      <td style={{ fontSize: "14px" }}>End Date</td>
                      <td style={{ fontSize: "14px" }}>Details</td>
                      <td style={{ fontSize: "14px" }}>Indore</td>
                      <td style={{ fontSize: "14px" }}>Abhinav</td>
                      <td style={{ fontSize: "14px" }}>Active</td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  )
}

export default memo(DashBoard);