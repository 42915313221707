import React, { useState } from "react";
import { Col, Container, Form, Row, Button, Card } from "react-bootstrap";
import { setAlert } from '../../slices/home.js';
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { importScriptAction } from "../../action/Admin.action";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';



function ImportScripts() {

    const [previewUrl, setPreviewUrl] = useState(null);
    const [showButton, setShowButton] = useState(false);
    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const navigate = useNavigate();


    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            //setValidated(false);
            setDisabled(true);

            let postData = new FormData();

            let formDataData = { ...formData };


            var projectId = localStorage.getItem("projectId");

            postData.append('projectId', projectId);
            postData.append('scriptFile', formDataData.file);

            let msg = "You have successfully script imported.";


            // dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
            setDisabled(false);
            let resp = await importScriptAction(postData);

            if (resp.code === 200) {
                toast.success(msg);
                setFormData({})
                navigate(`${process.env.PUBLIC_URL}/addOneLinerScript`);
            } else {
                toast.error(resp.msg);
                setFormData({})
            }
            return false;
        }
    };

    const fileChangedHandler = (event) => {
        setShowButton(true);
        event.preventDefault();
        const file = event.target.files[0];
        setFormData({ file: file });
        setPreviewUrl(URL.createObjectURL(file));
    };

    return (
        <div className="pt-4">
            <Card>
                <Card.Body className="crdBdy">
                    <Container className="mt-3 pt-2">
                        <Row  className="pb-2">
                        <Col lg={8}><h6 className="crdHd1">Import Your Script</h6></Col>
                          <Col lg={4}>
                                <Link to={`${process.env.PUBLIC_URL}/projectDetails`} className="p-2">&laquo; To Project</Link>
                                <Link to={`${process.env.PUBLIC_URL}/addOneLinerScript`} className="p-2"> To Scene Shedule &raquo;</Link>
                            </Col>
                        </Row>
                        <hr className="mrZero"/>
                       {/* <Row className="mt-3 pt-3 text-end">
                            <Col lg={12}>
                                <Link to={`${process.env.PUBLIC_URL}/projectDetails`} className="p-2">&laquo; To Project</Link>
                                <Link to={`${process.env.PUBLIC_URL}/addOneLinerScript`} className="p-2"> To Scene Shedule &raquo;</Link>
                            </Col>
                        </Row>*/}
                        <Row className="mt-3 pt-3">

                            <Col lg={12}><p style={{ fontSize: '12px' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p></Col>
                            <Col lg={12} className="mt-4">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                            <input className="form-control frmcnt form-control-lg form-control-sm" id="formFileLg" accept=".doc, .pdf, .txt" type="file" onChange={e => fileChangedHandler(e)} required />
                                            <p className="msg1 mb-0">Supported formats: .doc, .docx, .txt, .pdf</p>
                                            <p className="msg1">File Size: upto 20MB</p>
                                            <Row className="justify-content-center">
                                                <Col lg={3}>
                                                    {showButton && <Button variant="dark" type="submit" className="lgbt" disabled={isDisabled}>Submit</Button>
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                    <Row className="justify-content-center mt-5">
                        <Col lg={10}>
                            {showButton && (
                                <Card>
                                    <Card.Header as="h5">Preview Your Script File</Card.Header>
                                    <Card.Body>
                                        <Card.Text>
                                            <iframe title="preview" src={previewUrl} width="100%" height="512px" />
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ImportScripts;