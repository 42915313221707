import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/home';
import { Link } from "react-router-dom";
import { addEditCrewAction } from '../../action/Admin.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { getRoleDataAction } from '../../action/Admin.action';
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import Header from '../../Layout/Header';


const AddDirMembers = (props) => {

    const dispatch = useDispatch();
    const masters = useSelector((state) => state.home.masters);
    const [formData, setFormData] = useState({ department: "656b8e49f477e2b35e434afc" });
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [role, setRole] = useState({});

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let msg = "You have successfully created member.";
            let formDataData = {...formData};
            let item = formData.role;
            let list = [];
                  for(let j in item){
                      list.push(item[j].value);}
                      formDataData.role=list;
            let resp = await addEditCrewAction(formDataData);
            setDisabled(false);

            if (resp.code === 200) {
                props.getList();
                setValidated(false);
                if (props.getList()) {

                }
                toast.success(resp.msg);
                // dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                setFormData({ department: "656b8e49f477e2b35e434afc" });
            } else {
                setDisabled(false);
                toast.error(resp.msg);
                // dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }

            return false;
        }
    };

    const getList = async (event) => {
        let resp = await getRoleDataAction();
        if (resp.code == 200) {
            setRole(resp.data)
            if (resp.data && resp.data.length) {

                let artiests = resp.data;
                let roleList = [];
                for (let i in artiests) {
                    let row = artiests[i];
                    if(row.departmentId=="656b8e49f477e2b35e434afc"){
                    roleList.push({ label: row.name, value: row._id });
                    }
                }
                setRole(roleList);
            }
        }
    }

    // const handleChange = (post) => {

    //     let name = post.name;
    //     let event = post.event;
    //     let from = { ...formData };

    //     from[name] = event.target.value;

    //     setFormData({ ...formData, ...from });
    // }

    const handleChange = (name, event, extra = "") => {

        let from = { ...formData };

        if (extra === 'multi') {

            from[name] = event;

        } else if (name === 'ScheduleIds') {

            let arr = from[name] ? from[name] : [];

            if (event.target.checked) {
                arr.push(event.target.value);
            } else {
                const index = arr.indexOf(event.target.value);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }

            from[name] = arr;

        } else {
            from[name] = event.target.value;
        }

        setFormData({ ...formData, ...from });

    };

    useEffect(() => {
        getList();

        // if (props.editData && props.editData._id) {
        //     let edit = props.editData;
        //     setFormData({ _id: edit._id, name: edit.name, number: edit.number, designation: edit.designation, department: edit.department, email: edit.email, mobile: edit.mobile, role: edit && (edit.roleDocs).map((v,i)=>{
        //         let roleList1 = [];
        //         return (
        //             roleList1.push({ label: v.name, value: v._id })
        //         )
        //         return roleList1;
        //     }) });
        // }
        if (props.editData && props.editData._id) {
            let edit = props.editData;
            const roleList1 = edit.roleDocs.map((v, i) => ({
                label: v.name,
                value: v._id
            }));
            setFormData({
                _id: edit._id,
                name: edit.name,
                number: edit.number,
                designation: edit.designation,
                department: edit.department,
                email: edit.email,
                mobile: edit.mobile,
                role: roleList1
            });
        }
        if(props.refresh===true){
            setFormData({department: "656b8e49f477e2b35e434afc"})
        }

    }, [props.editData])

    useEffect(() => {
        {
            role && role.length > 0 && role.map((item, index) => {
                if (item.departmentId == "656b8e49f477e2b35e434afc") {
                    return <option value={item._id} key={index}>{item.name}</option>
                }
            })
        }
    }, [])

    console.log(formData)

    return (<Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>

        <Form.Group className="mb-3 txt" controlId="name">
            <Form.Control
                type="text"
                placeholder="Name"
                pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                minLength="2"
                className='frmcntadd'
                maxLength="25"
                value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required />
            <Form.Control.Feedback type="invalid">
                Please provide a valid name.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="department">
            <Form.Select className="locked frmcntadd" value="656b8e49f477e2b35e434afc" onChange={e => handleChange('department', e)} required disabled>
                <option value="">Direction Department</option>
                {masters && masters.department && masters.department.length > 0 && masters.department.map((item, index) => {
                    return <option value="656b8e49f477e2b35e434afc" key={index}>{item.name}</option>
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Please provide a valid department.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="designation">

            <Form.Select className=" frmcntadd" value={formData.designation ? formData.designation : ""} onChange={e => handleChange('designation', e)} required>
                <option value="">Select Designation</option>

                {masters && masters.designation && masters.designation.length > 0 && masters.designation.map((item, index) => {
                    if ("656b8e49f477e2b35e434afc" == item.departmentId) {
                        return <option value={item._id} key={index}>{item.name}</option>

                    }
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Please provide a valid designation.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='role'>
            <Select
                value={formData.role ? formData.role : []}
                placeholder="Select Role"
                isMulti
                options={role}
                className="basic-multi-select mselect"
                classNamePrefix="select Role"
                onChange={e => handleChange('role', e, 'multi')}
                required
            />

            {validated === true && ((!formData.role) || formData.role && formData.role.length === 0) &&
                <p className="err">This field is required</p>}
        </Form.Group>

        <Form.Group className="mb-3" controlId="mobile">
            <Form.Control
                type="text"
                pattern='^[6-9][0-9]{9}$'
                placeholder="Mobile"
                className='frmcntadd'
                value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange('mobile', e)} minLength="10" maxLength="10" required />
            <Form.Control.Feedback type="invalid">
                Please provide a valid mobile.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3 " controlId="email">
            <Form.Control
                type="text"
                placeholder="Email"
                className='frmcntadd'
                pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
            <Form.Control.Feedback type="invalid">
                Please provide a valid email.
            </Form.Control.Feedback>
        </Form.Group>

        <Row className="mt-4 mb-4 g-2">
            <Col lg={4}>
                <Button type="submit" className="bnt1 btn defaultBtn btn btn-primary"  disabled={isDisabled} onSubmit={handleSubmit}>
                    Save
                </Button>
            </Col>
            {/* <Col lg={4} className="mt-3">
                <span className='hand' title='Refresh' onClick={() => { setFormData({ department: "656b8e49f477e2b35e434afc" });setValidated(false) }}><FontAwesomeIcon size='lg' icon={faArrowsRotate} /></span>
            </Col> */}
        </Row>
    </Form>
    );
}

export default memo(AddDirMembers);
