import React, { useState, Fragment, } from "react";
import { Col, Container, Row } from "reactstrap";
import "../../../assets/css/style.css";
import Selectingteam from '../../../assets/images/frontPageImg/Selecting-team.png';
import userIcon from '../../../assets/images/frontPageImg/userIcon.png';
import arrowIcon from "../../../assets/images/frontPageImg/arrowIcon.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const SelectUserType = () => {
    const [selected, setSelected] = useState(null);
    const navigate = useNavigate();

    const handleUserTypeSelect = (type) => {
        setSelected(type);
    };

    const handleNextClick = async () => {
        if (selected === null) {
            toast.error("Please select a user type before proceeding.");
            return;
        }

        try {

            toast.success("User type selected successfully!", {
                autoClose: 2000,
            });

            setTimeout(() => {

                navigate(`${process.env.PUBLIC_URL}/userRegister`, { state: { userType: selected } });
            }, 2000);
        } catch (error) {
            toast.error("Failed to send user type");
        }
    };

    return (
        <Fragment>
            <Container fluid={true} className="p-0">
                <button
                    className="back-button"
                    onClick={() => navigate(-1)}
                    style={{
                        background: 'none',
                        border: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        paddingTop: '30px',
                        marginLeft: '30px'
                    }}
                >
                    <img src={arrowIcon} alt="Back" style={{ width: '15px', height: '15px', }} />
                    <span className="text-white" style={{ marginLeft: '10px' }}>Back</span>
                </button>
                <Container>
                    <Row>
                        <Col xs="12" className="text-center" style={{ height: "50vh" }}>
                            <img src={Selectingteam} alt="" style={{ height: "72%", maxWidth: "100%", }} />
                            <p className="text-white pt-3" >Select user type</p>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">

                        <Col xs={6} md={4} lg={2} className="d-flex flex-column align-items-center">
                            <div
                                className={`user-type-box ${selected === 3 ? "selected-box" : ""}`}
                                onClick={() => handleUserTypeSelect(3)}
                            >
                                <div className="user-icon">
                                    <img src={userIcon} alt="Vendor" />
                                </div>
                            </div>
                            <p className="text-center text-white pt-1">Vendor</p>
                        </Col>


                        <Col xs={6} md={4} lg={2} className="d-flex flex-column align-items-center">
                            <div
                                className={`user-type-box ${selected === 4 ? "selected-box" : ""}`}
                                onClick={() => handleUserTypeSelect(4)}
                            >
                                <div className="user-icon">
                                    <img src={userIcon} alt="Crew" />
                                </div>
                            </div>
                            <p className="text-center text-white pt-1">Crew</p>
                        </Col>


                        <Col xs={6} md={4} lg={2} className="d-flex flex-column align-items-center">
                            <div
                                className={`user-type-box ${selected === 5 ? "selected-box" : ""}`}
                                onClick={() => handleUserTypeSelect(5)}
                            >
                                <div className="user-icon">
                                    <img src={userIcon} alt="Other" />
                                </div>
                            </div>
                            <p className="text-center text-white pt-1">Other</p>
                        </Col>
                    </Row>
                    <div className="text-center mt-2 mb-5">

                        <button
                            type="button"
                            onClick={handleNextClick}
                            style={{ backgroundColor: "#AA1163", width: 250 }}
                            className="btn text-white"
                        >
                            Next
                        </button>
                    </div>

                </Container>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default SelectUserType;
