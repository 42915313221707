import React, { useState, memo, useEffect } from "react";
import { Country, State } from 'country-state-city';
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Table, Button, Form } from "react-bootstrap";
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import progress1 from '../../assets/images/OldProImg/pb/vendorePb/one.png';
import progress2 from '../../assets/images/OldProImg/pb/vendorePb/two.png';
import progress3 from '../../assets/images/OldProImg/pb/vendorePb/three.png';
import vendorIcn from '../../assets/images/OldProImg/vendor/vendorIcn.png';
import { setAlert } from "../../slices/home";
import { addEditVendorAction, getCategoryVendorDataAction, getVendorDataAction } from "../../action/Admin.action";
import AlertBox from "../component/AlertBox";
import { imgPath } from "../common/function";
import { VendorType, VendorRole } from "../common/constant";
// import { useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";



const VendorDetails = () => {
    const [Step, setStep] = useState(1);
    const location = useLocation();
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [category, setCategory] = useState({})
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [file, setFile] = useState("");
    const [vendorList, setVendorList] = useState({})
    const [ldata, setLdata] = useState(location.state)
    const navigate = useNavigate();
    const [counrtNm, setCounrtNm] = useState({});
    const [stdNm, setStdNm] = useState({});


    // const masters = useSelector((state) => state.home.masters);

    const getLocationList = async () => {
        const resp = await getVendorDataAction();
        if (resp.code === 200) {
            setVendorList(resp.data);
        }
    }

    const getCatList = async () => {
        const resp = await getCategoryVendorDataAction();
        if (resp.code === 200) {
            setCategory(resp.data);
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let postData = new FormData();
            for (let key in formData) {
                postData.append(key, formData[key]);
            }

            let stp = Step + 1;
            setStep(stp);
            setValidated(false)
            if (stp == 4) {

                let resp = await addEditVendorAction(postData);
                setDisabled(false);
                if (resp.code === 200) {
                    setFormData(resp.data)
                    setStep(1)
                    setLdata(resp.data)
                    // dispatch(setAlert({ open: true, severity: "success", msg: "Updated Successfully", type: '' }));
                    toast.success("Updated Successfully");
                    handleClose();
                } else {
                    let data = "";
                    if (resp.msg) {
                        data = resp.msg;
                    }
                    setDisabled(false);
                    // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
                    toast.error(data);
                }
                return false;
            }
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });

    }

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {
            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";

        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    useEffect(() => {
        getLocationList();
        getCatList();
        const countriesList = Country.getAllCountries();
        setCounrtNm(countriesList);
        const statesList = State.getStatesOfCountry(ldata.country);
        setStdNm(statesList)
        setFormData({ name: ldata.name, number: ldata.number, numberOfEmployee: ldata.numberOfEmployee, registeredNumber: ldata.registeredNumber, registeredSince: ldata.registeredSince, role: ldata.role, type: ldata.type, image: ldata.image, country: ldata.country, state: ldata.state, city: ldata.city, zipCode: ldata.zipCode, email: ldata.email, address: ldata.address, image: ldata.image, categoryId: ldata.categoryId, subCategoryId: ldata.subCategoryId, _id: ldata._id });
    }, [])

    console.log(formData)

    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy">
                        <Row className="mb-3 pt-3 mb-2">
                            <Col className="col-2 col-lg-6 col-sm-5 col-md-7 "><h6 className="crdHd1">Vendor Information</h6></Col>
                            {/* <Col className="col-7 col-md-4 text-end col-lg-2">
                    <Button className="bnt2 bntCr myCr smsz" onClick={() => { navigate('/vendor-option') }} >Back </Button>
                    </Col> */}
                            <Col className="text-end col-10 col-lg-6 col-md-5 col-sm-4 col" onClick={() => setStep(1)}>
                                <Button className="bnt2 bntCr myCr me-1 btnClre smsz" onClick={() => { navigate(`${process.env.PUBLIC_URL}/vendor-option`) }} >Back </Button>
                                <Button className="bnt2 bntCr myCr smsz btnClre" onClick={() => handleShow()}>Edit Vendor</Button>
                            </Col>
                        </Row>
                        <hr className="mrZero" />

                        <Row className="justify-content-center">


                            <Col lg={8}>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                        <Card.Text className="mt-3">
                                            <Table>
                                                <thead>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="tablData"><b> Name</b></td>
                                                        <td className="tablData">{ldata.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b> Number</b></td>
                                                        <td className="tablData">{ldata.number}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>Number of Employee</b></td>
                                                        <td className="tablData">{ldata.numberOfEmployee}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>Registered Number</b></td>
                                                        <td className="tablData">  {ldata.registeredNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b> E-mail </b></td>
                                                        <td className="tablData">  {ldata.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>Registered Since</b></td>
                                                        <td className="tablData"> {ldata.registeredSince}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>Role</b></td>
                                                        <td className="tablData">{ldata.role}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>Type</b></td>
                                                        <td className="tablData">{ldata.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>Country</b></td>
                                                        <td className="tablData">{counrtNm && counrtNm.length > 0 && counrtNm.map((v, i) => {
                                                            if (v.isoCode == ldata.country) {
                                                                return (
                                                                    v.name
                                                                )
                                                            }
                                                        })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b>State</b></td>
                                                        <td className="tablData">{stdNm && stdNm.length > 0 && stdNm.map((v, i) => {
                                                            if (v.isoCode == ldata.state) {
                                                                return (
                                                                    v.name
                                                                )
                                                            }
                                                        })}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b> City</b></td>
                                                        <td className="tablData">  {ldata.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b> Zip Code</b></td>
                                                        <td className="tablData"> {ldata.zipCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="tablData"><b> Address</b></td>
                                                        <td className="tablData">  {ldata.address}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card className="crdShadZero" style={{ borderRadius: '20px' }}>
                                    <Card.Body className="crdBdy">
                                        <Card.Text className="mt-3 smsz">Vendor Profile Image</Card.Text>
                                        <img className="ltdImg" src={ldata.image == "" ? vendorIcn : imgPath(ldata.image)} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Modal show={show} onHide={handleClose} animation={false}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered>
                                <Modal.Header closeButton className="size1 pb-0 mdlHdr" style={{ borderBottom: " 0 none" }}>
                                    <h6>Vendor</h6>
                                </Modal.Header>
                                <Modal.Body className="pt-0">

                                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                                        {Step == 1 &&

                                            <div>
                                                <Row>
                                            <Col lg={12}>
                                                <img src={progress1} style={{ width: "100%" }}></img>
                                            </Col>
                                        </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="name">
                                                            <Row><Col><Form.Label>Name<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" onChange={e => handleChange('name', e)} maxLength={32} minLength={2} value={formData.name ? formData.name : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Name.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="registeredNumber">
                                                            <Row><Col><Form.Label>Registered Number<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern='^(0*[1-9][0-9]{0,9})$' onChange={e => handleChange('registeredNumber', e)} value={formData.registeredNumber ? formData.registeredNumber : ""} maxLength={10} minLength={10} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Registered Number.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="registeredSince">
                                                            <Row><Col><Form.Label>Registered Since<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="number" onChange={e => handleChange('registeredSince', e)} value={formData.registeredSince ? formData.registeredSince : ""} maxLength={4} minLength={4} min={1700} max={2022} pattern="[0-9]*" required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Select Year Between 1700 to 2022.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="type">
                                                            <Form.Label>Vendor Type<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                onChange={e => handleChange('type', e)} value={formData.type ? formData.type : ""} required>
                                                                <option value="">Select Vendor</option>
                                                                {VendorType && VendorType.length > 0 && VendorType.map((val, ind) => {
                                                                    return (
                                                                        <option value={val.value}>{val.name}</option>
                                                                    )
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Type.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="role">
                                                            <Form.Label>Vendor Role<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                onChange={e => handleChange('role', e)} value={formData.role ? formData.role : ""} required>
                                                                <option value="">Select Vendor Role</option>
                                                                {VendorRole && VendorRole.length > 0 && VendorRole.map((val, ind) => {
                                                                    if (val.subCatOf == formData.type) {
                                                                        return <option value={val.value}>{val.name}</option>
                                                                    }
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Vendor Role.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="email">
                                                            <Row><Col><Form.Label>Email Id<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="email" pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$" onChange={e => handleChange('email', e)} value={formData.email ? formData.email : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Email Id.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-3" controlId="number">
                                                            <Row><Col><Form.Label>Mobile Number<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('number', e)} value={formData.number ? formData.number : ""} pattern='^[6-9][0-9]{9}$' maxLength={10} minLength={10} required />

                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Mobile Number.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-3" controlId="numberOfEmployee">
                                                            <Row><Col><Form.Label>Number of Employee<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('numberOfEmployee', e)} value={formData.numberOfEmployee ? formData.numberOfEmployee : ""} pattern='^(0*[1-9][0-9]{0,9})$' maxLength={10} minLength={1} required />

                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Number Of Employee.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row className=" mx-2 mt-3 ">
                                                    {/* <Col lg={8} className="text-end">
                                                <Button variant="secondary" onClick={()=>{handleClose()}} className="btnk" type="submit">
                                                    cancel
                                                </Button>
                                            </Col> */}
                                                    <Col className='col-6 col-lg-4 col-sm-6' >
                                                        <Button className="btnk cldrBtn" type="submit">
                                                            Next
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>

                                        }

                                        {Step == 2 &&
                                            <div>
                                                <Row>
                                            <Col lg={12}>
                                                <img src={progress2} style={{ width: "100%" }}></img>
                                            </Col>
                                        </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="country">
                                                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                                                                <GetAllCountries />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please provide a Country.
                                                                </Form.Control.Feedback>
                                                            </Form.Select>

                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="state">
                                                            <Form.Label>State<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                                                                <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} />
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a State.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="city">
                                                            <Form.Label>City<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                                placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                                                                <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a City.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="address">
                                                            <Row><Col><Form.Label>Address<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" maxLength={120} minLength={2} onChange={e => handleChange('address', e)} value={formData.address ? formData.address : ""} required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Address.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group controlId="zipCode">
                                                            <Row><Col><Form.Label>Postal Code<span className='star'>*</span></Form.Label>
                                                            </Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('zipCode', e)} value={formData.zipCode ? formData.zipCode : ""} maxLength={6} minLength={6} pattern="^(?!0{6})0*[1-9]\d{0,5}$" required />
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Postal Code.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="g-2 mx-2">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>   <Button variant="secondary" className="btnk mt-4 cldrBtn" onClick={e => setStep(Step - 1)}>
                                                        Back
                                                    </Button></Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>   <Button variant="secondary" className="btnk mt-4 cldrBtn" type="submit">
                                                        Next
                                                    </Button></Col>
                                                </Row>

                                            </div>
                                        }

                                        {Step == 3 &&
                                            <div>
                                                <Row>
                                            <Col lg={12}>
                                                <img src={progress3} style={{ width: "100%" }}></img>
                                            </Col>
                                        </Row>
                                                <Row className="mx-2">

                                                    {/* <Col lg={4} className="txt">
                                                <Form.Group controlId="address">
                                                    <Row><Col><Form.Label>Address</Form.Label>
                                                    </Col></Row>
                                                    <Form.Control className="brdr" type="text" onChange={e => handleChange('address', e)} value={formData.address ? formData.address : ""} required/>
                                                    <Form.Control.Feedback type="invalid">
                                                        Please provide a valid Address.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col> */}

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="categoryId">
                                                            <Form.Label>Category<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('categoryId', e)} value={formData.categoryId ? formData.categoryId : ""} required>
                                                                <option value="" >Select Category</option>
                                                                {category && category.length > 0 && category.map((val, ind) => {
                                                                    if (val.categoryId == "000000000000000000000000") {
                                                                        return <option value={val._id} key={ind}>{val.name}</option>
                                                                    }
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid Category.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={4} className="txt">
                                                        <Form.Group className="mb-1" controlId="subCategoryId">
                                                            <Form.Label>Sub Category<span className='star'>*</span></Form.Label>
                                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('subCategoryId', e)} value={formData.subCategoryId ? formData.subCategoryId : ""} required>
                                                                <option value="" >Select Sub Category</option>
                                                                {category && category.length > 0 && category.map((val, ind) => {
                                                                    if (val.categoryId != "000000000000000000000000" && (formData.categoryId == val.categoryId)) {
                                                                        return <option value={val._id} key={ind}>{val.name}</option>
                                                                    }
                                                                })}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                Please provide a valid sub Category.
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row className="mx-2">
                                                    <Col lg={4}>

                                                        <Form.Group className="txt" controlId="image">
                                                            <Row><Col><Form.Label>Photo</Form.Label></Col></Row>
                                                            <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={4} className="imgcss">
                                                        <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 g-2 mx-2">
                                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" onClick={e => setStep(Step - 1)}>
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                                        <Button variant="secondary" type='submit' className="btnk cldrBtn" >
                                                            Save
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Form>
                                </Modal.Body>
                            </Modal>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default memo(VendorDetails);