import React, { useState, useEffect, memo } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getVendorProductCategory } from "../../action/Admin.action";
import { useDispatch } from "react-redux";
import { setAlert } from "../../slices/home";
import CardView from "./CardView";
import Pagination from "../../admin/common/Pagination";

const List = (props) => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [formData, setFormData] = useState({
    page: 0,
    limit: 10,
    sortName: "createdAt",
    sortBy: "DESC",
  });

  const getDataList = async (action = "") => {
    let data = { ...formData };
    if (action === "clear") {
      data = {};
      setFormData({});
    }

    dispatch(
      setAlert({
        open: true,
        severity: "success",
        msg: "Loading...",
        type: "loader",
      })
    );
    const resp = await getVendorProductCategory(data);
    dispatch(
      setAlert({
        open: false,
        severity: "success",
        msg: "Loading...",
        type: "loader",
      })
    );
    if (resp.code === 200) {
      setDataList(resp.data);
      setFormData({ ...data, ...{ totalItems: resp.count } });
    }
  };

  useEffect(() => {
    getDataList();
  }, [formData.page]);

  const formHandler = (e, field) => {
    let data = { ...formData };
    if (e.target) {
      data[field] = e.target.value;
    } else {
      data[field] = e.value;
    }

    setFormData(data);
  };
  //Serial Number
  const startSno = formData.page * formData.limit + 1;
  return (
    <>
      <div className="mt-2">
        <Card className="mt-5">
          <Card.Body className="crdBdy">
            <Row className="justify-content-center mt-3 pt-2">
              <Col md={8} lg={7}>
                <h6 className="crdHd1">Vendor Product Category Management</h6>
              </Col>
              <Col md={4} lg={5} className="text-right text-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/admin/vendor-product-category/add`}
                  className="text-white btn btn-primary pull-right vndrBtn"
                >
                  Add Category/ SubCategory
                </Link>
              </Col>
            </Row>

            <Row>
              <Col className="pd-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Category Name
                </label>
                <input
                  type="text"
                  className="form-control filedbg cmnsz"
                  id="exampleFormControlInput1"
                  placeholder="e.g. Category Name"
                  value={formData.name ? formData.name : ""}
                  onChange={(e) => formHandler(e, "name")}
                />
              </Col>
              <Col className="pd-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Select Status
                </label>
                <select
                  className="form-select filedbg cmnsz"
                  aria-label="Default select example"
                  value={formData.status > -1 ? formData.status : ""}
                  onChange={(e) => formHandler(e, "status")}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </Col>
              <Col className="pd-5 pt-4">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-1">
                  <button
                    className="btn btn-primary vndrBtn"
                    type="button"
                    onClick={(e) => getDataList()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-primary vndrBtn"
                    type="button"
                    onClick={(e) => getDataList("clear")}
                  >
                    Clear
                  </button>
                </div>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={12}>
                {/*<Rolelistingtbl {...props} getDataList={e=>getDataList()} dataList={dataList}/>*/}
                <table className="table table-light">
                  <thead className="headingtbl">
                    <tr>
                      <th scope="col">S. No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Parent Category</th>
                      <th scope="col">Status </th>
                      <th scope="col">Action </th>
                    </tr>
                  </thead>
                  <tbody className="listing">
                    {dataList &&
                      dataList.length > 0 &&
                      dataList.map((val, ind) => {
                        const serialNumber = startSno + ind;

                        return (
                          <CardView
                            {...props}
                            getDataList={(e) => getDataList()}
                            key={ind}
                            item={val}
                            // sno={ind + 1}
                            sno={serialNumber}
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={formData.limit}
                  totalItems={formData.totalItems ? formData.totalItems : 0}
                  setPage={(e) => setFormData({ ...formData, ...e })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default memo(List);
