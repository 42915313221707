import React, { useState, useEffect, memo } from 'react';
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { addEditGenreAction } from "../../action/Admin.action";
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/home';
import { PermissionList } from '../../admin/common/constant';

const AddEdit = (props) => {

  let navigate = useNavigate();
  let { state } = useLocation();

  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({ permission: [] });

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      //setValidated(false);
      setDisabled(true);
      let msg = "";
      if (formData._id) {
        msg = "You have successfully Genre updated.";
      } else {
        msg = "You have successfully added new Genre.";
      }
      let resp = await addEditGenreAction(formData);

      if (resp.code === 200) {

        dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
        setTimeout(() => {
          navigate({ pathname: `${process.env.PUBLIC_URL}/admin/genres`, state: "" });
        }, 2000);
      } else {
        setDisabled(false);
        dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
      }
      return false;
    }
  };

  const handleChange = (post) => {

    let name = post.name;
    let event = post.event;
    let from = { ...formData };

    from[name] = event.target.value;

    setFormData({ ...formData, ...from });

  }

  const cancelBtn = () => { navigate({ pathname: `${process.env.PUBLIC_URL}/admin/genres`, state: "" }) }

  useEffect(() => {

    if (state && state._id) {

      let data = state;
      let editData = {
        _id: data._id,
        name: data.name,
        status: data.status,
      }
      setFormData(editData);
    }

  }, []);

  return (
    <div className='mt-5 pt-2'>
      <Card>
        <Card.Body className="crdBdy">
          <div className="Role-page text-start companyhed mt-3">
            <Row className="justify-content-center" >
              <Col md={9} lg={10}>
                <h6 className='crdHd1'>{formData._id ? 'Update Genre' : 'Add New Genre'}</h6>
              </Col>
              <Col md={3} lg={2} className="text-right">
                <Link to={`${process.env.PUBLIC_URL}/admin/genres`} className="text-white btn savebtn pull-right">Back</Link>
              </Col>
            </Row>
            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
              <div className="mt-4">
                <Row>
                  <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>Name<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" minLength={2} maxLength={32} placeholder="Name" className="filedbg cmnsz" value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                  {/* <Col xs="12" lg="4">
                    <div className="mb-3">
                      <Form.Label>Listed Status<span className="star"> *</span></Form.Label>
                      <Form.Select aria-label="Default select example" className="filedbg cmnsz" value={formData.status > -1 ? formData.status : ""} onChange={e => handleChange({ name: 'status', event: e })} required>
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Listed Status.
                      </Form.Control.Feedback>
                    </div>
                  </Col> */}
                </Row>
                <Button type='submit' className="vndrBtn" size="lg" disabled={isDisabled}>Save</Button>{' '}
                <Button className="vndrBtn" onClick={cancelBtn} size="lg">Cancel</Button>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default memo(AddEdit);

