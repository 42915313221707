import React, { useState, useEffect, Fragment, memo } from "react";
import { Row, Col, Button, Table, Card } from "react-bootstrap";
import {
  projectListingAction,
  projectDetailAction,
  _deleteAction,
} from "../../action/Admin.action";
import { useDispatch } from "react-redux";
import { setAlert, setProjectDetails } from "../../slices/home";
import Pagination from "../common/Pagination";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { productColumns, productData } from "../../Data/Ecommerce/ProductList";
import { confirmAlert } from "react-confirm-alert";

const ProjectListing = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [formData, setFormData] = useState({
    page: 0,
    limit: 10,
    sortName: "createdAt",
    sortBy: "DESC",
  });
  const [startNum, setStartNum] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const getList = async (action = "") => {
    let fData = { ...formData, search: searchTerm };

    if (action === "clear") {
      fData = {
        page: 0,
        limit: 10,
        sortName: "createdAt",
        sortBy: "DESC",
        search: searchTerm,
      };
      setFormData(fData);
    }

    setStartNum(fData.page * fData.limit);

    dispatch(
      setAlert({
        open: true,
        severity: "success",
        msg: "Loading...",
        type: "loader",
      })
    );
    let resp = await projectListingAction(fData);
    dispatch(
      setAlert({
        open: false,
        severity: "success",
        msg: "Loading...",
        type: "loader",
      })
    );
    if (resp.code === 200) {
      setList(resp.data);
      setFormData({ ...formData, ...{ totalItems: resp.count } });
    }
  };

  const formHandler = (e, field) => {
    let data = { ...formData };
    if (e.target) {
      data[field] = e.target.value;
    } else {
      data[field] = e;
    }
    setFormData(data);
  };
  const _delete = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            dispatch(
              setAlert({
                open: false,
                severity: "success",
                msg: "Loading...",
                type: "loader",
              })
            );

            const resp = await _deleteAction({ _id: id, type: "project" });
            if (resp.code === 200) {
              // getProjectList();
              getList();
              dispatch(
                setAlert({
                  open: true,
                  severity: "success",
                  msg: "You have successfully deleted Company.",
                  type: "",
                })
              );
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const startProject = async (project) => {
    localStorage.setItem("projectName", project.name);
    localStorage.setItem("projectId", project._id);
    localStorage.setItem("project", JSON.stringify(project));

    dispatch(setProjectDetails(project));

    navigate(`${process.env.PUBLIC_URL}/projectDetails`);

    // window.open(`${process.env.PUBLIC_URL}/projectDetails`, "_self");

    // let resp = await projectDetailAction();
    // if (resp.code === 200) {
    //     localStorage.setItem('project', JSON.stringify(resp.data));
    // }

    //window.location.reload();
  };

  // useEffect(() => {
  //   //getList();/
  // }, []);

  useEffect(() => {
    getList();
  }, [formData.page, searchTerm]);

  return (
    <>
      <div className="mt-3 pt-3 dfltp">
        <Card className="mt-2">
          <Card.Body className="crdBdy">
            <Row className="marginzr">
              <Col lg={12} className="table-container mt-4">
                <Row className="justify-content-end mx-1">
                  <Col md={6} className="px-0">
                    <h6 className="crdHd1 mt-2">Project Listing</h6>
                  </Col>
                  <Col md={4} className="lgsix p-1 mb-3">
                    <input
                      type="text"
                      style={{ backgroundColor: "transparent" }}
                      placeholder="Search Projects"
                      className="BrdRm"
                      value={formData.name ? formData.name : ""}
                      onChange={(e) => formHandler(e, "name")}
                    ></input>
                    <i
                      onClick={(e) => getList()}
                      className="fa fa-search hand"
                    ></i>
                  </Col>
                  <Col md={2}>
                    <button
                      className="btn btn-primary vndrBtn"
                      type="button"
                      onClick={(e) => getList("clear")}
                    >
                      Clear
                    </button>
                  </Col>
                  <hr />
                </Row>
                <Table className="table">
                  <thead className="headingtbl tbl2">
                    <tr className="ProjList">
                      <th scope="col" className="tblHead">
                        S.No.
                      </th>
                      <th scope="col" className="tblHead">
                        Name
                      </th>
                      <th scope="col" className="tblHead">
                        Producer
                      </th>
                      <th scope="col" className="tblHead">
                        Type
                      </th>
                      <th scope="col" className="tblHead">
                        Language
                      </th>
                      <th scope="col" className="tblHead">
                        Director
                      </th>
                      <th scope="col" className="tblHead">
                        DOP
                      </th>
                      <th scope="col" className="tblHead">
                        Genre
                      </th>
                      <th scope="col" className="tblHead">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="listing lis">
                    {list &&
                      list.length > 0 &&
                      list.map((val, ind) => {
                        return (
                          <Fragment key={ind}>
                            <tr>
                              <td className="truncate-text tblPr">
                                {startNum + ind + 1}
                              </td>
                              <td className="truncate-text tblPr">
                                {val.name}
                              </td>
                              <td className="truncate-text tblP">
                                {val.producerName}
                              </td>
                              <td className="truncate-text tblP">
                                {val.projectTypeName}
                              </td>
                              <td className="truncate-text tblP">
                                {val.languageName}
                              </td>
                              <td className="truncate-text tblP">
                                {val.directorName}
                              </td>
                              <td className="truncate-text tblP">{val.dop}</td>
                              <td className="truncate-text tblP">
                                {val.genreName}
                              </td>
                              <td>
                                <i
                                  className="fa fa-toggle-right hand"
                                  title="start"
                                  onClick={(e) => startProject(val)}
                                ></i>
                                <Link
                                  to={`${process.env.PUBLIC_URL}/editProject`}
                                  state={val}
                                >
                                  <i
                                    className="fa fa-pencil mx-3 hand"
                                    title="Edit"
                                  ></i>
                                </Link>
                                <i
                                  className="fa fa-trash-o hand"
                                  title="Delete"
                                  onClick={(e) => _delete(val._id)}
                                ></i>
                                {/*
                                                <Button className='btn bnt1 btnn' onClick={e => startProject(val)}>Start</Button>
                                           */}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    {list == 0 ? "Data not avaialble." : ""}
                  </tbody>
                </Table>
              </Col>

              {/* <Col lg={12}>
                    <DataTable noHeader pagination paginationServer columns={productColumns} data={list} highlightOnHover={true} striped={true} responsive={true} />
                </Col> */}
            </Row>
            <Row className="justify-content-end pageRow">
              <Col lg={3} className="pageCol">
                <Pagination
                  itemsPerPage={formData.limit}
                  totalItems={formData.totalItems ? formData.totalItems : 0}
                  setPage={(e) => setFormData({ ...formData, ...e })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default memo(ProjectListing);
