import React, { useState, useEffect, memo } from 'react';
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { editCompanyAction } from "../../action/Admin.action";
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/home';
import { PermissionList } from '../../admin/common/constant';

const AddEdit = (props) => {

  let navigate = useNavigate();
  let { state } = useLocation();

  const dispatch = useDispatch();
  const [isDisabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      //setValidated(false);
      setDisabled(true);
      let msg = "";
      msg = "You have successfully Company updated.";
      let resp = await editCompanyAction(formData);

      if (resp.code === 200) {

        dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
        setTimeout(() => {
          navigate({ pathname: `${process.env.PUBLIC_URL}/admin/company`, state: "" });
        }, 2000);
      } else {
        setDisabled(false);
        dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
      }
      return false;
    }
  };
  const handleChange = (name, event) => {
    let from = { ...formData };

    from[name] = event.target.value;

    setFormData({ ...formData, ...from });

  }

  useEffect(() => {

    if (state && state._id) {

      let data = state;
      let editData = {
        _id: data._id,
        companyName: data.companyName,
        contactPersonName: data.contactPersonName,
        email: data.email,
        personMobile: data.personMobile,
        address: data.address,
        status: data.status
      }
      setFormData(editData);
    }

  }, []);
  const cancelBtn = () => { navigate({ pathname: `${process.env.PUBLIC_URL}/admin/company`, state: "" }) }


  return (
    <div className="mt-5">
      <Card>
        <Card.Body className='crdBdy'>
          <div className="Role-page text-start companyhed mt-3 pt-2">
            <Row className="justify-content-center" >
              <Col md={9} lg={10}>
                <h6 className='crdHd1'>{formData._id ? 'Update Company' : 'Add New Company'}</h6>
              </Col>
              <Col md={3} lg={2} className="text-right">
                <Link to={`${process.env.PUBLIC_URL}/admin/company`} className="text-white btn savebtn pull-right">Back</Link>
              </Col>
            </Row>
            <hr className='mrZero' />
            <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
              <div className="mt-4">
                <Row>
                  <Col xs="12" lg="6">
                    <div className="mb-3">
                      <Form.Label>Company Name<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Update Company Name" className="filedbg" value={formData.companyName ? formData.companyName : ""} onChange={e => handleChange('companyName', e)} minLength={2} maxLength={32} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Company Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>

                  <Col xs="12" lg="6">
                    <div className="mb-3">
                      <Form.Label>Contact Person Name<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Update Contact Person Name" className="filedbg" value={formData.contactPersonName ? formData.contactPersonName : ""} onChange={e => handleChange('contactPersonName', e)} minLength={2} maxLength={32} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Contact Person Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>

                  <Col xs="12" lg="6">
                    <div className="mb-3">
                      <Form.Label>Email<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$" placeholder="Update Email" className="filedbg" value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Email Name.
                      </Form.Control.Feedback>
                    </div>
                  </Col>

                  <Col xs="12" lg="6">
                    <div className="mb-3">
                      <Form.Label>Contact Person Mobile Number<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern='^[6-9][0-9]{9}$' placeholder="Update Mobile Number" className="filedbg" value={formData.personMobile ? formData.personMobile : ""} onChange={e => handleChange('personMobile', e)} minLength={10} maxLength={10} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Mobile Number.
                      </Form.Control.Feedback>
                    </div>
                  </Col>

                  <Col xs="12" lg="12">
                    <div className="mb-3">
                      <Form.Label>Address<span className="star"> *</span></Form.Label>
                      <Form.Control type="text" pattern="^(?!.* {2})[A-Za-z0-9,\/\\ ]+$" minLength={2} maxLength={120} placeholder="Update Address Name" className="filedbg" value={formData.address ? formData.address : ""} onChange={e => handleChange('address', e)} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Address.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                </Row>
                <Row className="text-end">
                  <Col lg={12}>
                    <Button type='submit' className="savebtn vndrBtn" size="lg" disabled={isDisabled}>Save</Button>{' '}
                    <Button className="cancelbtn vndrBtn" onClick={cancelBtn} size="lg">Cancel</Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default memo(AddEdit);

