import React, { Fragment, } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { ToastContainer } from "react-toastify";
import Header from "../Header/HeaderloginLite";
import "../../../assets/css/style.css";

const UserDashboard = () => {
    const stats = [
        { label: 'Total Searches', value: 70 },
        { label: 'Total Views', value: 100 },
        // { label: 'Total Clicks', value: 100 },
        { label: 'Total Enquiry', value: 100 },
        // { label: 'Total Bookings', value: 100 },
        // { label: 'Total Services', value: 5 }
    ];
    return (
        <Fragment>
            <Container fluid className="p-0">
                {/* <Header /> */}

                <Container  >
                    <div className="dashboard-header mt-4">
                        <h1>Google Ads</h1>
                    </div>
                    <div className="dashboard-stats mt-4">
                        <Row className="stat-box-container text-center">
                            {stats.map((stat, idx) => (
                                <Col key={idx} sm={6} md={4} lg={2} className="stat-box">
                                    <div className="stat-value">{stat.value}</div>
                                    <div className="stat-title">{stat.label}</div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <div className="dashboard-container">
                        <div className="grid-container">
                            {/* My Bookings */}
                            <div className="grid-item bookings pb-5">
                                <h5 style={{ marginBottom: '18px', marginTop: '20px', color: "white" }}>My Bookings</h5>
                                <Table className="table-dar">
                                    <thead>
                                        <tr >
                                            <th >Date</th>
                                            <th>Service Name</th>
                                            <th>Company Name</th>
                                            <th>Contact Number</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[1, 2].map((_, idx) => (
                                            <tr key={idx}>
                                                <td>18.5.2024</td>
                                                <td>Lorem Ipsum is simply dummy</td>
                                                <td>Company Name</td>
                                                <td>123-456-7890</td>
                                                <td><span className="status-gree">Completed</span></td>
                                                <td className="table-actions">
                                                    <button className="btn-success" size="sm">+</button>
                                                    <button className="btn-warning" size="sm">✎</button>
                                                    <button className="btn-danger" size="sm">✕</button>
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </Table>
                            </div>


                            <div className="grid-item enquiry pb-5">
                                <h5 style={{ marginBottom: '18px', marginTop: '20px', color: "white" }}>Enquiry</h5>
                                <Table striped hover className="table-dar">
                                    <thead>
                                        <tr className="">
                                            <th>Date</th>
                                            <th>Details</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[1, 2].map((_, idx) => (
                                            <tr key={idx}>
                                                <td>18.5.2024</td>
                                                <td>Lorem Ipsum is simply dummy</td>
                                                <td className="table-actions">
                                                    <button className="btn-success" size="sm">+</button>
                                                    <button className="btn-danger" size="sm">✕</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default UserDashboard;
