import { Card, Col, Container, form, Row, Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from "react";
import { addLocation } from "../../action/Admin.action";
import { setAlert } from '../../slices/home.js';
import { objToArray } from "../common/function";
import { Locationpermission } from "../common/constant";
import { Link } from "react-router-dom";
import { checkName, checkNumber, emailValidation, checkMobileNumber } from "../common/function";

const LocationBtn = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const masters = useSelector((state) => state.home.masters);
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [show1, setShow1] = useState(false);


    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();

            let resp = await addLocation(formData);

            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
                props.data();
                setFormData({});
            } else {
                dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }

            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });

    }

    return (
        <>
            <Modal show={show1}>
                <Modal.Header className="mdlHdr">
                    <h6 className="crdHd1">Add Location</h6>
                </Modal.Header>
                <hr className="mrZero" />
                <Modal.Body>
                    <Form className="p-4 pt-0 pb-0" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Control
                                type="text"
                                className="frmcnt"
                                pattern = "^[A-Za-z]+(?: [A-Za-z]+)*$"
                                placeholder="Name"
                                autoFocus
                                minLength="1"
                                maxLength="32"
                                value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Location name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="locationType">
                            <Form.Select aria-label="Default select example" className="filedbg frmcnt" value={formData.locationTypeId?formData.locationTypeId:""} onChange={e=>handleChange('locationTypeId', e)} required>
                                <option value="">Select Location Type</option>
                            { masters && masters.locationType && masters.locationType.length>0 && masters.locationType.map((item, index)=>{
                                return <option value={item._id} key={index}>{item.name}</option>
                            })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Location Type.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="shortListFor">
                            <Form.Control
                                type="text"
                                pattern = "^[A-Za-z]+(?: [A-Za-z]+)*$"
                                placeholder="ShortList For"
                                minLength="2"
                                className="frmcnt"
                                maxLength="32"
                                value={formData.shortListFor ? formData.shortListFor : ""} onChange={e => handleChange('shortListFor', e)} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid ShortList For.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="contactPerson">
                            <Form.Control
                                type="text"
                                className="frmcnt"
                                placeholder="Contact Person"
                                value={formData.contactPerson ? formData.contactPerson : ""} onChange={e => handleChange('contactPerson', e)} minLength="2" maxLength="32" pattern = "^[A-Za-z]+(?: [A-Za-z]+)*$" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid ShortList For.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="permission">
                            <Form.Select className="frmcnt" aria-label="Default select example" onChange={e => handleChange('permission', e)} value={formData.permission ? formData.permission : ""} required>
                                <option value="">Permission Require or Not</option>
                                {objToArray(Locationpermission).map((val, index) => {
                                    return <option value={index} key={index}>{val}</option>
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Row className="mt-4 mb-4 g-2">
                            <Col lg={6}>
                                <Button type="submit" className="vndrBtn">
                                    Save & Add More
                                </Button>
                            </Col>
                            <Col lg={6} className="text-end  mt-3">
                      <Button className="vndrBtn"  onClick={()=>{handleClose1(); setFormData({});setValidated(false)}} >
                        Cancel
                      </Button></Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <Button type="Button" className="vndrBtn" onClick={handleShow1}>
                + Location
            </Button>
        </>
    );
}

export default LocationBtn;