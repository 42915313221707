import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./index1.css";
import App from "./App";
import "./i18n";
// import "../src/assets/css/style.css"
import "../src/FrontPage/Componets/frontPage.css"
import reportWebVitals from "./reportWebVitals";
import store from './store';
import { Provider } from 'react-redux';


const Root = () => {
  return (
    <div className="App">
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// ReactDOM.createRoot(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
