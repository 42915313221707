import { Link } from 'react-router-dom';
import fireImage from '../../../assets/images/giftools.gif';
import Slider from 'react-slick';
import { notificationSliderOption } from './NotificationSliderOption';
import { useSelector } from 'react-redux';
import { Row, Col, Dropdown, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from "../../../assets/images/logo.png";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";

import { LI, UL, Image, P } from "../../../AbstractElements";
import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import UserHeader from '../RightHeader/UserHeader';



const NotificationSlider = () => {


    const history = useNavigate();
    const [profile, setProfile] = useState("");
    const [name, setName] = useState("Emay Walter");
    const { layoutURL } = useContext(CustomizerContext);
    const authenticated = JSON.parse(localStorage.getItem("authenticated"));
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

    useEffect(() => {
        setProfile(localStorage.getItem("profileURL") || man);
        setName(localStorage.getItem("Name") ? localStorage.getItem("Name") : name);
    }, []);

    const Logout = () => {
        localStorage.removeItem("profileURL");
        localStorage.removeItem("token");
        localStorage.removeItem("auth0_profile");
        localStorage.removeItem("Name");
        localStorage.setItem("authenticated", false);
        history(`${process.env.PUBLIC_URL}/login`);
        localStorage.clear();
    };

    const UserMenuRedirect = (redirect) => {
        history(redirect);
    };


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const project = useSelector((state) => state.home.projectDetails);
    const [visible, setVisible] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [show1, setShow1] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleDropdownClose = () => {
        setTimeout(() => {
            setDropdownOpen(false);
        }, 3000);
    };
    const handleDropdownToggle1 = () => {
        setDropdownOpen1(!dropdownOpen1);
    };
    const handleDropdownClose1 = () => {
        setTimeout(() => {
            setDropdownOpen1(false);
        }, 3000);
    };

    return (
        <div className='notification-slider'>

            {/* <p>left</p> */}
            <Navbar collapseOnSelect expand="lg">
                <Container fluid>
                    {/* <Navbar.Brand to="/project" as={Link}>
                        <img className="picture" src={logo} alt="" />
                    </Navbar.Brand> */}
                    <Row><Col lg={12}>
                        {localStorage && localStorage.getItem('projectId') && project.name &&
                        <Nav.Link className="pname" to={`${process.env.PUBLIC_URL}/projectDetails`} as={Link}><h6 className="m-0" style={{color:"#167d7f", fontWeight:"800"}}>{project.name}</h6></Nav.Link>
                    }
                    
                    </Col>
                    <Col lg={12}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="align-items-center">
                            <NavDropdown title={
                                <Row className="d-block" onClick={() => handleDropdownToggle()}>
                                    <Col>
                                        {/* <img src={scene} height={40} width={40} /> */}
                                    </Col>
                                    <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Projects</Col>
                                </Row>
                            } id="transP collapsible-nav-dropdown" className="mx-1"
                                show={dropdownOpen}
                                style={{backgroundColor:"transparent",color:"black"}}
                                onMouseLeave={handleDropdownClose}>
                                <div className="dropDown1  justify-content-center">

                                {/* ${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL} */}


                                    <Nav.Item>
                                        <Nav.Link style={{ fontSize: "13px" }} className='addProject' to={`${process.env.PUBLIC_URL}/addProject`} as={Link}>
                                            + Add
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link style={{ fontSize: "13px" }} className='projectListing' to={`${process.env.PUBLIC_URL}/projectListing`} as={Link}>
                                            Project Listing
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                            </NavDropdown>

                            {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' &&
                                <NavDropdown title={
                                    <Row className="d-block" onClick={() => handleDropdownToggle1()}>
                                        <Col>
                                            {/* <img src={img1} height={40} width={40} /> */}
                                        </Col>
                                        <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Team</Col>
                                    </Row>
                                } id="transP collapsible-nav-dropdown" className="mx-1" show={dropdownOpen1}
                                    onMouseLeave={handleDropdownClose1}>
                                    <div className="dropDown1">
                                        <Nav.Item>
                                            <Nav.Link style={{ fontSize: "13px" }} className='addDirMember'  to={`${process.env.PUBLIC_URL}/addDirMember`} as={Link}>
                                                Direction
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ fontSize: "13px" }} className='addProdMembers' to={`${process.env.PUBLIC_URL}/addProductionMember`} as={Link}>
                                                Production
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link style={{ fontSize: "13px" }} className='addHods' to={`${process.env.PUBLIC_URL}/addHods`} as={Link}>
                                                HOD's
                                            </Nav.Link>
                                        </Nav.Item>
                                         <Nav.Item>
                                            <Nav.Link style={{ fontSize: "13px" }} className='addHods' to={`${process.env.PUBLIC_URL}/artist-menu`} as={Link}>
                                                Artists
                                            </Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </NavDropdown>
                            }

                            {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' && <Nav.Item className="mx-1 text-center">
                                <Nav.Link className='sheet-generator' to={`${process.env.PUBLIC_URL}/sheet-generator`} as={Link}>
                                    <Row className="d-block">
                                        <Col>
                                            {/* <img src={script} height={40} width={40} /> */}
                                        </Col>
                                        <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Report</Col>
                                    </Row>
                                </Nav.Link>
                            </Nav.Item>
                            }
                            
                            {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' &&
                                <Nav.Item className="mx-5">
                                    <Nav.Link className='calendar' to={`${process.env.PUBLIC_URL}/calendarT`} as={Link}>
                                        <Row className="d-block text-center">
                                            <Col>
                                                {/* <img src={calendar} height={35} width={35} /> */}
                                            </Col>
                                            <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Calendar</Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            }

                            {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' &&

                                <Nav.Item className="mx-3">
                                    <Nav.Link className="vendor-option" to={`${process.env.PUBLIC_URL}/vendor-option`} as={Link}>
                                        <Row className="d-block">
                                            <Col>
                                                {/* <img src={vendor} height={35} width={35} /> */}
                                            </Col>
                                            <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Vendor</Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            }
                            
                            {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' &&

                                <Nav.Item className="mx-4">
                                    <Nav.Link className="schedule-menu" to={`${process.env.PUBLIC_URL}/schedule-menu`} as={Link}>
                                        <Row className="d-block">
                                            <Col>
                                                {/* <img src={vendor} height={35} width={35} /> */}
                                            </Col>
                                            <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Schedule</Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            }
                             
 {/*                           {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' &&

                                <Nav.Item className="mx-1">
                                    <Nav.Link className="vendor-option" to={`${process.env.PUBLIC_URL}/vendor-option`} as={Link}>
                                        <Row className="d-block">
                                            <Col>
                                                <img src={vendor} height={35} width={35} />
                                            </Col>
                                            <Col className="text-center" style={{ fontSize: "14px" }}>Sheet</Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            }
*/}
                            {localStorage.getItem('projectId') && localStorage.getItem('projectId') !== '' &&

                                <Nav.Item className="mx-3">
                                    <Nav.Link className="importScript" to={`${process.env.PUBLIC_URL}/importScript`} as={Link}>
                                        <Row className="d-block">
                                            <Col>
                                                {/* <img src={vendor} height={35} width={35} /> */}
                                            </Col>
                                            <Col className="text-start pl-0" style={{ fontSize: "14px" }}>Script</Col>
                                        </Row>
                                    </Nav.Link>
                                </Nav.Item>
                            }

                        </Nav>

                        {/* <Nav>
                            <Dropdown className="d-inline profile-menu">
                                <Dropdown.Toggle variant="outline-dark" className='nav-menu-btn' id="dropdown-basic">
                                    <img src={profileImage} width="20" /> My Profile
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className="active-link border-0 mb-2" size="lg" >
                                        <img src={profileImage} className="float-start" width="25" />&ensp;SUper
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => { handleShow(e); setFormData({}) }} className="text-center">
                                        Change PassWord
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={e => handleLogout()} className="text-center">
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav> */}
                    </Navbar.Collapse>
                        </Col>
                    </Row>
                </Container>
            </Navbar>



            {/* <Slider className='m-0' {...notificationSliderOption}>
        <div className='d-flex h-100'>
          <Image attrImage={{ src: fireImage, alt: 'gif' }} />
          <H6 attrH6={{ className: 'mb-0 f-w-400' }}>
            <span className='font-primary'>Don't Miss Out! </span>
            <span className='f-light'>Out new update has been release.</span>
          </H6>
          <i className='icon-arrow-top-right f-light' />
        </div>
        <div className='d-flex h-100'>
          <Image attrImage={{ src: fireImage, alt: 'gif' }} />
          <H6 attrH6={{ className: 'mb-0 f-w-400' }}>
            <span className='f-light'>Something you love is now on sale! </span>
          </H6>
          <Link className='ms-1' to='https://1.envato.market/3GVzd' target='_blank'>
            Buy now !
          </Link>
        </div>
      </Slider> */}
        </div>
    );
};

export default NotificationSlider;
