import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { Col, Row, Container, Button, Alert, Card, Table } from "react-bootstrap";

const data = [
  { time: "Apr", actualWork: 20, work: 10 },
  { time: "May", actualWork: 10, work: 25 },
  { time: "Jun", actualWork: 25, work: 22 },
  { time: "July", actualWork: 18, work: 18 },
  { time: "Aug", actualWork: 15, work: 30 },
  { time: "Sep", actualWork: 25, work: 8 },
  { time: "Oct", actualWork: 12, work: 22 },
  { time: "Nov", actualWork: 20, work: 20 },
  { time: "Dec", actualWork: 10, work: 10 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const selectedData = payload[0].payload;
    const difference = selectedData.work - selectedData.actualWork;

    return (
      <>
        <Card className='cstmGrhpCrd mt-5'>
            <Card.Body className='crdBdy'>
                <Row className='mt-2'>
                    <Col className='col-6 grphTime'>{selectedData.actualWork + selectedData.time}</Col>
                    <Col className='col-6'>{difference>=0?<span className='grphDiff'>+ {difference} Days</span>:<span className='grphDiff1'>{difference} days</span>}</Col>
                </Row>
                <Row>
                    <Col className='col-6 grphTime'>Actual time:</Col>
                    <Col className='col-6 grphDiff'>{selectedData.work+selectedData.time}</Col>
                </Row>
                <Row>
                    <Col className='col-6 grphTime'>Status:</Col>
                    <Col className='col-6'>{(difference>=0)?(difference===0?<span class="grphDiff">'On Time'</span>:<span class="grphDiff">'Before Time'</span>):<span class="grphDiff1">'Delay'</span>}</Col>
                </Row>
            </Card.Body>
          </Card>
        </>
    );
  }

  return null;
};

const CustomizedAreaChart = () => {
  return (
    <ResponsiveContainer width="100%" height={270}>
      <AreaChart data={data}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis ticks={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]} />
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="work"
          // fill="linear-gradient(180.22deg, rgba(246, 203, 203, 0.79) 0.19%, rgba(246, 203, 203, 0.08) 78.8%);"
          stroke="rgba(236, 135, 135, 1)"
          strokeWidth={2}
          dot={false}
        />

        <Area
          type="monotone"
          dataKey="actualWork"
          fill="url(#actualWorkGradient)"
          stroke="rgba(22, 125, 127, 1)"
          strokeWidth={2}
          dot={false}
          className='red1'
        />

        {/* Define gradient for the work area */}
        <defs>
          <linearGradient id="workGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#8884d8" stopOpacity={0.7} />
            <stop offset="100%" stopColor="#8884d8" stopOpacity={0.1} />
          </linearGradient>
        </defs>

        {/* Define gradient for the actual work area */}
        <defs>
          <linearGradient id="actualWorkGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#82ca9d" stopOpacity={0.7} />
            <stop offset="100%" stopColor="#82ca9d" stopOpacity={0.1} />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomizedAreaChart;
