import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getMemberOnDepartment, getCategoryVendorDataAction } from "../../../action/Admin.action";
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";

import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import HomeFooter from "../Footer/HomeFooter";

const CategoryProfileListing = ({ selected }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({ name: '', subCategoryId: '' });
    const [suggestions, setSuggestions] = useState([]);
    const [matchSubCategories, setMatchSubCategories] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState('');


    var categoryId = location.state?.id?._id || '';
    const categoryName = location.state?.id?.name || '';
    const businessCountry = location.state?.businessCountry || '';
    const businessState = location.state?.businessState || '';
    const businessCity = location.state?.businessCity || '';

    const fetchCategoryData = async () => {
        setLoading(true);
        const response = await getMemberOnDepartment({ categoryId, subCategoryId, businessCountry, businessState, businessCity });

        if (response.code === 200) {
            setCategoryData(response.data);
        }
        setLoading(false);
    };

    const getData = async () => {

        if (categoryId) {
            const resp = await getCategoryVendorDataAction();
            if (resp && resp.code === 200) {
                const matchedCategory = resp.data.find(item => item._id === categoryId);
                if (matchedCategory) {
                    const subCategories = matchedCategory.subCategory || [];
                    setMatchSubCategories(subCategories)
                } else {
                    console.warn("No matching category found for categoryId:", categoryId);
                }
            } else {
                console.error("Error fetching suggestions:", resp);
            }
        } else {

        }
    };
    useEffect(() => {

        getData();
        fetchCategoryData();

    }, [categoryId, subCategoryId, categoryName, businessCountry, businessState, businessCity]);


    const fetchSuggestions = async (name) => {
        if (name.length >= 1) {
            const resp = await getMemberOnDepartment({ name });
            if (resp && resp.code === 200) {
                setSuggestions(resp.data);
            } else {
                console.error("Error fetching suggestions:", resp);
            }
        } else {
            setSuggestions([]);
        }
    };


    const handleInputChange = async (e) => {
        const name = e.target.value;
        setFormData({ ...formData, name });

        await fetchSuggestions(name);
    };

    const handleSuggestionClick = (category) => {
        setFormData({ ...formData, name: category.businessContactName });
        setSuggestions([]);
    };


    const handleSearch = async (e) => {
        e.preventDefault();
        const { name } = formData;
        const subCategoryId = formData.subCategoryId;

        if (name || subCategoryId) {
            try {

                const response = await getMemberOnDepartment({ name, subCategoryId, categoryId, businessCountry, businessState, businessCity });

                if (response && response.code === 200) {

                    const matchedData = response.data;
                    setCategoryData(matchedData);
                } else {
                    console.error("Error fetching search results:", response);
                }
            } catch (error) {
                console.error("Error occurred during search:", error);
            }
        } else {
            console.warn("Please provide either a search keyword or select a subcategory.");
        }
    };

    const handleCardClick = (id) => {
        navigate(`${process.env.PUBLIC_URL}/categoryProfileDetails`, { state: { id, categoryName } });
    };
    const status = JSON.parse(localStorage.getItem("status"));
    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="search-baree-section">
                <Container className="search-bar" >
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '20px' }}>
                        <Col md={8} >
                            <Form onSubmit={handleSearch} style={{ paddingTop: '25px' }}>
                                <Row className="g-2">

                                    <Col xs={12} md={4}>
                                        <Form.Group>

                                            <Form.Select
                                                name="subCategoryId"
                                                value={formData.subCategoryId || ''}
                                                onChange={(e) => setFormData({ ...formData, subCategoryId: e.target.value })}
                                            >
                                                <option value="">Select Subcategory</option>
                                                {matchSubCategories.map((sub, index) => (
                                                    <option value={sub._id} key={index}>{sub.name}</option>
                                                ))}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Sub Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <div className="d-flex search-baree" >
                                            <Form.Control
                                                style={{ display: 'none' }}
                                                type="text"
                                                name="name"
                                                placeholder="🔍 Search for Keyword"
                                                className="custom-inputee custom-placeholder"
                                                onChange={handleInputChange}
                                                value={formData.businessContactName}
                                                autocomplete="off"
                                            />
                                            <Button className="search-button" type="submit">
                                                <img src={searchIcon} alt="Search" />
                                            </Button>
                                        </div>

                                        {suggestions.length > 0 && (
                                            <ul className="suggestions-list">
                                                {suggestions.map((category) => (
                                                    <li
                                                        key={category._id}
                                                        onClick={() => handleSuggestionClick(category)}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="suggestion-icon">🔍</span>
                                                        <div className="suggestion-details">
                                                            <span className="suggestion-name">{category.name}</span>
                                                            <span className="suggestion-category">Category</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col md={4} className="text-end" style={{ paddingTop: '25px' }} >
                            <button className="ms-2 custom-button" style={{ width: '105px', padding: '5px', borderRadius: '8px' }} onClick={() => navigate(-1)}>Back</button>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <div className="card-grid mt-5">
                        {categoryData.map((item, index) => (
                            <Card key={index} className="card-custom hand" onClick={() => handleCardClick(item._id, categoryName)}>

                                <div className="rating-card">
                                    <div className="rating-badge">

                                    </div>
                                </div>
                                <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                    <div className="card-content flex-grow-1">
                                        <Card.Title>{item.businessContactName}</Card.Title>
                                        <Card.Subtitle className="mb-2 ">{categoryName}</Card.Subtitle>
                                        <Card.Text><i class="fas fa-map-marker-alt"></i>{item.businessAddress}</Card.Text>
                                    </div>
                                    <div className="button-group mt-auto">
                                        <Button variant="danger" className="me-2">Send Enquiry</Button>
                                        <Button variant="success">Call Now</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </Container>
            </section>
        </Fragment >
    );
};

export default CategoryProfileListing;
