import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getMemberDetails } from "../../../action/Admin.action";
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";

const CategoryProfileListing = ({ selected }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [categoryDetailsData, setCategoryDetailsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const categoryId = location.state?.id;
    const categoryName = location.state?.categoryName;

    const fetchCategoryDetailsData = async () => {
        setLoading(true);
        const response = await getMemberDetails({ _id: categoryId });
        if (response.code === 200) {


            setCategoryDetailsData(response.data);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (categoryId) {
            fetchCategoryDetailsData();
        }
    }, [categoryId]);
    const status = JSON.parse(localStorage.getItem("status"));

    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="search-barDetail-section">
                <Container className="search-bar">
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '20px' }}>
                        <Col md={8} >
                            <Form style={{ paddingTop: '25px' }}>
                                <Row className="g-2">
                                    <Col xs={12} md={2}>

                                    </Col>
                                    <Col xs={12} md={4}>

                                    </Col>

                                </Row>
                            </Form>
                        </Col>
                        <Col md={4} className="text-end" style={{ paddingTop: '25px' }} >
                            <button className="ms-2 custom-button" style={{ width: '105px', padding: '5px', borderRadius: '8px' }} onClick={() => navigate(-1)}>Back</button>
                        </Col>
                    </Row>
                </Container>
                <Container className="search-bar">
                    <Card className="profile-container">

                        <div className=" card-header-section">
                            <div className="header-content">

                            </div>
                        </div>

                        <Row className="">

                            <Col md={8}>

                                <Card className="profile-card mb-4">

                                    {categoryDetailsData.length > 0 && (

                                        <div className="p-4">
                                            <h3 className="profile-title-name">{categoryDetailsData[0].businessContactName}</h3>

                                            <div className="d-flex align-items-center mb-3 rating-section">
                                                <p className="subtitle">{categoryName}</p>
                                            </div>
                                            <p className="description">
                                                {categoryDetailsData[0].description}

                                            </p>
                                        </div>
                                    )}
                                </Card>

                                <h4 className="portfolio-title mt-4">Portfolio</h4>


                                <div className="portfolio-grid">
                                    <Card className="portfolio-card">

                                        <Card.Body>
                                            <Card.Title className="card-title">Outdoor</Card.Title>
                                        </Card.Body>
                                    </Card>

                                    <Card className="portfolio-card">

                                        <Card.Body>
                                            <Card.Title className="card-title">Indoor</Card.Title>
                                        </Card.Body>
                                    </Card>

                                    <Card className="portfolio-card">

                                        <Card.Body>
                                            <Card.Title className="card-title">Personal Portfolio</Card.Title>
                                        </Card.Body>
                                    </Card>

                                    <Card className="portfolio-card">

                                        <Card.Body>
                                            <Card.Title className="card-title">Editing</Card.Title>
                                        </Card.Body>
                                    </Card>

                                    <Card className="portfolio-card">

                                        <Card.Body>
                                            <Card.Title className="card-title">AI Generated</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>

                            <Col md={4}>
                                <Card className="contact-card p-4">
                                    <Card.Body>
                                        <div className="button-groupe">
                                            <Button variant="danger">Send Enquiry</Button>
                                            <Button variant="success">Call Now</Button>
                                        </div>
                                        {categoryDetailsData.length > 0 && (
                                            <>
                                                <p className="contact-info">
                                                    <i className="fas fa-map-marker-alt"></i> {categoryDetailsData[0].businessAddress},{categoryDetailsData[0].businessCity}-{categoryDetailsData[0].businessPostalCode}
                                                </p>
                                                <p className="contact-info">
                                                    <i className="fas fa-phone"></i> {categoryDetailsData[0].businessContactNumber}
                                                </p>
                                                <p className="contact-info">
                                                    <i className="fas fa-envelope"></i> {categoryDetailsData[0].email}
                                                </p>
                                                <p className="contact-info">
                                                    <i className="fas fa-globe"></i>    {categoryDetailsData[0].website ? categoryDetailsData[0].website : "N/A"}
                                                </p>
                                            </>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Container>
            </section>
        </Fragment >
    );
};

export default CategoryProfileListing;
