import { React, useState } from "react";
import { Modal } from 'react-bootstrap';
import { Facebook, Linkedin, Twitter } from "react-feather";
import { Link } from "react-router-dom";
import { H6, P } from "../AbstractElements";
import UserRegistration from "../admin/pages/UserRegistration";
import OtpVerification from "../admin/pages/OtpVerification";

const OtherWay = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const refresh = (data) => {
    setShow1(data);
  }
  const refresh1 = (data) => {
    setShow1(data);
  }

  return (
    <>
      <div className="login-social-title">
        {/*<H6 attrH6={{ className: "text-muted or mt-4" }}>Or Sign up with</H6>*/}
      </div>
      {/*<div className="social my-4 ">
        <div className="btn-showcase">
          <a className="btn btn-light" href="https://www.linkedin.com/login" rel="noreferrer" target="_blank">
            <Linkedin className="txt-linkedin" /> LinkedIn
          </a>
          <a className="btn btn-light" href="https://twitter.com/login?lang=en" rel="noreferrer" target="_blank">
            <Twitter className="txt-twitter" />
            twitter
          </a>
          <a className="btn btn-light" href="https://www.facebook.com/" rel="noreferrer" target="_blank">
            <Facebook className="txt-fb" />
            facebook
          </a>
        </div>
      </div>*/}
      <Link to={`${process.env.PUBLIC_URL}/loginWithOtp`} >Login with OTP</Link>
      <p className="text-center mb-0 mt-3 font12">
        Don't have account?
        <p className="hand font12" style={{ color: "blue" }} onClick={(e) => { handleShow(e) }}>
          Create Account
        </p>
      </p>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="crdHd1" >
          Registration
        </Modal.Header>
        <hr className="mrZero" />
        <Modal.Body className="crdBdy">
          <UserRegistration data={refresh} morn={handleClose} />
        </Modal.Body>
      </Modal>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton className="crdHd1" >
          OTP Verification
        </Modal.Header>
        <hr className="mrZero" />
        <Modal.Body className="crdBdy">
          <OtpVerification data1={refresh1} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtherWay;
