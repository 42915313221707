import React, { Fragment, useState, memo, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { H5, UL, LI } from '../../../AbstractElements';
import { Earning, Expense, OverallBalanceTitle } from '../../../Constant';
// import LightCard from './LitghtCard';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import ReactApexChart from 'react-apexcharts';
import { CurrencyChartData } from '../../../Data/DefaultDashboard/Chart';
// import { LightCardData } from '../../../Data/DefaultDashboard';
import ProjectListing from "../../../admin/component/ProjectListing";
import {Container, Form, Button, Modal } from "react-bootstrap";
import '../../../../src/index';
import { useNavigate } from 'react-router-dom';
import { addProject } from "../../../action/Admin.action.js";
import { setAlert, setProjectDetails } from '../../../slices/home.js';
import ProjectMasterModal from '../../../admin/component/ProjectMasterModal.js';
import {projectListingAction} from '../../../action/Admin.action';
//import { getProject } from '../../common/function';

const Project = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [masterModal, setMasterModal] = useState(false);
  const [file, setFile] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [validated, setValidated] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [isDisabled, setDisabled] = useState(false);
  const masters = useSelector((state) => state.home.masters);
  const [ count, setCount] = useState();

   const handleSubmit = async (event) => {
     const form = event.currentTarget;


     if (form.checkValidity() === false) {

       event.preventDefault();
       event.stopPropagation();
       setValidated(true);

     }

     else {
       event.preventDefault();
       event.stopPropagation();
       setValidated(false);
       setDisabled(true);

 
       let postData = new FormData();
       for (let key in formData) {
         postData.append(key, formData[key]);
       }
       let resp = await addProject(postData);

       setDisabled(false);

       if (resp.code === 200) {
        let msg = "You have successfully created project.";

      toast.success(msg);
         handleClose();
         localStorage.setItem('projectName', resp.data.name);
         localStorage.setItem('projectId', resp.data._id);
         localStorage.setItem('project', JSON.stringify(resp.data));
         dispatch(setProjectDetails(resp.data));
         navigate(`${process.env.PUBLIC_URL}/projectDetails`); 
         setMasterModal(true);

       } else {
        toast.error(resp.msg);
       }
       return false;
     }

   };

   const getList = async()=>{
    let resp = await projectListingAction();
    if(resp.code===200){
      setCount((resp.data).length)
    }
   }

   const fileChangedHandler = (event, elename) => {
     event.preventDefault();
     let formErrorsData = formErrors;

     let formDataData = formData;
     let file = event.target.files[0];
     setFile(URL.createObjectURL(event.target.files[0]));
     if (!file && file === undefined) {

       return false;
     }
     var fileName = (file && file.name ? file.name : '');
     let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

     if (file.size > 20971520) {
       formErrorsData[elename] = "File size not greater then 20MB.";
     } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

      formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
       formDataData['fileType'] = extensions;
       formDataData[elename] = event.target.files[0];
       setFormData({ ...formData, ...formDataData });
     } else {
       formErrorsData[elename] = "File extensions doesn't match.";
     }
     setFormErrors({ ...formErrors, ...formErrorsData });
   }

   const handleChange = (post) => {

     let name = post.name;
     let event = post.event;
     let from = { ...formData };

     from[name] = event.target.value;

     setFormData({ ...formData, ...from });
   }

   useEffect(() => {
    getList();

     if (localStorage.getItem('projectName') && localStorage.getItem('projectName') != '') {
       // let project = getProject();         
      // if(project.artiest.length === 0 || project.calendar.length === 0 || project.location.length === 0 || project.schedule.length === 0 || project.team.length === 0){
       //    setMasterModal(true);
       // }else if(project.file == ''){
       //    //window.location.href = "/importScript";
       // }else{
       //    window.location.href = "/project-details";
       // }
     }

   }, []);

   console.log(count)

  return (
    <Fragment>
    <Col xxl='8' lg='12' className='box-col-12 px-0'>
      <Card>
        <CardBody className='pt-0 crdBdy'>
          {count==0 && <>
          <Row className='m-0 overall-card'>
            <Col xl='12' md='12' sm='7' className='p-0'>
              <div className='chart-right'>
                <Row>
                  <Col xl='12' className='col-xl-12'>
                    <CardBody className='p-0 crdBdy'>
                    <Container>
          <main id="main" className="main mainCls">
            <section className="section">
              <Container className="mt-5 mb-5 cont">
                <Row className="p-4 g-0 mar">
                  <Col lg={5} md={6}>
                   {/* <div className="embed-responsive embed-responsive-26by19">
                      <iframe title="demo" className="embed-responsive-item ifram1" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                        allowFullScreen></iframe>
                    </div>*/}
                  <div className="embed-responsive embed-responsive-10by9">
                  <iframe title="demo" className="embed-responsive-item ifram1" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowFullScreen></iframe>
                  </div>
                  </Col>
                  <Col lg={7} md={6} className="px-4 px-lg-0">
                    <div className="scnDVdo">
                    <h5 className="smp2">PROJECTS</h5>
                    <p className="smp">All of your projects in one place</p>
                    <p className="smp1">Welcome to SortMyShoot! This is where your photo and video projects will live. Create a project below</p>
                    <Button type="button" className="bnt1 btn addPro vndrBtn mt-2" onClick={() => { handleShow(); setValidated(false); setFormData({}) }}>+ New
                      Project</Button>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </main>
        </Container>
                    </CardBody>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <LightCard LightCardData={LightCardData} /> */}
          </Row>
          </>}
          
          <Row>
            <Col className="dfltPg px-0">
         <ProjectListing load={true} />
         <hr />
         {count>0 && <Row className="m-1">
            <Col lg={12} className="text-start">
            <Button type="button" className=" btn vndrBtn mt-2" onClick={() => { handleShow(); setValidated(false); setFormData({}) }}>+ New
                      Project</Button>
            </Col>
          </Row>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
    <Col>
             <Modal show={show} onHide={handleClose} size="lg">
           <Modal.Header closeButton></Modal.Header>
           {/* <div className="box1 mt-1"></div> */}
           <h6 className="text-center mt-3">Add New Project</h6>
           <Modal.Body>
             <Form noValidate validated={validated} onSubmit={handleSubmit}>

               <Row className="mb-2 m-4">
                 <Col xs="12" md="4" >
                   <Form.Label>Project Name<span className="star"> *</span></Form.Label>
                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required

                   />
                   <Form.Control.Feedback type="invalid">
                     Please provide a valid Name.
                   </Form.Control.Feedback>
                 </Col>

                 <Col xs="12" md="4">
                   <Form.Label>Language<span className="star"> *</span></Form.Label>
                   <Form.Select aria-label="Default select example" className="filedbg brdr selectFnt" value={formData.languageId ? formData.languageId : ""} onChange={e => handleChange({ name: 'languageId', event: e })} required>
                     <option value="">Select Language Type</option>
                     {masters && masters.language && masters.language.length > 0 && masters.language.map((item, index) => {
                       return <option value={item._id} key={index}>{item.name}</option>
                     })}
                   </Form.Select>
                   <Form.Control.Feedback type="invalid">
                     Please provide a valid language.
                   </Form.Control.Feedback>
                 </Col>

                 <Col xs="12" md="4">
                   <Form.Label>Project Type<span className="star"> *</span></Form.Label>
                   <Form.Select aria-label="Default select example" className="filedbg brdr selectFnt" value={formData.projectTypeId ? formData.projectTypeId : ""} onChange={e => handleChange({ name: 'projectTypeId', event: e })} required>
                     <option value="">Select Project Type</option>
                     {masters && masters.projectType && masters.projectType.length > 0 && masters.projectType.map((item, index) => {
                       return <option value={item._id} key={index}>{item.name}</option>
                     })}
                   </Form.Select>
                   <Form.Control.Feedback type="invalid">
                     Provide a valid project type.
                   </Form.Control.Feedback>
                 </Col>

               </Row>
               <Row className="mb-2 m-4">

                 <Col xs="12" md="4" >
                   <Form.Label>Production House<span className="star"> *</span></Form.Label>
                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.productionHouse ? formData.productionHouse : ""} onChange={e => handleChange({ name: 'productionHouse', event: e })} required />
                   <Form.Control.Feedback type="invalid">
                     Provide a valid Production House.
                   </Form.Control.Feedback>
                 </Col>

                 <Col xs="12" md="4" >
                   <Form.Label>Producer Name<span className="star"> *</span></Form.Label>
                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.producerName ? formData.producerName : ""} onChange={e => handleChange({ name: 'producerName', event: e })} required />
                   <Form.Control.Feedback type="invalid">
                     Provide a valid Producer Name.
                   </Form.Control.Feedback>
                 </Col>

                 <Col xs="12" md="4" >
                   <Form.Label>Director Name<span className="star"> *</span></Form.Label>
                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.directorName ? formData.directorName : ""} onChange={e => handleChange({ name: 'directorName', event: e })} required />
                   <Form.Control.Feedback type="invalid">
                     Provide a valid Director Name.
                   </Form.Control.Feedback>
                 </Col>

               </Row>
               <Row className="mb-2 m-4">

                 <Col xs="12" md="4" >
                   <Form.Label>Director of Photography<span className="star"> *</span></Form.Label>
                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.dop ? formData.dop : ""} onChange={e => handleChange({ name: 'dop', event: e })} required />
                   <Form.Control.Feedback type="invalid">
                     Please provide a valid dop
                   </Form.Control.Feedback>
                 </Col>

                 <Col xs="12" md="8" >
                   <Form.Label>Synopsis</Form.Label>
                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={250} as="textarea" className="filedbg brdr " value={formData.synopsis ? formData.synopsis : ""} onChange={e => handleChange({ name: 'synopsis', event: e })} />
                   <Form.Control.Feedback type="invalid">
                     Please provide a valid synopsis.
                   </Form.Control.Feedback>
                 </Col>
                 <Col xs="12" md="4">
                   <Form.Label>Genre<span className="star"> *</span></Form.Label>
                   <Form.Select className="filedbg brdr selectFnt" value={formData.genreId ? formData.genreId : ""} onChange={e => handleChange({ name: 'genreId', event: e })} required>
                     <option value="">Select Genre Type</option>
                     {masters && masters.genre && masters.genre.length > 0 && masters.genre.map((item, index) => {
                       return <option value={item._id} key={index}>{item.name}</option>
                     })}
                   </Form.Select>
                   <Form.Control.Feedback type="invalid">
                     Please provide a valid Genre.
                   </Form.Control.Feedback>
                 </Col>
                 <Col lg={8}>

                   <Form.Group className="mb-3" controlId="image">
                     <Form.Label>Image</Form.Label>
                     <Form.Control className="fileFnt" type="file" placeholder=" " accept="image/png, image/jpg, image/jpeg" onChange={(e) => { fileChangedHandler(e, 'image') }} />
                   </Form.Group>
                   <Form.Control.Feedback type="invalid">
                     It only accept png, jpg, jpeg files.
                   </Form.Control.Feedback>
                 </Col>
                 <Col lg={4} className="imgcss">
                   <img src={file} />
                 </Col>

               </Row>
               <Row className="justify-content-center">
                 {/*<Col lg={10}>
                   <Form.Group className="mb-2" controlId="projectName">
                     <Form.Control
                       type="text"
                       placeholder="Project Name"
                       autoFocus
                       value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)}
                       minLength="1" maxLength="60"
                       required
                     />
                     <Form.Control.Feedback type="invalid">
                         Please provide a valid project name[1-60 character].
                     </Form.Control.Feedback>
                   </Form.Group>
                 </Col>*/}

               </Row>
               <Row>
               <Col className="text-center">
                 <Button type="submit" className="lgbt btn-dark lgbt2" disabled={isDisabled}>
                   Save
                 </Button>
               </Col>
             </Row>
             </Form>
             {/* <Row className="mb-2 m-4 mt-1" >
               <Col xs="12" md="4" ></Col>
               <Form>
                 <Form.Group className="mb-3" controlId="image">
                   <Form.Label>Import Script</Form.Label>
                   <Form.Control className="fileFnt" type="file" placeholder=" " accept=".pdf" onChange={(e) => { fileChangedHandler(e, 'image') }} />
                 </Form.Group>
                 <Form.Control.Feedback type="invalid">
                   It only accept png, jpg, jpeg files.
                 </Form.Control.Feedback>
               </Form>
             </Row> */}
           
           </Modal.Body>
         </Modal>
         {masterModal &&
           <ProjectMasterModal />
         }
    </Col>
    </Fragment>
  );
};

export default memo(Project)

// import React, { Fragment, useState, memo, useEffect } from "react";
// import { Col, Container, Form, Row, Button, Modal } from "react-bootstrap";
// import '../../index.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { addProject } from "../../action/Admin.action.js";
// import { setAlert, setProjectDetails } from '../../slices/home.js';
// import ProjectListing from "../components/ProjectListing";
// import ProjectMasterModal from '../components/ProjectMasterModal';
// //import { getProject } from '../../common/function';

// const Project = () => {

//   const navigate = useNavigate();

//   const masters = useSelector((state) => state.home.masters);


//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [masterModal, setMasterModal] = useState(false);
//   const [file, setFile] = useState("");
//   const [formErrors, setFormErrors] = useState("");

//   const dispatch = useDispatch();
//   // const [validated, setValidated] = useState(false);
//   const [validated, setValidated] = useState(false);
//   const [formData, setFormData] = useState({});
//   const [isDisabled, setDisabled] = useState(false);


//   const handleSubmit = async (event) => {
//     const form = event.currentTarget;


//     if (form.checkValidity() === false) {

//       event.preventDefault();
//       event.stopPropagation();
//       setValidated(true);

//     }

//     else {
//       event.preventDefault();
//       event.stopPropagation();
//       //setValidated(false);
//       setDisabled(true);

//       let msg = "You have successfully created project.";

//       // dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' })); 
//       let postData = new FormData();
//       for (let key in formData) {
//         postData.append(key, formData[key]);
//       }
//       let resp = await addProject(postData);

//       setDisabled(false);

//       if (resp.code === 200) {

//         dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
//         handleClose();
//         localStorage.setItem('projectName', resp.data.name);
//         localStorage.setItem('projectId', resp.data._id);
//         localStorage.setItem('project', JSON.stringify(resp.data));
//         dispatch(setProjectDetails(resp.data));
//         navigate("/overView");
//         setMasterModal(true);

//       } else {

//         dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
//       }
//       return false;
//     }

//   };

//   const fileChangedHandler = (event, elename) => {
//     event.preventDefault();
//     let formErrorsData = formErrors;

//     let formDataData = formData;
//     let file = event.target.files[0];
//     setFile(URL.createObjectURL(event.target.files[0]));
//     if (!file && file === undefined) {

//       return false;
//     }
//     var fileName = (file && file.name ? file.name : '');
//     let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

//     if (file.size > 20971520) {
//       formErrorsData[elename] = "File size not greater then 20MB.";
//     } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

//       // formErrorsData[elename] = "";
//       // formErrorsData["preview"] = "";
//       formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
//       formDataData['fileType'] = extensions;
//       formDataData[elename] = event.target.files[0];
//       setFormData({ ...formData, ...formDataData });
//     } else {
//       formErrorsData[elename] = "File extensions doesn't match.";
//     }
//     setFormErrors({ ...formErrors, ...formErrorsData });
//   }

//   const handleChange = (post) => {

//     let name = post.name;
//     let event = post.event;
//     let from = { ...formData };

//     from[name] = event.target.value;

//     setFormData({ ...formData, ...from });
//   }

//   useEffect(() => {

//     if (localStorage.getItem('projectName') && localStorage.getItem('projectName') != '') {
//       // let project = getProject();         
//       // if(project.artiest.length === 0 || project.calendar.length === 0 || project.location.length === 0 || project.schedule.length === 0 || project.team.length === 0){
//       //    setMasterModal(true);
//       // }else if(project.file == ''){
//       //    //window.location.href = "/importScript";
//       // }else{
//       //    window.location.href = "/project-details";
//       // }
//     }

//   }, []);

//   // console.log(formData)

//   return (
//     <>
//       <div className="mainCls">
//         <Container>
//           <main id="main" className="main mainCls">
//             <section className="section">
//               <Container className="mt-5 mb-5 cont">
//                 <Row className="p-4 g-0 mar">
//                   <Col lg={5}>
//                     <div className="embed-responsive embed-responsive-26by9">
//                       <iframe title="demo" className="embed-responsive-item ifram1" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
//                         allowFullScreen></iframe>
//                     </div>
//                   </Col>
//                   <Col lg={7}>
//                     <h5 className="smp2">PROJECTS</h5>
//                     <p className="smp">All of your projects in one place</p>
//                     <p className="smp1">Welcome to SortMyShoot! This is where your photo and video projects will live. Create a project below</p>
//                     <Button type="button" className="bnt1 btn addPro mt-3" onClick={() => { handleShow(); setValidated(false); setFormData({}) }}>+ New
//                       Project</Button>
//                   </Col>
//                 </Row>
//               </Container>
//             </section>
//           </main>
//         </Container>
//         <hr />
//         <ProjectListing load={true} />

//         <Modal show={show} onHide={handleClose} size="lg">
//           <Modal.Header closeButton></Modal.Header>
//           {/* <div className="box1 mt-1"></div> */}
//           <h6 className="text-center mt-3">Name Your Project</h6>
//           <Modal.Body>
//             <Form noValidate validated={validated} onSubmit={handleSubmit}>

//               <Row className="mb-2 m-4">
//                 <Col xs="12" md="4" >
//                   <Form.Label>Project Name<span className="star"> *</span></Form.Label>
//                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required

//                   />
//                   <Form.Control.Feedback type="invalid">
//                     Please provide a valid Name.
//                   </Form.Control.Feedback>
//                 </Col>

//                 <Col xs="12" md="4">
//                   <Form.Label>Language<span className="star"> *</span></Form.Label>
//                   <Form.Select aria-label="Default select example" className="filedbg brdr selectFnt" value={formData.languageId ? formData.languageId : ""} onChange={e => handleChange({ name: 'languageId', event: e })} required>
//                     <option value="">Select Language Type</option>
//                     {masters && masters.language && masters.language.length > 0 && masters.language.map((item, index) => {
//                       return <option value={item._id} key={index}>{item.name}</option>
//                     })}
//                   </Form.Select>
//                   <Form.Control.Feedback type="invalid">
//                     Please provide a valid language.
//                   </Form.Control.Feedback>
//                 </Col>

//                 <Col xs="12" md="4">
//                   <Form.Label>Project Type<span className="star"> *</span></Form.Label>
//                   <Form.Select aria-label="Default select example" className="filedbg brdr selectFnt" value={formData.projectTypeId ? formData.projectTypeId : ""} onChange={e => handleChange({ name: 'projectTypeId', event: e })} required>
//                     <option value="">Select Project Type</option>
//                     {masters && masters.projectType && masters.projectType.length > 0 && masters.projectType.map((item, index) => {
//                       return <option value={item._id} key={index}>{item.name}</option>
//                     })}
//                   </Form.Select>
//                   <Form.Control.Feedback type="invalid">
//                     Provide a valid project type.
//                   </Form.Control.Feedback>
//                 </Col>

//               </Row>
//               <Row className="mb-2 m-4">

//                 <Col xs="12" md="4" >
//                   <Form.Label>Production House<span className="star"> *</span></Form.Label>
//                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.productionHouse ? formData.productionHouse : ""} onChange={e => handleChange({ name: 'productionHouse', event: e })} required />
//                   <Form.Control.Feedback type="invalid">
//                     Provide a valid Production House.
//                   </Form.Control.Feedback>
//                 </Col>

//                 <Col xs="12" md="4" >
//                   <Form.Label>Producer Name<span className="star"> *</span></Form.Label>
//                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.producerName ? formData.producerName : ""} onChange={e => handleChange({ name: 'producerName', event: e })} required />
//                   <Form.Control.Feedback type="invalid">
//                     Provide a valid Producer Name.
//                   </Form.Control.Feedback>
//                 </Col>

//                 <Col xs="12" md="4" >
//                   <Form.Label>Director Name<span className="star"> *</span></Form.Label>
//                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.directorName ? formData.directorName : ""} onChange={e => handleChange({ name: 'directorName', event: e })} required />
//                   <Form.Control.Feedback type="invalid">
//                     Provide a valid Director Name.
//                   </Form.Control.Feedback>
//                 </Col>

//               </Row>
//               <Row className="mb-2 m-4">

//                 <Col xs="12" md="4" >
//                   <Form.Label>Director of Photography<span className="star"> *</span></Form.Label>
//                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr " value={formData.dop ? formData.dop : ""} onChange={e => handleChange({ name: 'dop', event: e })} required />
//                   <Form.Control.Feedback type="invalid">
//                     Please provide a valid dop
//                   </Form.Control.Feedback>
//                 </Col>

//                 <Col xs="12" md="8" >
//                   <Form.Label>Synopsis</Form.Label>
//                   <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={250} as="textarea" className="filedbg brdr " value={formData.synopsis ? formData.synopsis : ""} onChange={e => handleChange({ name: 'synopsis', event: e })} />
//                   <Form.Control.Feedback type="invalid">
//                     Please provide a valid synopsis.
//                   </Form.Control.Feedback>
//                 </Col>
//                 <Col xs="12" md="4">
//                   <Form.Label>Genre<span className="star"> *</span></Form.Label>
//                   <Form.Select className="filedbg brdr selectFnt" value={formData.genreId ? formData.genreId : ""} onChange={e => handleChange({ name: 'genreId', event: e })} required>
//                     <option value="">Select Genre Type</option>
//                     {masters && masters.genre && masters.genre.length > 0 && masters.genre.map((item, index) => {
//                       return <option value={item._id} key={index}>{item.name}</option>
//                     })}
//                   </Form.Select>
//                   <Form.Control.Feedback type="invalid">
//                     Please provide a valid Genre.
//                   </Form.Control.Feedback>
//                 </Col>
//                 <Col lg={8}>

//                   <Form.Group className="mb-3" controlId="image">
//                     <Form.Label>Image</Form.Label>
//                     <Form.Control className="fileFnt" type="file" placeholder=" " accept="image/png, image/jpg, image/jpeg" onChange={(e) => { fileChangedHandler(e, 'image') }} />
//                   </Form.Group>
//                   <Form.Control.Feedback type="invalid">
//                     It only accept png, jpg, jpeg files.
//                   </Form.Control.Feedback>
//                 </Col>
//                 <Col lg={4} className="imgcss">
//                   <img src={file} />
//                 </Col>

//               </Row>
//               <Row className="justify-content-center">
//                 {/*<Col lg={10}>
//                   <Form.Group className="mb-2" controlId="projectName">
//                     <Form.Control
//                       type="text"
//                       placeholder="Project Name"
//                       autoFocus
//                       value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)}
//                       minLength="1" maxLength="60"
//                       required
//                     />
//                     <Form.Control.Feedback type="invalid">
//                         Please provide a valid project name[1-60 character].
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>*/}

//               </Row>
//               <Row>
//               <Col className="text-center">
//                 <Button type="submit" className="lgbt btn-dark btnk btnclrrr11" disabled={isDisabled}>
//                   Save
//                 </Button>
//               </Col>
//             </Row>
//             </Form>
//             {/* <Row className="mb-2 m-4 mt-1" >
//               <Col xs="12" md="4" ></Col>
//               <Form>
//                 <Form.Group className="mb-3" controlId="image">
//                   <Form.Label>Import Script</Form.Label>
//                   <Form.Control className="fileFnt" type="file" placeholder=" " accept=".pdf" onChange={(e) => { fileChangedHandler(e, 'image') }} />
//                 </Form.Group>
//                 <Form.Control.Feedback type="invalid">
//                   It only accept png, jpg, jpeg files.
//                 </Form.Control.Feedback>
//               </Form>
//             </Row> */}
           
//           </Modal.Body>
//         </Modal>
//         {masterModal &&
//           <ProjectMasterModal />
//         }
//       </div>
//     </>
//   );
// }

// export default memo(Project);