import React, { useState, memo, useEffect } from "react";
import { Card, Col, Container, Form, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import pv1 from '../../assets/images/OldProImg/PictureVehicle/car1.png'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { setAlert } from "../../slices/home";
import AlertBox from "../component/AlertBox";
import { getVendorPictureDataAction, addEditVendorPictureAction, getCrewListAction, getVendorDataAction } from "../../action/Admin.action";
// import { Link } from "react-router-dom";
import { listSceneScheduleAction } from "../../action/Admin.action";
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { imgPath } from "../common/function";
import { VehicleType } from "../common/constant";
import line1 from '../../assets/images/OldProImg/line1.png'

const PictureVehicle = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formErrors, setFormErrors] = useState({});
    const [file, setFile] = useState("");
    const handleShow = () => setShow(true);
    const [artistList, setArtistList] = useState([]);
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const [vendorList1, setVendorList1] = useState([]);
    const [sceneList, setSceneList] = useState();
    const masters = useSelector((state) => state.home.masters);
    const navigate = useNavigate();

    // const getList1 = async () => {
    //     let resp = await projectDetailAction();
    //     if (resp.code === 200) {
    //         if (resp.data.artiest && resp.data.artiest.length) {
    //             let artiests = resp.data.artiest;
    //             let artiestsList = [];
    //             for (let i in artiests) {
    //                 let row = artiests[i];
    //                 artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
    //             }
    //             setArtistList(artiestsList);
    //         }
    //     }
    //     let resp1 = await getVendorDataAction();
    //     if (resp1.code === 200) {
    //         setVendorList1(resp1.data)
    //     }

    //     const resp2 = await listSceneScheduleAction();
    //     if (resp2.code === 200) {
    //         setSceneList(resp2.data);
    //     }
    // }

    const getList1 = async () => {
        let resp = await getCrewListAction();
        if (resp.code === 200) {
            let artiestsList = [];
            {resp && (resp.data.length>0 && (resp.data).map((v,i)=>{
                if(v.department=="000000000000000000000001"){
                    artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
                }
            }))}
            setArtistList(artiestsList);
        }

        let resp1 = await getVendorDataAction();
        if (resp1.code === 200) {
            setVendorList1(resp1.data)
        }

        const resp2 = await listSceneScheduleAction();
        if (resp2.code === 200) {
            setSceneList(resp2.data);
        }

    }

    const getList = async () => {
        const resp = await getVendorPictureDataAction();
        if (resp.code === 200) {
            setVendorList(resp.data);
        }
    }
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let postData = new FormData();
            for (let key in formData) {
                if (key == 'vehicleForWhom') {

                } else {
                    postData.append(key, formData[key]);
                }
            }

            let artistNoList = [];
            for (let j in formData.vehicleForWhom) {

                let a = formData.vehicleForWhom[j];
                artistNoList.push(a.value);
            }
            postData.append('vehicleForWhom', JSON.stringify(artistNoList));
            let resp = await addEditVendorPictureAction(postData);
            setDisabled(false);


            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
                getList();
                handleClose();
                setValidated(false);
                setFormData({})
            } else {
                var data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            return false;
        }
    };

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;
        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {
            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();
        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {
            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";
        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }


    const handleChange = (name, event, extra = "") => {

        let from = { ...formData };
        if (extra === 'multi') {
            from[name] = event;
        } else if (name === 'vehicleForWhom') {
            let arr = [];

            if (event.target.checked) {
                arr.push(event.target.value);
            } else {
                const index = arr.indexOf(event.target.value);
                if (index !== -1) {
                    arr.splice(index, 1);
                }
            }
            from[name] = arr;
        } else {
            from[name] = event.target.value;
        }
        setFormData({ ...formData, ...from });
    };

    useEffect(() => {
        getList();
        getList1();
    }, [])

    return (
        <>
            <div className="pt-4">
                <Card>
                    <Card.Body className="crdBdy">
                    <Row className="mt-3 pt-2">
                {/* <i className="fa fa-arrow-left hand" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}></i> */}
                    <Col className="col-8 col-lg-10 col-sm-6 col col"><h6 className="crdHd1">Picture Vehicle</h6></Col>
                    {/* <Col className="col-2 col-lg-2 col-sm-6 text-end  col px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={()=>{navigate(`${process.env.PUBLIC_URL}/travel-management`)}}>Back</Button></Col> */}
                    <Col className="col-2 col-lg-2 col-sm-6 text-start col px-0"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="vndrBtn" size="sm" onClick={() => { handleShow(); setValidated(false) }}>+ Add</Button></Col>
                </Row>
                <hr />
                <Row className="mt-2">
                    {
                        vendorList && vendorList.length > 0 && vendorList.map((val, ind) => {
                            return <Col lg={3} md={6}   className="mt-1 col col-12 col-md-6" key={ind} >
                                <Card className="text-center prDtlCrd hand brdr" style={{ borderRadius: '20px' }} onClick={() => { navigate(`${process.env.PUBLIC_URL}/picture-vehicleDetails`, { state: val }) }}>
                                    <Card.Body className="pb-0 crd1Bdy">
                                        <h6 className="font12 mb-0">Name of Vehicle</h6>
                                        <Card.Text class="d-inline-block text-truncate  mb-0 font12" style={{ maxWidth: '90%' }}>
                                            {val.vehicleName}
                                        </Card.Text>
                                    </Card.Body>
                                    <img src={val.image ? imgPath(val.image) : pv1} alt="" className="img-fluid pvImg" />
                                </Card>
                            </Col>
                        })
                    }
                </Row>
                    </Card.Body>
                </Card>
            </div>
            <Modal show={show} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                    <Modal.Title className='crdHd1'>Picture Vehicle</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-1">

                    <Row className="pt-1">
                        <Col className="p-0">
                            <img src={line1} alt="" className="img-fluid12" />
                        </Col>
                    </Row>
                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                        <Row className="mx-2">
                            <Col lg={4} className="txt">
                                <Form.Group className="mb-1" controlId="vehicleName">
                                    <Row><Col><Form.Label>Vehicle Name<span className='star'>*</span></Form.Label>
                                    </Col></Row>
                                    <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" autoFocus onChange={e => handleChange('vehicleName', e)} maxLength={16} minLength={2} value={formData.vehicleName ? formData.vehicleName : ""} required />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Vehicle Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group className="mb-1" controlId="typeOfVehicle">
                                    <Form.Label>Type of vehicle<span className='star'>*</span></Form.Label>
                                    <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                        onChange={e => handleChange('typeOfVehicle', e)} value={formData.typeOfVehicle ? formData.typeOfVehicle : ""} required>
                                        <option value="">Select Vehicle Type</option>
                                        {VehicleType && VehicleType.length && VehicleType.map((val, ind) => {
                                            return <option value={val.id} key={ind}>{val.name}</option>
                                        })}

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Type of vehicle.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group controlId="sceneNo">
                                    <Row><Col><Form.Label>Vehicle for scene no.<span className='star'>*</span></Form.Label>
                                    </Col></Row>
                                    <Form.Select className="brdr frmcnt" onChange={e => handleChange('sceneNo', e)} value={formData.sceneNo ? formData.sceneNo : ""} required>
                                        <option value="">Select Option</option>
                                        {
                                            sceneList && sceneList.length > 0 && sceneList.map((val, ind) => {
                                                return (
                                                    val && (val.sceneData).length) > 0 && (val.sceneData).map((itm, indx) => {
                                                        return <option key={indx} value={itm._id}>{itm.scene}</option>
                                                    })
                                            })
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Vehicle for scene no.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group className="mb-1" controlId="vendorId">
                                    <Row><Col><Form.Label>Vendore Name<span className='star'>*</span></Form.Label>
                                    </Col></Row>
                                    <Form.Select className="brdr frmcnt" onChange={e => handleChange('vendorId', e)} value={formData.vendorId ? formData.vendorId : ""} required >
                                        <option value="">Select Vendor</option>
                                        {vendorList1 && vendorList1.length > 0 && vendorList1.map((val, ind) => {
                                            return <option value={(val._id)} key={ind}  >{val.name}</option>
                                        })}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Vendore Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group className="mb-1" controlId="location">
                                    <Form.Label>Location<span className='star'>*</span></Form.Label>
                                    <Form.Control className="brdr frmcnt" minLength={2} maxLength={32} type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" placeholder="Name of location" value={formData.location ? formData.location : ""} onChange={e => handleChange('location', e)} required />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Location.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group controlId="vehicleForWhom" className="txt">
                                    <Form.Label >For Whom<span className='star'>*</span></Form.Label>
                                    <Select className="brdr1 mselect frmcnt" aria-label="Default select example"
                                        value={formData.vehicleForWhom ? formData.vehicleForWhom : []}
                                        placeholder="Select"
                                        isMulti
                                        required
                                        options={artistList}
                                        // className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={e => handleChange('vehicleForWhom', e, 'multi')}>
                                        <option value="">Select Option</option>
                                        {
                                            artistList && artistList.length > 0 && artistList.map((val, ind) => {
                                                return <option value={(val.value)} key={ind}  >{val.label}</option>
                                            })
                                        }
                                    </Select>
                                    {validated === true && ((!formData.vehicleForWhom) || formData.vehicleForWhom && formData.vehicleForWhom.length === 0) &&
                                        <p className="err">This field is required</p>}
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a Selection.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group controlId="price">
                                    <Row><Col><Form.Label>Price<span className='star'>*</span></Form.Label>
                                    </Col></Row>
                                    <Form.Control className="brdr frmcnt" type="text" onChange={e => handleChange('price', e)} value={formData.price ? formData.price : ""} maxLength={15} minLength={1} pattern='^(?=.*[1-9])\d{1,15}(?:\.\d{1,2})?$' required />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Price.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={4} className="txt">
                                <Form.Group className="mb-1" controlId="status">
                                    <Form.Label>Status<span className='star'>*</span></Form.Label>
                                    <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                        onChange={e => handleChange('status', e)} value={formData.status ? formData.status : ""} required>
                                        <option value="">Select Status</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Status.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={4} className="txt">
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Image</Form.Label>
                                    <Form.Control className="brdr frmcnt" type="file" ize="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg"
                                        onChange={e => fileChangedHandler(e, 'image')}
                                    />
                                </Form.Group>
                                <Form.Control.Feedback type="invalid">
                                    It only accept png, jpg, jpeg files.
                                </Form.Control.Feedback>
                            </Col>
                            <Col lg={4} className='imgcss'>
                                <img src={file} />
                            </Col>
                        </Row>

                        <Row className="g-2 mx-2">
                            <Col className='col-6 col-lg-8 col-sm-6 '>
                                <Button className="btnk cldrBtn" onClick={() => { setFormData({}); handleClose(); }}>
                                    Cancel
                                </Button>
                            </Col>
                            <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                <Button type='submit' className="btnk cldrBtn"
                                    disabled={isDisabled}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default memo(PictureVehicle);