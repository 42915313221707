import React, { memo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faEye,
  faPencil,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { _deleteAction, changeStatusAction } from "../../action/Admin.action";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { setAlert } from "../../slices/home";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import LocationViewModal from "./LocationViewModal";

const CardView = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setFormData(data);
  };

  const _delete = async (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            dispatch(
              setAlert({
                open: false,
                severity: "success",
                msg: "Loading...",
                type: "loader",
              })
            );

            const resp = await _deleteAction({ _id: id, type: "locationType" });
            if (resp.code === 200) {
              props.getDataList();
              dispatch(
                setAlert({
                  open: true,
                  severity: "success",
                  msg: "You have successfully deleted Location Type.",
                  type: "",
                })
              );
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const changeStatus = async (id, status, text) => {
    Swal.fire({
      title: "Confirm to " + text,
      text: "Are you sure to do this.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
    }).then(async (result) => {
      if (result.value) {
        const resp = await changeStatusAction({
          _id: id,
          status: status,
          type: "locationType",
        });
        if (resp.code === 200) {
          Swal.fire({
            title: text + "!",
            text: "Your lead has been " + text,
            icon: "success",
          });
          props.getDataList();
        }
      }
    });
  };

  return (
    <>
      <tr>
        <td>{props.sno}</td>
        <td>{props.item.name}</td>
        {/* <td>{props.item.status}</td> */}
        <td className={props.item.status == 1 ? "text-success" : "text-danger"}>
          {props.item.status == 1 ? "Active" : "InActive"}
        </td>
        <td>
          <span
            className="px-1 hand text-info"
            onClick={(e) => handleShow(props.item)}
          >
            <FontAwesomeIcon icon={faEye} size="xs" />
          </span>
          &ensp;
          <Link
            className="px-1"
            to={`${process.env.PUBLIC_URL}/admin/location-types/edit`}
            state={props.item}
            title="Edit Location Type"
          >
            <FontAwesomeIcon icon={faPencil} size="xs" />
          </Link>
          &ensp;
          {parseInt(props.item.status) === 1 ? (
            <>
              <span
                className="px-1 hand"
                onClick={(e) => changeStatus(props.item._id, 0, "InActive")}
              >
                <FontAwesomeIcon icon={faBan} size="xs" />
              </span>
              &ensp;
            </>
          ) : (
            <>
              <span
                className="px-1 hand text-success"
                title="Lead Active"
                onClick={(e) => changeStatus(props.item._id, 1, "Active")}
              >
                <FontAwesomeIcon icon={faCheck} size="xs" />
              </span>
              &ensp;
            </>
          )}
          <span
            title="Location Type delete"
            className="px-1 hand text-danger"
            onClick={(e) => _delete(props.item._id)}
          >
            <FontAwesomeIcon icon={faTrash} size="xs" />
          </span>
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <LocationViewModal formData={formData} />
          </Modal>
        </td>
      </tr>
    </>
  );
};

export default memo(CardView);
