// import { Form, Row, Col, Button, Dropdown, DropdownButton } from "react-bootstrap";
// import { useDispatch, useSelector } from 'react-redux';
// import React, { useState, useEffect, memo } from 'react';
// import { addEditCrewAction } from "../../action/Admin.action";
// import { setAlert } from '../../slices/home.js';
// import { objToArray } from "../common/function";
// import { Locationpermission } from "../common/constant";
// import { Link } from "react-router-dom";
// import { emailValidation, checkMobileNumber, checkNumber, checkName } from "../common/function";
// import { ToastContainer, toast } from "react-toastify";

// const AddEditArtist = (props) => {

//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);

//     const masters = useSelector((state) => state.home.masters);

//     const dispatch = useDispatch();
//     const [validated, setValidated] = useState(false);
//     const [formData, setFormData] = useState({});
//     const [isDisabled, setDisabled] = useState(false);
//     const [error, setError] = useState(false);
//     const [disabled1, setdisabled1] = useState(true);

//     const [formErrors, setFormErrors] = useState({});


//     const handleSubmit = async (event) => {
//         const form = event.currentTarget;        

//         if (form.checkValidity() === false) {

//             event.preventDefault();
//             event.stopPropagation();
//             setValidated(true);

//         } else {
//             event.preventDefault();
//             event.stopPropagation();
//             setDisabled(true);

//             let msg = "Done...!";

//             let resp = await addEditCrewAction(formData);
//             setDisabled(false);

//             if (resp.code === 200) {
//                 if(props.getList()){

//                 }
//                 toast.success(msg);
//                 setFormData({});
//                 setValidated(false)
//             } else {
//                 setDisabled(false);
//                 toast.error(resp.msg);
//             }

//             return false;
//         }
//     };

//     const handleChange = (post) => {

//         let name = post.name;
//         let event = post.event;
//         let from = { ...formData };

//         from[name] = event.target.value;

//         setFormData({ ...formData, ...from });
//     }
//     // console.log(formData);

//     useEffect(() => {

//         if (props.editData && props.editData._id) {
//             let edit = props.editData;
//             setFormData({ _id: edit._id, name: edit.name, number: edit.number, mobile: edit.mobile, email: edit.email, characterName: edit.characterName });
//         }


//     }, [props.editData])

//     // const NextPage = () => {
//     //     window.location.href = "/importScript";
//     // }


//     return (
//         <>
//             <div>
//                 <div className="col-12 w-6" style={{ margin: "auto" }}>
//                     {/* <div className="modal-header teamBox">
//                     </div> */}
//                     {/* <h5 className="paraCenter mb-3 mt-3">{localStorage.getItem("projectName")}</h5> */}

//                     <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
//                   <Form.Group className="mb-3" controlId="name">
//                     <Form.Control
//                       type="text"
//                       placeholder="Name"
//                       autoFocus
//                       className="frmcntadd "
//                       pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
//                       minLength="2"
//                       maxLength="32"
//                       value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required />
//                     <Form.Control.Feedback type="invalid">
//                       Please provide a valid name.
//                     </Form.Control.Feedback>
//                   </Form.Group>

//                   <Form.Group className="mb-3" controlId="Number">
//                     <Form.Control
//                       type="text"
//                       placeholder="Number"
//                       minLength={1}
//                       maxLength={10}
//                       className="frmcntadd "
//                       pattern='^(0*[1-9][0-9]{0,9})$'
//                       value={formData.number ? formData.number : ""} onChange={e => handleChange({ name: 'number', event: e })} required />
//                     <Form.Control.Feedback type="invalid">
//                       Please provide a valid number.
//                     </Form.Control.Feedback>
//                   </Form.Group>

//                   <Form.Group className="mb-3" controlId="email">
//                     <Form.Control
//                       type="email"
//                       placeholder="Email"
//                       className="frmcntadd "
//                       pattern = "^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
//                       value={formData.email ? formData.email : ""} onChange={e => handleChange({ name: 'email', event: e })} required />
//                     <Form.Control.Feedback type="invalid">
//                       Please provide a valid email.
//                     </Form.Control.Feedback>
//                   </Form.Group>

//                   <Form.Group className="mb-3" controlId="mobile">
//                     <Form.Control
//                       type="text"
//                       placeholder="Mobile"
//                       className="frmcntadd "
//                       value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange({ name: 'mobile', event: e })} minLength={10} maxLength={10} pattern='^[6-9][0-9]{9}$' required />
//                     <Form.Control.Feedback type="invalid">
//                       Please provide a valid mobile.
//                     </Form.Control.Feedback>
//                   </Form.Group>

//                   <Form.Group className="mb-3" controlId="characterName">
//                     <Form.Control
//                       type="text"
//                       pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
//                       minLength={2}
//                       maxLength={120}
//                       className="frmcntadd "
//                       placeholder="character Name"
//                       value={formData.characterName ? formData.characterName : ""} onChange={e => handleChange({ name: 'characterName', event: e })} required />
//                     <Form.Control.Feedback type="invalid">
//                       Please provide a valid character Name.
//                     </Form.Control.Feedback>
//                   </Form.Group>

//                   <Row className="mt-4 mb-4 g-2">
//                     <Col lg={6}>
//                       <Button type="submit" className="vndrBtn" onSubmit={handleSubmit}>
//                        Save
//                       </Button>
//                     </Col>
//                   </Row>
//                 </Form>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default AddEditArtist;



import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/home';
import { Link } from "react-router-dom";
import { addEditCrewAction } from '../../action/Admin.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { getRoleDataAction } from '../../action/Admin.action';
import { ToastContainer, toast } from "react-toastify";


const AddEditArtist = (props) => {

  const dispatch = useDispatch();
  const masters = useSelector((state) => state.home.masters);

  const [formData, setFormData] = useState({ department: "000000000000000000000001" });
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [role, setRole] = useState({});

  const getList = async (event) => {
    let resp = await getRoleDataAction();
    if (resp.code == 200) {
      setRole(resp.data)
    }
  }

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      let msg = "You have successfully created member.";
      let formDataData = { ...formData };
      let item = formData.role;
      let list = [];
      for (let j in item) {
        list.push(item[j].value);
      }
      formDataData.role = list;

      let resp = await addEditCrewAction(formDataData);
      setDisabled(false);

      if (resp.code === 200) {
        setValidated(false);
        if (props.getList()) {

        }
        toast.success(resp.msg);
        // dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
        setFormData({ department: "000000000000000000000001" });
      } else {
        setDisabled(false);
        toast.error(resp.msg);
        // dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
      }

      return false;
    }
  };

  const handleChange = (post) => {

    let name = post.name;
    let event = post.event;
    let from = { ...formData };

    from[name] = event.target.value;

    setFormData({ ...formData, ...from });
  }

  useEffect(() => {
    getList();

    if (props.editData && props.editData._id) {
      let edit = props.editData;
      setFormData({ _id: edit._id, name: edit.name, number: edit.number, designation: edit.designation, department: edit.department, email: edit.email, mobile: edit.mobile, role: edit.role });
    }
    if (props.refresh === true) {
      setFormData({ department: "000000000000000000000001" })
    }

  }, [props.editData])

  console.log(formData);

  return (
    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>

      <Form.Group className="mb-3" controlId="name">
        <Form.Control
          type="text"
          placeholder="Name"
          autoFocus
          className="frmcntadd "
          pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
          minLength="2"
          maxLength="32"
          value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required />
        <Form.Control.Feedback type="invalid">
          Please provide a valid name.
        </Form.Control.Feedback>
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="department">
                    <Form.Control
                      type="text"
                      placeholder="department"
                      minLength={1}
                      maxLength={10}
                      className="frmcntadd "
                      pattern='^(0*[1-9][0-9]{0,9})$'
                      value="000000000000000000000001" onChange={e => handleChange({ name: 'department', event: e })} required />
                    <Form.Control.Feedback type="invalid">
                      Please provide a valid department.
                    </Form.Control.Feedback>
                  </Form.Group> */}

      <Form.Group className="mb-3" controlId="Number">
        <Form.Control
          type="text"
          placeholder="Number"
          minLength={1}
          maxLength={10}
          className="frmcntadd "
          pattern='^(0*[1-9][0-9]{0,9})$'
          value={formData.number ? formData.number : ""} onChange={e => handleChange({ name: 'number', event: e })} required />
        <Form.Control.Feedback type="invalid">
          Please provide a valid number.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="email">
        <Form.Control
          type="email"
          placeholder="Email"
          className="frmcntadd "
          pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
          value={formData.email ? formData.email : ""} onChange={e => handleChange({ name: 'email', event: e })} required />
        <Form.Control.Feedback type="invalid">
          Please provide a valid email.
        </Form.Control.Feedback>

      </Form.Group>

      <Form.Group className="mb-3" controlId="mobile">
        <Form.Control
          type="text"
          placeholder="Mobile"
          className="frmcntadd "
          value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange({ name: 'mobile', event: e })} minLength={10} maxLength={10} pattern='^[6-9][0-9]{9}$' required />
        <Form.Control.Feedback type="invalid">
          Please provide a valid mobile.
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="characterName">
        <Form.Control
          type="text"
          pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
          minLength={2}
          maxLength={120}
          className="frmcntadd "
          placeholder="character Name"
          value={formData.characterName ? formData.characterName : ""} onChange={e => handleChange({ name: 'characterName', event: e })} required />
        <Form.Control.Feedback type="invalid">
          Please provide a valid character Name.
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mt-4 mb-4 g-2">
        <Col lg={4}>
          <Button type="submit" className="bnt1 btn defaultBtn btn btn-primary" disabled={isDisabled} onSubmit={handleSubmit}>
            Save
          </Button>
        </Col>
        {/* <Col lg={4} className="mt-3">
            <span className='hand' title='Refresh' onClick={()=>{setFormData({department:"000000000000000000000001"})}}><FontAwesomeIcon size='lg' icon={faArrowsRotate} /></span>
            </Col> */}
      </Row>
    </Form>
  );
}

export default memo(AddEditArtist);
