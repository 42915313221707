import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";

const TermsAndConditions = ({ selected }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            <HomeHeader />
            <section className="terms-and-conditions-section">
                <div className="terms-container">
                    <h2 className="text-center" style={{ color: '#FFFFFF', paddingBottom: '15px' }}>Terms and Conditions</h2>
                    <p className="intro-text">
                        Welcome to the Film Crew app (the “App”). Your access and use of the App and its features, products, and services provided by Film Crew Inc. (“Film Crew,” “we,” “us,” or “our”) are governed by the terms and conditions in this Terms of Use (this “ToU”).
                    </p>
                    <p className="intro-text">By accessing or using the App, or by clicking to accept or agree to this ToU if presented to you in a user interface, you agree to be bound by this ToU. If you do not agree to these terms, you may not use the App.</p>
                    <p className="intro-text"><span className="spanText">1.ToU Updates</span> Film Crew may update this ToU at any time, and we will post the updated version on the App. You understand and agree that by continuing to use the App after updates are posted, you accept the revised ToU. If you do not agree with any portion of the updated ToU, you must stop using the App immediately.</p>
                    <p className="intro-text"> <span className="spanText">2.Provision of the App</span> You are responsible for any Internet connection and telecommunications fees that you incur while accessing the App. Film Crew may make changes to the App at any time without prior notice to you.
                    </p>
                    <p className="intro-text"> <span className="spanText">3.Termination of Service</span>  Film Crew reserves the right to deny access to the App at our sole discretion. We may stop providing the App or restrict your use of it at any time, with or without notice, for any reason, including but not limited to violations of this ToU or suspected fraudulent or illegal activities. If access to your account is disabled, you may be unable to access your account details or any content stored therein.
                    </p>
                    <span className="spanText text-white">4.Accounts and Security</span>
                    <p className="intro-text"> <span className="spanText">4.1. Account Creation:</span> To use the App, you must create an account by completing the registration process. You agree to provide accurate, current, and complete information during registration and to keep your account information updated.
                    </p>
                    <p className="intro-text"> <span className="spanText">4.2. Account Security:</span> You are responsible for maintaining the confidentiality of your account password and any content you upload to the App. Notify Film Crew immediately of any unauthorized use of your account or password.
                    </p>
                    <p className="intro-text"> <span className="spanText">4.3. Account Sharing or Transfers:</span> Accounts are personal and may not be sold, transferred, or shared with others. Sharing your account or password is prohibited.</p>
                    <p className="intro-text"> <span className="spanText">4.4. Cancellation by You:</span>  You may cancel your account at any time by following the cancellation instructions in the App. Upon cancellation, your personal information will no longer be visible to other users, but previously shared content may remain visible until deleted by those users. To reactivate a canceled account, contact us at support@filmcrew.com.</p>
                    <p className="intro-text"> <span className="spanText"> 4.5. Termination by Film Crew:</span>  We may terminate your account at any time for reasons including but not limited to: a. Breach of this ToU or engagement in fraudulent or illegal activities; b. Legal requirements necessitating termination; c. Discontinuation of the App or significant parts of it; d. Any other reason at our sole discretion.
                    </p>
                    <p className="intro-text"> <span className="spanText">4.6. Effect of Termination:</span> If your account is terminated for abuse or ToU violations, reactivation may not be permitted. Accounts voluntarily canceled by you may be reactivated by logging in with your email address or Facebook account.
                    </p>
                    <span className="spanText text-white">5.Use Requirements</span>
                    <p className="intro-text"> <span className="spanText"> 5.1. License Grant:</span> Subject to this ToU, Film Crew grants you a limited, non-exclusive, personal, non-sublicensable, non-assignable license to use the App. This license is solely for personal use in connection with the App and must adhere to any rules, restrictions, or documentation provided by Film Crew.
                    </p>
                    <p className="intro-text"> <span className="spanText">5.2. Use Restrictions:</span>  You agree to use the App only in accordance with this ToU and any additional guidelines provided. The App and its features may evolve, and you agree to comply with any new requirements as they are implemented.
                    </p>
                    <span className="spanText text-white">6.Restrictions and Conditions of Use</span>
                    <p className="intro-text"> <span className="spanText">6.1. Use of the Service:</span>
                        Film Crew permits you to view and use the App and the Service solely for your personal use. You agree not to license, create derivative works from, transfer, sell, or re-sell any information, content, materials, data, or services obtained from the App or the Service. Film Crew reserves the right to add or remove information, content, or Services from the App at any time at its sole discretion.
                    </p>
                    <p className="intro-text"> <span className="spanText">6.2. Accessing the Service</span>
                        You agree not to access, or attempt to access, the Service by any means other than through the user interface provided through the App. You specifically agree not to access, or attempt to access, the Service through any automated means (including, without limitation, through the use of scripts, bots, spiders, or web crawlers).
                    </p>
                    <p className="intro-text"> <span className="spanText">6.3. No Violation of Laws</span>

                        You agree that you will not, in connection with your use of the App or the Service, violate any applicable law, ordinance, rule, regulation, or treaty. Without limiting the foregoing, you agree that you will not make available through the App or the Service any material or information that infringes any copyright, trademark, patent, trade secret, or other right of any party (including rights of privacy or publicity).
                    </p>
                    <p className="intro-text"> <span className="spanText">6.4. Use Restrictions </span>

                        You may not connect to or use the App or the Service in any way that is not expressly permitted by this ToU.
                    </p>
                    <span className="spanText text-white">a. You may not:</span>
                    <p className="intro-text2">
                        (i) remove any proprietary notices from the App or the Service;
                    </p>
                    <p className="intro-text2">
                        (ii) cause, permit, or authorize the modification, creation of derivative works, translation, reverse engineering, decompiling, disassembling, or hacking of the App or the Service;
                    </p>
                    <p className="intro-text2">
                        (iii) sell, assign, rent, lease, act as a service bureau, or grant rights in the Service, including, without limitation, through sublicense, to any other person or entity without the prior written consent of Film Crew; or
                    </p>
                    <p className="intro-text2">
                        (iv) make any false, misleading, or deceptive statement or representation regarding Film Crew and the App or the Service.
                    </p>
                    <span className="spanText text-white">b. Without limiting the foregoing, you agree that you will not: </span>
                    <p className="intro-text2">
                        (i) institute, assist, or become involved in any type of attack including, without limitation, denial of service attacks, upon the App or the Service (or any servers, systems, or networks connected to the App or the Service) or otherwise attempt to obstruct, disrupt, or interfere with the operation of the App or the Service or any other person’s or entity’s use of the App or the Service (or any servers, systems, or networks connected to the App or the Service);
                    </p>
                    <p className="intro-text2">
                        (ii) attempt to gain unauthorized access to the App, the Service, accounts registered to other users, or any servers, systems, or networks connected to the App or the Service;
                    </p>
                    <p className="intro-text2">
                        (iii) use the App or the Service for any commercial purpose unless consistent with this ToU and the intended use of the same, or for the benefit of any third party, or charge any person or entity, or receive any compensation for, the use of the App or the Service, unless you are specifically authorized to do so in a separate written agreement with Film Crew;
                    </p>
                    <p className="intro-text2">
                        (iv) use the App or the Service to develop, generate, transmit, or store information that is defamatory, harmful, abusive, obscene, or hateful;
                    </p>
                    <p className="intro-text2">
                        (v) use the App or the Service to perform any unsolicited commercial communication not permitted by applicable law; or
                    </p>
                    <p className="intro-text2">
                        (vi) use the App or the Service to engage in any activity that (A) constitutes harassment or a violation of privacy or threatens other people or groups of people; (B) is harmful to children in any manner; (C) constitutes phishing, pharming or impersonates any other person or entity, or steals or assumes any person’s identity (whether a real identity or online nickname or alias); or (D) violates any applicable law, ordinance, rule, regulation, or treaty.
                    </p>
                    <p className="intro-text2">
                        E. Without limiting the foregoing, you agree that you will not use the App or the Service for any other unlawful, prohibited, abnormal, or unusual activity as determined by Film Crew in its sole discretion.
                    </p>
                    <span className="spanText text-white">6.5. No Data Mining or Harmful Code </span>
                    <span className="spanText text-white">You agree that you will not:  </span>
                    <p className="intro-text2">
                        (a) obtain or attempt to obtain any information from the Service including, without limitation, email addresses or mobile phone numbers of other account holders or other software data;
                    </p>
                    <p className="intro-text2">
                        (b) intercept, examine, or otherwise observe any proprietary communications protocol used by the App or the Service, whether through the use of a network analyzer, packet sniffer, or other device; or
                    </p>
                    <p className="intro-text2">
                        (c) use any type of bot, spider, virus, clock, timer, counter, worm, software lock, drop dead device, Trojan horse routing, trap door, time bomb, or any other codes, instructions, or third-party software that is designed to provide a means of surreptitious or unauthorized access to, or distort, delete, damage, or disassemble, the App or the Service.
                    </p>
                    <span className="spanText text-white">6.6. Violation of this ToU  </span>
                    <p className="intro-text2">
                        You acknowledge and agree that you are solely responsible, and Film Crew has no responsibility or liability to you or any other person or entity, for any breach by you of this ToU or for the consequences of any such breach. Film Crew may, at its option, terminate its relationship with you or suspend your account immediately if it determines you are using the Service contrary to the restrictions found in this Section 6 or any other terms of this ToU.
                    </p>
                    <span className="spanText text-white">7. Links</span>
                    <span className="spanText text-white">7.1. Links from the App</span>
                    <p className="intro-text2">
                        The App may contain links to websites operated by other parties. Film Crew provides these links to other websites as a convenience, and use of these websites is at your own risk. The linked websites are not under the control of Film Crew and Film Crew is not responsible for the content available on the other websites. Such links do not imply Film Crew’s endorsement of information or material on any other website, and Film Crew disclaims all liability with regard to your access to and use of such linked websites.
                    </p>
                    <span className="spanText text-white">7.2. Links to the App </span>
                    <p className="intro-text2">
                        Unless otherwise set forth in a written agreement between you and Film Crew, you must adhere to Film Crew’s linking policy as follows:
                    </p>
                    <p className="intro-text2">
                        (a) the appearance, position, and other aspects of the link may not be such as to damage or dilute the goodwill associated with Film Crew’s or its licensors’ names and trademarks;
                    </p>
                    <p className="intro-text2">
                        (b) the appearance, position, and other attributes of the link may not create the false appearance that your organization or entity is sponsored by, affiliated with, or associated with Film Crew; and
                    </p>
                    <p className="intro-text2">
                        (c) when selected by a user, the link to the App must display the App on full-screen and not within a “frame” on the linking site. Film Crew reserves the right to revoke its consent to the link at any time and in its sole discretion.
                    </p>
                    <span className="spanText text-white"> 8. Intellectual Property </span>
                    <span className="spanText text-white"> **8.1. Trademarks**   </span>
                    <p className="intro-text2">
                        The Film Crew name and logo are trademarks and service marks of Film Crew. Unless permitted in a separate written agreement with Film Crew, you do not have the right to use any of Film Crew’s trademarks, service marks, or logos, and your unauthorized use of any of these may be a violation of federal and state trademark laws.
                    </p>
                    <span className="spanText text-white"> **8.1. Trademarks**   </span>
                    <p className="intro-text2">
                        The Film Crew name and logo are trademarks and service marks of Film Crew. Unless permitted in a separate written agreement with Film Crew, you do not have the right to use any of Film Crew’s trademarks, service marks, or logos, and your unauthorized use of any of these may be a violation of federal and state trademark laws.
                    </p>
                    <span className="spanText text-white"> **8.2. Ownership**  </span>
                    <p className="intro-text2">
                        You acknowledge and agree that Film Crew, or its licensors, owns all right, title, and interest in and to the App and the Service, including all intellectual property, industrial property, and proprietary rights recognized anywhere in the world at any time, and that the App and the Service are protected by U.S. and international copyright laws. Further, you acknowledge that the Service may contain information that Film Crew has designated as confidential and you agree not to disclose such information without Film Crew’s prior written consent.
                    </p>
                    <span className="spanText text-white">**8.3. Copyright Agent**</span>
                    <p className="intro-text2">
                        Film Crew respects the intellectual property rights of others and requires that people who use the App and the Service do the same. Film Crew maintains a policy of terminating users of the App and the Service who engage in repeated infringing conduct. If you believe that your work has been copied in a way that constitutes copyright infringement, please forward the following information to the Copyright Agent, designated as such pursuant to the Digital Millennium Copyright Act, 17 U.S.C. § 512(c)(2), named below:
                    </p>
                    <ul className="intro-text2">
                        <li>• Your address, telephone number, and email address;  </li>
                        <li>• A description of the copyrighted work that you claim has been infringed;   </li>
                        <li>• A description of where the alleged infringing material is located;  </li>
                        <li>• A statement by you that you have a good faith belief that the disputed use is not authorized by you, the copyright owner, its agent, or the law;  </li>
                        <li>• An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest; and  </li>
                        <li>
                            • A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.   </li>
                    </ul>
                </div>
            </section>
            <HomeFooter />
        </Fragment >
    );
};

export default TermsAndConditions;
