import Card from 'react-bootstrap/Card';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { projectListingAction, projectDetailAction, addProject, getVendorDataAction } from "../../action/Admin.action";
import { useState, memo, useEffect } from 'react';
import { Col, Row, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { setAlert } from '../../slices/home.js';
import dash2 from '../../assets/images/OldProImg/dashboard1/dash2.png';
import { Data } from "../common/constant"
import GuageChart from '../common/guageChart';
import { imgPath } from '../common/function';

const DashBoard1 = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [formData, setFormData] = useState({});
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [artistList, setArtistList] = useState([]);
    const [file, setFile] = useState("");
    const [count, setCount] = useState();
    const [projectLst, setProjectLst] = useState({});
    const [masterModal, setMasterModal] = useState(false);
    const [Step, setStep] = useState(1);
    const [vendoList, setVendorList] = useState({});
    const width1 = { width: "40%" };
    const masters = useSelector((state) => state.home.masters);


    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);
            let postData = new FormData();
            for (let key in formData) {
                postData.append(key, formData[key]);
            }

            setValidated(false)
            let resp = await addProject(postData);
            setDisabled(false);
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: "Updated Successfully", type: '' }));
                handleClose();
            } else {
                let data = "";
                if (resp.msg) {
                    data = resp.msg;
                }
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
            }
            return false;
        }
    };

    const getList1 = async () => {
        let resp = await projectDetailAction();
        if (resp.code === 200) {
            if (resp.data.artiest && resp.data.artiest.length) {
                let artiests = resp.data.artiest;
                let artiestsList = [];
                for (let i in artiests) {
                    let row = artiests[i];
                    artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
                }
                setArtistList(artiestsList);
            }
        }

    }

    const getVendorList = async () => {
        const resp = await getVendorDataAction();
        if (resp.code === 200) {
            setVendorList(resp.data);
        }
        const resp1 = await projectListingAction();
        if (resp1.code === 200) {
            setCount(resp1.count)
            setProjectLst(resp1.data);
        }
    }

    const handleChange = (post) => {

        let name = post.name;
        let event = post.event;
        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });
    }

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {

            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            // formErrorsData[elename] = "";
            // formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";
        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }
    // console.log(formData);
    useEffect(() => {
        getList1();
        getVendorList();
    }, [])
    return (
        <div className='pt-4'>
            <Card>
                <Card.Body className='crdBdy'>
                    <Row className="mt-3 pt-2">
                        <Col className="col-5 col-sm-5 col-lg-2 col-md-2 pt-2">
                            <h6 className="pt-2 smsz">Project</h6>
                        </Col>
                        <Col className="mt-2 col-7 col-sm-7 text-end col-lg-10 col-md-10"><i className="bi bi-search serBx m-2" /><i className="bi bi-filter m-2"></i><Button className="btnClre btnClr smsz"
                            onClick={() => { handleShow(); setValidated(false) }}
                        >+ New Project</Button>
                        </Col>
                    </Row>
                    <hr className='mrZero' />

                    <Row className='mt-3 mb-2'>
                        <Col><h6 className='crdHd1'>{count} Active Project</h6></Col>
                    </Row>

                    <Row>
                        {
                            projectLst && projectLst.length > 0 && projectLst.map((val, ind) => {
                                return (
                                    <Col key={ind} lg={4} md={6} className=" mt-1" >
                                        <Card style={{ borderRadius: '20px' }}  >
                                            <img src={file == "" ? val.image ? imgPath(val.image) : dash2 : file} className="img-fluid tmImg1" />
                                            <Card.Title className=" mb-0" ></Card.Title>
                                            <p className="cardtitle paratit  m-0 mx-3" style={{ fontSize: '16px', backgroundColor: "#ebdbdb", padding: "10px" }}>{val.name}</p>
                                            <p className="cardtitle paratit1 m-0 mx-3 mt-2" style={{ fontSize: '14px' }}>start Date-</p>
                                            <p className="cardtitle paratit1 m-0 mx-3 mt-2" style={{ fontSize: '14px' }}>Expected End Date-</p>
                                            <p className="cardtitle paratit1 m-0 mx-3 mt-1" style={{ fontSize: '14px' }}> Director Name - {val.directorName}</p>
                                            <p className="cardtitle paratit1 m-0 mx-3 mt-1" style={{ fontSize: '14px' }}>Actoress Name -{val.actoressName}</p>
                                            <p className="cardtitle paratit1 m-0 mx-3 mt-1" style={{ fontSize: '14px' }}>Actor Name - {val.actorName}</p>
                                            <Row>
                                                <Col className='mx-3 mt-3 mb-1 text-start paratit1' style={{ fontSize: '13px' }}>Project Progress</Col>
                                                <Col className='mx-3 mt-3 mb-1 text-end paratit1' style={{ fontSize: '13px' }}>{width1.width}</Col>
                                            </Row>
                                            <Row className='justify-content-center'>
                                                <Col lg={12}>
                                                    <div className='outer' style={{ width: "92%", marginLeft: "15px" }}><div className='inner' style={width1}></div></div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-3 m-2'>
                                                <Col lg={4} md={4}><GuageChart className='uperChrt' /></Col>
                                                <Col lg={4} md={4}><GuageChart className='uperChrt' /></Col>
                                                <Col lg={4} md={4}><GuageChart className='uperChrt' /></Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>

                    <div >
                        <Modal show={show} onHide={handleClose} size="lg">
                            <Row>
                                <Col lg={10} className='col-10'> <h6 className="text-center mt-3">Add New Project !</h6></Col>
                                <Col lg={2} className='col-2 mt-3 text-end pe-4'><span className='hand' onClick={() => { handleClose() }}>X</span></Col>
                            </Row>
                            <hr />
                            <Modal.Body>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                    <Row className="mb-2">
                                        <Col xs="12" md="4" >
                                            <Form.Label>Project Name<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr frmcnt" value={formData.name ? formData.name : ""} onChange={e => handleChange({ name: 'name', event: e })} required

                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Name.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs="12" md="4" >
                                            <Form.Label>Producer Name<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr frmcnt" value={formData.producerName ? formData.producerName : ""} onChange={e => handleChange({ name: 'producerName', event: e })} required />
                                            <Form.Control.Feedback type="invalid">
                                                Provide a valid Producer Name.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <Form.Label>Project Type<span className="star"> *</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="filedbg brdr frmcnt" value={formData.projectTypeId ? formData.projectTypeId : ""} onChange={e => handleChange({ name: 'projectTypeId', event: e })} required>
                                                <option value="">Select Project Type</option>
                                                {masters && masters.projectType && masters.projectType.length > 0 && masters.projectType.map((item, index) => {
                                                    return <option value={item._id} key={index}>{item.name}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Provide a valid project type.
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">

                                        <Col xs="12" md="4">
                                            <Form.Label>Language<span className="star"> *</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="filedbg brdr frmcnt" value={formData.languageId ? formData.languageId : ""} onChange={e => handleChange({ name: 'languageId', event: e })} required>
                                                <option value="">Select Language Type</option>
                                                {masters && masters.language && masters.language.length > 0 && masters.language.map((item, index) => {
                                                    return <option value={item._id} key={index}>{item.name}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid language.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs="12" md="4" >
                                            <Form.Label>Director Name<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr frmcnt" value={formData.directorName ? formData.directorName : ""} onChange={e => handleChange({ name: 'directorName', event: e })} required />
                                            <Form.Control.Feedback type="invalid">
                                                Provide a valid Director Name.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs="12" md="4" >
                                            <Form.Label>DOP (Director of Photography)<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={32} className="filedbg brdr frmcnt" value={formData.dop ? formData.dop : ""} onChange={e => handleChange({ name: 'dop', event: e })} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid dop
                                            </Form.Control.Feedback>
                                        </Col>

                                    </Row>

                                    <Row className="mb-2">

                                        <Col xs="12" md="8" >
                                            <Form.Label>Synopsis</Form.Label>
                                            <Form.Control type="text" pattern= "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$" minLength={2} maxLength={250} as="textarea" className="filedbg brdr frmcnt" value={formData.synopsis ? formData.synopsis : ""} onChange={e => handleChange({ name: 'synopsis', event: e })} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid synopsis.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col xs="12" md="4">
                                            <Form.Label>Genre<span className="star"> *</span></Form.Label>
                                            <Form.Select className="filedbg brdr frmcnt" value={formData.genreId ? formData.genreId : ""} onChange={e => handleChange({ name: 'genreId', event: e })} required>
                                                <option value="">Select Genre Type</option>
                                                {masters && masters.genre && masters.genre.length > 0 && masters.genre.map((item, index) => {
                                                    return <option value={item._id} key={index}>{item.name}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Genre.
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col lg={4}>

                                            <Form.Group className="txt" controlId="image">
                                                <Row><Col><Form.Label>Photo</Form.Label></Col></Row>
                                                <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a valid Photo.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} className="imgcss">
                                            <img src={file} />
                                        </Col>

                                    </Row>

                                    <Row className="justify-content-center">
                                        <Col lg={8} className="mt-3 text-center">
                                            <Button type="submit" className="vndrBtn btn-dark" disabled={isDisabled}>
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
export default memo(DashBoard1);



