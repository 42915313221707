import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import React, { useState } from "react";
import { addArtiest } from "../../action/Admin.action";
import { setAlert } from '../../slices/home.js';
import { Link } from "react-router-dom";
import { checkName, checkNumber, emailValidation, checkMobileNumber} from "../common/function.js";

const Artist = ({ setActiveTab }) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [isDisabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [formErrors, setFormErrors] = useState({});


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        
        var projectId = localStorage.getItem("projectId");        


        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            var pair = { projectId: projectId }
            var obj = { ...formData, ...pair };
            let resp = await addArtiest(obj);
            
            if (resp.code === 200) {
                dispatch(setAlert({ open: true, severity: "success", msg: resp.msg, type: '' }));
                setFormData({});
            } else {
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }
           
            return false;
        }
    };

    const handleChange = (name, event) => {

        let from = { ...formData };
        from[name] = event.target.value;
        setFormData({ ...formData, ...from });

    }

    const updateTab = () => {

        setActiveTab(3);

    }    


    return (

        <>            
            <div>
                <div className="col-11 w-6" style={{ margin: "auto" }}>
                    <div className="modal-header teamBox">
                    </div>
                    <h5 className="paraCenter mb-3 mt-3">{localStorage.getItem("projectName")}</h5>

                    <Form noValidate validated={validated} onSubmit={e=>handleSubmit(e)}>

                        <Form.Group className="mb-3" controlId="name">
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                autoFocus
                                value={formData.name ? formData.name : ""} onChange={e => handleChange('name', e)} required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="Number">
                            <Form.Control
                                type="number"
                                placeholder="Number"                                
                                value={formData.number ? formData.number : ""} onChange={e => handleChange('number', e)} required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid number.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="email">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="mobile">
                            <Form.Control
                                type="text"
                                placeholder="Mobile"
                                value={formData.mobile ? formData.mobile : ""} onChange={e => handleChange('mobile', e)} minLength="10" maxLength="10" pattern="\d*" required/>
                           <Form.Control.Feedback type="invalid">
                                Please provide a valid mobile.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="characterName">
                            <Form.Control
                                type="text"
                                placeholder="character Name"
                                value={formData.characterName ? formData.characterName : ""} onChange={e => handleChange('characterName', e)}  required/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid character Name.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Row className="mt-4 mb-4 g-2">
                            <Col lg={6}>
                                <Button type="submit" style={{ padding: ".5rem 5rem .5rem 5rem" }} className="lgbt" variant="dark">
                                    Add More
                                </Button>
                            </Col>
                            <Col lg={6} className="text-end  mt-3">
                                <Link className="lgbt12 nextBtn rounded-1" to="#" onClick={e => updateTab()}>
                                    Next
                                </Link></Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Artist;