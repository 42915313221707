import { useState, memo, useEffect, Year, Month, Week, Days } from 'react';
import { Col, Row, Container, Button, Alert, Card } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
// import pbb1 from '../../assets/images/OldProImg/pbb1.png'
import location1 from '../../assets/images/OldProImg/location1.png';
import pbbi from '../../assets/images/OldProImg/pb/vendorePb/one.png';
import pbb2 from '../../assets/images/OldProImg/pb/vendorePb/two.png';
import pbb3 from '../../assets/images/OldProImg/pb/vendorePb/three.png';
import { addLocation, getCategoryDataAction, getLocationSceneDataAction, getScheduleListAction } from "../../action/Admin.action";
import { setAlert } from "../../slices/home";
import moment from 'moment';
import { objToArray } from "../common/function";
import { imgPath } from '../common/function';
import AlertBox from '../../admin/component/AlertBox';
import { Locationpermission } from "../common/constant";
import { ToastContainer, toast } from "react-toastify";


const LocationDetails = () => {

  const [Step, setStep] = useState(1);
  const location = useLocation();
  let locationData = location.state;

  const [ldata, setLdata] = useState(locationData);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({});
  const [sceneList, setSceneList] = useState({});
  const [scheduleList, setScheduleList] = useState({});
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const masters = useSelector((state) => state.home.masters);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [file, setFile] = useState("");


  const handleStartDateChange = date => {
    setStartDate(date);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);

      let postData = new FormData();
      for (let key in formData) {
        postData.append(key, formData[key]);
      }
      let a = (Step + 1)
      setStep(a);
      setValidated(false);
      setDisabled(false)
      if (a == 4) {
        let resp = await addLocation(postData);
        setDisabled(false);
        let msg = "Location Updated Successfully";

        if (resp.code === 200) {
          setLdata(resp.data)
          getDataList();
          toast.success(msg);
          let b = (a == 4) ? handleClose() : ''
        } else {
          var data = "";
          if (resp.msg) {
            data = resp.msg;
          }
          setDisabled(false);
          // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
          toast.error(data);
        }
      }
      return false;
    }
  };
  const handleChange = (name, event) => {
    let from = { ...formData };
    from[name] = event.target.value;
    //start Date And Date Validation
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);
    const endDateInput = document.getElementById("endDate");
    if (endDateInput) {
      endDateInput.min = selectedStartDate;
      if (selectedStartDate > endDate) {
        setEndDate("");
      }
    }

    const selectedEndDate = event.target.value;
    setEndDate(selectedEndDate);
    const startDateInput = document.getElementById("startDate");
    if (startDateInput) {
      startDateInput.max = selectedEndDate;
      if (startDate && selectedEndDate < startDate) {
        setStartDate("");
      }
    }

    setFormData({ ...formData, ...from });

  }

  const getDataList = async () => {
    const resp = await getCategoryDataAction();
    if (resp.code === 200) {
      setDataList(resp.data);
    }
  }
  const getSceneList = async () => {
    const resp = await getLocationSceneDataAction({ projectLocationId: ldata._id });
    if (resp.code === 200) {
      setSceneList(resp.data);
    }

    const Sresp = await getScheduleListAction();
    if (Sresp.code === 200) {
      setScheduleList(Sresp.data);
    }
  }

  useEffect(() => {
    getDataList();
    getSceneList();
  }, []);

  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    let formErrorsData = formErrors;

    let formDataData = formData;
    let file = event.target.files[0];
    setFile(URL.createObjectURL(event.target.files[0]));
    if (!file && file === undefined) {
      return false;
    }
    var fileName = (file && file.name ? file.name : '');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

    if (file.size > 20971520) {
      formErrorsData[elename] = "File size not greater then 20MB.";
    } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

      formErrorsData[elename] = "";
      formErrorsData["preview"] = "";
      formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
      formDataData['fileType'] = extensions;
      formDataData[elename] = event.target.files[0];
      setFormData({ ...formData, ...formDataData });
    } else {
      formErrorsData[elename] = "File extensions doesn't match.";

    }
    setFormErrors({ ...formErrors, ...formErrorsData });
  }


  useEffect(() => {

    let startDate = (moment(ldata.startDate).format("yyyy-MM-DD"))
    let endDate = (moment(ldata.endDate).format("yyyy-MM-DD"))
    setFormData({ name: ldata.name, country: ldata.country, state: ldata.state, city: ldata.city, locationName: ldata.locationName, locationTypeId: ldata.locationTypeId, genreId: ldata.genreId, paymnetStatus: ldata.paymnetStatus, dayWise: ldata.dayWise, categoryId: ldata.categoryId, subCategoryId: ldata.subCategoryId, locationDesc: ldata.locationDesc, pricing: ldata.pricing, hourly: ldata.hourly, permissionRequired: ldata.permissionRequired, permissionFrom: ldata.permissionFrom, startDate: startDate, endDate: endDate, expense: ldata.expense, image: ldata.image, intExt: ldata.intExt, dayNight: ldata.dayNight, _id: ldata._id, longitute: ldata.longitute, latitude: ldata.latitude });
  }, [])

  console.log(formData)

  return (
    <>
      <div className="pt-4">
        <Card>
          <Card.Body className='crdBdy'>
            <Row className="pt-2 mt-3 mb-2">
              <Col className="col-2 col-lg-7 col-sm-5 col-md-7">
                <h6 className="crdHd1">Location</h6>
              </Col>
              {/* <Col className="text-end  col-3 col-lg-1 col-md-1 col-sm-3">
         
        </Col> */}
              <Col className="text-end col-10 col-lg-5 col-md-5 col-sm-4 col" onClick={() => setStep(1)}>
                <Button className="btnClr me-1 btnClre" variant="primary" onClick={() => { navigate(`${process.env.PUBLIC_URL}/location-option`) }}>
                  Back
                </Button>
                <Button className="btnClr  btnClre" variant="primary" onClick={() => { handleShow(); setValidated(false); }}>
                  Edit Location
                </Button>
              </Col>
            </Row>
            <hr className='mrZero' />
            <Modal show={show} onHide={handleClose} animation={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered>
              <Modal.Header className='pbZero' closeButton>
                <Modal.Title className='crdHd1'>Location</Modal.Title>
              </Modal.Header>

              {/* <Row><Col lg={11}><Modal.Title className="size1 m-3 mt-4 mb-0">Location</Modal.Title></Col>
          <Col lg={1}>
            <span className='hand cancel' onClick={() => { handleClose() }}>x</span>
          </Col>
        </Row> */}
              <Modal.Body className='mt-0 pt-0'>
                <AlertBox />
                <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                  {Step == 1 &&
                    <div>
                      <Row>
                        <Col className='p-0 topPb'>
                          <img src={pbbi} alt="first" />
                        </Col>
                      </Row>

                      <Row className='mx-2'>
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="name">
                            <Row><Col><Form.Label>Title<span className='star'>*</span></Form.Label>
                            </Col></Row>
                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Location" onChange={e => handleChange('name', e)} value={formData.name ? formData.name : ""} minLength="2" maxLength="64" required />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid name.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1 " controlId="country">
                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                              <GetAllCountries />
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please select Country.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1 " controlId="state">
                            <Form.Label>State<span className='star'>*</span></Form.Label>
                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                              placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                              <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} />
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please select State.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                      </Row>
                      <Row className="mb-1 mx-2">
                        <Col lg={4} className="txt">
                          <Form.Group controlId="city">
                            <Form.Label>City<span className='star'>*</span></Form.Label>
                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                              placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} required>
                              <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please select City.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group controlId="longitute">
                            <Row><Col><Form.Label>Longitute<span className='star'>*</span></Form.Label>
                            </Col></Row>
                            <Form.Control className="brdr frmcnt" type="text" pattern='^-?(180(?:\.0+)?|1[0-7]\d(?:\.\d+)?|\d{1,2}(?:\.\d+)?)$' placeholder=" 75°49'21.59''E" onChange={e => handleChange('longitute', e)} maxLength={20} value={formData.longitute ? formData.longitute : ""} required />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid longitute between -180 and 180.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group controlId="latitude">
                            <Row><Col><Form.Label>Latitude<span className='star'>*</span></Form.Label>
                            </Col></Row>
                            <Form.Control className="brdr frmcnt" type="text" pattern='^-?(90(?:\.0+)?|\d{1,2}(?:\.\d+)?)$'
                              placeholder="26°55'15.59''N" onChange={e => handleChange('latitude', e)} value={formData.latitude ? formData.latitude : ""} required />
                            <Form.Control.Feedback type="invalid">
                              Please enter a valid latitude between -90 and 90.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                      </Row>
                      <Row className='mt-3 mx-3'>
                        <Button type='submit' className="btnk vndrBtn">
                          Next
                        </Button>
                      </Row>

                    </div>
                  }
                  {Step == 2 &&
                    <div>
                      <Row>
                        <Col className='p-0 topPb'>
                          <img src={pbb2} alt="second" />
                        </Col>
                      </Row>
                      <Row className='mx-2'>
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="locationTypeId">
                            <Row><Col><Form.Label>Type of Location<span className='star'>*</span></Form.Label>
                            </Col></Row>
                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.locationTypeId ? formData.locationTypeId : {}} onChange={e => handleChange('locationTypeId', e)} required>
                              <option value="">Select Type of Location</option>
                              {masters && masters.locationType && masters.locationType.length > 0 && masters.locationType.map((item, index) => {
                                return <option value={item._id} key={index}>{item.name}</option>
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Location.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="categoryId">
                            <Form.Label>Category<span className='star'>*</span></Form.Label>
                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.categoryId ? formData.categoryId : ""} onChange={e => handleChange('categoryId', e)} required>
                              <option value="">Select Category</option>
                              {dataList && dataList.length > 0 &&
                                dataList.map((item, index) => {
                                  if (item.categoryId == "000000000000000000000000") {
                                    return <option value={item._id} key={index}>{item.name}</option>
                                  }
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Category.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="subCategoryId">
                            <Form.Label>Sub Category<span className='star'>*</span></Form.Label>
                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.subCategoryId ? formData.subCategoryId : ""} onChange={e => handleChange('subCategoryId', e)} required>
                              <option value="">Select SubCategory</option>
                              {dataList && dataList.length > 0 &&
                                dataList.map((item, index) => {
                                  if ((item.categoryId != "000000000000000000000000" && (formData.categoryId == item.categoryId))) {
                                    return <option value={item._id} key={index}>{item.name}</option>
                                  }
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Sub Category.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                      </Row>
                      <Row className="mb-1 mx-2">
                        <Col lg={4} className="txt">
                          <Form.Group controlId="genreId">
                            <Form.Label>Genre<span className='star'>*</span></Form.Label>
                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.genreId ? formData.genreId : ""} onChange={e => handleChange('genreId', e)} required>
                              <option value="">Select Genere Type</option>
                              {masters && masters.genre && masters.genre.length > 0 && masters.genre.map((item, index) => {
                                return <option value={item._id} key={index}>{item.name}</option>
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Genre.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group controlId="freePaid">
                            <Form.Label >Free/Paid<span className='star'>*</span></Form.Label>
                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                              placeholder="Select" onChange={e => handleChange('paymnetStatus', e)} value={formData.paymnetStatus ? formData.paymnetStatus : ""} required>
                              <option value="">Select Payment Status</option>
                              <option value="Free" id='isChecked1'>Free</option>
                              <option value="Paid">Paid</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please Select a Valid Payment Type.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                        <Col lg={4} className="txt">
                          <Form.Group controlId="dayWise">
                            <Form.Label >DayWise<span className='star'>*</span></Form.Label>
                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                              placeholder="Select" onChange={e => handleChange('dayWise', e)} value={formData.dayWise ? formData.dayWise : ""} required>
                              <option value="">Select Day Wise</option>
                              <option value="Year" >Year</option>
                              <option value="Month" >Month</option>
                              <option value="Week" >Week</option>
                              <option value="Days" >Days</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid DayWise.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>

                      </Row>
                      <Row className='mx-2'>
                        <Col lg={4} className="txt">
                          <Form.Group controlId="hourly">
                            <Form.Label>{formData.dayWise}<span className='star'>*</span></Form.Label>
                            <Form.Control className="brdr frmcnt" type="text" pattern='^[0-9]+(\.[0-9]{1,2})?$' minLength="1" maxLength="15" onChange={e => handleChange('hourly', e)} value={formData.hourly ? formData.hourly : ""} required />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid hourly.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                        <Col className="txt">
                          <Form.Group className="mb-2" controlId="locationDesc">
                            <Form.Label>Location Description</Form.Label>
                            <Form.Control className='frmcnt' as="textarea" minLength="2" maxLength="250" onChange={e => handleChange('locationDesc', e)} value={formData.locationDesc ? formData.locationDesc : ""} rows={2} />
                            <Form.Control.Feedback type="invalid">
                              Please provide a Valid Description.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                      </Row>

                      <Row className='margin_ mx-2'>
                        <Col lg={4} className="text" maxlength="11">
                          <Form.Group className="mb-4" controlId="pricing">
                            <Row><Col><Form.Label>Pricing<span className='star'>*</span></Form.Label>
                            </Col></Row>
                            <Form.Control className="brdr frmcnt" pattern='^[0-9]+(\.[0-9]{1,2})?$' minLength={1} maxLength={15} type="text" onChange={e => handleChange('pricing', e)} disabled={formData.paymnetStatus === "Free" ? true : false} value={formData.pricing ? formData.pricing : ""} required />
                            <Form.Control.Feedback type="invalid">
                              Please provide a Valid Pricing.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>
                      </Row>

                      <Row className='mx-2'>
                        <Col className='col-6 col-lg-8 col-sm-6 '>
                          <Button className="btnk vndrBtn" onClick={e => setStep(Step - 1)}>
                            Back
                          </Button>
                        </Col>
                        <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                          <Button type="submit" className="btnk vndrBtn">
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  }
                  {Step == 3 &&
                    <div>
                      <Row>
                        <Col className='p-0 topPb'>
                          <img src={pbb3} alt="third" />
                        </Col>
                      </Row>
                      <Row className='mx-2'>
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="permissionRequired">
                            <Form.Label className="txt">Permission Required<span className='star'>*</span></Form.Label>
                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('permissionRequired', e)} value={formData.permissionRequired ? formData.permissionRequired : ""} required>

                              <option value="">Select Permission Option</option>
                              {objToArray(Locationpermission).map((val, index) => {
                                return <option value={val} key={index}>{val}</option>
                              })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a Valid Permission Required Option.
                            </Form.Control.Feedback>
                          </Form.Group>


                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="permissionFrom">
                            <Form.Label>Permission From<span className='star'>*</span></Form.Label>
                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                              placeholder="Select" onChange={e => handleChange('permissionFrom', e)} value={formData.permissionFrom ? formData.permissionFrom : ""} required>
                              <option value="">Select Option</option>
                              <option value="Admin">Admin</option>
                              <option value="Producer">Producer</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Please provide a Valid Permission From.
                            </Form.Control.Feedback>
                          </Form.Group>

                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="startDate">
                            <Form.Label className="txt">Start Date<span className='star'>*</span></Form.Label>
                            <Form.Control className='frmcnt' type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid Start Date.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-1 mx-2">
                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="endDate">
                            <Form.Label className="txt">End Date<span className='star'>*</span></Form.Label>
                            <Form.Control className='frmcnt' type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                            <Form.Control.Feedback type="invalid">
                              Please provide a valid End Date.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col lg={4} className="txt">
                          <Form.Group className="mb-1" controlId="image">
                            <Form.Label>Image</Form.Label>
                            <Form.Control className="brdr frmcnt" type="file" ize="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                          </Form.Group>
                          <Form.Control.Feedback type="invalid">
                            It only accept png, jpg, jpeg files.
                          </Form.Control.Feedback>
                        </Col>
                        <Col lg={4} className='imgcss'>
                          <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />
                        </Col>
                      </Row>

                      <Row className='mx-2 mt-3'>
                        <Col className='col-6 col-lg-8 col-sm-6 '>
                          <Button className="btnk vndrBtn" onClick={e => setStep(Step - 1)}>
                            Back
                          </Button>
                        </Col>
                        <Col className='col-6 col-lg-4 col-sm-6 text-end'>

                          <Button type='submit' className="btnk vndrBtn"
                            disabled={isDisabled}>
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  }
                </Form>
              </Modal.Body>
            </Modal>
            <div className="m-3">
              <Row className="justify-content-center">
                <Col md={7} className="brdR mb-2 imgSz">
                  <img src={ldata.image ? imgPath(ldata.image) : location1} className="float-start brdR" alt="Paris" />
                  <br></br>
                </Col>
                <Col md={5}>
                  <Col lg={12}>
                    <h5>{ldata.name}</h5>
                  </Col>

                  <Col lg={12} className='mb-4 spanfont'><h6 className='spanfont'><span>{ldata.country}</span>,<span>{ldata.state}</span> ,<span>{ldata.city}</span></h6>
                  </Col>

                  <Row>
                    <Col lg={12} className='mb-4' style={{ fontSize: '12px' }}>{ldata.locationDesc}</Col>
                  </Row>

                  <Row >
                    <Col>
                      <h5 style={{ fontSize: '13px' }}>Longitude-<small className='wordsapce' style={{ fontSize: '12px' }}>{ldata.longitute}</small></h5>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <h5 style={{ fontSize: '13px' }}>Latitude-<small style={{ fontSize: '12px' }}>{ldata.latitude}</small></h5>
                    </Col>
                    <Col sm={6}>
                      {/* <h5 >Need to Add Location Tag Icon</h5> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <Row className="my-3 m-3">


              <Table className="float-start brdR mb-5">
                <thead>
                  <tr>
                    <th className="tblHead" >Scene</th>
                    <th className="tblHead" >Tittle</th>
                    <th className="tblHead" >Date</th>
                    <th className="tblHead" >Schedule</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    sceneList && sceneList.length > 0 && sceneList.map((v, i) => {

                      return (
                        v.sceneData && v.sceneData.length > 0 && v.sceneData.map((val, ind) => {
                          if (val.projectLocationId == ldata._id) {
                            return (
                              <tr>
                                <td className='tblPr'>{val.scene}</td>
                                <td className='tblPr'>{val.synopsis}</td>
                                <td className='tblP'>{moment(v.startDate).format('DD/MM/yyyy')}</td>
                                <td className='tblP'>{scheduleList && scheduleList.length > 0 && scheduleList.map((v1, i1) => {
                                  if (v1._id == v.scheduleId) {
                                    return (
                                      (v1.name)
                                    )
                                  }
                                })}</td>
                              </tr>
                            )
                          }
                        })
                      )
                    })

                  }
                </tbody>
              </Table>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  )
}

export default memo(LocationDetails);