import React, { useState, useEffect, memo } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { companyListAction } from "../../action/Admin.action";
import { useDispatch } from "react-redux";
import { setAlert } from "../../slices/home";
import CardView from "./CardView";
import Pagination from "../../admin/common/Pagination";

const List = (props) => {
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState([]);
  const [formData, setFormData] = useState({
    page: 0,
    limit: 10,
    sortName: "createdAt",
    sortBy: "DESC",
  });

  const getDataList = async (action = "") => {
    let data = { ...formData };
    if (action === "clear") {
      data = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
      setFormData(data);
    }

    const resp = await companyListAction(data);
    if (resp.code === 200) {
      setDataList(resp.data);
      setFormData({ ...data, ...{ totalItems: resp.count } });
    }
  };

  useEffect(() => {
    getDataList();
  }, [formData.page]);

  const formHandler = (e, field) => {
    let data = { ...formData };
    if (e.target) {
      data[field] = e.target.value;
    } else {
      data[field] = e.value;
    }

    setFormData(data);
  };
  //Serial Number
  const startSno = formData.page * formData.limit + 1;
  return (
    <>
      <div className="mt-5 pt-2">
        <Card>
          <Card.Body className="crdBdy">
            <Row className="justify-content-center mt-3 pt-2">
              <Col md={9} lg={9}>
                <h6 className="crdHd1">Company Management</h6>
              </Col>
              <Col md={3} lg={3} className="text-right">
                <Link
                  to={`${process.env.PUBLIC_URL}/admin/company/add`}
                  className="text-white btn btn-primary pull-right vndrBtn"
                >
                  Add New Company
                </Link>
              </Col>
            </Row>

            <Row>
              <Col className="pd-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  {" "}
                  Company Name
                </label>
                <input
                  type="text"
                  className="form-control filedbg cmnsz"
                  id="exampleFormControlInput1"
                  placeholder="e.g. Company Name"
                  value={formData.name ? formData.name : ""}
                  onChange={(e) => formHandler(e, "name")}
                />
              </Col>
              <Col className="pd-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Select Status
                </label>
                <select
                  className="form-select filedbg cmnsz"
                  aria-label="Default select example"
                  value={formData.status > -1 ? formData.status : ""}
                  onChange={(e) => formHandler(e, "status")}
                >
                  <option value="">Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </Col>
              <Col className="pd-5 pt-3">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-1">
                  <button
                    className="btn btn-primary vndrBtn"
                    type="button"
                    onClick={(e) => getDataList()}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-primary vndrBtn"
                    type="button"
                    onClick={(e) => getDataList("clear")}
                  >
                    Clear
                  </button>
                </div>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col lg={12}>
                <table className="table table-light">
                  <thead className="headingtbl">
                    <tr>
                      <th scope="col">S.N.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Contact Person</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No.</th>
                      <th scope="col">Address </th>
                      <th scope="col">Status</th>
                      <th scope="col" style={{ width: "100px" }}>
                        Action{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="listing">
                    {dataList &&
                      dataList.length > 0 &&
                      dataList.map((val, ind) => {
                        const serialNumber = startSno + ind;
                        return (
                          <CardView
                            {...props}
                            getDataList={(e) => getDataList()}
                            key={ind}
                            item={val}
                            sno={serialNumber}
                          />
                        );
                      })}
                  </tbody>
                </table>
                <Pagination
                  itemsPerPage={formData.limit}
                  totalItems={formData.totalItems ? formData.totalItems : 0}
                  setPage={(e) => setFormData({ ...formData, ...e })}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default memo(List);
