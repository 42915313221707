import React, { useState, memo, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Card, Col, Row, Table, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import Map from '../../assets/images/OldProImg/Map.png';
import Scene6 from '../../assets/images/OldProImg/Scenes/scene6.jpeg';
import Modal from 'react-bootstrap/Modal';
import line1 from '../../assets/images/OldProImg/line1.png';
import { setAlert } from "../../slices/home";
import { editSceneAction, projectDetailAction, getCrewListAction } from "../../action/Admin.action";
import AlertBox from "../component/AlertBox";
import { imgPath } from "../common/function";


const SceneDetails = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [file, setFile] = useState("");
  const [artistList, setArtistList] = useState([]);
  const [ldata, setLdata] = useState(location.state);
  const [newart, setNewart] = useState([]);
  const [isChecked1, setIsChecked1] = useState(ldata.vfxStatus);
  const [isChecked2, setIsChecked2] = useState(ldata.sfxStatus);
  const navigate = useNavigate();
  let projectId = localStorage.getItem('projectId');


  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {

      event.preventDefault();
      event.stopPropagation();
      setValidated(true);

    } else {
      event.preventDefault();
      event.stopPropagation();
      setDisabled(true);
      let formDataData = { ...formData };
      // let postData = new FormData();
      // for (let key in formDataData) {
      //   postData.append(key, formDataData[key]);
      // }
      let b = { vfxStatus: isChecked1, sfxStatus: isChecked2 }
      let d = (isChecked1 == false) ? "" : formDataData.vfx
      let e = (isChecked2 == false) ? "" : formDataData.sfx
      let a = { dayNight: formDataData.dayNight, image: formDataData.image, intExt: formDataData.intExt, preview: formDataData.preview, projectId: formDataData.projectId, scene: formDataData.scene, synopsis: formDataData.synopsis, vfx: d, sfx: e, _id: formDataData._id }


      let postData1 = { ...a, ...b }
      let postData2 = new FormData();
      for (let key in postData1) {
        postData2.append(key, postData1[key]);
      }
      let resp = await editSceneAction(postData2);
      setDisabled(false);

      setValidated(true);
      if (resp.code === 200) {
        setFormData(resp.data)
        setLdata(resp.data)
        dispatch(setAlert({ open: true, severity: "success", msg: "Updated Successfully", type: '' }));
        handleClose();
        // setValidated(true);
      } else {
        let data = "";
        if (resp.msg) {
          data = resp.msg;
        }
        setDisabled(false);
        dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
      }
      return false;
    }
  };

  // const getList1 = async () => {
  //   let resp = await projectDetailAction();
  //   if (resp.code === 200) {
  //     if (resp.data.artiest && resp.data.artiest.length) {
  //       let artiests = resp.data.artiest;
  //       let artiestsList = [];
  //       for (let i in artiests) {
  //         let row = artiests[i];
  //         artiestsList.push({ label: row.name + " (" + row.number + ")", value: row._id });
  //       }
  //       // setArtistList(artiestsList);
  //       let newArray = artiestsList.filter(item => ldata.artistNo.includes(item.value));
  //       setNewart(newArray)
  //     }
  //   }
  // }

  const getList1 = async () => {
    let resp = await getCrewListAction({page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC",projectId:projectId});
    if (resp.code === 200) {
      console.log(resp.data)
      // .department=="000000000000000000000001"
      let artiestsList = [];

      {
        resp && resp.data && (resp.data).length > 0 && (resp.data).map((v, i) => {
          if (v.department == "000000000000000000000001") {
            artiestsList.push({ label: v.name + " (" + v.number + ")", value: v._id });
          }
        })
      }
      setArtistList(artiestsList);
      let newArray = artiestsList.filter(item => ldata.artistNo.includes(item.value));
      setNewart(newArray)
    }
  }

  const handleChange = (name, event) => {
    let from = { ...formData };
    from[name] = event.target.value;
    setFormData({ ...formData, ...from });
  }

  const handleCheckboxChange1 = (event) => {
    setIsChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };

  // useEffect(() => {
  //   getList1();
  // }, [newart])


  const fileChangedHandler = (event, elename) => {
    event.preventDefault();
    let formErrorsData = formErrors;

    let formDataData = formData;
    let file = event.target.files[0];
    setFile(URL.createObjectURL(event.target.files[0]));
    if (!file && file === undefined) {

      return false;
    }
    var fileName = (file && file.name ? file.name : '');
    let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

    if (file.size > 20971520) {
      formErrorsData[elename] = "File size not greater then 20MB.";
    } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

      formErrorsData[elename] = "";
      formErrorsData["preview"] = "";
      formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
      formDataData['fileType'] = extensions;
      formDataData[elename] = event.target.files[0];
      setFormData({ ...formData, ...formDataData });
    } else {
      formErrorsData[elename] = "File extensions doesn't match.";

    }
    setFormErrors({ ...formErrors, ...formErrorsData });
  }
  useEffect(() => {
    getList1();
  }, [])

  useEffect(() => {
    getList1();
    setFormData({ sceneNumber: ldata.scene, intExt: ldata.intExt, dayNight: ldata.dayNight, scene: ldata.scene, synopsis: ldata.synopsis, vfx: ldata.vfx, sfx: ldata.sfx, image: ldata.image, vfxStatus: ldata.vfxStatus, sfxStatus: ldata.sfxStatus, _id: ldata._id });
  }, [])

  return (
    <>
      <div className="pt-4">
        <Card>
          <Card.Body className="crdBdy">
            <Row className="mt-3 pt-3">
              <Col className="col-lg-7 col-12 col-md-3 mt-2" ><h6 className="crdHd1">Scenes {ldata.scene}</h6></Col>

              <Col className="text-end col-12 col-md-9 col-lg-5">
                <Button className="btnClre btnClr smsz btn btn-primary m-1" onClick={() => { navigate(`${process.env.PUBLIC_URL}/scene-option`) }} >Back </Button>
                <Button className="btnClre btnClr smsz btn btn-primary m-1" onClick={() => { handleShow(); }}>Edit</Button>
                <Button className="btnClre btnClr smsz btn btn-primary m-1" onClick={() => { navigate(`${process.env.PUBLIC_URL}/artist-option`, { state: ldata }) }} >Master Add /Edit</Button>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={7} className="mt-3">
                <Row>
                  <Col lg={12}>
                    <Card className="crdShadZero mb-0" style={{ borderRadius: '8px' }}>
                      <Card.Body className="crdBdy23">
                        <Card.Text className="mt-3">
                          <p className="font12">Set Name - {ldata.set} </p>
                          <p className="font12">Int/Ext - {ldata.intExt}</p>
                          <p className="font12">Estimated time - {ldata.estTime}</p>
                          {/* <h6>VFX Status - {ldata.vfxStatus}</h6>
                      <h6>VFX - {ldata.vfx}</h6>
                      <h6>SFX Status - {ldata.sfxStatus}</h6>
                      <h6>SFX - {ldata.sfx}</h6> */}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <hr />
                <p className="crdHd1 mb-0">Synopsis</p>
                <Card className="crdShadZero mb-0" style={{ borderRadius: '8px' }}>
                  <Card.Body className="crdBdy">
                    <Card.Text className="mt-3 font12" >
                      {formData.synopsis}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={5} className="mt-3">
                <Row>
                  {/* <Col lg={12} style={{ fontSize: '18px' }}>
                <p className="smsz">Scene Picture</p>
              </Col> */}
                  <Col className="col-md-6 col-lg-12">
                    <Card className="crdShadZero mb-0" style={{ borderRadius: '8px' }}>
                      <Card.Body className="crdBdy">
                        <Card.Text className="mt-2">
                          <img className="vdImg" src={ldata.image == "" ? Scene6 : imgPath(ldata.image)} />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={7} className="mt-2">
                <h6 className="crdHd1">Location</h6>
                <img src={Map} alt="" className="img-fluid" />
              </Col>
              <Col lg={5} className="mt-2">
                <h6 className="crdHd1">Cast details</h6><button className="d-none" onclick={() => { getList1() }}>Show</button>
                <Card style={{ borderRadius: '8px' }}>
                  <Card.Body className="crdBdy">
                    <Card.Text className="mt-3" style={{ fontSize: '18px' }}>
                      <Table striped bordered hover>
                        <thead>
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                          <tr>
                            <td style={{ fontSize: "12px" }}>{
                              newart && newart.length > 0 && newart.map((v, i) => {
                                let nm = [v.label, <hr />]
                                return (nm)
                              })
                            }</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Modal size="lg" show={show} onHide={handleClose} animation={false} >
              <Modal.Header className="mdlHdr pb-0" closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <Modal.Title className="crdHd1 m-1 mt-0 mb-0">Sence</Modal.Title>
                </Modal.Title>
              </Modal.Header>
              <hr />
              {/* <Row>
                <Col>
                  <img src={line1} alt="" className="img-fluid12" />
                </Col>
              </Row> */}
              <Modal.Body className="pt-0">
                <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                  <Row className="mb-2 mx-2">
                    <Col lg={4}>
                      <Form.Group className="mb-2" controlId="synopsis">
                        <Form.Label>Title<span className='star'>*</span></Form.Label>
                        <Form.Control className="brdr frmcnt" autoFocus minLength={2} maxLength={150} type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-\, \,\]+)$" placeholder="Name of location" value={formData.synopsis ? formData.synopsis : ""} onChange={e => handleChange('synopsis', e)} required />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Title.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="mb-2" controlId="scene">
                        <Form.Label>Scene Number<span className='star'>*</span></Form.Label>
                        <Form.Control className="brdr frmcnt" type="text" pattern='^(0*[1-9][0-9]{0,9})$' maxLength={10} minLength={1} placeholder="5" value={formData.scene ? formData.scene : ""} onChange={e => handleChange('scene', e)} required />
                        <Form.Control.Feedback type="invalid">
                          Please provide a  valid Scene Number.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="mb-2" controlId="intExt">
                        <Form.Label>IntExt<span className='star'>*</span></Form.Label>
                        <Form.Select className="brdr frmcnt" aria-label="Default select example"
                          placeholder="IntExt" onChange={e => handleChange('intExt', e)} value={formData.intExt ? formData.intExt : ""} required>
                          <option value="">Select Option</option>
                          <option value="Int">INT</option>
                          <option value="Ext">EXT</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please provide a  valid Int/Ext.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mx-2 ">
                    <Col lg={4}>
                      <Form.Group className="mb-2" controlId="dayNight">
                        <Form.Label>DayNight<span className='star'>*</span></Form.Label>
                        <Form.Select className="brdr frmcnt" aria-label="Default select example"
                          placeholder="dayNight" onChange={e => handleChange('dayNight', e)} value={formData.dayNight ? formData.dayNight : ""} required>
                          <option value="">Select Option</option>
                          <option value="Day">Day</option>
                          <option value="Night">Night</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          Please provide a  valid Day/Night.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="mb-2" controlId="vfx">
                        <Row><Col><Form.Label>VFX</Form.Label>
                        </Col><Col><Form.Check reverse
                          checked={isChecked1}
                          onChange={e => handleCheckboxChange1(e)}
                          label=""
                          name="group1" value={formData.vfxStatus ? formData.vfxStatus : ""} /></Col></Row>
                        <Form.Control className="brdr frmcnt " type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" minLength={2} maxLength={32} onChange={e => handleChange('vfx', e)} value={formData.vfx ? formData.vfx : ""} disabled={!isChecked1} />
                        <Form.Control.Feedback type="invalid">
                          Please provide a  valid VFX.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={4}>
                      <Form.Group className="mb-2" controlId="sfx">
                        <Row><Col><Form.Label>SFX</Form.Label>
                        </Col><Col> <Form.Check reverse
                          checked={isChecked2}
                          onChange={e => handleCheckboxChange2(e)}
                          label=""
                          name="group1" value={formData.sfxStatus ? formData.sfxStatus : ""} /></Col></Row>
                        <Form.Control className="brdr frmcnt" type="text" pattern="^(?!0+$)(?!.*\s{2})[A-Za-z0-9\s]*[A-Za-z][A-Za-z0-9\s]*$" minLength={2} maxLength={32} onChange={e => handleChange('sfx', e)} value={formData.sfx ? formData.sfx : ""} disabled={!isChecked2} />
                        <Form.Control.Feedback type="invalid">
                          Please provide a  valid SFX.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mx-2">
                    <Col lg={4}>

                      <Form.Group className="mb-2" controlId="image">
                        <Form.Label>Photo</Form.Label>
                        <Form.Control className="brdr frmcnt" type="file" placeholder="Select Picture" accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                        <Form.Control.Feedback type="invalid">
                          Please provide a  valid Image.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={4} className='imgcss'>
                      <img src={file == "" ? ldata.image ? imgPath(ldata.image) : "" : file} />

                    </Col>
                  </Row>
                  <Row className="g-2 mt-2 mx-2">
                    <Col className='col-6 col-lg-8 col-sm-6 t'>
                      <Button onClick={() => { handleClose() }} className="vndrBtn">
                        Cancel
                      </Button>
                    </Col>
                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                      <Button className="vndrBtn" type="submit" disabled={isDisabled}>
                        Save
                      </Button></Col>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default memo(SceneDetails);