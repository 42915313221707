import React, { useState, useEffect, memo } from 'react';
import { Row, Col, Button, Form, Card, Modal } from "react-bootstrap";
import { getScheduleListAction, deleteAction } from "../../action/Admin.action";
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../slices/home';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import Schedule from '../component/AddSchedule';
import Pagination from '../common/Pagination';

const ScheduleMenu = (props) => {

    const dispatch = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [formData, setFormData] = useState({ page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" });
    const [startNum, setStartNum] = useState(0);
    const [editData, setEditData] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [st, setSt] = useState(false);
    const navigate = useNavigate();
    let startNum1 = 0;

    const getList = async (action = '') => {
        setEditData({});
        handleClose();
        let fData = { ...formData };
        if (action === 'clear') {
            fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
            setFormData(fData);
        }

        setStartNum(fData.page * fData.limit);

        dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
        const resp = await getScheduleListAction(fData);
        dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
        if (resp.code === 200) {
            setDataList(resp.data);
            setFormData({ ...formData, ...{ totalItems: resp.count } });
        }
    }

    // const getList = async (action = '') => {
    //     setEditData({});
    //     let fData = { ...formData };
    //     if (action === 'clear') {

    //         fData = { page: 0, limit: 10, sortName: "createdAt", sortBy: "DESC" };
    //         setFormData(fData);
    //     }

    //     setStartNum(fData.page * fData.limit);

    //     dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' }));
    //     const resp = await getScheduleListAction(fData);
    //     dispatch(setAlert({ open: false, severity: "success", msg: "Loading...", type: 'loader' }));
    //     if (resp.code === 200) {
    //         setDataList(resp.data);
    //         setFormData({ ...formData, ...{ totalItems: resp.count } });
    //     }
    // }

    const deleteHandele = async (Vid) => {
        let rsp = await deleteAction({ type: "projectSchedule", _id: Vid });
        if (rsp.code == 200) {
            getList();
        }
    }

    const decide = () => {
        if (dataList.length == '0') {
            navigate(`${process.env.PUBLIC_URL}/addOneLinerScript`)
        } else {

        }
    }

    const formHandler = (e, field) => {

        let data = { ...formData };
        if (e.target) {
            data[field] = e.target.value;
        } else {
            data[field] = e;
        }
        setFormData(data);
    }

    useEffect(() => {
        getList();
    }, [formData.page])

    useEffect(() => {
        const timer = setTimeout(() => {
            decide();
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [dataList]);

    return (
        <main id="main" className="main">
            <Row className=" mx-2">
                <Col className='resp' lg={12} md={12}>
                    <Card className='resp mt-4'>
                        <Card.Body className='crdBdy'>
                            <Row className='pt-3 mt-3'>
                                <Col lg={12}>
                                    <Row className="mx-2">
                                        <Col md={7} className='mt-2 px-0'><h6 className='crdHd1'>SCHEDULE </h6></Col>
                                        <Col md={3} className='lgsix p-1 mb-3'>
                                            <input type="text" placeholder='Search Schedule Name' className='BrdRm' onChange={e => formHandler(e, 'name')} onClick={e => getList()}></input><i onClick={e => getList()} className="fa fa-search"></i>
                                        </Col>
                                        <Col md={2} className="text-end" onClick={() => { setFormData({}) }}>
                                            <Button type="button" className="bnt1 btn defaultBtn" onClick={() => { handleShow(); setSt(true) }}>Add New</Button>
                                        </Col>
                                    </Row>
                                    <hr className='mrZero' />
                                    {/* <Row className="mx-1">

                                <Col md={8} className='mt-2'><h6>SCHEDULE</h6></Col>
                                <Col md={4} className='lgsix p-1 mb-3'>
                                    <input type="text" placeholder='Search Schedule Name' className='BrdRm' onChange={e => formHandler(e, 'name')}></input><i onClick={e => getList()} className="fa fa-search"></i>
                                </Col>
                            </Row> */}
                                    <Row>
                                        <Col md={12} className='table-container col-md-12'>
                                            <table className="tbl table">
                                                <thead className="headingtbl tbl2">
                                                    <tr>
                                                        <th scope="col" className="tableHead">S.No.</th>
                                                        <th scope="col" className="tableHead"> Name</th>
                                                        <th scope="col" className="tableHead">Start Date</th>
                                                        <th scope="col" className="tableHead">End Date</th>
                                                        <th scope="col" className="tableHead">Asst. Director 1</th>
                                                        <th scope="col" className="tableHead">Asst. Director 2</th>
                                                        <th scope="col" colspan="2" className='text-center tableHead'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="listing lis">
                                                    {
                                                        dataList && dataList.length > 0 && dataList.map((val, ind) => {
                                                            let startD = (moment(val.startDate).format("DD-MM-yyyy"))
                                                            let endD = (moment(val.endDate).format("DD-MM-yyyy"))
                                                            startNum1 = startNum1 + 1
                                                            return <tr key={ind}>
                                                                <td className="tablData tblP">{startNum1}</td>
                                                                <td className='truncate-text tablData tblP' title={val.name}>{val.name}</td>
                                                                <td className="tablData tblP">{startD}</td>
                                                                <td className="tablData tblP">{endD}</td>
                                                                <td className='truncate-text tablData tblP' title={val.assistantDirector1}>{val.assistantDirector1}</td>
                                                                <td className='truncate-text tblP' title={val.assistantDirector2}>{val.assistantDirector2}</td>
                                                                <td className='text-end tblP'>
                                                                    <i onClick={e => { setEditData(val); handleShow(); setSt(false) }} title="Edit" className="fa fa-pencil hand"></i>
                                                                </td>
                                                                {/* <td>
                                                        <i onClick={e => setEditData(val)} title="Edit" className="fa fa-pencil hand"></i>
                                                        </td> */}
                                                                <td>
                                                                    <i onClick={() => { deleteHandele(val._id); }} title="Delete" className="icofont icofont-bin hand"></i>
                                                                </td>
                                                            </tr>
                                                        })
                                                    }
                                                    {dataList && dataList.length === 0 ? 'No Data Just a Second' : ''}
                                                </tbody>
                                            </table>
                                           <Row className='justify-content-end mt-3'>
                                            <Col md={3}>
                                            <Pagination itemsPerPage={formData.limit} totalItems={formData.totalItems ? formData.totalItems : 0} setPage={e => setFormData({ ...formData, ...e })} />
                                            </Col>
                                           </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                {/* navigate('/addOneLinerScript') */}
                <Col className="mt-4">
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
            <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                    <Modal.Title className='crdHd1'>Add Schedule</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <Schedule getList={e => getList()} editData={editData} refresh={st} />
                </Modal.Body>
            </Modal>
        </main>
    );
}

export default memo(ScheduleMenu);
