import React, { memo, useState, useEffect } from "react";
import { Card, Col, Container, Row, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../../index.css';
import FilmSlate from '../../assets/images/OldProImg/film-slate.png';
import locations from '../../assets/images/OldProImg/locations.png';
import montage from '../../assets/images/OldProImg/montage.png';
import Studioset from '../../assets/images/OldProImg/studio-set.png';
import team from '../../assets/images/OldProImg/team.png';
import vendor from '../../assets/images/OldProImg/vendor.png';
import shuttel from '../../assets/images/OldProImg/shuttle.jpg';
import motel from '../../assets/images/OldProImg/motel.jpg';
import Scene from '../../assets/images/OldProImg/sceneIcn12.jpeg';
import artist from '../../assets/images/OldProImg/artist.jpg';
import AddProdMembers from './ProdMemberList';
import LocationAdd from './AddLocations'
import { getLocationList, addLocation, getCategoryDataAction } from "../../action/Admin.action";
import { useDispatch, useSelector } from 'react-redux';
import location1 from '../../assets/images/OldProImg/location1.png';
import { imgPath } from "../common/function";
import { useLocation, useNavigate } from 'react-router-dom';
import { GetAllCountries, GetStatesOfCountry, GetCitiesOfState } from "../common/function";
import { setAlert } from "../../slices/home";
import AlertBox from "../component/AlertBox";
import pbbi from '../../assets/images/OldProImg/pb/vendorePb/one.png';
import pbb2 from '../../assets/images/OldProImg/pb/vendorePb/two.png';
import pbb3 from '../../assets/images/OldProImg/pb/vendorePb/three.png';
import vendorIcn from '../../assets/images/OldProImg/vendor/vendorIcn.png';
import { objToArray } from "../common/function";
import { Locationpermission } from "../common/constant";
import { ToastContainer, toast } from "react-toastify";
import ScheduleBtn from "../component/AddScheduleCom";
import ScheduleFrm from "../component/addScheduleForm";
import VendoreFrm from "./vendorFrm"

const ProjectDetails = () => {
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [modalShow, setModalShow] = useState(true);
    const [Step, setStep] = useState(1);
    const [category, setCategory] = useState({})
    const [modalShow1, setModalShow1] = useState(false);
    const [formData, setFormData] = useState({});
    const [file, setFile] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [validated, setValidated] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [vendorList, setVendorList] = useState([]);
    const masters = useSelector((state) => state.home.masters);
    const [locationList, setLocationList] = useState();
    const [dataList, setDataList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const navigate = useNavigate();

    //locations part
    const getList = async () => {
        const resp = await getLocationList();
        if (resp.code === 200) {
            setLocationList(resp.data);
        }
    }

    const getDataList = async () => {
        const resp = await getCategoryDataAction();
        if (resp.code === 200) {
            setDataList(resp.data);
        }
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let postData = new FormData();
            for (let key in formData) {
                postData.append(key, formData[key]);
            }
            let a = (Step + 1)
            setStep(a);
            setValidated(false);
            setDisabled(false)
            if (a == 4) {
                let resp = await addLocation(postData);
                setDisabled(false);
                let msg = "Location Info. Saved !";

                if (resp.code === 200) {
                    getList();
                    getDataList();
                    setFormData({})
                    toast.success(msg);
                    handleClose2();
                    // dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                    let b = (a == 4) ? handleClose2() : ''
                } else {
                    var data = "";
                    if (resp.msg) {
                        data = resp.msg;
                    }
                    handleClose2();
                    setDisabled(false);
                    // dispatch(setAlert({ open: true, severity: "danger", msg: data, type: '' }));
                    toast.error(resp.msg);
                }
            }
            return false;
        }
    };
    const handleChange = (name, event) => {
        let from = { ...formData };
        from[name] = event.target.value;
        //start Date And Date Validation
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);
        const endDateInput = document.getElementById("endDate");
        if (endDateInput) {
            endDateInput.min = selectedStartDate;
            if (selectedStartDate > endDate) {
                setEndDate("");
            }
        }

        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
        const startDateInput = document.getElementById("startDate");
        if (startDateInput) {
            startDateInput.max = selectedEndDate;
            if (startDate && selectedEndDate < startDate) {
                setStartDate("");
            }
        }

        setFormData({ ...formData, ...from });

    }


    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let formErrorsData = formErrors;

        let formDataData = formData;
        let file = event.target.files[0];
        setFile(URL.createObjectURL(event.target.files[0]));
        if (!file && file === undefined) {

            return false;
        }
        var fileName = (file && file.name ? file.name : '');
        let extensions = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

        if (file.size > 20971520) {
            formErrorsData[elename] = "File size not greater then 20MB.";
        } else if (extensions == 'jpg' || extensions == 'png' || extensions == 'jpeg') {

            formErrorsData[elename] = "";
            formErrorsData["preview"] = "";
            formDataData['preview'] = URL.createObjectURL(event.target.files[0]);
            formDataData['fileType'] = extensions;
            formDataData[elename] = event.target.files[0];
            setFormData({ ...formData, ...formDataData });
        } else {
            formErrorsData[elename] = "File extensions doesn't match.";
        }
        setFormErrors({ ...formErrors, ...formErrorsData });
    }

    useEffect(() => {
        getList();
        getDataList();
    }, [])

    //locations part end

    return (
        <main id="main" className="main mainCls main-oth">
            <section className="mt-5 section">
                <div className="mt-5">
                    <Row className="m-4 cardlist">
                        <Col lg={3} md={4}>
                            <Link to={`${process.env.PUBLIC_URL}/addProductionMember`}>
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        <img src={FilmSlate} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Production Team</Card.Title>
                                        {/*<small className="prDtlTxt">+ Add Team</small>*/}
                                        <Button variant="outline-info" onClick={(e) => { handleShow1(); e.preventDefault(); }} className="projectDetailsInfo">+ Add Team</Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={3} md={4}>
                            <Link to={`${process.env.PUBLIC_URL}/location-option`}>
                                <Card className=" me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        <img src={locations} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Location </Card.Title>
                                        <Link to={`${process.env.PUBLIC_URL}/location-menu`}><Button variant="outline-info" onClick={(e) => { handleShow2(); e.preventDefault(); }} className="projectDetailsInfo">+ Add Location</Button></Link>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={3} md={4}>
                            <Link to={`${process.env.PUBLIC_URL}/schedule-menu`}>
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        <img src={Studioset} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Schedule</Card.Title>
                                        <Link to={`${process.env.PUBLIC_URL}/addOneLinerScript`}><Button variant="outline-info" onClick={(e) => { handleShow3(e); e.preventDefault(e); }} className="projectDetailsInfo">+ Add Schedule</Button></Link>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={3} md={4}>
                            <Link to={`${process.env.PUBLIC_URL}/vendor-option`}>
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        <img src={vendor} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Vendors</Card.Title>
                                        <Link to={`${process.env.PUBLIC_URL}/addOneLinerScript`}><Button variant="outline-info" onClick={(e) => { handleShow4(e); e.preventDefault(e); }} className="projectDetailsInfo">+ Add Vendore</Button></Link>                                        
                                        {/* <Button variant="outline-info" className="projectDetailsInfo">+ Add Vendore</Button> */}
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        {/* <Col lg={3} md={6}>
                            <Link to={`${process.env.PUBLIC_URL}/artist-option`}>
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy1">
                                        <img src={artist} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Artist Management</Card.Title>
                                        <Button variant="outline-info" className="projectDetailsInfo">+ Add Member</Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col> */}
                        <Col lg={3} md={4}>
                            <Link to={`${process.env.PUBLIC_URL}/travel-management`}>
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        <img src={shuttel} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Travel Management</Card.Title>
                                        <Button variant="outline-info" className="projectDetailsInfo">+ Add Travel</Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={3} md={4}>
                            <Link to={`${process.env.PUBLIC_URL}/scene-option`}>
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        <img src={Scene} alt="" className="img-fluid projDtlsImg" />
                                        <Card.Title className="fw-bold prDtlHd">Scene Management</Card.Title>
                                        <Link to={`${process.env.PUBLIC_URL}/addOneLinerScript`}><Button variant="outline-info" className="projectDetailsInfo">+ Add Scene</Button></Link>
                                        {/* <Button variant="outline-info" className="projectDetailsInfo">+ Add Scene</Button> */}
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col lg={3} md={4}>
                            <Link to="">
                                <Card className="me-4 text-center pt-4 prDtlCrd">
                                    <Card.Body className="mb-3 proDtlBdy">
                                        {/* <img src={shuttel} alt="" className="img-fluid projDtlsImg" /> */}
                                        <div className="shad"><p><i className="fa-solid fa-plus hand"></i></p></div>
                                        <Card.Title className="mb-4 fw-bold prDtlHd is">Add Department</Card.Title>
                                        <small className="prDtlTxt"></small>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </section>
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header className='pbZero' closeButton style={{ padding: "0px" }}>
                    <Modal.Title className='crdHd1'>Add Production Team</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    {/* <h6 className="mb-3 mt-1">Add Production Team</h6> */}
                    <AddProdMembers getList={e=>getList(e)} editData={""} refresh={""} />
                </Modal.Body>
            </Modal>

            <Modal show={show2} onHide={handleClose2} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className='pbZero' closeButton>
                    <Modal.Title className='crdHd1'>Location</Modal.Title>
                </Modal.Header>

                {/* <Row><Col lg={11}><Modal.Title className="size1 m-3 mt-4 mb-0">Location</Modal.Title></Col>
            <Col lg={1}>
              <span className='hand cancel btn-close' onClick={() => { handleClose() }}></span>
            </Col>
          </Row> */}
                <Modal.Body className='mt-0 pt-0'>
                    <AlertBox />
                    <Form className="m-0" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                        {Step == 1 &&
                            <div>
                                <Row>
                                    <Col className='p-0 topPb'>
                                        <img src={pbbi} alt="first" />
                                    </Col>
                                </Row>

                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="name">
                                            <Row><Col><Form.Label>Title<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Location" onChange={e => handleChange('name', e)} value={formData.name ? formData.name : ""} minLength="2" maxLength="64" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid name.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="country">
                                            <Form.Label>Country<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr selectcountry frmcnt" value={formData.country ? formData.country : ""} onChange={e => handleChange('country', e)} required>
                                                <GetAllCountries />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select Country.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="state">
                                            <Form.Label>State<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr selectcountry frmcnt" aria-label="Default select example"
                                                placeholder="state" onChange={e => handleChange('state', e)} value={formData.state ? formData.state : ""} required>
                                                <GetStatesOfCountry countryCode={formData.country ? formData.country : ""} />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select State.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row className=" mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="city">
                                            <Form.Label>City<span className='star'></span></Form.Label>
                                            <Form.Select className="brdr selectcountry frmcnt" aria-label="Default select example"
                                                placeholder="city" onChange={e => handleChange('city', e)} value={formData.city ? formData.city : ""} >
                                                <GetCitiesOfState countryCode={formData.country ? formData.country : ""} stateCode={formData.state ? formData.state : ""} required />
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please select City.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="longitute">
                                            <Row><Col><Form.Label>Longitute<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^-?(180(?:\.0+)?|1[0-7]\d(?:\.\d+)?|\d{1,2}(?:\.\d+)?)$' placeholder=" 75°49'21.59''E" onChange={e => handleChange('longitute', e)} maxLength={20} value={formData.longitute ? formData.longitute : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid longitute between -180 and 180.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group controlId="latitude">
                                            <Row><Col><Form.Label>Latitude<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^-?(90(?:\.0+)?|\d{1,2}(?:\.\d+)?)$'
                                                placeholder="26°55'15.59''N" onChange={e => handleChange('latitude', e)} value={formData.latitude ? formData.latitude : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter a valid latitude between -90 and 90.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row className=" mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="shortListFor">
                                            <Row><Col><Form.Label>ShortListFor<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern= "^(?!.*(.)\1{3})[a-zA-Z0-9\s.,]*$" placeholder="Name of Location" onChange={e => handleChange('shortListFor', e)} value={formData.shortListFor ? formData.shortListFor : ""} minLength="2" maxLength="32" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid shortListFor.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="contactPerson">
                                            <Row><Col><Form.Label>Contact Person<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern="^[A-Za-z]+(?: [A-Za-z]+)*$" placeholder="Name of Contact Person" onChange={e => handleChange('contactPerson', e)} value={formData.contactPerson ? formData.contactPerson : ""} minLength="2" maxLength="64" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid contactPerson.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="contactNumber">
                                            <Row><Col><Form.Label>Contact Number<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^[6-9][0-9]{9}$' placeholder="Person's Contact Number" onChange={e => handleChange('contactNumber', e)} value={formData.contactNumber ? formData.contactNumber : ""} minLength="2" maxLength="64" required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid contactNumber.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row className="mx-3 mt-4 justify-content-end">
                                    <Button type='submit' className="btnk vndrBtn">
                                        Next
                                    </Button>
                                </Row>

                            </div>
                        }
                        {Step == 2 &&
                            <div>
                                <Row>
                                    <Col className='p-0 topPb'>
                                        <img src={pbb2} alt="first" />
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="locationTypeId">
                                            <Row><Col><Form.Label>Type of Location<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.locationTypeId ? formData.locationTypeId : {}} onChange={e => handleChange('locationTypeId', e)} required>
                                                <option value="">Select Type of Location</option>
                                                {masters && masters.locationType && masters.locationType.length > 0 && masters.locationType.map((item, index) => {
                                                    return <option value={item._id} key={index}>{item.name}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Location.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="categoryId">
                                            <Form.Label>Category<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.categoryId ? formData.categoryId : ""} onChange={e => handleChange('categoryId', e)} required>
                                                <option value="">Select Category</option>
                                                {dataList && dataList.length > 0 &&
                                                    dataList.map((item, index) => {
                                                        if (item.categoryId == "000000000000000000000000") {
                                                            return <option value={item._id} key={index}>{item.name}</option>
                                                        }
                                                    })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="subCategoryId">
                                            <Form.Label>Sub Category<span className='star'></span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.subCategoryId ? formData.subCategoryId : ""} onChange={e => handleChange('subCategoryId', e)}>
                                                <option value="">Select SubCategory</option>
                                                {dataList && dataList.length > 0 &&
                                                    dataList.map((item, index) => {
                                                        if ((item.categoryId != "000000000000000000000000" && (formData.categoryId == item.categoryId))) {
                                                            return <option value={item._id} key={index}>{item.name}</option>
                                                        }
                                                    })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Sub Category.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="genreId">
                                            <Form.Label>Genre<span className='star'>*</span></Form.Label>
                                            <Form.Select aria-label="Default select example" className="brdr frmcnt" value={formData.genreId ? formData.genreId : ""} onChange={e => handleChange('genreId', e)} required>
                                                <option value="">Select Genere Type</option>
                                                {masters && masters.genre && masters.genre.length > 0 && masters.genre.map((item, index) => {
                                                    return <option value={item._id} key={index}>{item.name}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Genre.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                    <Col lg={4} className="txt ">
                                        <Form.Group className="" controlId="freePaid">
                                            <Form.Label  >Free/Paid<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="Select" onChange={e => handleChange('paymnetStatus', e)} value={formData.paymnetStatus ? formData.paymnetStatus : ""} required>
                                                <option value="">Select Payment Status</option>
                                                <option value="Free" id='isChecked1'>Free</option>
                                                <option value="Paid">Paid</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please Select a Valid Payment Type.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={4} className="txt ">
                                        <Form.Group className="" controlId="dayWise">
                                            <Form.Label >DayWise<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="Select" onChange={e => handleChange('dayWise', e)} value={formData.dayWise ? formData.dayWise : ""} required>
                                                <option value="">Select Day Wise</option>
                                                <option value="Year" >Year</option>
                                                <option value="Month" >Month</option>
                                                <option value="Week" >Week</option>
                                                <option value="Days" >Days</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid DayWise.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt ">
                                        <Form.Group className="" controlId="hourly">
                                            <Form.Label >{formData.dayWise} <span className='star'>*</span> </Form.Label>
                                            <Form.Control className="brdr frmcnt" type="text" pattern='^[0-9]+(\.[0-9]{1,2})?$' minLength="1" maxLength="15" onChange={e => handleChange('hourly', e)} value={formData.hourly ? formData.hourly : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid hourly.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                    <Col lg={8} className="txt">
                                        <Form.Group className=" frmcnt mb-5 mt-2" controlId="locationDesc">
                                            <Form.Label className="">Location Description</Form.Label>
                                            <Form.Control as="textarea" minLength="2" maxLength="250" onChange={e => handleChange('locationDesc', e)} value={formData.locationDesc ? formData.locationDesc : ""} rows={2} />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Valid Description.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                </Row>

                                <Row className=' mx-2 margin_'>
                                    <Col lg={4} className="text" maxlength="11">
                                        <Form.Group className="mb-1 mt-2" controlId="pricing">
                                            <Row><Col><Form.Label>Pricing<span className='star'>*</span></Form.Label>
                                            </Col></Row>
                                            <Form.Control className="brdr frmcnt" pattern='^[0-9]+(\.[0-9]{1,2})?$' minLength={1} maxLength={15} type="text" onChange={e => handleChange('pricing', e)} disabled={formData.paymnetStatus === "Free" ? true : false} value={formData.pricing ? formData.pricing : ""} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Valid Pricing.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>
                                </Row>

                                <Row className="mt-3 mx-3">
                                    <Col className='col-6 col-lg-8 col-sm-6 '>
                                        <Button className="btnk vndrBtn" onClick={e => setStep(Step - 1)}>
                                            Back
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end'>
                                        <Button type="submit" className="btnk vndrBtn">
                                            Next
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {Step == 3 &&
                            <div>
                                <Row>
                                    <Col className='p-0 topPb'>
                                        <img src={pbb3} alt="first" />
                                    </Col>
                                </Row>
                                <Row className="mx-2">
                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="permissionRequired">
                                            <Form.Label className="txt">Permission Required<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example" onChange={e => handleChange('permissionRequired', e)} value={formData.permissionRequired ? formData.permissionRequired : ""} required>

                                                <option value="">Select Permission Option</option>
                                                {objToArray(Locationpermission).map((val, index) => {
                                                    return <option value={val} key={index}>{val}</option>
                                                })}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Valid Permission Required Option.
                                            </Form.Control.Feedback>
                                        </Form.Group>


                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="permissionFrom">
                                            <Form.Label>Permission From<span className='star'>*</span></Form.Label>
                                            <Form.Select className="brdr frmcnt" aria-label="Default select example"
                                                placeholder="Select" onChange={e => handleChange('permissionFrom', e)} value={formData.permissionFrom ? formData.permissionFrom : ""} required>
                                                <option value="">Select Option</option>
                                                <option value="Admin">Admin</option>
                                                <option value="Producer">Producer</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a Valid Permission From.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                    </Col>

                                    <Col lg={4} className="txt ">
                                        <Form.Group className=" " controlId="startDate">
                                            <Form.Label className="txt ">Start Date<span className='star'>*</span></Form.Label>
                                            <Form.Control className="frmcnt" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.startDate ? formData.startDate : ""} onChange={e => handleChange('startDate', e)} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Start Date.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className=" mx-2">
                                    <Col lg={4} className="txt ">
                                        <Form.Group className="" controlId="endDate">
                                            <Form.Label className="txt">End Date<span className='star'>*</span></Form.Label>
                                            <Form.Control className="frmcnt" type="date" min={new Date().toISOString().split('T')[0]} max={(new Date().getFullYear() + 10) + "-12-31"} value={formData.endDate ? formData.endDate : ""} onChange={e => handleChange('endDate', e)} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid End Date.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>

                                    <Col lg={4} className="txt">
                                        <Form.Group className="" controlId="image">
                                            <Form.Label>Image</Form.Label>
                                            <Form.Control className="brdr frmcnt" type="file" size="lg" placeholder=" " accept="image/png, image/jpg, image/jpeg" onChange={e => fileChangedHandler(e, 'image')} />
                                        </Form.Group>
                                        <Form.Control.Feedback type="invalid">
                                            It only accept png, jpg, jpeg files.
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col lg={4} className='imgcss'>
                                        <img src={file} />
                                    </Col>
                                </Row>
                                <Row className=' mt-3 mx-3'>
                                    <Col className='col-6 col-lg-8 col-sm-6'>
                                        <Button className="btnk vndrBtn" onClick={e => setStep(Step - 1)} >
                                            Back
                                        </Button>
                                    </Col>
                                    <Col className='col-6 col-lg-4 col-sm-6 text-end' >
                                        <Button type='submit' className="btnk vndrBtn"
                                            disabled={isDisabled}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={show3} onHide={handleClose3} animation={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className="mdlHdr" closeButton>
                    <Modal.Title className="crdHd1 mb-2">Add Schedule</Modal.Title>
                </Modal.Header>
                <hr className="mrZero" />
                <Modal.Body className='mt-3 pt-0'>
                    <ScheduleFrm open="true" btn="none" />
                </Modal.Body>
            </Modal>

            <Modal  size="lg" show={show4} onHide={handleClose4} animation={false}
                aria-labelledby="contained-modal-title-vcenter" 
                centered>
                <Modal.Header className="mdlHdr" closeButton>
                    <Modal.Title className="crdHd1">Add Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mt-0 pt-0'>
                    <VendoreFrm open="true" btn="none" />
                </Modal.Body>
            </Modal>

        </main>
    );
}

export default memo(ProjectDetails);