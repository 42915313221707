import React, { Fragment, useState, memo, useEffect } from "react";
import { Col, Container, Card, Row, Button, Modal } from "react-bootstrap";
import '../../index.css';
import { useDispatch } from 'react-redux';
import { addProject } from "../../action/Admin.action.js";
import { setAlert } from '../../slices/home.js';
import ProjectListing from "./ProjectListing";
import Team from "../component/Team.js";
import Artist from "../component/Artist.js";
import Schedule from "../component/AddSchedule.js";
import Calendar from "../component/AddCalendar.js";
import Location from "../component/AddLocation.js";
import { NavTypes } from "../common/constant.js";
import { Spinner, Alert } from 'react-bootstrap';


const ProjectMasterModal = () => {

  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
         setShow(true);
  },[]);

  const updateTab = (k) => {
    setActiveTab(k);
  } 

  return (<Modal show={show} onHide={handleClose} size="lg">
          <Modal.Body>
            <Row>
              <Col sm={3}>
                {
                  NavTypes && NavTypes.length > 0 && NavTypes.map((val, ind) => {
                    return <Fragment key={ind} >
                      <div className={val.id===activeTab?"box2 boxtwo mt-3 c_pointer selected-tab":"c_pointer box2 boxtwo mt-3"} onClick={(k) => updateTab(val.id)}>
                        <div className="box3 boxthree chckbox-tab" >
                        </div>
                        <p className="creatPro">{val.name}</p>
                      </div>
                    </Fragment>
                  })
                }
              </Col>
              <Col sm={9}>
                {activeTab == 1 ?
                  <Team setActiveTab={setActiveTab} />
                  : activeTab == 2 ?
                    <Artist setActiveTab={setActiveTab} />
                    : activeTab == 3 ?
                      <Schedule setActiveTab={setActiveTab} />
                      : activeTab == 4 ?
                        <Calendar setActiveTab={setActiveTab} />
                        :
                        <Location setActiveTab={setActiveTab} />
                }
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
  );
}

export default memo(ProjectMasterModal);