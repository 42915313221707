import React, { useState, useEffect, memo } from 'react';
import { Col, Row, Form, Button, Card } from "react-bootstrap";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { createCompanyAction } from "../../action/Admin.action";
import Pagination from '../../admin/common/Pagination';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../slices/home';
// import { PermissionList } from '../../common/constant';
import { ToastContainer, toast } from "react-toastify";

const AddEdit = (props) => {

    let navigate = useNavigate();
    // let { state } = useLocation();

    const dispatch = useDispatch();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({ permission: [] });

    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            //setValidated(false);
            setDisabled(true);
            let msg = "";
            if (formData._id) {
                msg = "You have successfully Company updated.";
            } else {
                msg = "You have successfully added new Company.";
            }
            let resp = await createCompanyAction(formData);

            if (resp.code === 200) {

                // dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                toast.success(msg); 
                setTimeout(() => {
                    navigate({ pathname: `${process.env.PUBLIC_URL}/admin/company`, state: "" });
                }, 2000);
            } else {
                setDisabled(false);
                toast.error(resp.msg);
                // dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }
            return false;
        }
    };


    const handleChange = (name, event) => {

        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });

    }

    const cancelBtn = () => { navigate({ pathname: `${process.env.PUBLIC_URL}/admin/company`, state: "" }) }

    useEffect(() => {
    }, []);

    return (
        <div className='mt-5 pt-2'>
            <Card>
                <Card.Body className="crdBdy">
                    <div className="Role-page text-start companyhed mt-3">
                        <Row className="justify-content-center" >
                            <Col md={9} lg={10}>
                                <h6 className="crdHd1">{formData._id ? 'Update Company' : 'Add New Company'}</h6>
                            </Col>
                            <Col md={3} lg={2} className="text-right">
                                <Link to={`${process.env.PUBLIC_URL}/admin/company`} className="text-white btn savebtn pull-right">Back</Link>
                            </Col>
                        </Row>
                        
                        <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                            <div className="mt-4">
                                <Row>
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Form.Label>Company Name<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" placeholder="Name" className="filedbg" value={formData.companyName ? formData.companyName : ""} onChange={e => handleChange('companyName', e)} maxLength={32} minLength={2} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Company Name.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Form.Label>Contact Person Name<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$" placeholder="Name" minLength={2} maxLength={32} className="filedbg" value={formData.contactPersonName ? formData.contactPersonName : ""} onChange={e => handleChange('contactPersonName', e)} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Contact Person Name.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col xs="12" lg="4">
                                        <div className="mb-3">
                                            <Form.Label>Mobile No.<span className="star"> *</span></Form.Label>
                                            <Form.Control type="text" placeholder="Mobile Number" value={formData.personMobile ? formData.personMobile : ""} onChange={e => handleChange('personMobile', e)} minLength="10" maxLength="10" pattern='^[6-9][0-9]{9}$' required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid mobile.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6">
                                        <div className="mb-3">
                                            <Form.Label>Email<span className="star"> *</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                pattern="^[A-Za-z][A-Za-z0-9.]*@[A-Za-z]{2,}\.[A-Za-z]{2,}$"
                                                placeholder="Email"
                                                value={formData.email ? formData.email : ""} onChange={e => handleChange('email', e)} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6">
                                        <div className="mb-3">
                                            <Form.Label>Password<span className="star"> *</span></Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Password"
                                                minLength={3}
                                                maxLength={32}
                                                value={formData.password ? formData.password : ""} onChange={e => handleChange('password', e)} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Password.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="12">
                                        <div className="mb-3">
                                            <Form.Label>Full Address<span className="star"> *</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Address"
                                                minLength={2}
                                                maxLength={120}
                                                pattern="^(?!.* {2})([A-Za-z0-9,\/\\ \-]+)$"
                                                value={formData.address ? formData.address : ""} onChange={e => handleChange('address', e)} required />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Address.
                                            </Form.Control.Feedback>
                                        </div>
                                    </Col>
                                </Row>
                                <Button type='submit' className="savebtn vndrBtn" size="lg" disabled={isDisabled}>Save</Button>{' '}
                                <Button className="cancelbtn vndrBtn" onClick={cancelBtn} size="lg">Cancel</Button>
                            </div>
                        </Form>
                        <Pagination itemsPerPage={formData.limit} totalItems={formData.totalItems ? formData.totalItems : 0} setPage={e => setFormData({ ...formData, ...e })} />
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default memo(AddEdit);

