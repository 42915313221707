import react, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import forgetImg from '../../assets/images/OldProImg/forget1.png';
import { resetPasswordAction } from '../../action/Admin.action';
import OtpInput from 'react-otp-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";


const ChangePassword = (props) => {
  const [formData, setFormData] = useState({});
  const [visibl, setVisibl] = useState(false);
  const [seconds, setSeconds] = useState(120);
  const [pass, setPass] = useState(true);
  const dispatch = useDispatch();

  const handelSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let resp = await resetPasswordAction(formData);
    if (resp.code === 200) {
      setVisibl(true);
      formData({});
    toast.success(resp.msg);
    }else{
      toast.error(resp.msg); 
    }
  }


  const handelChange = (name, event) => {
    let newForm = { ...formData };
    if (name === 'code') {
      newForm[name]=event
    } else {
      newForm[name] = event.target.value;

    }

    let emailId = {email:props.email}

    setFormData({ ...formData, ...newForm,...emailId });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        clearInterval(intervalId);
        setFormData({})
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  useEffect(() => { setFormData({}) }, []);

  // console.log(formData);

  return (
    <>
      <Form onSubmit={(e) => {handelSubmit(e)}}>
        <Row>
          <Col lg={12} md={12} sm={12} className='mt-2 mb-2'>Check Your E-mail - <b>{props.email}</b> For Otp</Col>
          <Col lg={12} md={12} sm={12}>
            <OtpInput
              value={formData.code ? formData.code : ''}
              onChange={(e) => {handelChange('code',e)}}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              inputStyle={{ width: 40 }}
            />
          </Col>
          <Col lg={12}>
            <Form.Group>
              <Form.Label>Enter New Password</Form.Label>
              <Form.Control type={pass == true ? 'password' : 'text'} value={formData.password ? formData.password : ''} onChange={(e) => { handelChange('password', e) }} placeholder='password@****' required />
              <div><FontAwesomeIcon onClick={() => setPass(pass === true ? false : true)} icon={faEyeSlash} className='passVsbl1 hand' /></div>
            </Form.Group>
          </Col>
          {/* <Col><p>
            Otp Expired In: {minutes}:{remainingSeconds < 10 ? '0' : ''}{remainingSeconds}
          </p></Col> */}
          <Col lg={12} md={12} sm={12} className='text-center mt-3'>
            <Button type="submit" className="btn vndrBtn">Verify</Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChangePassword;