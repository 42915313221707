import React, { useState, useEffect, memo } from 'react';
import { Form, Row, Col, Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { addLocation } from "../../action/Admin.action";
import { setAlert } from '../../slices/home.js';
import { objToArray } from '../common/function.js';
import { Locationpermission } from '../common/constant.js';
import { Link } from "react-router-dom";
import { emailValidation, checkMobileNumber, checkNumber, checkName } from "../common/function.js";


const Location = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const masters = useSelector((state) => state.home.masters);

    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({});
    const [isDisabled, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    const [disabled1, setdisabled1] = useState(true);

    const [formErrors, setFormErrors] = useState({});


    const handleSubmit = async (event) => {
        const form = event.currentTarget;        

        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            setDisabled(true);

            let msg = "Done...!";
            
            dispatch(setAlert({ open: true, severity: "success", msg: "Loading...", type: 'loader' })); 
            let resp = await addLocation(formData);
            setDisabled(false);

            if (resp.code === 200) {
                if(props.getList()){

                }
                dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                setFormData({});
                setValidated(false)
            } else {
                setDisabled(false);
                dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }

            return false;
        }
    };


    const handleChange = (post) => {

        let name = post.name;
        let event = post.event;
        let from = { ...formData };

        from[name] = event.target.value;

        setFormData({ ...formData, ...from });
    }

    useEffect(() => {

        if (props.editData && props.editData._id) {
            let edit = props.editData;
            setFormData({ _id: edit._id, name: edit.name, locationName: edit.locationName, shortListFor: edit.shortListFor, contactPerson: edit.contactPerson, contactNumber: edit.contactNumber, permission: edit.permission, locationTypeId: edit.locationTypeId  });
        }

    }, [props.editData])


    return (
        <>
            <div>
                <div className="col-12 w-6" style={{ margin: "auto" }}>
                    {/* <div className="modal-header teamBox">
                    </div> */}
                    {/* <h5 className="paraCenter mb-2 mt-3">{localStorage.getItem("projectName")}</h5> */}

                    <Form noValidate validated={validated} onSubmit={e => handleSubmit(e)}>

                        <Form.Group className="mb-2" controlId="name">
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                autoFocus
                                pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                                minLength={2}
                                maxLength={32}
                                className="frmcntadd "
                                value={formData.name ? formData.name : ""} onChange={e => handleChange({name:'name', event:e})} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Location name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-2" controlId="locationTypeId">
                        
                            <Form.Select aria-label="Default select example" className="filedbg   frmcntadd" value={formData.locationTypeId ? formData.locationTypeId : ""} onChange={e => handleChange({name:'locationTypeId', event:e})} required>
                                <option value="">Select Location Type</option>
                                {masters && masters.locationType && masters.locationType.length > 0 && masters.locationType.map((item, index) => {
                                    return <option value={item._id} key={index}>{item.name}</option>
                                })}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Location Type.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="shortListFor">
                            <Form.Control
                                type="text"
                                pattern = "^[A-Za-z]+(?: [A-Za-z]+)*$"
                                placeholder="shortList For"
                                className="frmcntadd "
                                value={formData.shortListFor ? formData.shortListFor : ""} onChange={e => handleChange({name:'shortListFor', event:e})}
                                minLength={2} maxLength={32} required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Short listFor.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="contactPerson">
                            <Form.Control
                                type="text"
                                placeholder="Contact Person Name"
                                minLength={2}
                                maxLength={32}
                                pattern = "^(?!0+$)(?!.*\s{2})(?!.*(?:([A-Za-z])\1.*){5})[A-Za-z0-9\s.]*[A-Za-z][A-Za-z0-9\s.]*$"
                                className="frmcntadd "
                                value={formData.contactPerson ? formData.contactPerson : ""} onChange={e => handleChange({name:'contactPerson', event: e})}  required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Contact Person.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="mobile">
                            <Form.Control
                                type="text"
                                placeholder="Contact No."
                                pattern='^[6-9][0-9]{9}$'
                                className="frmcntadd "
                                value={formData.contactNumber ? formData.contactNumber : ""} onChange={e => handleChange({name:'contactNumber', event:e})} minLength="10" maxLength="10" required />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Contact Number.
                            </Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group className="mb-2" controlId="permission">
                            <Form.Select aria-label="Default select example"   className="frmcntadd " onChange={e => handleChange({name:'permission', event: e})} value={formData.permission ? formData.permission : ""} required>

                                <option value="">Permission Require or Not</option>
                                {objToArray(Locationpermission).map((val, index) => {
                                    return <option value={val} key={index}>{val}</option>
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Row className="mt-4 mb-4 g-2">
                            <Col lg={6}>
                                <Button type="submit" className="lgbt lgbtTm bnt1 btnn btn btn-dark" variant="dark" disabled={isDisabled}>
                                    Save
                                </Button>
                            </Col>
                            {/* <Col lg={6} className="text-end  mt-2">
                                <Button type="submit" className="lgbt12 nextBtn" variant="light" onClick={NextPage} disabled={isDisabled}>
                                    Next
                                </Button></Col> */}
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default Location;