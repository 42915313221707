import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import { Card } from "react-bootstrap";

const FilmAssociations = ({ selected }) => {
    const filmAssociations = [
        {
            sn: 1,
            name: "Akhil Bharatiya Marathi Chitrapat Mahamandal",
            contact: "91 0231-2452460/91 0231-245460",
            email: "chitrapatmahamandal@gmail.com",
            address: "60 B Shri Ganesh Appt. In front of Deval Club, Khasbag, Kolhapur - 416001"
        },
        {
            sn: 2,
            name: "All India Film Employees Confederation",
            contact: "044-26213030",
            email: "fefkafed@gmail.com",
            address: "36, Jawaharlal Nehru Salai, 100 Feet Road, Bada Palani, Chennai - 600026"
        },
        {
            sn: 3,
            name: "Asian Society of Film And Television",
            contact: "91-120-4831100",
            email: "help@aaft.com",
            address: "Marwah Studios Complex FC-14/15, Sector-16A, Noida Film City, Uttar Pradesh - 201301"
        },
        {
            sn: 4,
            name: "Association of Cine & Television Art Directors",
            contact: "022 26742433",
            email: "productionexe103@gmail.com",
            address: "103, Kuber Complex, Opp. Laxmi Industrial Estate, New Link Road, Andheri (West), Mumbai - 400053"
        },
        {
            sn: 5,
            name: "Association of Cine & TV Ad Production Executives",
            contact: "022 26742433",
            email: "productionexe103@gmail.com",
            address: "103, Kuber Complex, Opp. Laxmi Industrial Estate, New Link Road, Andheri (West), Mumbai - 400053"
        },
        {
            sn: 6,
            name: "Association of Film & Video Editors",
            contact: "022 28792859",
            email: "afvemumbai@gmail.com",
            address: "Unit No. 203, 2nd floor, Plot No. D/11, New Link Road, Goregaon (West), Mumbai – 400104"
        },
        {
            sn: 7,
            name: "Association of Flim Set Material & Hirers",
            contact: "022-2353 4839/ 0381",
            email: "N/A",
            address: "103, Tulsiwadi, Tardeo, Mumbai-400034"
        },
        {
            sn: 8,
            name: "Association of Motion Picture Studios",
            contact: "022-2605-4093/94/3259 0653",
            email: "N/A",
            address: "Saba Palace, Office No. 401, 4th Floor, 4th Road, Khar (West), Mumbai-400050"
        },
        {
            sn: 9,
            name: "Association of Voice Artistes",
            contact: "022-26365675/66683107",
            email: "avindia.voice@gmail.com",
            address: "C/o Shri Vijay Chaudhary, 201, Amar Apartments, Amar Prem Society, J.P. Road, 7-Bungalows, Andheri (West), Mumbai-400061"
        },
        {
            sn: 10,
            name: "Bengal Film And Television Chamber of Commerce",
            contact: "081008 30003/098304 12449",
            email: "bftcc.kolkata@gmail.com",
            address: "184, Lenin Sarani, 1st Floor, Suite No.3, Kolkata – 700 013"
        },
        {
            sn: 11,
            name: "Chamber of Movie & TV Programme Producers",
            contact: "011 – 2550 4275",
            email: "cmtpp.delhiindia@gmail.com",
            address: "B-32, 1st Floor, Community Centre, Janak Puri, New Delhi – 110 058"
        },
        {
            sn: 12,
            name: "Cine & TV Artistes Association",
            contact: "022 26730511/26730513",
            email: "cintaamumbai@gmail.com",
            address: "221, Kartik Complex, 2nd Floor, Opp. Laxmi Industrial Estate, New Link Road, Andheri (W), Mumbai - 400053"
        },
        {
            sn: 13,
            name: "Cine Agents Combine",
            contact: "9892116140/9320719119",
            email: "N/A",
            address: "C/o Shri Pappu Lekhraj Nanda - 19, Nanda Bhavan, Bajaj Road, Vile Parle (West), Mumbai - 400056"
        },
        {
            sn: 14,
            name: "Cine Equipments & Studios Association",
            contact: "26054093/ 4",
            email: "N/A",
            address: "401, 4th Floor, Saba Palace, 4th Road, Khar (W), Mumbai-52"
        },
        {
            sn: 15,
            name: "Cine Music Directors Association",
            contact: "022-26375921",
            email: "N/A",
            address: "608, A Wing, Royal Sands, Opp. New Link Road, Andheri (W), Mumbai - 400053"
        },
        {
            sn: 16,
            name: "Cine Musicians Association",
            contact: "022-24454305",
            email: "cmamumbai@yahoo.co.in",
            address: "\"Poonam\", Ground Floor, Sitladevi Temple Road, Mahim, Mumbai - 400016"
        },
        {
            sn: 17,
            name: "Cine Singers Association",
            contact: "9769201122",
            email: "cinesingersassociation@rediffmail.com",
            address: "413/B, Mastermind 1, IT Park, Royal Palms Estate, Aarey Milk Colony Road, Goregaon (East), Mumbai - 400065"
        },
        {
            sn: 18,
            name: "Cine Still Photographers Association",
            contact: "022-26741671",
            email: "cspaindia@gmail.com",
            address: "662, Adarsh Nagar, Behind Hanuman Mandir, New Link Road, Oshiwara, Jogeshwari (W), Mumbai - 400102"
        },
        {
            sn: 19,
            name: "Cine, TV Artiste's & Workers Association",
            contact: "022-26730510/ 11/ 13",
            email: "N/A",
            address: "D-12, Ground Floor, Grace Plaza, S.V. Road, Jogeshwari (W), Mumbai - 400053"
        },
        {
            sn: 20,
            name: "Cinema Owners Exhibitors Association",
            contact: "022 - 2389 4026",
            email: "cinema.mumbai@gmail.com",
            address: "42,43, 1st Floor, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan Road, Mumbai - 400 004."
        },
        {
            sn: 21,
            name: "Docs & Shorts",
            contact: "022 – 2215 1612",
            email: "docsandshorts@gmail.com",
            address: "C-23, Usha Sadan, Near Colaba Post Office, Mumbai – 400 005"
        },
        {
            sn: 22,
            name: "Eastern India Motion Picture Association",
            phone: "033 - 2212 6605/06/07",
            email: "eimpacinema@gmail.com",
            address: "EIMPA House, 98E, B N Sircar Sarani, Kolkata - 700 072"
        },
        {
            sn: 23,
            name: "Federation of Western India Cine Employees",
            phone: "022-26730137/64509447/67023321",
            email: "federationcineemployees@yahoo.co.in",
            address: "113, 1st Floor, Kartik Complex, Opp. Laxmi Industrial Estate, New Link Road, Andheri (West), Mumbai - 400053"
        },
        {
            sn: 24,
            name: "Film Federation of India",
            phone: "022 23515531/ 98210-95649",
            email: "filmfed52@gmail.com",
            address: "Plot No. 3, 3rd Floor, Everest Building, Tardeo Road, Tardeo, Mumbai - 400034"
        },
        {
            sn: 25,
            name: "Film Makers Combine",
            phone: "022 26732868/ 26742892",
            email: "indiafilm@gmail.com",
            address: "G-1 To 7, Crescent Tower, Near Morya House, Opp. VIP Plaza, Link Road, Mumbai - 400053"
        },
        {
            sn: 26,
            name: "Film Studios Setting & Allied Mazdoor Union",
            phone: "28251526/28379343",
            email: "alliedmazdoorunion@gmail.com",
            address: "7, Teresa Triumph CHS., 56, Baman Puri Road, J.B. Nagar, Andheri (E), Mumbai - 400059"
        },
        {
            sn: 27,
            name: "Indian Council of Impex For Films & TV Programmers",
            phone: "022 6675 4082",
            email: "filmexporters@gmail.com",
            address: "201, Patel Estate, Opp. T series, above Evershine builders, Andheri West, Mumbai, Maharashtra 400053"
        },
        {
            sn: 28,
            name: "Indian Documentary Producers Association",
            phone: "022-2496 1020",
            email: "snpaindia@gmail.com",
            address: "223, Famous Cine Studio Building 20 Dr E. Moses Road, Mahalakshmi, Mumbai - 400 011"
        },
        {
            sn: 29,
            name: "Indian Film & Television Directors Association",
            phone: "022 6516 4283",
            email: "iftdaindia@gmail.com",
            address: "G-8/9/10, Crescent Tower, Opp. Infinity Mall, Off. New Link Road, Andheri (W), Mumbai - 400053"
        },
        {
            sn: 30,
            name: "Indian Film & Television Producers Council",
            phone: "022 26399740/ 742",
            email: "indianfilmtvproducerscouncil@gmail.com",
            address: "1201, Lotus Trade Centre, D.N. Nagar Metro Station, Opposite Star Bazaar, New Link Road, Andheri (West), Mumbai – 400 053"
        },
        {
            sn: 31,
            name: "Indian Film Dance Directors Association",
            phone: "022 65220351",
            email: "ifdda75@yahoo.com",
            address: "401, Vishaka Arcade, Veera Desai Road, Opp. Courtyard Restaurant Lane, Andheri (West), Mumbai - 400 053"
        },
        {
            sn: 32,
            name: "Indian Motion Picture Distributors Association",
            phone: "022 23884422",
            email: "mopsnis@gmail.com",
            address: "33-38, 1St Floor Vijay Chambers, Tribhuvan Road, Mumbai-400004"
        },
        {
            sn: 33,
            name: "Indian Motion Picture Producers' Association",
            phone: "022 26732868/ 26742892",
            email: "imppa1937@gmail.com",
            address: "G-1 To 7, Crescent Tower, Near Morya House, Opp. VIP Plaza, Link Road, Mumbai-400053"
        },
        {
            sn: 34,
            name: "Junior Artistes Association",
            phone: "",
            email: "",
            address: "Navalkar Wadi, Galla No. 3, Purshottam Com, Near Fish Market, Jogeshwari (East), Mumbai - 400 060"
        },
        {
            sn: 35,
            name: "Kannada Film Producers Association",
            phone: "080 2340 1386",
            email: "kfpa82@gmail.com",
            address: "S.V. Towers, 2nd Floor, Chord Rd, Jedara Halli, Rajaji Nagar, Bengaluru, Karnataka 560010"
        },
        {
            sn: 36,
            name: "Karnataka Film Chamber of Commerce",
            phone: "080 – 2226 5370 / 71",
            email: "karnatakafilmhouse@yahoo.com",
            address: "28, 1st Main, Crescent Road, High Ground, Bengaluru – 560 001"
        },
        {
            sn: 37,
            name: "Motion Pictures Association, India Office",
            phone: "022 61397000",
            email: "uday_singh@mpaa-india.org",
            address: "808, Aston, Sundervan Complex Road, Shastri Nagar, Andheri (West), Mumbai – 400 053"
        },
        {
            sn: 38,
            name: "Movie Action Dummy Effect Association",
            phone: "022 26844620",
            email: "madea@rediffmail.com",
            address: "B-31, Oshiwara Industrial Centre, Off. Link Road, Opp. Goregaon Bus Depot, Goregaon (West), Mumbai"
        },
        {
            sn: 39,
            name: "Movie Stunt Artistes Association",
            phone: "022 26735187/26732787",
            email: "moviestuntartist@gmail.com",
            address: "602-604, Dilkap Chambers, Veera Desai Indl. Estate, veera Desai Road, Andheri (West), Mumbai - 400 053"
        },
        {
            sn: 40,
            name: "Music Composers Association of India",
            phone: "022 26733757",
            email: "mcaimusic@gmail.com",
            address: "D-708, Crystal Plaza, Opp. Infinity Mall, New Link Road, Andheri (W), Mumbai - 400053"
        },
        {
            id: 41,
            name: "Northern India Motion Pictures Association",
            phone: "0181 - 2239930",
            email: "nimpajalandhar@gmail.com",
            address: "48, Pratap Road, Jalandhar City - 144 001"
        },
        {
            id: 42,
            name: "Rashtria Kamgar Union Film Artist Workers Association",
            phone: "022 29272367",
            email: "mumbaisandhyanews@gmail.com",
            address: "Mane Bhavan, Radium Apartment, Shreyas Colony, Goregaon (E), Mumbai - 63"
        },
        {
            id: 43,
            name: "ScreenWriters Association",
            phone: "022 26733027/26733108",
            email: "contact@swaindia.org",
            address: "201-204, Richa Building, Plot No. B-29, Off. New Link Road, Andheri (West), Mumbai - 400053"
        },
        {
            id: 44,
            name: "South Indian Film Exporters Association",
            phone: null,
            email: "sifeachennai1@gmail.com",
            address: "No.42, New Avadi Road, Kilpauk, Chennai - 600 010"
        },
        {
            id: 45,
            name: "Tamil Film Producers Council",
            phone: "044 2829 4780, 044 2829 5235",
            email: "info@tfpc.org",
            address: "Film Chamber Compound, Thousand Lights West, Thousand Lights, Chennai, Tamil Nadu 600006"
        },
        {
            id: 46,
            name: "Tamil Nadu Film Chamber of Commerce",
            phone: "044 2829 1507",
            email: "ccfea1976@gmail.com",
            address: "Anna Salai, Thousand Lights, Chennai, Tamil Nadu 600006"
        },
        {
            id: 47,
            name: "Telangana State Film Chamber of Commerce",
            phone: "040 23552150",
            email: "hsfcc41@gmail.com",
            address: "A-33, Road No.2, Above Vijaya Bank, Filmnagar, Jubliee Hills, Hyderabad-500 096"
        },
        {
            id: 48,
            name: "Telugu Film Chamber of Commerce",
            phone: "040 2360 7882",
            email: "info@apfilmchamber.com",
            address: "Dr. D. Ramanaidu Building, Film Nagar, Jubilee Hills, Hyderabad, Telangana 500096"
        },
        {
            id: 49,
            name: "Telugu Film Producers Council",
            phone: "040 23546108",
            email: "tfpchyd@gmail.com",
            address: "2nd Floor, Film Nagar Housing Society Comp., Film Nagar, Near Apollo Hospital, Hyderabad - 500096"
        },
        {
            id: 50,
            name: "The Film & Television Producers Guild of India Ltd.",
            phone: "91 22 26733065/68",
            email: "guild@filmtvguildindia.org",
            address: "1003-04, 10th Floor, Sri Krishna, Fun Republic Lane, New Link Road, Andheri (West), Mumbai 400 053"
        },
        {
            id: 51,
            name: "The Film & Television Producers Guild of South India",
            phone: "044 24334551/52",
            email: "guildsouth1234@gmail.com",
            address: "No 19/5 Rams Flat, Jagatheeswaran Street, T Nagar, Opposite to Hotel Mathyesa, Chennai - 600017"
        },
        {
            id: 52,
            name: "The Kerala Film Chamber of Commerce",
            phone: "0484 2351546",
            email: "kfccekm@gmail.com",
            address: "M.G. Road, Near Shenoys, Kochi, Kerala - 682035"
        },
        {
            id: 53,
            name: "The South Indian Film Chamber of Commerce",
            phone: "044 28291507",
            email: "sifcc1939@gmail.com",
            address: "604 & 605, Sundaram salai, Thousand Lights, T R Sundaram Avenue, near Gemini Flyover, Anna Salai, Chennai - 600006"
        },
        {
            id: 54,
            name: "Utkal Cine Chamber of Commerce",
            phone: "0671 243 2280",
            email: "dillip@tirumalaproductions.com",
            address: "Biswal Bagicha, 61, Telenga Bazar, Cuttack, Odisha 753009"
        },
        {
            id: 55,
            name: "Western India Cinematographers Association",
            phone: "022 26355396/26355926",
            email: "wicaoffice@yahoo.com",
            address: "Office No. 24, 2nd Floor, Om Heera Panna Arcade, Off. New Link Road, Behind Oshiwara Police Station, Oshiwara, Jogeshwari (West), Mumbai - 400 102"
        },
        {
            id: 56,
            name: "Western India Film Producers Association",
            phone: "022-2673-2960",
            email: "wiftpa1960@gmail.com",
            address: "206, Richa Building, 2nd Floor, Plot No. B-29, New Link Road, Andheri (West), Mumbai – 400 053"
        },
        {
            id: 57,
            name: "Western India Motion Picture & Television Sound Engineers Association",
            phone: "022 26744421/28410343/40038235",
            email: "wimptsea56@gmail.com",
            address: "A-223, 2nd Floor, Morya House, Veera Industrial Estate, Oshiwara Link Road, Andheri (West), Mumbai - 400053"
        },
        {
            id: 58,
            name: "Association of Advertising Producers",
            phone: "9619990000",
            email: "info@asapindia.in",
            address: "Andheri West, Mumbai - 400053"
        },
        {
            id: 59,
            name: "Shiv Sena Chitrapat Shakha",
            phone: "022-26557001",
            email: null,
            address: "G 9, Nootan Nagar Premises Chs, Guru Nanak Road, Bandra West, Mumbai - 400050, Near Bandra Talav"
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <HomeHeader />
            <section className="container" style={{ marginTop: "125px" }}>
                <Card>
                    <Card.Header>
                        <h2 className="text-center" >Film Org. & Associations</h2>
                    </Card.Header>
                    <table className="table table-striped mt-3">
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Film Association</th>
                                <th>Contact No.</th>
                                <th>Email</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filmAssociations.map((association, index) => (
                                <tr key={index}>
                                    <td>{association.sn}</td>
                                    <td>{association.name}</td>
                                    <td>{association.contact}</td>
                                    <td>{association.email}</td>
                                    <td>{association.address}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Card>
            </section>
            <HomeFooter />
        </Fragment >
    );
};

export default FilmAssociations;
